<template>
  <div>
    <span>SORT BY</span>
    <span class="mkb-clickable-selection" @click="sortColour(true)"
      ><AZ :class="{ active: $store.state.sort }"
    /></span>
    <span class="mkb-clickable-selection" @click="sortColour(false)"
      ><ColorPalette :class="{ active: !$store.state.sort }"
    /></span>
  </div>
</template>
<script>
import ColorPalette from "./svg/icons/ColorPalette";
import AZ from "./svg/icons/AZ";
export default {
  components: {
    ColorPalette,
    AZ
  },
  methods: {
    sortColour(value) {
      if (this.$store.state.sort === value) return;
      this.$store.commit("setSortColour", value);
    }
  }
};
</script>
<style lang="scss">
.sort {
  text-align: right;
  svg {
    margin: 0 5px 5px 5px;
    padding: 0 3px 0 3px;
  }
  svg.active {
    .st0 {
      fill: #002f6c !important;
    }
  }
  svg:hover {
    .st0 {
      fill: #002f6c !important;
    }
  }
  span {
    font-size: 0.7rem;
  }
}
@media (max-width: 767.98px) {
  .sort {
    text-align: center !important;
  }
}
</style>
