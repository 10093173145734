<template>
  <div class="w-100">
    <div class="mkb-loading-start" v-if="loading">
      <b-spinner variant="primary" label="Spinning"> </b-spinner>
    </div>
    <div class="mkb-step-container mkb-start-page" v-show="!loading">
      <div class="mkb-text-container d-flex flex-column">
        <div class="d-flex flex-column h-100">
          <div class="mkb-heading d-flex justify-content-center">
            <div class="mkb-logo-container">
              <div v-if="$store.state.tenant === 'steeline'">
                <img
                  src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/BMGM_CombinationMarque_White.png"
                  class="mkb-bmgm-logo"
                  alt="steeline"
                />
                <img
                  src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/logo-light.svg"
                  class="mkb-steeline-logo"
                  alt="steeline"
                />
              </div>

              <Logo v-else />
            </div>
          </div>
          <div class="mkb-main-text h-100 d-flex justify-content-center">
            <div class="align-self-center">
              <h1 class="mb-4">Mesh Kit Builder</h1>
              <div class="mkb-body-text text-center">
                With all-inclusive kits & specially tailored componentry, our
                gutter mesh is easy to install.
              </div>
            </div>
          </div>
        </div>
        <div class="p-2 p-md-0 pb-md-5 mb-5 mb-md-0 mkb-button-container">
          <b-button
            :to="{ name: 'installationtype' }"
            @click="interactEngagement()"
            class="py-4 py-md-3 mx-4 mx-md-0"
            >Begin</b-button
          >
          <template v-if="contactInformation[$store.state.tenant]">
            <h5 class="pt-5 mkb-info">
              {{ contactInformation[$store.state.tenant].email }}
            </h5>

            <h5 class="mkb-info">
              {{ contactInformation[$store.state.tenant].number }}
            </h5>
          </template>

          <!-- <b-link class="mkb-help-link" @click.prevent="showTypeform"
          >I need help in building my mesh kit</b-link
        > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "../assets/img/logo.svg";
import * as typeformEmbed from "@typeform/embed";
import { contactInformation } from "../data/tenants";
import axios from "axios";
export default {
  name: "Start",
  components: {
    Logo
  },
  data() {
    return {
      loading: true,
      contactInformation
      // VctrModelApi,
    };
  },
  mounted() {
    window.onload = () => {
      setTimeout(() => {
        this.loading = false;
        console.log(this.$store.state.tenant, "tenant");
        if (this.$store.state.tenant !== "localhost") {
          this.loadedEngagement();
        }
      }, 1000);
    };
    const API_URL = `${process.env.VUE_APP_API_UAT_ENDPOINT}/airtable/get-mkb-images-300`;
    axios
      .get(API_URL, {
        headers: {
          "api-key": process.env.VUE_APP_API_KEY
        }
      })
      .then(response => {
        console.log("response", response);
        // Handle successful response
        const filteredDomeImages = {};
        const filteredRollImages = {};
        const filteredProfileImages = {};
        const filteredScrewImages = {};
        const filteredTrimImages = {};
        const filteredSolarImages = {};
        for (const [key, value] of Object.entries(response.data.data)) {
          if (key.includes("domed-valley-mesh")) {
            // this is for domeImages
            filteredDomeImages[key] = value;
            this.$store.commit("setDomeImages", filteredDomeImages);
          }

          if (key.includes("mm-")) {
            //this is for rollImages
            filteredRollImages[key] = value;
            this.$store.commit("setRollImages", filteredRollImages);
          }

          if (
            key.includes("-profile-") ||
            key.includes("-saddle-") ||
            key.includes("tile-clips-galvanise")
          ) {
            //this is for profileImages
            filteredProfileImages[key] = value;
            this.$store.commit("setProfileImages", filteredProfileImages);
          }

          if (key.includes("screws-")) {
            //this is for screwImages
            filteredScrewImages[key] = value;
            this.$store.commit("setScrewImages", filteredScrewImages);
          }

          if (key.includes("gutter-trim")) {
            //this is for trimImages
            filteredTrimImages[key] = value;
            this.$store.commit("setTrimImages", filteredTrimImages);
          }

          if (key.includes("solar panel mesh clip")) {
            //this is for trimImages
            filteredSolarImages[key] = value;
            this.$store.commit("setSolarImages", filteredSolarImages);
          }
        }
      })
      .catch(error => {
        // Handle error
        console.log(error);
      });
  },
  methods: {
    async loadedEngagement() {
      const API_ENDPOINT = `${process.env.VUE_APP_API_ENDPOINT}/tool/add-engagement`;

      try {
        const payloads = {
          tenant: this.$store.state.tenant,
          engagement: 1,
          tool: "mkb"
        };

        await axios.post(API_ENDPOINT, payloads, {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async interactEngagement() {
      if (this.$store.state.tenant === "localhost") return;
      const API_ENDPOINT = `${process.env.VUE_APP_API_ENDPOINT}/tool/add-engagement`;

      try {
        const payloads = {
          tenant: this.$store.state.tenant,
          engagement: 2,
          tool: "mkb"
        };

        await axios.post(API_ENDPOINT, payloads, {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    showTypeform() {
      const reference = typeformEmbed.makePopup(
        "https://bmco497112.typeform.com/to/JqBujCw2",
        {
          mode: "popup",
          autoClose: 3000,
          hideHeaders: true,
          hideFooters: true
        }
      );
      reference.open();
    }
  }
};
</script>

<style lang="scss">
.mkb-loading-start {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 60px;
  line-height: 60px;
  margin-left: -100px;
  margin-top: -30px;
  text-align: center;
}
mesh-kit-builder[tenant="bunnings"],
mesh-kit-builder[tenant="steeline"] {
  .mkb-logo-container {
    visibility: visible !important;
    .mkb-bmgm-logo {
      width: 50%;
    }
    .mkb-steeline-logo {
      width: 330px;
      padding: 20px;
      @media (max-width: 767px) {
        width: 50%;
        padding: 10px;
      }
    }
  }
}
.mkb-start-page {
  display: flex;
  justify-content: center !important;
  text-align: center;
  color: #ffffff;
  background-color: #002f6c;
  background-image: url("https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/start-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;

  .mkb-heading {
    margin-bottom: 65px;
  }

  .mkb-logo-container {
    width: 60%;
    height: auto;
    path {
      fill: #ffffff;
    }
    visibility: hidden;
  }

  .mkb-text-container {
    align-self: center !important;
  }

  .mkb-main-text {
    margin-bottom: 110px;
  }

  .mkb-body-text {
    font-size: 1.45rem;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  .btn {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.35rem;
    background-color: #ffffff;
    color: #002f6c;
    padding: 0.9rem 7.25rem;
    border: 0 !important;

    &:hover {
      background-color: #d3d3d3;
      color: #002f6c;
    }
  }

  .mkb-info {
    color: #ffffff !important;
    display: block;
    font-size: 1rem;
  }
  .mkb-help-link {
    padding-top: 1.9rem;
    text-decoration: underline;
    color: #ffffff !important;
    display: block;
    font-size: 1.1rem;
  }

  h1 {
    font-size: 3.25rem;
    color: #ffffff !important;
  }

  @media (max-width: 767.98px) {
    .mkb-logo-container {
      width: 65%;
    }

    .mkb-heading {
      margin-top: 100px;
      margin-bottom: 0px;
    }

    h1 {
      font-size: 2.35rem;
      color: #ffffff !important;
    }

    .mkb-text-container {
      height: 100%;
    }

    .mkb-body-text {
      color: #a3becc;
      width: 75%;
      text-align: left;
      font-size: 0.95rem;
      padding-bottom: 120px;
    }

    .btn {
      display: block;
    }

    .mkb-main-text {
      margin-bottom: 0;
    }

    .mkb-button-container {
      margin-top: auto;
    }

    background-image: url("https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/start-bg-mobile.webp");
  }
}
</style>
