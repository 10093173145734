<template>
  <div id="app" :class="{ 'top-border': $route.name !== 'start' }" class="mb-5">
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import VueScrollTo from "vue-scrollto";

export default {
  name: "App",
  computed: {
    step() {
      return this.$store.state.step;
    },
    enquiry() {
      return this.$store.state.enquiry;
    },
    roof() {
      return this.$store.state.roof;
    },
    products() {
      return this.$store.state.new_products;
    }
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/3779244.js";
    script.id = "hs-script-loader";
    document.body.appendChild(script);
    setTimeout(() => {
      this.$store.commit(
        "setTenant",
        document.querySelector("mesh-kit-builder").getAttribute("tenant")
      );
      this.$store.commit("setProducts");
    }, 500);
  },
  watch: {
    step(value) {
      if (_.isNull(value)) {
        return;
      } else if (this.$store.state.enquiry) {
        switch (this.$store.state.enquiry) {
          case "custom-kit-form":
            this.$router.push({ name: "enquiry" });
            break;
          case "thank-you":
            this.$router.push({ name: "thankyou" });
            break;
          case "email-form":
            this.$router.push({ name: "emailmemykitform" });
            break;
          case "contact-form":
            this.$router.push({ name: "contactourteamform" });
            break;
          default:
            this.$router.push({ name: "form" });
        }
      } else if (this.$store.getters.steps[value]) {
        this.$router.push({
          name: this.$store.getters.steps[value].name
        });
        this.$nextTick(() => {
          VueScrollTo.scrollTo(".active", {
            container: "#mkb-horizontal-menu",
            offset: -20,
            x: true,
            y: false
          });
        });
      } else {
        if (value > this.$store.getters.steps.length - 1) {
          if (this.$store.state.otherRoof) {
            this.$router.push({ name: "enquiry" });
          } else {
            this.$router.push({ name: "code" });
          }
        }
      }
    },
    roof(value) {
      if (_.isNull(value)) {
        this.$store.state.installationType = null;
      }
    }
  }
};
</script>
