<template>
  <div class="mkb-installation-method-page">
    <h4 class="mb-4 mb-md-5 text-left">
      Choose your installation method
    </h4>
    <b-row class="d-flex">
      <b-col md="6" class="mkb-install-method-container" v-if="hasSaddle">
        <div
          class="mkb-clickable-container"
          @click.prevent="setMethod('Saddles')"
        >
          <div class="mkb-image-container">
            <img
              src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/klip-lok-install-saddles.png"
              alt="Standard Gutter"
            />
          </div>
          <h4 class="mt-3">
            With Saddles
          </h4>
        </div>
        <div class="mkb-info">
          <span class="mkb-info-head">The quickest installation method!</span>
          <b-list-group
            class="mkb-info-list py-4 mx-auto text-left"
            v-if="$store.state.roof === 'Klip-Lok'"
          >
            <p class="mkb-info-sub-head">Suitable for:</p>
            <b-row class="pl-2">
              <b-col class="p-0">
                <b-list-group-item class="border-0 pr-1 pl-2 py-0"
                  >• Lysaght® Klip-Lok® Hi Strength</b-list-group-item
                >
                <b-list-group-item class="border-0 pr-1 pl-2 py-0"
                  >• Lysaght® Klip-Lok® 406</b-list-group-item
                >
                <b-list-group-item class="border-0 pr-1 pl-2 py-0"
                  >• Lysaght® Klip-Lok® Classic</b-list-group-item
                >
                <b-list-group-item class="border-0 pr-1 pl-2 py-0"
                  >• Fielders KingKlip®</b-list-group-item
                >
              </b-col>
              <b-col class="p-0">
                <b-list-group-item class="border-0 pr-1 pl-2 py-0"
                  >• Metroll Metlok®</b-list-group-item
                >
                <b-list-group-item class="border-0 pr-1 pl-2 py-0"
                  >• Revolution Revklip</b-list-group-item
                >
                <b-list-group-item class="border-0 pr-1 pl-2 py-0"
                  >• Stramit Speed Deck Ultra®</b-list-group-item
                >
              </b-col>
            </b-row>
          </b-list-group>
        </div>
      </b-col>
      <b-col md="6" class="mkb-install-method-container" v-if="hasTraditional">
        <h1
          class="mkb-stratco-info-prodek"
          v-if="
            $store.state.roof === 'Prodek' || $store.state.roof === 'Spacedek®'
          "
        >
          The installation method for this roof profile is a screw-based install
        </h1>
        <div
          class="mkb-clickable-container"
          @click.prevent="setMethod('Traditional (screws only)')"
        >
          <div class="mkb-image-container">
            <img
              src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/klip-lok-install-screws.png"
              alt="Install Screws"
            />
          </div>
          <div
            class="text-left mt-3 d-none d-lg-block"
            v-if="
              $store.state.roof === 'Prodek' ||
                $store.state.roof === 'Spacedek®'
            "
          >
            <div class="mkb-next-button py-1 px-4 btn btn-primary">
              <div class="description">
                NEXT
              </div>
            </div>
          </div>
          <h4 class="mt-3" v-else>Traditional (screws only)</h4>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "InstallationMethod",
  components: {},
  data() {
    return {
      hovering: null
    };
  },
  computed: {
    hasSaddle() {
      return this.$store.getters.products.some(
        item => item.installationType === "Saddles"
      );
    },
    hasTraditional() {
      return this.$store.getters.products.some(
        item => item.installationType === "Traditional (screws only)"
      );
    }
  },
  methods: {
    setHover(hover) {
      this.hovering = hover;
    },
    setMethod(type) {
      this.$store.commit("setInstallationType", type);
      this.$store.commit("advance");
    }
  }
};
</script>

<style lang="scss">
.mkb-stratco-info-prodek {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 20px;
  color: #002f6c;
}
.mkb-info-list {
  width: 80%;
  font-size: 11px;
  color: #707070;
  .list-group-item {
    height: 15px !important;
  }
}
.mkb-info {
  span.mkb-info-head {
    font-size: 12px;
    font-weight: bold;
  }
  p.mkb-info-sub-head {
    font-size: 12px;
  }
}
.mkb-installation-method-page {
  text-align: center;

  h4 {
    color: #002f6c;
  }

  h2,
  h5 {
    color: #002f6c;
  }

  .row {
    img {
      display: block;
      width: 100%;
      height: 360px;
      border: 0 !important;
      object-fit: cover;
    }
    h5 {
      color: #002f6c !important;
      text-align: center;
      margin: 15px 0px 0px 0px;
    }
  }

  .mkb-image-container {
    &:hover {
      filter: brightness(0.75);
    }
  }

  @media (max-width: 767.98px) {
    h2 {
      font-size: 1.6rem;
      text-align: left !important;
    }

    .mkb-image-container {
      max-height: 160px;
      overflow: hidden;

      img {
        margin-top: -30%;
      }
    }
    h5 {
      margin-top: 10px !important;
    }
    .mkb-install-method-container {
      margin-bottom: 20px;
    }
  }
}
</style>
