var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mkb-container"},[_c('div',{staticClass:"mkb-kit-wrapper"},[_c('b-col',{staticClass:"p-0 position-relative",attrs:{"md":"6","cols":"6"}},[_c('img',{staticClass:"mkb-mesh-image",class:{
          'mkb-no-profile':
            _vm.$store.state.valley.includes('Domed') &&
            _vm.$store.state.installationType !== 'Saddles'
        },attrs:{"src":_vm.meshImage,"alt":"Mesh Roll"}})]),_c('b-col',{staticClass:"mkb-profile-kit pl-1"},[_c('div',{staticClass:"d-flex position-relative"},[(
            !_vm.$store.state.valley.includes('Domed') &&
              _vm.$store.state.installationType !== 'Traditional (screws only)'
          )?_c('img',{staticClass:"mkb-profile-image",class:{
            'mkb-pitch-fin': _vm.$store.state.valley.includes('Multi-Pitch'),
            'mkb-klip-lok': _vm.$store.state.installationType === 'Saddles',
            'mkb-saddle':
              !_vm.$store.state.valley.includes('Multi-Pitch') &&
              !_vm.$store.state.installationType
          },attrs:{"src":_vm.profileImage,"alt":"Profile"}}):_vm._e(),_c('img',{staticClass:"mkb-screw-image",attrs:{"src":_vm.screwImage,"alt":"Screw"}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }