// export const domeImages = color => {
//   switch (color) {
//     case "woodland-grey":
//       return "https://drive.google.com/uc?export=view&id=1PH-2xwV1C3y9IqUR2GXqg4WiThCwstcP";
//     case "zincalume":
//       return "https://drive.google.com/uc?export=view&id=1lHJHuDndIWBEGpwjUz25cS1eHd7bVlsJ";
//     case "windspray":
//       return "https://drive.google.com/uc?export=view&id=1mghcDrl7Uco6aRUtvCG2mRpXTVGJHLSU";
//     case "wallaby":
//       return "https://drive.google.com/uc?export=view&id=1eGca9KJDakmckbZkjCH1nZmZySaGZsI5";
//     case "wilderness":
//       return "https://drive.google.com/uc?export=view&id=1cnq0_jpl6Sz1XCtPJEco2Y-qOCZfYYHP";
//     case "surfmist":
//       return "https://drive.google.com/uc?export=view&id=1jeRZPjUstHea8Efh0-FVxH7shlrkSjyI";
//     case "terrain":
//       return "https://drive.google.com/uc?export=view&id=1RQ3iP4yaBCNiAGoilPAcymEidtzowTxF";
//     case "sandbank":
//       return "https://drive.google.com/uc?export=view&id=1sV3qVI7zdg0nnnF3gQNESV7rZPPULkS";
//     case "shale-grey":
//       return "https://drive.google.com/uc?export=view&id=1_wssm5mIA6LqsbIZTvl_uLUDWdfjVnBd";
//     case "pale-terra":
//       return "https://drive.google.com/uc?export=view&id=142FljJ2glGNCpcX1qQpYs6YH7j-bdTgT";
//     case "paperbark":
//       return "https://drive.google.com/uc?export=view&id=12G6wWbtN52EFCYbagt6PzX6VrCBm9dg2";
//     case "night-sky":
//       return "https://drive.google.com/uc?export=view&id=1msNg4mgHUg2AIUmOkRH2iX8EAg82GaTf";
//     case "pale-eucal":
//       return "https://drive.google.com/uc?export=view&id=1rHSzJ07yPI_srTzdAyU4C_kjH_YlxfN1";
//     case "mangrove":
//       return "https://drive.google.com/uc?export=view&id=15TncE8fn5X8A3rqAuWy1dGUrqW4OP-ZI";
//     case "manor-red":
//       return "https://drive.google.com/uc?export=view&id=1DOQafbZWV0FNZ3yiZF1-vvRQrsuZZSmG";
//     case "monument":
//       return "https://drive.google.com/uc?export=view&id=1CzuTZzATB7P_euJgnGD1zUMs866RdwYD";
//     case "jasper":
//       return "https://drive.google.com/uc?export=view&id=1eDAME63EWvplAI-zmeFSl8kDR8BBgvHq";
//     case "loft":
//       return "https://drive.google.com/uc?export=view&id=1aLAuqzXuc3m51CtVAjW8bf-sLb69ud0c";
//     case "headland":
//       return "https://drive.google.com/uc?export=view&id=1hD3___uj8bguW7QqyKsZyGYe74qqKMbu";
//     case "ironstone":
//       return "https://drive.google.com/uc?export=view&id=1fowP22z5CAFT1ZWYoDy_6vJ7rMPunuak";
//     case "galvanise":
//       return "https://drive.google.com/uc?export=view&id=1C4Z4Sxf8FkzNqwuAkvlvUo-mfRe3c1xG";
//     case "gully":
//       return "https://drive.google.com/uc?export=view&id=17NASlzT__jGDu9ssL6RoGeF42BSQGmot";
//     case "dune":
//       return "https://drive.google.com/uc?export=view&id=1XCxJ9CKgjgvgx1eDDsK6xqUHWIUqvJ3W";
//     case "evening-haze":
//       return "https://drive.google.com/uc?export=view&id=1ngAkXPGX2NKr4kmSfdjaxfx9iee8-Ai3";
//     case "deep-ocean":
//       return "https://drive.google.com/uc?export=view&id=1pGUFwcX1NDrHxd041QyxZSQBx-qOba3j";
//     case "classic-cream":
//       return "https://drive.google.com/uc?export=view&id=17uBdJuO_2qIweh-vcB81yu5x38iTBmyn";
//     case "cottage-green":
//       return "https://drive.google.com/uc?export=view&id=1IODZl-GJiRKyv1C3tlEiVtm2kD6IyqUg";
//     case "cove":
//       return "https://drive.google.com/uc?export=view&id=1n11oV_xceEea_MgC4J9TbH14bk2Pm4xX";
//     case "basalt":
//       return "https://drive.google.com/uc?export=view&id=1GLfBgX1hF2qYIgvzV8oOmQyQ24TagUcC";
//     case "bushland":
//       return "https://drive.google.com/uc?export=view&id=1TbF-WepG4xysU30sYohkvoyMUlhi3bc5";
//     default:
//       return "https://drive.google.com/uc?export=view&id=1C4Z4Sxf8FkzNqwuAkvlvUo-mfRe3c1xG";
//   }
// };
import store from "../store";
export const domeImages = color => {
  // console.log('color used', store.state.domeImages[color.toUpperCase()])
  if (color === "domed-valley-mesh-null")
    return store.state.domeImages["domed-valley-mesh-zincalume"];
  return store.state.domeImages[color];
};
