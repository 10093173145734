<template>
  <div>
    <div class="mkb-valley-type-page">
      <h4 class="text-primary mb-4 mb-md-5 text-left">
        Choose a valley installation type
      </h4>
      <b-row cols="1" :cols-md="valleyTypes.length" class="w-100 m-0">
        <b-col
          class="mkb-c-col mb-5 mb-md-0 px-2 "
          v-for="valley in valleyTypes"
          :key="valley"
        >
          <div
            class="mkb-clickable-container "
            @click="setValley(valley)"
            @mouseover="setHover(valley)"
            @mouseout="setHover(null)"
          >
            <div class="mkb-valley-image-container rounded">
              <template v-if="valley === 'Corrugated Valley Multi-Pitch Fin'">
                <b-img
                  src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/corro-valley-mpf-main.png"
                  class="mkb-valley-img"
                  :hovered="hovering === valley"
                  alt=""
                />
              </template>
              <template v-else-if="valley === 'Valley Roll'">
                <b-img
                  v-if="$store.state.roof === 'Trimdek'"
                  src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/valley-roll-trimdek-main-2.png"
                  class="mkb-valley-img"
                  :hovered="hovering === valley"
                >
                </b-img>
                <b-img
                  v-else-if="$store.state.roof === 'Tile'"
                  src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/valley-roll-tile-main-2.jpg"
                  class="mkb-valley-img"
                  :hovered="hovering === valley"
                >
                </b-img>
                <b-img
                  v-else
                  src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/valley-roll-main.jpg"
                  class="mkb-valley-img"
                  :hovered="hovering === valley"
                  alt=""
                />
              </template>
              <template v-else-if="valley === 'Domed Valley'">
                <b-img
                  src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/domed-valley-main.JPG"
                  class="mkb-valley-img"
                  :hovered="hovering === valley"
                  alt=""
                />
              </template>
            </div>
          </div>
          <h4>
            {{ valley }}
            <b-link
              class="mkb-info-link"
              @click="showValleyModal(valley)"
              v-if="$store.state.tenant !== 'steeline'"
            >
              <tooltip></tooltip>
            </b-link>
          </h4>
        </b-col>
        <!-- <b-col
          class="mkb-c-col d-none d-md-flex"
          v-if="$store.getters.valleyTypes.length === 1"
        >
          <div class="mt-auto d-flex mkb-illustration-purposes-only">
            <div class="mr-2">
              <font-awesome-icon :icon="['fas', 'caret-left']" />
            </div>
            <div>
              <div>
                {{ $store.getters.valleyTypes[0] }} installation on corrugated
                roof.
              </div>
              <div>For illustration purposes only.</div>
            </div>
          </div>
        </b-col> -->
      </b-row>
    </div>
    <ValleyInfoModal ref="valleyInfoModal"></ValleyInfoModal>
  </div>
</template>

<script>
import Tooltip from "../components/svg/icons/Tooltip";
import ValleyInfoModal from "../components/ValleyInfoModal";
export default {
  name: "ValleyType",
  components: {
    Tooltip,
    ValleyInfoModal
  },
  data() {
    return {
      hovering: null,
      otherValleyTypeOptions: ["Valley Roll", "Domed Valley"]
    };
  },
  computed: {
    valleyTypes() {
      if (this.$store.state.otherRoof) return this.otherValleyTypeOptions;
      return this.$store.getters.valleyTypes;
    }
  },
  methods: {
    setHover(hover) {
      this.hovering = hover;
    },
    setValley(valley) {
      this.$store.commit("setValley", valley);
      this.$store.commit("advance");
    },
    showValleyModal(valley) {
      this.$refs.valleyInfoModal.show(valley);
    }
  }
};
</script>

<style lang="scss">
.mkb-valley-type-page {
  .mkb-info-link {
    svg {
      padding-bottom: 5px;
    }
    svg:hover {
      .st0 {
        fill: #002f6c;
      }
    }
  }
  .mkb-valley-img {
    width: 100%;
    height: 320px;
    object-fit: cover;
    border-radius: 8px;
  }
  .mkb-valley-image-container {
    margin-bottom: 15px;
  }

  .mkb-clickable-container:hover {
    .mkb-valley-image-container {
      filter: brightness(0.75);
    }
  }

  h4 {
    color: #002f6c;
    margin: 0;
    font-size: 1.6rem;
  }

  .mkb-illustration-purposes-only {
    color: #707070;
    font-size: 0.8rem;
    padding-bottom: 40px;
  }

  @media (max-width: 767.98px) {
    h4 {
      font-size: 1.2rem !important;
    }

    .mkb-c-col {
      .mkb-valley-img {
        height: 35vw;
      }
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
