<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      filename="Results"
      :paginate-elements-by-height="1100"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- page 1 -->
        <section class="m-4 p-4">
          <section v-if="$store.state.tenant === '4933348840'">
            <StratcoLogo />
          </section>
          <section class=" w-50 mx-auto my-5">
            <h2 class="mkb-pdf-header">
              Here's your kit:
            </h2>
            <h3 class="mkb-instruction">
              {{ PDFcontent[$store.state.tenant] }}
            </h3>
          </section>
          <section>
            <div class="w-50 mx-auto my-5">
              <div class="mkb-info-row">
                <div class="mkb-info-label">Product Code</div>
                <h1 class="mkb-info-value mkb-info-value-code">
                  {{ $store.getters.products[0].code }}
                </h1>
              </div>
              <div class="mkb-info-row">
                <div class="mkb-info-label">Description</div>
                <div class="mkb-info-value mkb-info-value-description">
                  {{ $store.getters.products[0].description }}
                </div>
              </div>
              <div class="mkb-info-row" v-if="$store.getters.method">
                <div class="mkb-info-label">
                  Installation Method
                </div>
                <div class="mkb-info-value mkb-info-value-meshcolour">
                  {{ $store.getters.method }}
                  <span v-if="$store.getters.method === 'Klip-Lok'">®</span>
                </div>
              </div>

              <div class="mkb-colour-rows mb-3 mb-md-0">
                <div class="mkb-info-row">
                  <div class="mkb-info-label-colour align-self-center">
                    Mesh Colour
                  </div>
                  <div class="mkb-info-value mkb-info-value-meshcolour">
                    <inline-colour
                      :colour="$store.state.meshColour"
                    ></inline-colour>
                  </div>
                </div>
                <div
                  class="mkb-info-row"
                  v-if="
                    $store.state.trim !== 'No Trim' &&
                      $store.state.trim !== null
                  "
                >
                  <div class="mkb-info-label-colour align-self-center">
                    Trim Colour
                  </div>
                  <div
                    class="mkb-info-value info-value-trimcolour align-self-center"
                  >
                    <inline-colour
                      :colour="$store.state.trimColour"
                    ></inline-colour>
                  </div>
                </div>
              </div>
              <div
                class="mkb-length-rows my-3 mb-md-0"
                v-if="$store.state.type.includes('Solar')"
              >
                <div class="mkb-info-row">
                  <div class="mkb-info-label-length align-self-center">
                    Horizontal Measurement
                  </div>
                  <div class="mkb-info-value mkb-info-value-horizontal-length">
                    {{ $store.state.panelWidth }} metres
                  </div>
                </div>
                <div class="mkb-info-row">
                  <div class="mkb-info-label-length align-self-center">
                    Vertical Measurement
                  </div>
                  <div class="mkb-info-value mkb-info-value-vertical-length">
                    {{ $store.state.panelHeight }} metres
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="">
            <div class="mkb-kit-image-wrapper p-2">
              <div
                :class="{
                  'mkb-image-wrapper-solar': $store.state.type.includes(
                    'Solar'
                  ),
                  'mkb-image-wrapper':
                    $store.state.type.includes('Standard') ||
                    $store.state.type.includes('Valley') ||
                    $store.state.type.includes('Gutter')
                }"
              >
                <Kit />
              </div>
            </div>
          </section>
        </section>
        <!-- end of page 1 -->
      </section>
    </vue-html2pdf>
    <div
      class="mkb-copy-button mkb-clickable-container mb-2 mkb-non-standard"
      @click="generatePDF()"
    >
      Download PDF
    </div>
    <HubspotModalBeforePDFDownload
      ref="hubspotModal"
      @emitDownload="downloadPDF"
    ></HubspotModalBeforePDFDownload>
    <div class="w-100 text-center">
      <vue-topprogress ref="topProgress"></vue-topprogress>
    </div>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import Kit from "../components/Kit";
import InlineColour from "../components/InlineColour";
import { PDFcontent } from "../data/tenants";
import axios from "axios";
import HubspotModalBeforePDFDownload from "../components/HubspotModalBeforePDFDownload.vue";
import StratcoLogo from "../components/svg/logos/Stratco.vue";
export default {
  components: {
    VueHtml2pdf,
    Kit,
    InlineColour,
    HubspotModalBeforePDFDownload,
    StratcoLogo
  },
  data() {
    return {
      PDFcontent
    };
  },
  methods: {
    async beforeDownload({ html2pdf, pdfContent }) {
      // var opt = {
      //   enableLinks: true,
      //   filename: "Results.pdf",
      //   image: { type: "png", quality: 1 },
      //   html2canvas: {
      //     dpi: 192,
      //     scale: 4,
      //     letterRendering: true,
      //     useCORS: true
      //   },
      //   jsPDF: {
      //     unit: "mm",
      //     format: "a4",
      //     orientation: "portrait"
      //   }
      // };
      const opt = {
        filename: "Results.pdf",
        image: { type: "png", quality: 1 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait"
        },
        documentProperties: { title: "My PDF" }
      };

      // pdf to download
      await html2pdf()
        .set(opt)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setTextColor("#002f6c");
            pdf.setFontSize(10);
            pdf.text(
              "Page " + i + " of " + totalPages,

              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 5
            );

            // pdf.text(
            //   'To view more tools, visit www.bluemountainco.com.au/?',
            //   pdf.internal.pageSize.getWidth() * 0.33,
            //   pdf.internal.pageSize.getHeight() - 5
            // );
            // pdf.addImage(
            //   imgData,
            //   'PNG',
            //   pdf.internal.pageSize.getWidth() * 0.03,
            //   pdf.internal.pageSize.getHeight() - 11,
            //   39,
            //   11
            // );
          }
        })
        .save();
    },
    generatePDF() {
      this.downloadPDF();
      // if (this.$store.state.tenant !== "localhost") {
      //   this.$refs.hubspotModal.show();
      // } else {
      //   this.downloadPDF();
      // }
    },
    async downloadEngagement() {
      if (this.$store.state.tenant === "localhost") return;
      const API_ENDPOINT = `${process.env.VUE_APP_API_ENDPOINT}/tool/add-engagement`;

      try {
        const payloads = {
          tenant: this.$store.state.tenant,
          engagement: 4,
          tool: "mkb"
        };

        await axios.post(API_ENDPOINT, payloads, {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async downloadPDF() {
      this.$refs.topProgress.start();
      await this.$refs.html2Pdf.generatePdf();
      await this.downloadEngagement();
      this.$refs.topProgress.done();
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-pdf-header {
  color: #002f6c !important;
  font-size: 18px !important;
}
.mkb-instruction {
  color: #000 !important;
  font-size: 12px !important;
}
.mkb-kit-image-wrapper {
  width: 350px;
  margin: 0 auto;
  border: 1px solid #707070;
  img {
    max-width: 100%;
    height: auto;
  }
  .mkb-image-wrapper-solar {
    position: relative;
    width: 100%;
    padding-top: 100%;
    div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .mkb-image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;

    div {
      position: absolute;
      top: 2;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
.mkb-non-standard {
  background-color: #ffffff;
  border: 1px solid #002f6c;
  color: #002f6c;
  text-align: center;
  padding: 15px 24px;
  font-weight: 500;

  path {
    fill: #002f6c;
  }

  &:hover {
    color: #ffffff;
    background-color: #002f6c;
    path {
      fill: #ffffff;
    }
  }
}
//info
.mkb-info-row {
  display: flex;
  margin-bottom: 15px;
}

.mkb-info-row:last-child {
  margin-bottom: 0;
}
.mkb-info-label {
  width: 130px !important;
  color: #707070;
  font-size: 0.75rem;
  margin-right: 15px;
}
.mkb-info-label-length,
.mkb-info-label-colour {
  width: 145px !important;
  color: #707070;
  font-size: 0.75rem;
  margin-right: 15px;
}
.mkb-info-value {
  width: 100%;
  color: #002f6c;
}

.mkb-info-value-code {
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 1;
}
.mkb-info-value-description {
  font-size: 0.75rem;
}
</style>
