<template>
  <div class="mkb-container">
    <div class="mkb-kit-wrapper">
      <b-col class="p-0 position-relative" md="6" cols="6">
        <img
          :src="meshImage"
          alt="Mesh Roll"
          class="mkb-mesh-image"
          :class="{
            'mkb-no-profile':
              $store.state.valley.includes('Domed') &&
              $store.state.installationType !== 'Saddles'
          }"
        />
      </b-col>
      <b-col class="mkb-profile-kit pl-1">
        <div class="d-flex position-relative">
          <img
            v-if="
              !$store.state.valley.includes('Domed') &&
                $store.state.installationType !== 'Traditional (screws only)'
            "
            :src="profileImage"
            alt="Profile"
            class="mkb-profile-image"
            :class="{
              'mkb-pitch-fin': $store.state.valley.includes('Multi-Pitch'),
              'mkb-klip-lok': $store.state.installationType === 'Saddles',
              'mkb-saddle':
                !$store.state.valley.includes('Multi-Pitch') &&
                !$store.state.installationType
            }"
          />
          <img :src="screwImage" alt="Screw" class="mkb-screw-image" />
        </div>
      </b-col>
    </div>
  </div>
</template>
<script>
import { rollImages, profileImages, screwImages } from "../../data/rollImages";
import { domeImages } from "../../data/domeImages";
export default {
  computed: {
    meshImage() {
      // return dome if true, else roll
      const mesh = {
        "2mm Steel": "steel-2mm",
        "4mm Steel": "steel-4mm",
        "5.4mm Steel": "steel-5 4mm",
        "2mm Aluminium": "aluminium-2mm",
        "4mm Aluminium": "aluminium-4mm",
        "4mm Aluminium Ultra": "aluminium-4mm"
      };
      if (!this.domedValleyChecker())
        return domeImages("domed-valley-mesh-" + this.$store.state.meshColour);
      return rollImages(
        `${mesh[this.$store.state.mesh]}-${this.$store.state.meshColour}`,
        mesh[this.$store.state.mesh]
      );
      // if (!this.domedValleyChecker())
      //   return domeImages("domed-valley-mesh-" + this.$store.state.meshColour);
      // return rollImages(
      //   this.$store.state.mesh,
      //   this.$store.state.meshColour,
      //   this.$store.state
      // );
    },
    profileImage() {
      const profile = {
        Corrugated: {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "CGI Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "Cooldek® Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        Trimdek: "trimdek-saddle",
        Superdek: "trimdek-saddle",
        "Cooldek® Classic": "trimdek-saddle",
        SteelClad: "trimdek-saddle",
        "Klip-Lok": "kliplok-saddle",
        "Lokdek 680/700": "kliplok-saddle",
        "Topdek® 700": "kliplok-saddle",
        Bullnose: "bullnose-profile",
        Spandek: "spandek-profile",
        Steelspan: "spandek-profile",
        Smartspan: "spandek-profile",
        Decramastic: "decramastic-profile",
        Longspan: "longspan-profile",
        Tile: "tile-clips"
      };
      const mesh = this.$store.state.mesh.includes("Aluminium")
        ? "Aluminium"
        : "Steel";
      return profileImages(
        profile[this.$store.state.roof],
        this.$store.state.meshColour,
        mesh
      );
      // return profileImages(
      //   this.$store.state.roof,
      //   this.$store.state.meshColour,
      //   this.$store.state
      // );
    },
    screwImage() {
      return screwImages(this.$store.state.meshColour);
    }
  },
  methods: {
    domedValleyChecker() {
      let valleyTypes = this.$store.getters.valleyTypes;
      if (
        valleyTypes.find(element => element === "Domed Valley") !== undefined &&
        valleyTypes.length === 1
      )
        return false;
      return true;
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .mkb-kit-wrapper {
    display: flex;
    flex-wrap: wrap;
    .mkb-profile-kit {
      padding: 0 13px 0 13px;
    }
    img.mkb-mesh-image,
    .mkb-profile-image,
    .mkb-screw-image {
      width: 100%;
    }
    .mkb-no-profile {
      position: absolute;
      left: 20px;
    }
    .mkb-saddle {
      margin-top: 70px;
    }
    .mkb-pitch-fin {
      margin-top: 60px;
    }
    .mkb-klip-lok {
      margin-top: 50px;
    }
    @media (max-width: 767px) {
      .mkb-saddle {
        width: 80%;
        margin-top: 160px;
      }
      .mkb-pitch-fin {
        width: 80%;
        margin-top: 130px;
      }
      .mkb-klip-lok {
        width: 80%;
        margin-top: 115px;
      }
      img.mkb-screw-image {
        width: 80% !important;
        top: 115px !important;
        left: 80px !important;
      }
    }
    img.mkb-screw-image {
      width: 100%;
      margin-top: 85px;
      transform: rotateZ(90deg);
      position: absolute;
      top: 2px;
      left: 40px;
    }
    img.mkb-screw-image:first-child {
      left: 10px !important;
    }
    img.mkb-long-profile {
      width: 50%;
      margin-left: 10px;
    }
  }
}
</style>
