export const browse = [
  {
    tenant: "bmco",
    url: "https://bluemountainmesh.com.au/products/"
  },
  {
    tenant: "bmco-www",
    // url: "https://aumarketplace.bluemountainco.com/collections/gutter-mesh"
    //TEMPORARY URL
    url: "https://bluemountainmesh.com.au/products/"
  },
  {
    tenant: "bunnings",
    url: "https://bluemountainmesh.com.au/products/"
  },
  {
    tenant: "demo",
    url: "https://bluemountainmesh.com.au/products/"
  },
  {
    tenant: "mitre-10-Strathalbyn",
    url: "https://bluemountainmesh.com.au/products/"
  },
  {
    tenant: "steeline",
    url: "https://bluemountainmesh.com.au/products/"
  },
  {
    tenant: "4933348840",
    url: "https://bluemountainmesh.com.au/products/"
  }
];
export const forms = {
  bmco: {
    contactFormId: "5d38d5b6-8ff4-4fe8-8925-8473e1d509ba",
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "134e967a-1a54-4b58-ab1c-40e085775dd9",
    otherRoofFormId: "9cc0d7b9-69e2-49e1-b1e4-91b5fcf45c62",
    solarPanelFormId: "f0c10d09-dedc-458c-9f37-1add6664e235",
    contactUsFormId: "7fdd866a-f46c-4a49-831d-0c3c696a8010",
    meshTypeFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    valleyFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    galvanizedFormId: "5d38d5b6-8ff4-4fe8-8925-8473e1d509ba",
    meshLockFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    formBeforeDownload: "cbbe4aaf-2ab7-4b1c-9888-d8fa97365265"
  },
  "bmco-www": {
    contactFormId: "5d38d5b6-8ff4-4fe8-8925-8473e1d509ba",
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "134e967a-1a54-4b58-ab1c-40e085775dd9",
    otherRoofFormId: "9cc0d7b9-69e2-49e1-b1e4-91b5fcf45c62",
    solarPanelFormId: "f0c10d09-dedc-458c-9f37-1add6664e235",
    contactUsFormId: "7fdd866a-f46c-4a49-831d-0c3c696a8010",
    meshTypeFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    valleyFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    galvanizedFormId: "5d38d5b6-8ff4-4fe8-8925-8473e1d509ba",
    meshLockFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    formBeforeDownload: "cbbe4aaf-2ab7-4b1c-9888-d8fa97365265"
  },
  bunnings: {
    contactFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "bb70fc57-2be2-41e4-9492-59e46c20cf64",
    otherRoofFormId: "a0e0aaca-b0ed-498e-9ce8-bd0fd068bc8f",
    solarPanelFormId: "816bd4d4-07e6-4441-8653-de4c6dc221bd",
    contactUsFormId: "7fdd866a-f46c-4a49-831d-0c3c696a8010",
    meshTypeFormId: "48442a4f-722f-43eb-9791-f046831c115b",
    valleyFormId: "48442a4f-722f-43eb-9791-f046831c115b",
    galvanizedFormId: "bacc62b6-96fa-48af-b1d8-c67ef46cda48",
    meshLockFormId: "48442a4f-722f-43eb-9791-f046831c115b",
    formBeforeDownload: "cfe5d875-062a-4e3e-ad64-1aef4e112090"
  },
  demo: {
    contactFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "d78d17be-3ef4-4c02-baee-420b512e4198",
    otherRoofFormId: "ddc961e7-61ca-4650-8e80-4254a554c526",
    solarPanelFormId: "525da955-a0a5-43f8-b7f5-cefdc4722405",
    contactUsFormId: "7fdd866a-f46c-4a49-831d-0c3c696a8010",
    meshTypeFormId: "5cf808b6-e2da-4d96-958c-f9f382b481eb",
    valleyFormId: "5cf808b6-e2da-4d96-958c-f9f382b481eb",
    galvanizedFormId: "482215c7-04ff-470d-b1f8-985bd68fc9af",
    meshLockFormId: "5cf808b6-e2da-4d96-958c-f9f382b481eb",
    formBeforeDownload: "0bc158f4-fffc-425a-b545-76cd69118d99"
  },
  "mitre-10-Strathalbyn": {
    contactFormId: "9035aac0-66e1-4efc-971c-0a3e01c6851a", //! * contact us - galvanized
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "35f208df-5cb3-4959-bc55-2f022d7dd852", //!
    otherRoofFormId: "2b27d2c1-ee59-4290-9cc7-5cee7e5bc87d", //!
    solarPanelFormId: "088cd650-2d03-4cea-a605-5a841c18c3f9", //!
    contactUsFormId: "e4e3aa7a-1510-4e22-9cf3-e6d7ea71a0ca", //! * contact us - aluminium galvanized
    meshTypeFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    valleyFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    galvanizedFormId: "9035aac0-66e1-4efc-971c-0a3e01c6851a",
    meshLockFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    quoteFormId: "e3bbcf2b-69a3-450c-ac95-e0b5b0188bb7", //!
    solarPanelQuoteFormId: "abfa1a65-80ac-4514-9561-956a66e935d8",
    formBeforeDownload: "bceaf449-4e48-41dd-9b4e-17d571822727"
  },
  steeline: {
    contactFormId: "d405d145-93f8-4e3f-9b32-8ff5ef3a6f06", //! * contact us - galvanized
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "d15b6236-90a4-47da-bca8-a607244e91dc", //! email me my result
    otherRoofFormId: "2b27d2c1-ee59-4290-9cc7-5cee7e5bc87d", //!
    solarPanelFormId: "7d53a5fb-fe3e-483c-add0-3c04f380ac5d", //!
    contactUsFormId: "e4e3aa7a-1510-4e22-9cf3-e6d7ea71a0ca", //! * contact us - aluminium galvanized
    meshTypeFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    valleyFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    galvanizedFormId: "d405d145-93f8-4e3f-9b32-8ff5ef3a6f06",
    meshLockFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    quoteFormId: "613067ba-4898-4b1a-a9c4-2811f0dec00d", //!
    solarPanelQuoteFormId: "a2062373-8df7-4ea2-9033-1c9cd4b7821b",
    formBeforeDownload: "760e3e39-42f5-40f6-b4e1-27b76475ebce"
  },
  "4933348840": {
    contactFormId: "5ca5cd42-a15d-4738-87ed-9092ec69c0e3", //! * contact us - galvanized
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "4da3e021-8516-4e79-94c8-e257d26ea1b5", //! email me my result
    otherRoofFormId: "2b27d2c1-ee59-4290-9cc7-5cee7e5bc87d", //!
    solarPanelFormId: "a474ae31-56ee-4785-817c-8b267a4e11b1", //!
    contactUsFormId: "e4e3aa7a-1510-4e22-9cf3-e6d7ea71a0ca", //! * contact us - aluminium galvanized
    meshTypeFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    valleyFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    galvanizedFormId: "5ca5cd42-a15d-4738-87ed-9092ec69c0e3", //! * contact us - galvanized
    meshLockFormId: "68ad6235-8fc6-4fcf-bafc-981a93fab27b", //! Contact Us – Mesh Types / Valley / Mesh Lock Trims
    quoteFormId: "1e839ecd-be6c-40dd-b570-42f422023792", //!
    solarPanelQuoteFormId: "30aea1dd-1dcb-48e8-adf7-2ad1e9623aa1",
    formBeforeDownload: "a58c4346-306b-4838-9bf9-8ae40d5c8427"
  },
  "display-mkb": {
    meshTypeFormId: "b26b4948-4fbd-4a83-afa6-e168e66ed44d",
    valleyFormId: "b26b4948-4fbd-4a83-afa6-e168e66ed44d",
    galvanizedFormId: "b26b4948-4fbd-4a83-afa6-e168e66ed44d",
    meshLockFormId: "b26b4948-4fbd-4a83-afa6-e168e66ed44d",
    //updated
    formBeforeDownload: "7ca3cb13-4741-4888-ae83-1f7e8cee5a4a", // download pdf
    quoteFormId: "087d54eb-6034-4b36-b1ef-78991fca7c6a", //get a quote not solar
    solarPanelFormId: "23c89e23-c975-45e2-865f-ae6a07c161cc", // solar panel custom
    contactUsFormId: "b26b4948-4fbd-4a83-afa6-e168e66ed44d", //
    emailFormId: "f74c2677-96fc-4d49-ba6a-24a8ed11aa15",
    otherRoofFormId: "1f86b553-5baa-4d92-9347-4707a052a758",
    solarPanelQuoteFormId: "c0184451-25aa-4b3d-bda7-d38e27f258d6"
  },
  localhost: {
    contactFormId: "5d38d5b6-8ff4-4fe8-8925-8473e1d509ba",
    needHelpFormId: "5dcee8e6-f98c-4e3f-93d2-1577a2134719",
    emailFormId: "134e967a-1a54-4b58-ab1c-40e085775dd9",
    otherRoofFormId: "9cc0d7b9-69e2-49e1-b1e4-91b5fcf45c62",
    solarPanelFormId: "f0c10d09-dedc-458c-9f37-1add6664e235",
    contactUsFormId: "7fdd866a-f46c-4a49-831d-0c3c696a8010",
    meshTypeFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    valleyFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    galvanizedFormId: "5d38d5b6-8ff4-4fe8-8925-8473e1d509ba",
    meshLockFormId: "a2d67dc1-be90-4d1b-af87-6391a2e4b456",
    formBeforeDownload: null
  }
};

export const installationTypes = {
  bmco: [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],

  "bmco-www": [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],
  bunnings: [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],
  demo: [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],
  "mitre-10-Strathalbyn": [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],
  steeline: [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],
  "4933348840": [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],
  "display-mkb": [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ],
  localhost: [
    {
      name: "Standard Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-standard-gutter.jpg"
    },
    {
      name: "Valley",
      img: "https://tools.bmco.com.au/mkb/img/install-type-valley.jpg"
    },
    {
      name: "Box Gutter",
      img: "https://tools.bmco.com.au/mkb/img/install-type-box-gutter.jpg"
    },
    {
      name: "Solar Panel",
      img: "https://tools.bmco.com.au/mkb/img/install-type-solar-panel.jpg"
    }
  ]
};

export const orderedRoofTypes = {
  bmco: [
    "Corrugated",
    "Tile",
    "Trimdek",
    "Klip-Lok",
    "Bullnose",
    "Spandek",
    "Longspan",
    "Decramastic",
    "Ember Guard",
    "Other"
  ],
  "bmco-www": [
    "Corrugated",
    "Tile",
    "Trimdek",
    "Klip-Lok",
    "Bullnose",
    "Spandek",
    "Longspan",
    "Decramastic",
    "Ember Guard",
    "Other"
  ],
  bunnings: [
    "Corrugated",
    "Tile",
    "Trimdek",
    "Klip-Lok",
    "Bullnose",
    "Spandek",
    "Longspan",
    "Decramastic",
    "Ember Guard",
    "Other"
  ],
  demo: [
    "Corrugated",
    "Tile",
    "Trimdek",
    "Klip-Lok",
    "Bullnose",
    "Spandek",
    "Longspan",
    "Decramastic",
    "Ember Guard",
    "Other"
  ],
  "mitre-10-Strathalbyn": [
    "Corrugated",
    "Tile",
    "Trimdek",
    "Klip-Lok",
    "Bullnose",
    "Spandek",
    "Longspan",
    "Decramastic",
    "Ember Guard",
    "Other"
  ],
  steeline: [
    "Corrugated",
    "Tile",
    "SteelClad",
    "Lokdek 680/700",
    "Bullnose",
    "Steelspan",
    "Decramastic",
    "Steel Rib 500",
    "Steel Rib 675",
    "Steeline Hi Dek"
  ],
  "4933348840": [
    "CGI Corrugated",
    "Cooldek® Corrugated",
    "Tile",
    "Superdek",
    "Cooldek® Classic",
    "Smartspan",
    "Topdek® 700",
    "Prodek",
    "Maximus 22",
    "Maximus 33",
    "Spacedek®"
  ],
  "display-mkb": [
    "Corrugated",
    "Tile",
    "Trimdek",
    "Klip-Lok",
    "Bullnose",
    "Spandek",
    "Longspan",
    "Decramastic",
    "Ember Guard",
    "Other"
  ],
  localhost: [
    "Corrugated",
    "Tile",
    "Trimdek",
    "Klip-Lok",
    "Bullnose",
    "Spandek",
    "Longspan",
    "Decramastic",
    "Ember Guard",
    "Other"
  ]
};

export const contactInformation = {
  bmco: { email: "info@bmco.com.au", number: "+617 3248 9600" },
  "bmco-www": { email: "info@bmco.com.au", number: "+617 3248 9600" },
  bunnings: { email: "info@bmco.com.au", number: "+617 3248 9600" },
  demo: { email: "info@bmco.com.au", number: "+617 3248 9600" },
  "mitre-10-Strathalbyn": {
    email: "hello@strathalbynmitre10.com.au",
    number: "08 8536 2065"
  },
  steeline: null,
  "display-mkb": { email: "info@bmco.com.au", number: "+617 3248 9600" },
  "4933348840": null
};

export const PDFcontent = {
  bmco: null,
  "bmco-www": null,
  bunnings:
    "Take this PDF to the Special Orders desk at your local Bunnings to order",
  demo: null,
  "mitre-10-Strathalbyn":
    "Please take this to the front counter at Strathalbyn Mitre 10.",
  steeline: "Take this to the staff at your local Steeline store",
  "display-mkb": "",
  "4933348840": "Take this to the staff at your local Stratco store"
};
export const thankyouContent = {
  bmco:
    "Our team will be in touch as soon as possible. <br /> You can restart the tool to create another mesh kit, or browse through our product range.",
  "bmco-www":
    "Our team will be in touch as soon as possible. <br /> You can restart the tool to create another mesh kit, or browse through our product range.",
  bunnings:
    "Our team will be in touch as soon as possible. <br /> You can restart the tool to create another mesh kit, or browse through our product range.",
  demo:
    "Our team will be in touch as soon as possible. <br /> You can restart the tool to create another mesh kit, or browse through our product range.",
  "mitre-10-Strathalbyn":
    "The BMCo Team will review your requirements and be in touch as soon as possible so that you can organise a quote or place your order with Mitre 10 Strathalbyn.",
  steeline: {
    quoteFormId:
      "Your selected store will be in touch as soon as possible. <br /> You can restart the tool to create another mesh kit, or browse through our product range.",
    emailFormId:
      "Your kit will be in your inbox in a few minutes. <br /> You can restart the tool to create another mesh kit, or browse through our product range."
  },
  "display-mkb":
    "Our team will be in touch as soon as possible. <br /> You can restart the tool to create another mesh kit, or browse through our product range.",
  "4933348840": {
    quoteFormId:
      "Your selected store will be in touch as soon as possible. <br /> You can restart the tool to create another mesh kit, or browse through our product range.",
    emailFormId:
      "Your kit will be in your inbox in a few minutes. <br /> You can restart the tool to create another mesh kit, or browse through our product range."
  }
};
