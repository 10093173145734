var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mkb-sidebar-menu"},[_c('ul',{staticClass:"step-menu mb-0 pl-0"},_vm._l((_vm.dataSteps.steps),function(step,index){return _c('li',{key:step.name,staticClass:"step",class:step.name === _vm.$route.name ? 'active' : ''},[_c('div',{staticClass:"d-flex",style:(_vm.$store.state.step > index ? 'cursor: pointer' : ''),on:{"click":function($event){return _vm.goBackSpecificStep(index)}}},[_c('div',{staticClass:"mkb-step-number d-flex align-self-center justify-content-center",class:_vm.$store.state.step > index ? 'completed' : ''},[(_vm.$store.state.step <= index)?_c('span',{staticClass:"d-block align-self-center"},[_vm._v(_vm._s(index + 1))]):_c('span',{staticClass:"d-block align-self-center"},[_c('div',{staticStyle:{"width":"26px","height":"auto"}},[_c('check')],1)])]),_c('div',{staticClass:"mkb-step-name align-self-center text-uppercase"},[_vm._v(" "+_vm._s(step.title)+" ")])]),_c('div',{staticClass:"mkb-step-selection"},[(_vm.$store.state[step.sets] && step.title.includes('Colour'))?[(_vm.$store.getters.colours[_vm.$store.state[step.sets]])?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"align-self-center mr-2"},[_vm._v(" "+_vm._s(_vm.$store.getters.colours[_vm.$store.state[step.sets]].name)),(_vm.$store.getters.colours[_vm.$store.state[step.sets]].hasTm)?_c('span',[_vm._v("™")]):_vm._e(),(_vm.$store.getters.colours[_vm.$store.state[step.sets]].hasR)?_c('span',[_vm._v("®")]):_vm._e()]),_c('div',{staticClass:"mkb-color-swatch align-self-center",style:('background-color: ' +
                  _vm.$store.getters.colours[_vm.$store.state[step.sets]].code +
                  ';')})]):_c('div',[_vm._v("Not available")])]:(_vm.$store.state[step.sets] && step.title.includes('Width'))?[_vm._v(" "+_vm._s(_vm.$store.state[step.sets].standard ? "Standard" : "Non-Standard")+" "+_vm._s(_vm.$store.state[step.sets].width)+"mm ")]:(
            _vm.$store.state[step.sets] && step.title.includes('Measurement')
          )?[_vm._v(" "+_vm._s(_vm.$store.state[step.sets])+"m ")]:(_vm.$store.state[step.sets])?[(_vm.$store.state[step.sets] === 'Klip-Lok')?_c('span',[_vm._v(_vm._s(_vm.$store.state[step.sets])+"® or Flat Pan")]):(
              _vm.$store.state[step.sets] === 'Trimdek' ||
                _vm.$store.state[step.sets] === 'Spandek' ||
                _vm.$store.state[step.sets] === 'Longspan'
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$store.state[step.sets])+"® ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$store.state[step.sets])+" ")])]:_vm._e()],2)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }