<template>
  <div class="mkb-mesh-colour-page">
    <b-row>
      <b-col md="5" class="d-md-block pr-3 mkb-mesh-kit-image-container">
        <Kit />
        <div class="text-center mt-3 d-none d-lg-block">
          <div
            class="mkb-next-button py-1 px-4 btn btn-primary"
            @click.prevent="setColour()"
            :class="selectedColour === null ? 'disabled' : ''"
          >
            <div class="description">
              NEXT
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="p-1">
        <div>
          <h3 class="text-primary">Choose your trim colour</h3>
          <div class="text-center my-2 d-lg-none">
            <div
              class="mkb-next-button py-2 px-4 btn btn-primary btn-block"
              @click.prevent="setColour()"
              :class="selectedColour === null ? 'disabled' : ''"
            >
              <div class="description">
                NEXT
              </div>
            </div>
          </div>
          <b-row align-h="between">
            <b-col>
              <div>
                <p>Select to suit your gutter.</p>
              </div>
            </b-col>
            <b-col class="sort">
              <ColourFilter />
            </b-col>
          </b-row>
        </div>
        <div class="mkb-colour-listing">
          <div
            class="mkb-colour-item"
            v-for="(colour, key) in newColours"
            :key="colour.name"
          >
            <colour
              :colour="colour"
              @click="selectColour(colour, key)"
              @hover="hoverColour(key)"
              @mouseleave="mouseLeave"
              :selectedColour="selectedColour"
              :displayAsterisk="false"
            ></colour>
          </div>
        </div>
        <div class="mkb-colour-info">
          <p class="mkb-small-colour-text">
            COLORBOND&#174; and all colour names used are registered trade marks
            and &#8482; colour names are trade marks of BlueScope Steel Limited.
            &copy; 2022 BlueScope Steel Limited ABN 16 000 011 058. All rights
            registered.
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Colour from "../components/Colour";
import ColourFilter from "../components/ColourFilter";
import Kit from "../components/Kit";
export default {
  name: "TrimColour",
  components: {
    Colour,
    Kit,
    ColourFilter
  },
  data() {
    return {
      selectedColour: null,
      allColours: this.$store.getters.colours
    };
  },
  computed: {
    newColours() {
      let newColours = { ...this.allColours };
      //sorting
      if (this.$store.state.sort) {
        newColours = Object.keys(newColours)
          .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: newColours[key]
            }),
            {}
          );
      }
      //end of sorting
      //reducing
      delete newColours["pale-terra"];
      delete newColours["galvanise"];
      return newColours;
    }
  },

  methods: {
    setColour() {
      this.$store.commit("setTrimColour", this.selectedColour.key);
      this.$store.commit("advance");
    },
    selectColour(colour, key) {
      this.selectedColour = { colour, key };
    },
    hoverColour(colour) {
      this.$store.commit("setTrimColour", colour);
    },
    mouseLeave() {
      if (!this.selectedColour) return;
      this.$store.commit("setTrimColour", this.selectedColour.key);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.trim === "No Trim") {
        vm.$router.push({ name: "code" });
      }
    });
  }
};
</script>
