export const steelColours = {
  surfmist: {
    hasR: true,
    name: "Surfmist",
    code: "#dddacc"
  },
  "classic-cream": {
    hasTm: true,
    name: "Classic Cream",
    code: "#e8d5af"
  },
  sandbank: {
    hasR: true,
    name: "Sandbank",
    code: "#d1b988"
  },
  paperbark: {
    hasR: true,
    name: "Paperbark",
    code: "#c6b69a"
  },
  cove: {
    hasTm: true,
    name: "Cove",
    code: "#a69f8a"
  },
  "evening-haze": {
    hasR: true,
    name: "Evening Haze",
    code: "#c4c2aa"
  },
  dune: {
    hasR: true,
    name: "Dune",
    code: "#b2ada3"
  },
  gully: {
    hasTm: true,
    name: "Gully",
    code: "#867e72"
  },
  wallaby: {
    hasR: true,
    name: "Wallaby",
    code: "#7f7d79"
  },
  jasper: {
    hasR: true,
    name: "Jasper",
    code: "#685d50"
  },
  "pale-terra": {
    hasR: true,
    name: "Pale Terracotta",
    code: "#c89975",
    hasTwoAsterisks: true
  },
  terrain: {
    hasR: true,
    name: "Terrain",
    code: "#67442f"
  },
  headland: {
    hasR: true,
    name: "Headland",
    code: "#a95045"
  },
  "manor-red": {
    hasR: true,
    name: "Manor Red",
    code: "#67120c"
  },
  loft: {
    hasR: true,
    name: "Loft",
    code: "#43383c"
  },
  bushland: {
    hasR: true,
    name: "Bushland",
    code: "#959485"
  },
  mangrove: {
    hasR: true,
    name: "Mangrove",
    code: "#747663"
  },
  "pale-eucal": {
    hasR: true,
    name: "Pale Eucalypt",
    code: "#73826a"
  },
  wilderness: {
    hasR: true,
    name: "Wilderness",
    code: "#66887a"
  },
  "cottage-green": {
    hasR: true,
    name: "Cottage Green",
    code: "#084a39"
  },
  // galvanise: {
  //   name: "Galvanised",
  //   code: "#cdd4d7",
  //   hasAsterisk: true
  // },
  zincalume: {
    name: "Zinc",
    code: "#b3b2b3"
    // hasAsterisk: true
  },
  "shale-grey": {
    hasTm: true,
    name: "Shale Grey",
    code: "#b1b6b1"
  },
  windspray: {
    hasR: true,
    name: "Windspray",
    code: "#7b8181"
  },
  basalt: {
    hasR: true,
    name: "Basalt",
    code: "#6d6d6f"
  },
  "woodland-grey": {
    hasR: true,
    name: "Woodland Grey",
    code: "#414440"
  },
  ironstone: {
    hasR: true,
    name: "Ironstone",
    code: "#343e4c"
  },
  "deep-ocean": {
    hasR: true,
    name: "Deep Ocean",
    code: "#263b50"
  },
  monument: {
    hasR: true,
    name: "Monument",
    code: "#2d2e32"
  },
  "night-sky": {
    hasR: true,
    name: "Night Sky",
    code: "#000000"
  }
};
export const steelColoursGalvanisedNotAvailable = {
  surfmist: {
    hasR: true,
    name: "Surfmist",
    code: "#dddacc"
  },
  zincalume: {
    name: "Zinc",
    code: "#b3b2b3"
    // hasAsterisk: true
  },
  "shale-grey": {
    hasTm: true,
    name: "Shale Grey",
    code: "#b1b6b1"
  },
  dune: {
    hasR: true,
    name: "Dune",
    code: "#b2ada3"
  },
  windspray: {
    hasR: true,
    name: "Windspray",
    code: "#7b8181"
  },
  wallaby: {
    hasR: true,
    name: "Wallaby",
    code: "#7f7d79"
  },
  basalt: {
    hasR: true,
    name: "Basalt",
    code: "#6d6d6f"
  },
  "woodland-grey": {
    hasR: true,
    name: "Woodland Grey",
    code: "#414440"
  },
  "deep-ocean": {
    hasR: true,
    name: "Deep Ocean",
    code: "#263b50"
  },
  ironstone: {
    hasR: true,
    name: "Ironstone",
    code: "#343e4c"
  },
  monument: {
    hasR: true,
    name: "Monument",
    code: "#2d2e32"
  },
  "night-sky": {
    hasR: true,
    name: "Night Sky",
    code: "#000000"
  },
  "evening-haze": {
    hasR: true,
    name: "Evening Haze",
    code: "#c4c2aa"
  },
  paperbark: {
    hasR: true,
    name: "Paperbark",
    code: "#c6b69a"
  },
  cove: {
    hasTm: true,
    name: "Cove",
    code: "#a69f8a"
  },
  bushland: {
    hasR: true,
    name: "Bushland",
    code: "#959485"
  },
  "classic-cream": {
    hasTm: true,
    name: "Classic Cream",
    code: "#e8d5af"
  },
  sandbank: {
    hasR: true,
    name: "Sandbank",
    code: "#d1b988"
  },
  gully: {
    hasTm: true,
    name: "Gully",
    code: "#867e72"
  },
  jasper: {
    hasR: true,
    name: "Jasper",
    code: "#685d50"
  },
  "pale-terra": {
    hasR: true,
    name: "Pale Terracotta",
    code: "#c89975",
    hasTwoAsterisks: true
  },
  headland: {
    hasR: true,
    name: "Headland",
    code: "#a95045"
  },
  "manor-red": {
    hasR: true,
    name: "Manor Red",
    code: "#67120c"
  },
  terrain: {
    hasR: true,
    name: "Terrain",
    code: "#67442f"
  },
  mangrove: {
    hasR: true,
    name: "Mangrove",
    code: "#747663"
  },
  "pale-eucal": {
    hasR: true,
    name: "Pale Eucalypt",
    code: "#73826a"
  },
  wilderness: {
    hasR: true,
    name: "Wilderness",
    code: "#66887a"
  },
  "cottage-green": {
    hasR: true,
    name: "Cottage Green",
    code: "#084a39"
  },
  loft: {
    hasR: true,
    name: "Loft",
    code: "#43383c"
  },

  galvanise: {
    name: "Galvanised",
    code: "#cdd4d7",
    hasAsterisk: true
  }
};
