<template>
  <b-container class="step-container mkb-installation-type-page">
    <div class="header">
      <page-header></page-header>
    </div>
    <h4 class="mb-3 d-none d-md-block">Mesh Kit Builder</h4>
    <h2 class="mb-4 mb-md-5">Choose your installation type</h2>
    <b-row class="d-flex justify-content-center">
      <b-col
        md="3"
        class="mkb-install-type-container"
        v-for="(type, index) in $store.getters.getInstallationTypes"
        :key="index"
      >
        <div
          class="mkb-clickable-container-start"
          @click.prevent="setType(type.name)"
        >
          <div class="mkb-image-container-start">
            <img :src="type.img" :alt="type.name" />
          </div>
          <h5>{{ type.name }}</h5>
        </div>
      </b-col>
      <!--
      <b-col md="3" class="mkb-install-type-container">
        <div
          class="mkb-clickable-container-start"
          @click.prevent="setType('Standard Gutter')"
        >
          <div class="mkb-image-container-start">
            <img :src="standardImgUrl" alt="Standard Gutter" />
          </div>
          <h5>Standard Gutter</h5>
        </div>
      </b-col>

      <b-col md="3" class="mkb-install-type-container">
        <div
          class="mkb-clickable-container-start"
          @click.prevent="setType('Valley')"
        >
          <div class="mkb-image-container-start">
            <img :src="valleyImgUrl" alt="Valley" />
          </div>
          <h5>Valley</h5>
        </div>
      </b-col>
      <b-col md="3" class="mkb-install-type-container">
        <div
          class="mkb-clickable-container-start"
          @click.prevent="setType('Box Gutter')"
        >
          <div class="mkb-image-container-start">
            <img :src="boxGutterImgUrl" alt="Box Gutter" />
          </div>
          <h5>Box Gutter</h5>
        </div>
      </b-col>
      <b-col md="3" class="mkb-install-type-container">
        <div
          class="mkb-clickable-container-start"
          @click.prevent="setType('Solar Panel')"
        >
          <div class="mkb-image-container-start">
            <img :src="solarPanel" alt="Solar Panel" />
          </div>
          <h5>Solar Panel</h5>
        </div>
      </b-col> -->
    </b-row>
  </b-container>
</template>

<script>
import PageHeader from "../components/PageHeader";
export default {
  name: "InstallationType",
  components: {
    PageHeader
  },
  data() {
    return {
      standardImgUrl: require("../assets/img/install-type-standard-gutter.jpg"),
      valleyImgUrl: require("../assets/img/install-type-valley.jpg"),
      boxGutterImgUrl: require("../assets/img/install-type-box-gutter.jpg"),
      solarPanel: require("../assets/img/install-type-solar-panel.jpg")
    };
  },
  created() {
    this.$store.commit("reset");
  },
  methods: {
    setType(type) {
      this.$store.commit("setType", type);
      this.$store.commit("advance");
    }
  }
};
</script>

<style lang="scss">
.mkb-clickable-container-start {
  cursor: pointer;
}
.mkb-installation-type-page {
  text-align: center;

  h4 {
    color: #758892;
  }

  h2,
  h5 {
    color: #002f6c;
  }

  .row {
    img {
      display: block;
      width: 100%;
      height: auto;
      border: 0 !important;
    }
    h5 {
      color: #002f6c !important;
      text-align: center;
      margin: 15px 0px 0px 0px;
    }
  }

  .mkb-clickable-container-start:hover {
    .mkb-image-container-start {
      filter: brightness(0.75);
    }
  }

  @media (max-width: 767.98px) {
    h2 {
      font-size: 1.6rem;
      text-align: left !important;
    }

    .mkb-image-container-start {
      max-height: 160px;
      overflow: hidden;

      &:hover {
        box-shadow: 0px 0px 6px 3px #a4d6e7;
      }

      img {
        margin-top: -30%;
      }
    }
    h5 {
      margin-top: 10px !important;
    }
    .mkb-install-type-container {
      margin-bottom: 20px;
    }
  }
}
</style>
