<template>
  <b-modal
    ref="mkbGalvanisedModal"
    size="md"
    content-class="mkb-galvanised-modal"
    centered
    hide-header
    body-class="p-0"
    @hidden="hide"
  >
    <b-link class="mkb-close-button" @click.prevent="hide">
      <close></close>
    </b-link>
    <div
      class="d-md-flex flex-md-row px-5"
      v-show="hubspotActive"
      :class="hubspotActive ? 'mkb-info-columns' : ''"
    >
      <div class="w-100 text-center" v-if="isLoading">
        <b-spinner
          variant="primary"
          label="Spinning"
          class="text-center"
        ></b-spinner>
      </div>
      <div id="modalHubspot" v-once></div>
    </div>
    <div
      class="d-md-flex flex-md-row px-5 mkb-info-columns"
      v-if="formSubmitted"
    >
      <div class="w-100 text-center">
        <div class="mkb-thank-you-wrapper">
          <div class="m-5">
            <h1 class="mkb-title text-center">
              Thank you.
            </h1>
          </div>
          <p class="mkb-description text-center ">
            Thank you our team will be in touch shortly.
          </p>
          <div>
            <div class="col-sm pt-5">
              <div>
                <div
                  class="standard-selection mkb-clickable-container"
                  @click="hide"
                >
                  <div class="description">
                    CONTINUE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mkb-info-columns d-md-flex flex-md-row px-5"
      v-if="!hubspotActive && !formSubmitted"
    >
      <div
        class="mkb-content"
        v-html="
          this.$store.state.mesh.includes('Steel')
            ? steelContent
            : aluminiumContent
        "
      ></div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-center">
        <div class="mkb-chat-icon">
          <b-img
            src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/talk-bubble.svg"
          ></b-img>
        </div>
        <span v-if="$store.state.tenant === 'steeline'"
          >Need more information?</span
        >
        <span v-else-if="$store.state.tenant === '4933348840'"
          >Do you have a galvanised gutter?</span
        >
        <span v-else
          >Do you have any questions about our gutter mesh kits?</span
        >
        <br />
        <b-link
          @click="setHubspotForm()"
          v-if="$store.state.tenant === 'steeline'"
          >Leave your contact details here</b-link
        >
        <b-link
          @click="setHubspotForm()"
          v-else-if="$store.state.tenant === '4933348840'"
          >Contact our team here for further advice.</b-link
        >
        <b-link @click="setHubspotForm()" v-else
          >Please send your enquiries here</b-link
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import Close from "./svg/icons/Close";
import { forms } from "@/data/tenants";
export default {
  name: "GalvanisedModal",
  components: {
    Close
  },
  data() {
    //windspray content: Our Galvanised kits are supplied with Windspray mesh and <b> Windspray </b> roof rittings. This is the closest colour we have to match a Galvanised roof. <br/> <br/> Please note that if you are working with a Galvanised gutter our gutter trims <b> are not </b> compatible with a Galvanised gutter. <br/> <br/> Please contact our team here for more information on how you can install Blue Mountain Mesh onto a Galvanised gutter.
    return {
      forms,
      hubspotActive: false,
      isLoading: false,
      steelContent:
        "Our Galvanised kits are supplied with <b> Windspray </b> mesh. This is the closest colour we have to match Galvanised. <br/> <br/> Please note that if you are working with a galvanised gutter our gutter trims <b> are not </b> compatible with a galvanised gutter. <br/> <br/> Please contact our team here for more information on how you can install onto a Galvanised gutter.",
      aluminiumContent:
        "Hi there, our aluminium mesh is not suitable or compatible with a galvanised roof. Our <b> steel </b> gutter guard is the only solution we recommend for a galvanised roof. Our steel gutter guard is compatible with a galvanised roof because like the Galvanised roof, the steel mesh has been hot-dipped in a zinc coating. <br/> <br/> Please note that if you are working with a galvanised gutter our gutter trims are not compatible with a Galvanised gutter. <br/> <br/> Please please go back and select a steel mesh for your kit - or contact our friendly team here for more information",
      formSubmitted: false
    };
  },
  mounted() {},
  methods: {
    setHubspotForm() {
      if (this.hubspotActive) return;
      this.isLoading = true;
      this.hubspotActive = true;
      this.formSubmitted = false;
      const form = this.forms[this.$store.state.tenant];
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            formId: form["galvanizedFormId"],
            portalId: "3779244",
            target: "#modalHubspot"
          });
        }
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormReady"
        ) {
          // this.$nuxt.$loading.start();
          document.querySelector(
            "input[name='mkb_roof_type']"
          ).value = this.$store.state.roof;
          document.querySelector(
            "input[name='mkb_install_type']"
          ).value = this.$store.state.type;
          this.isLoading = false;
        }
        if (event.data.eventName === "onFormSubmitted") {
          // this.$nuxt.$loading.finish();
          // this.submitted = true;
          this.hubspotActive = false;
          this.formSubmitted = true;
        }
      });
    },
    show() {
      this.$refs.mkbGalvanisedModal.show();
    },
    hide() {
      this.$refs.mkbGalvanisedModal.hide();
      this.hubspotActive = false;
      this.formSubmitted = false;
    }
  }
};
</script>

<style lang="scss">
.mkb-galvanised-modal {
  .submitted-message {
    display: none !important;
  }
  .mkb-thank-you-wrapper {
    max-width: 100%;
  }

  .mkb-content {
    color: #002f6c;
  }
  .mkb-close-button {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .mkb-info-columns {
    padding: 50px;
  }

  .mkb-left-column {
    // width: 291px !important;
    margin-right: 35px;
  }

  .mkb-right-column {
    flex: 1;
    .mkb-feature-block {
      height: 41px;
    }
  }

  .modal-body {
    padding: 0 !important;
  }

  h4,
  p {
    font-size: 14rem;
    color: #707070;
  }

  h2 {
    color: #002f6c;
  }

  p {
    line-height: 145%;
  }
  .mkb-learn-more-link {
    display: block;
    color: #ffffff;
    background-color: #002f6c;
    font-size: 0.95rem;
  }

  .mkb-link-icon {
    margin-top: -1px;
    width: 20px;
    height: 20px;
  }

  .mkb-feature-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    align-self: center !important;
  }

  .mkb-feature-description {
    font-weight: 500;
    color: #1a3768;
    align-self: center !important;

    .small {
      font-size: 0.7rem;
    }
  }

  .mkb-disclaimer {
    font-size: 0.6rem;
  }
  footer {
    background-color: #f0f0f0;
    span,
    a {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: #002f6c;
    }
    .mkb-chat-icon svg {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 767.98px) {
    .mkb-feature-description {
      color: #1a3768;
      font-size: 0.7rem;
      align-self: center !important;

      .small {
        font-size: 0.6rem;
      }
    }
    h2 {
      margin-top: 20px !important;
      margin-bottom: 25px !important;
    }

    p {
      font-size: 0.9rem;
    }

    .mkb-info-columns {
      padding: 15px !important;
    }

    .mkb-left-column {
      width: 100% !important;
      margin: 0 0px 25px 0px !important;
    }

    .mkb-mesh-image-container {
      width: 100%;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
</style>
