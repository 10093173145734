const valleyTypes = {
  "Corrugated Valley Multi-Pitch Fin": {
    images: [
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/corro-valley-mpf-1.png"
      },
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/corro-valley-mpf-2.png"
      },
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/corro-valley-mpf-3.png"
      }
    ],
    description:
      "Individual and highly adjustable, the Multi-Pitch Fin is an ideal solution for steel mesh installations on corrugated valleys. It can be fitted to roof valleys of varying degrees and is suitable when both sides of the valley have different angles"
  },
  "Valley Roll": {
    images: [
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/valley-roll-1.png"
      },
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/valley-roll-2.png"
      },
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/valley-roll-3.png"
      }
    ],
    description:
      "Aluminium Valley gutter mesh comes in ready-made rolls with all the screws and saddles you need for fast and easy installation"
  },
  "Domed Valley": {
    images: [
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/domed-valley-1.png"
      },
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/domed-valley-2.png"
      },
      {
        valleyImage:
          "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/domed-valley-3.png"
      }
    ],
    description:
      "A universal valley solution designed to quickly and easily fit to any roof. The pinch and drop installation method used on the steel domed valley mesh ensures a secure fit beneath the roof sheets on both sides of the valley"
  }
};

export default valleyTypes;
