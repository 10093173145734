var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mkb-step-container mkb-product-code-page"},[_c('div',{staticClass:"header"},[_c('page-header')],1),_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"md":"12"}},[_c('p',{staticClass:"title m-0 px-2 p-0"},[_vm._v("Here's your kit:")]),_c('div',{staticClass:"d-lg-flex mb-3"},[_c('div',{staticClass:"mkb-kit-image-wrapper p-2"},[_c('div',{class:{
              'mkb-image-wrapper-solar': _vm.$store.state.type.includes('Solar'),
              'mkb-image-wrapper':
                _vm.$store.state.type.includes('Standard') ||
                _vm.$store.state.type.includes('Valley') ||
                _vm.$store.state.type.includes('Gutter')
            }},[_c('Kit')],1)]),_c('div',{staticClass:"w-100 col-8 mkb-product-container "},[_c('div',{staticClass:"mkb-product-information d-md-flex h-100"},[_c('div',{staticClass:"w-100 pr-md-5"},[_c('div',{staticClass:"mkb-info-row"},[_c('div',{staticClass:"mkb-info-label"},[_vm._v("Product Code")]),_c('h1',{staticClass:"mkb-info-value mkb-info-value-code"},[_vm._v(" "+_vm._s(_vm.$store.getters.products[0].code)+" ")])]),_c('div',{staticClass:"mkb-info-row"},[_c('div',{staticClass:"mkb-info-label"},[_vm._v("Description")]),_c('div',{staticClass:"mkb-info-value mkb-info-value-description"},[_vm._v(" "+_vm._s(_vm.$store.getters.products[0].description)+" ")])]),(_vm.$store.getters.method)?_c('div',{staticClass:"mkb-info-row"},[_c('div',{staticClass:"mkb-info-label"},[_vm._v(" Installation Method ")]),_c('div',{staticClass:"mkb-info-value mkb-info-value-meshcolour"},[_vm._v(" "+_vm._s(_vm.$store.getters.method)+" "),(_vm.$store.getters.method === 'Klip-Lok')?_c('span',[_vm._v("®")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"mkb-colour-rows mb-3 mb-md-0"},[_c('div',{staticClass:"mkb-info-row"},[_c('div',{staticClass:"mkb-info-label-colour align-self-center"},[_vm._v(" Mesh Colour ")]),_c('div',{staticClass:"mkb-info-value mkb-info-value-meshcolour"},[_c('inline-colour',{attrs:{"colour":_vm.$store.state.meshColour}})],1)]),(
                    _vm.$store.state.trim !== 'No Trim' &&
                      _vm.$store.state.trim !== null
                  )?_c('div',{staticClass:"mkb-info-row"},[_c('div',{staticClass:"mkb-info-label-colour align-self-center"},[_vm._v(" Trim Colour ")]),_c('div',{staticClass:"mkb-info-value info-value-trimcolour align-self-center"},[_c('inline-colour',{attrs:{"colour":_vm.$store.state.trimColour}})],1)]):_vm._e()]),(_vm.$store.state.type.includes('Solar'))?_c('div',{staticClass:"mkb-length-rows my-3 mb-md-0"},[_c('div',{staticClass:"mkb-info-row"},[_c('div',{staticClass:"mkb-info-label-length align-self-center"},[_vm._v(" Horizontal Measurement ")]),_c('div',{staticClass:"mkb-info-value mkb-info-value-horizontal-length"},[_vm._v(" "+_vm._s(_vm.$store.state.panelWidth)+" metres ")])]),_c('div',{staticClass:"mkb-info-row"},[_c('div',{staticClass:"mkb-info-label-length align-self-center"},[_vm._v(" Vertical Measurement ")]),_c('div',{staticClass:"mkb-info-value mkb-info-value-vertical-length"},[_vm._v(" "+_vm._s(_vm.$store.state.panelHeight)+" metres ")])])]):_vm._e(),_c('div',{staticClass:"w-100 mt-4"},[_c('div',{staticClass:"under-product-info d-flex flex-column-reverse flex-md-row"},[_c('div',{staticClass:"mkb-disclaimer-text align-self-center"},[_c('div',{staticClass:"text-justify text-md-left small-colour-text"},[_vm._v(" COLORBOND® and all colour names used are registered trade marks and ™ colour names are trade marks of BlueScope Steel Limited. © 2022 BlueScope Steel Limited ABN 16 000 011 058. All rights registered. ")])])]),(
                    _vm.$store.state.tenant === 'bmco-www' ||
                      _vm.$store.state.tenant === 'bmco'
                  )?_c('div',{staticClass:"mkb-copy-button mkb-clickable-container mb-2 mkb-standard mt-4",on:{"click":_vm.buy}},[_c('div',{staticClass:"text-uppercase text-nowrap"},[_vm._v(" Buy now ")])]):_vm._e(),(
                    _vm.$store.state.tenant === 'mitre-10-Strathalbyn' ||
                      _vm.$store.state.tenant === 'steeline' ||
                      _vm.$store.state.tenant === 'display-mkb' ||
                      _vm.$store.state.tenant === '4933348840'
                  )?_c('div',{staticClass:"mkb-copy-button mkb-clickable-container mb-2 mkb-standard mt-4",on:{"click":function($event){return _vm.getAQuote()}}},[_c('div',{staticClass:"text-uppercase text-nowrap"},[_vm._v(" Get a Quote ")])]):_vm._e(),_c('div',[_c('div',{staticClass:"d-md-none mobile-footer-logo mt-1 pb-1 d-flex justify-content-center"},[_c('div',{staticClass:"footer-logo"},[_c('logo')],1)])])])]),_c('div',{staticClass:"mkb-copy-buttons d-md-flex flex-md-column mb-4 mb-md-0"},[(_vm.$toolContext === 'internal')?[_c('div',{staticClass:"text-nowrap mkb-copy-button mkb-clickable-container mb-3 text-uppercase",staticStyle:{"padding-left":"50px","padding-right":"50px"},on:{"click":_vm.copyProductCode}},[_vm._v(" Copy Code ")]),_c('div',{staticClass:"text-nowrap mkb-copy-button mkb-clickable-container text-uppercase",staticStyle:{"padding-left":"50px","padding-right":"50px"},on:{"click":_vm.copyColourCode}},[_vm._v(" Copy "+_vm._s(_vm.$store.state.trimColour ? "Colours" : "Colour")+" ")])]:(_vm.$toolContext === 'homeowner')?[(
                    _vm.$store.state.tenant === 'bmco-www' ||
                      _vm.$store.state.tenant === 'bmco'
                  )?_c('div',{staticClass:"mkb-copy-button mkb-clickable-container mb-2 mkb-non-standard",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showNeedHelpForm()}}},[_c('div',{staticClass:"text-uppercase text-nowrap mb-2"},[_vm._v(" Need Further Help? ")]),_c('div',{staticClass:"font-weight-normal mkb-button-subtext"},[_vm._v(" Measure my roof "),_c('br'),_vm._v("Get an installation quote "),_c('br'),_vm._v("Get a sample "),_c('br')])]):_vm._e(),(
                    _vm.$store.state.tenant !== 'bmco-www' ||
                      _vm.$store.state.tenant === 'bmco'
                  )?_c('PDFGenerator'):_vm._e(),_c('div',{staticClass:"mkb-copy-button mkb-clickable-container mb-2 mkb-non-standard",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showEmailForm()}}},[_c('div',{staticClass:"text-uppercase text-nowrap"},[_vm._v(" Email Me My Kit ")])]),(
                    _vm.$store.state.tenant === 'bmco-www' ||
                      _vm.$store.state.tenant === 'bmco'
                  )?_c('div',{staticClass:"mkb-copy-button mkb-clickable-container mb-2 mkb-non-standard",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showContactForm()}}},[_c('div',{staticClass:"text-uppercase text-nowrap"},[_vm._v(" Contact Our Team ")])]):_vm._e(),_c('div',{staticClass:"mkb-restart-link mkb-clickable-container align-self-center d-flex justify-content-center mb-2 mt-3",on:{"click":_vm.restart}},[_c('div',{staticClass:"align-self-center mr-2",staticStyle:{"width":"1.2rem","height":"auto"}},[_c('div',[_c('restart')],1)]),_c('div',{staticClass:"align-self-center text-nowrap"},[_vm._v(" Restart Tool ")])]),(_vm.$store.state.tenant === 'mitre-10-Strathalbyn')?_c('div',{staticClass:"mkb-restart-link mkb-m10-link-2 mkb-clickable-container align-self-center d-flex justify-content-center mb-2 mt-3",on:{"click":_vm.redirectToM10}},[_c('div',{staticClass:"align-self-center mr-1",staticStyle:{"width":"1.4rem","height":"auto"}},[_c('div',[_c('ArrowRight')],1)]),_c('div',{staticClass:"align-self-center w-75 text-center"},[_vm._v(" Go back to Mitre 10 Strathalbyn's website ")])]):_vm._e()]:(_vm.$toolContext === 'customer')?[_c('div',{staticClass:"mkb-copy-button mkb-clickable-container mb-2",staticStyle:{"width":"220px"},on:{"click":_vm.buy}},[_c('div',{staticClass:"text-uppercase text-nowrap"},[_vm._v(" Order Now ")])])]:_vm._e()],2)])])])])],1),_c('find-store-modal',{ref:"mkbFindStoreModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }