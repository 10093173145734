import _ from "lodash";
import Vue from "vue";
import VueRouter from "vue-router";
import Start from "../views/Start";
import InstallationType from "../views/InstallationType";
import ProductCode from "../views/ProductCode";
import EnquiryForm from "../views/panel/EnquiryForm.vue";
import HubSpotForm from "../views/HubSpotForm.vue";
import Thankyou from "../views/panel/Thankyou.vue";
import FormStep from "../components/FormStep";

import store from "../store/";

import { standardRoutes } from "./standard";
import { valleyRoutes } from "./valley";
import { boxGutterRoutes } from "./box";
import { solarPanelRoutes } from "./solarpanel";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "start",
    component: Start
  },
  {
    path: "/installation-type",
    name: "installationtype",
    component: InstallationType
  },
  {
    path: "/enquiry",
    name: "enquiry",
    component: EnquiryForm
  },
  {
    path: "/thank-you",
    name: "thankyou",
    component: Thankyou
  },
  {
    path: "/product-code",
    name: "code",
    component: ProductCode
  },
  {
    path: "/standard",
    component: FormStep,
    children: standardRoutes
  },
  {
    path: "/valley",
    component: FormStep,
    children: valleyRoutes
  },
  {
    path: "/box",
    component: FormStep,
    children: boxGutterRoutes
  },
  {
    path: "/solar-panel",
    component: FormStep,
    children: solarPanelRoutes
  },
  {
    path: "/form",
    component: HubSpotForm,
    name: "form"
  },
  {
    path: "/emailmemykitform",
    component: HubSpotForm,
    name: "emailmemykitform"
  },
  {
    path: "/contactourteamform",
    component: HubSpotForm,
    name: "contactourteamform"
  },
  {
    path: "/:catchAll(.*)",
    component: Start,
    name: "NotFound"
  }
];
const router = new VueRouter({
  // mode: "history",
  // hash: false,
  // base: 'mesh-kit-builder',
  routes
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "start" &&
    to.name !== "installationtype" &&
    _.isNull(store.state.type)
  ) {
    store.commit("reset");
    next({ name: "start" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (store.getters.steps.length) {
    const currentStep = _.findIndex(store.getters.steps, {
      name: to.name
    });
    if (currentStep != -1) {
      for (let step = currentStep; step < store.getters.steps.length; ++step) {
        if (_.has(store.getters.steps[step], "sets")) {
          store.commit("clearStepSelection", store.getters.steps[step].sets);
        }
      }
      store.commit("setStep", currentStep);
    }
  }

  next();
});

export default router;
