<template>
  <div class="d-flex align-items-end mkb-page-header">
    <div
      v-if="showNav"
      class="mkb-side-link align-self-center align-self-md-end"
    >
      <div
        class="d-flex mkb-clickable-container mkb-back-link"
        @click="goBack"
        v-if="previousLabel !== 'thank-you'"
      >
        <div class="mr-2 align-self-center mkb-back-container">
          <back></back>
        </div>
        <div class="align-self-center mkb-back-link d-none d-md-block">
          Back to {{ previousLabel }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-fill">
      <div class="mkb-logo-container d-none d-md-block">
        <Logo />
      </div>
      <div class="mkb-text-heading d-md-none">
        <div class="mkb-title">Mesh Kit Builder</div>
        <div class="mkb-subtitle" v-if="showNav">
          {{ $store.state.type }} Installation
        </div>
      </div>
    </div>
    <div v-if="showNav" class="mkb-side-link justify-content-end d-flex">
      <div class="text-left d-none d-md-block">
        <div class="mkb-mesh-builder-title">Mesh Kit Builder</div>
        <div class="mkb-mesh-builder-subtitle">
          {{ $store.state.type }} Installation
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from "./svg/icons/Back";
import Logo from "../assets/img/logo.svg";

export default {
  name: "PageHeader",
  components: {
    Logo,
    Back
  },
  computed: {
    showNav() {
      return this.$store.getters.steps.length;
    },
    previousLabel() {
      if (this.$store.state.step === 0) {
        return "Installation Type";
      } else if (
        this.$router.currentRoute.name === "form" ||
        this.$router.currentRoute.name === "emailmemykitform" ||
        this.$router.currentRoute.name === "contactourteamform"
      ) {
        return "Product Code";
      } else if (this.$router.currentRoute.name === "thankyou") {
        return "thank-you";
      } else {
        return this.$store.getters.steps[this.$store.state.step - 1].title;
      }
    }
  },
  methods: {
    goBack() {
      if (this.$store.state.step === 0) {
        this.$router.push({ name: "installationtype" });
      } else {
        this.$store.commit("goBack");
      }
    }
  }
};
</script>

<style lang="scss">
.mkb-page-header {
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 6px;
  margin-bottom: 35px !important;
  color: #98a4af;

  .mkb-back-container {
    width: 12px;
    height: auto;
  }

  .mkb-back-link:hover {
    color: #002f6c;

    .a {
      fill: #002f6c !important;
    }
  }

  .mkb-logo-container {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 230px;
    height: auto;
    path {
      fill: #002f6c;
    }
  }

  .mkb-text-heading {
    min-height: 46px;
    padding-top: 4px;
    text-align: center;

    .mkb-title {
      font-size: 1.1rem;
      color: #002f6c;
      font-weight: 500;
    }

    .mkb-subtitle {
      font-size: 0.8rem;
    }
  }

  .mkb-side-link {
    width: 30%;
  }

  .mkb-mesh-builder-title {
    color: #002f6c;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .mkb-mesh-builder-subtitle {
    font-size: 0.9rem;
  }

  .mkb-back-link {
    font-weight: 500;
    font-size: 0.95rem;
  }

  @media (max-width: 767.98px) {
    margin-bottom: 12px;

    .mkb-side-link {
      width: 10%;
    }
  }
}
</style>
