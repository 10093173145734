<template>
  <div class="mkb-solar-panel-position-page">
    <div class="w-100">
      <div>
        <h3 class="text-primary">Panel Position</h3>
        <p class="w-50 text-justify ">
          How are your panels positioned on the roof?
        </p>
      </div>

      <b-row cols="1" cols-md="2">
        <b-col class="mkb-panel-position">
          <div>
            <template>
              <img
                src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/Solar-Panel-Mesh-Flow-Panel-Position-1.svg"
                alt=""
                class="w-100 mkb-image"
              />
            </template>
          </div>

          <div
            class="standard-selection mkb-clickable-container py-3 my-5"
            @click.prevent="setPanelPosition('Parallel')"
          >
            <div class="description">
              Parallel to roof surface
            </div>
          </div>
        </b-col>
        <b-col>
          <div>
            <template>
              <img
                src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/Solar-Panel-Mesh-Flow-Panel-Position-2.svg"
                alt=""
                class="w-100"
              />
            </template>
          </div>
          <div
            class="standard-selection mkb-clickable-container py-3 my-5"
            @click.prevent="setPanelPosition('Angled')"
          >
            <div class="description">
              Tilted at an angle
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "SolarPanelPosition",
  methods: {
    setPanelPosition(panelPosition) {
      this.$store.commit("setPanelPosition", panelPosition);
      if (panelPosition == "Angled") {
        this.$store.commit("setcustomPanelPosition", true);
        // this.$store.commit("enquiryStep", 1);
      } else {
        this.$store.commit("setcustomPanelPosition", false);
      }
      this.next();
    },
    next() {
      if (
        this.$store.state.customPanelOffset ||
        this.$store.state.customPanelPosition
      ) {
        this.$store.commit("enquiryStep", "custom-kit-form");
      } else {
        this.$store.commit("advance");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-panel-position {
  padding-top: 62px;
}

.standard-selection {
  border: 2px solid #002f6c;
  background-color: #002f6c;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  padding: 1rem;
  font-weight: 400;

  .description {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .width {
    font-weight: 600;
    font-size: 1.6rem;
  }
}
.standard-selection:hover {
  background-color: #ffffff;
  color: #002f6c;
  border: 2px solid #002f6c;
}
@media (max-width: 575.98px) {
  p {
    width: 100% !important;
    font-size: 1.1rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
    width: 100%;
    text-align: center;
  }
}
</style>
