<template>
  <b-container>
    <div class="header">
      <page-header></page-header>
    </div>
    <div class="w-100 mx-auto">
      <div class="w-100 text-center">
        <vue-topprogress ref="topProgress"></vue-topprogress>
        <b-spinner
          variant="primary"
          label="Spinning"
          v-if="isLoading"
        ></b-spinner>
      </div>
      <b-row v-show="!isLoading">
        <b-col>
          <div class="mkb-product-container px-2 pt-3">
            <b-row class="mkb-product-info">
              <b-col md="5" class="mkb-component">
                <div class="mkb-image-wrapper">
                  <StandardKit v-if="$store.state.type.includes('Standard')" />
                  <ValleyKit v-if="$store.state.type.includes('Valley')" />
                  <BoxGutterKit v-if="$store.state.type.includes('Box')" />
                  <SolarPanelKit v-if="$store.state.type.includes('Solar')" />
                </div>
              </b-col>
              <b-col
                md="7"
                align-self="center"
                class="mkb-component-content pr-0"
              >
                <div class="mkb-info-row">
                  <h3 class="mkb-info-value mkb-info-value-code">
                    {{ $store.getters.products[0].code }}
                  </h3>
                </div>
                <div class="mkb-info-row">
                  <div class="mkb-info-value mkb-info-value-description">
                    {{ $store.getters.products[0].description }}
                  </div>
                </div>
              </b-col>
              <!-- <button @click="$store.commit('enquiryStep', 'thank-you')">
              test
            </button> -->
            </b-row>
            <div
              class="d-flex flex-column align-items-start flex-wrap mkb-product-wrapper mt-3"
              :style="{ height: dynamicHeight() }"
            >
              <div
                class="px-0 py-2 w-50"
                v-for="(item, index) in productList"
                :key="index"
              >
                <span class="mkb-title">{{ item.name }}</span>
                <h6
                  class="mkb-description p-0"
                  v-if="typeof item.data === 'object'"
                >
                  {{ item.data.standard ? "Standard" : "Non-Standard" }}
                  {{ item.data.width }}mm
                </h6>
                <div v-else-if="item.name.includes('colour')">
                  <inline-colour
                    class="mkb-description mb-2 mkb-form-inline-colour"
                    :colour="item.data"
                  ></inline-colour>
                </div>

                <h6 class="mkb-description" v-else>
                  {{
                    item.data === "Klip-Lok"
                      ? item.data + "® or Flat Pan"
                      : item.data === "Traditional (screws only)"
                      ? "Traditional"
                      : item.data === "Standard Gutter"
                      ? "Standard"
                      : item.data
                  }}{{ item.name.includes("measurement") ? "m" : "" }}
                </h6>
              </div>
            </div>
            <div
              class="under-product-info d-flex flex-column-reverse flex-md-row mt-3"
            >
              <div class="mkb-disclaimer-text align-self-center px-2 pb-3">
                <div class="text-justify text-md-left small-colour-text">
                  COLORBOND&#174; and all colour names used are registered trade
                  marks and &#8482; colour names are trade marks of BlueScope
                  Steel Limited. &copy; 2022 BlueScope Steel Limited ABN 16 000
                  011 058. All rights registered.
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="7" class="px-5">
          <p
            class="mkb-form-label"
            v-if="
              $store.state.enquiry === 'email-form' &&
                $store.state.tenant !== '4933348840'
            "
          >
            Please provide your contact info so we could send you your kit
            details.
          </p>
          <p
            class="mkb-form-label"
            v-if="
              $store.state.enquiry === 'email-form' &&
                $store.state.tenant === '4933348840'
            "
          >
            Please provide your contact information so we can email you the
            details of your kit.
          </p>
          <p
            class="mkb-form-label"
            v-if="$store.state.enquiry === 'contact-us'"
          >
            Help is on the way!
          </p>
          <div id="hubspotForm" v-once></div>
          <!-- <div
            v-if="
              $store.state.tenant === '4933348840' &&
                $store.state.enquiry === 'get-a-quote-form'
            "
          >
            <QuoteForm />
          </div> -->
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
// import QuoteForm from '../components/stratco/QuoteForm.vue';
import "../styles/hubspot.scss";
import StandardKit from "../components/kit/StandardMeshKit.vue";
import ValleyKit from "../components/kit/ValleyMeshKit.vue";
import BoxGutterKit from "../components/kit/BoxGutterMeshKit.vue";
import SolarPanelKit from "../components/kit/SolarPanelMeshKit.vue";
import PageHeader from "../components/PageHeader";
import InlineColour from "../components/InlineColour";
import { forms } from "@/data/tenants";
import axios from "axios";
export default {
  name: "HubspotForm",
  components: {
    PageHeader,
    StandardKit,
    ValleyKit,
    BoxGutterKit,
    SolarPanelKit,
    InlineColour
    // QuoteForm,
  },
  data() {
    return {
      forms,
      isLoading: false,

      getters: this.$store.getters,
      state: this.$store.state,
      productsInfo: [
        {
          name: "roof installation type",
          data: this.$store.state.type
        },
        { name: "roof type", data: this.$store.state.roof },
        {
          name: "valley installation type",
          data: this.$store.state.valley
        },
        {
          name: "installation method",
          data: this.$store.state.installationType
        },
        { name: "mesh type", data: this.$store.state.mesh },
        { name: "mesh colour", data: this.$store.state.meshColour },
        { name: "mesh width", data: this.$store.state.width },
        { name: "trim type", data: this.$store.state.trim },
        { name: "trim colour", data: this.$store.state.trimColour },
        {
          name: "horizontal measurement",
          data: this.$store.state.panelWidth
        },
        {
          name: "vertical measurement",
          data: this.$store.state.panelHeight
        },
        { name: "panel offset", data: this.$store.state.panelOffset },
        {
          name: "panel position",
          data: this.$store.state.panelPosition
        }
      ],
      emailForm: [
        { field: "mkb_install_type", data: this.$store.state.type },
        { field: "mkb_roof_type", data: this.$store.state.roof },
        {
          field: "mkb_kliplok_method",
          data: this.$store.state.installationType
        },
        { field: "mkb_mesh_type", data: this.$store.state.mesh },
        {
          field: "mkb_mesh_colour",
          data: this.$store.getters.colours[this.$store.state.meshColour].hasR
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "®"
            : this.$store.getters.colours[this.$store.state.meshColour].hasTm
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "™"
            : this.$store.getters.colours[this.$store.state.meshColour].name
        },
        {
          field: "mkb_mesh_width",
          data:
            this.$store.state.width === null
              ? "300"
              : this.$store.state.width.width + "mm"
        },
        { field: "mkb_trim_type", data: this.$store.state.trim },
        {
          field: "mkb_trim_colour",
          data:
            this.$store.state.trimColour === null
              ? "None"
              : this.$store.getters.colours[this.$store.state.trimColour].hasR
              ? this.$store.getters.colours[this.$store.state.trimColour].name +
                "®"
              : this.$store.getters.colours[this.$store.state.trimColour].hasTm
              ? this.$store.getters.colours[this.$store.state.trimColour].name +
                "™"
              : this.$store.getters.colours[this.$store.state.trimColour].name
        },
        {
          field: "valley_install_type",
          data: this.$store.state.valley
        },

        {
          field: "mkb_product_code",
          data: this.$store.getters.products[0].code
        },
        {
          field: "mkb_kit_description",
          data: this.$store.getters.products[0].description
        },
        {
          field: "mkb_kit_shopify_link",
          data: this.$store.getters.products[0].url.bmco
        },
        {
          field: "mkb_solar_mesh_colour",
          data: this.$store.getters.colours[this.$store.state.meshColour].hasR
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "®"
            : this.$store.getters.colours[this.$store.state.meshColour].hasTm
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "™"
            : this.$store.getters.colours[this.$store.state.meshColour].name
        },

        {
          field: "mkb_solar_mesh_type",
          data: this.$store.state.mesh
        },
        {
          field: "mkb_solar_panel_offset",
          data:
            this.$store.state.panelOffset === null
              ? ""
              : this.$store.state.panelOffset
        },
        {
          field: "mkb_solar_roof_type",
          data: this.$store.state.roof
        },
        {
          field: "mkb_solar_panel_position",
          data:
            this.$store.state.panelPosition === null
              ? ""
              : this.$store.state.panelPosition
        },
        {
          field: "mkb_solar_v_length",
          data:
            this.$store.state.panelHeight === null
              ? ""
              : this.$store.state.panelHeight
        },
        {
          field: "mkb_solar_h_length",
          data:
            this.$store.state.panelWidth === null
              ? ""
              : this.$store.state.panelWidth
        }
      ],
      needHelpForm: [
        { field: "mkb_install_type", data: this.$store.state.type },
        { field: "mkb_roof_type", data: this.$store.state.roof },
        { field: "mkb_mesh_type", data: this.$store.state.mesh },
        {
          field: "mkb_mesh_colour",
          data: this.$store.getters.colours[this.$store.state.meshColour].hasR
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "®"
            : this.$store.getters.colours[this.$store.state.meshColour].hasTm
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "™"
            : this.$store.getters.colours[this.$store.state.meshColour].name
        },
        {
          field: "trim_colour",
          data:
            this.$store.state.trimColour === null
              ? "None"
              : this.$store.getters.colours[this.$store.state.trimColour].hasR
              ? this.$store.getters.colours[this.$store.state.trimColour].name +
                "®"
              : this.$store.getters.colours[this.$store.state.trimColour].hasTm
              ? this.$store.getters.colours[this.$store.state.trimColour].name +
                "™"
              : this.$store.getters.colours[this.$store.state.trimColour].name
        },
        {
          field: "mkb_mesh_width",
          data:
            this.$store.state.width === null
              ? "300"
              : this.$store.state.width.width + "mm"
        },
        {
          field: "mkb_solar_panel_offset",
          data:
            this.$store.state.panelOffset === null
              ? ""
              : this.$store.state.panelOffset
        },
        {
          field: "mkb_solar_mesh_colour",
          data: this.$store.getters.colours[this.$store.state.meshColour].hasR
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "®"
            : this.$store.getters.colours[this.$store.state.meshColour].hasTm
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "™"
            : this.$store.getters.colours[this.$store.state.meshColour].name
        },

        {
          field: "mkb_solar_mesh_type",
          data: this.$store.state.mesh
        },
        {
          field: "mkb_solar_roof_type",
          data: this.$store.state.roof
        },
        {
          field: "mkb_product_code",
          data: this.$store.getters.products[0].code
        },
        {
          field: "mkb_solar_panel_position",
          data:
            this.$store.state.panelPosition === null
              ? ""
              : this.$store.state.panelPosition
        },
        {
          field: "mkb_solar_v_length",
          data:
            this.$store.state.panelHeight === null
              ? ""
              : this.$store.state.panelHeight
        },
        {
          field: "mkb_solar_h_length",
          data:
            this.$store.state.panelWidth === null
              ? ""
              : this.$store.state.panelWidth
        },
        {
          field: "valley_install_type",
          data: this.$store.state.valley
        }
      ],
      contactForm: [
        { field: "mkb_install_type", data: this.$store.state.type },
        { field: "mkb_roof_type", data: this.$store.state.roof },
        {
          field: "mkb_kliplok_method",
          data: this.$store.state.installationType
        },
        { field: "mkb_mesh_type", data: this.$store.state.mesh },
        {
          field: "mkb_mesh_colour",
          data: this.$store.getters.colours[this.$store.state.meshColour].hasR
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "®"
            : this.$store.getters.colours[this.$store.state.meshColour].hasTm
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "™"
            : this.$store.getters.colours[this.$store.state.meshColour].name
        },
        {
          field: "mkb_mesh_width",
          data:
            this.$store.state.width === null
              ? "300"
              : this.$store.state.width.width + "mm"
        },
        { field: "mkb_trim_type", data: this.$store.state.trim },
        {
          field: "mkb_trim_colour",
          data:
            this.$store.state.trimColour === null
              ? "None"
              : this.$store.getters.colours[this.$store.state.trimColour].hasR
              ? this.$store.getters.colours[this.$store.state.trimColour].name +
                "®"
              : this.$store.getters.colours[this.$store.state.trimColour].hasTm
              ? this.$store.getters.colours[this.$store.state.trimColour].name +
                "™"
              : this.$store.getters.colours[this.$store.state.trimColour].name
        },
        {
          field: "valley_install_type",
          data: this.$store.state.valley
        },
        {
          field: "mkb_product_code",
          data: this.$store.getters.products[0].code
        },
        {
          field: "mkb_kit_description",
          data: this.$store.getters.products[0].description
        },
        {
          field: "mkb_solar_mesh_colour",
          data: this.$store.getters.colours[this.$store.state.meshColour].hasR
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "®"
            : this.$store.getters.colours[this.$store.state.meshColour].hasTm
            ? this.$store.getters.colours[this.$store.state.meshColour].name +
              "™"
            : this.$store.getters.colours[this.$store.state.meshColour].name
        },

        {
          field: "mkb_solar_mesh_type",
          data: this.$store.state.mesh
        },
        {
          field: "mkb_solar_roof_type",
          data: this.$store.state.roof
        },
        {
          field: "mkb_solar_panel_offset",
          data:
            this.$store.state.panelOffset === null
              ? ""
              : this.$store.state.panelOffset
        },
        {
          field: "mkb_solar_panel_position",
          data:
            this.$store.state.panelPosition === null
              ? ""
              : this.$store.state.panelPosition
        },
        {
          field: "mkb_solar_v_length",
          data:
            this.$store.state.panelHeight === null
              ? ""
              : this.$store.state.panelHeight
        },
        {
          field: "mkb_solar_h_length",
          data:
            this.$store.state.panelWidth === null
              ? ""
              : this.$store.state.panelWidth
        }
      ],
      stratcoFormValues: {}
    };
    // this.$store.state.meshColour
  },
  async mounted() {
    await this.setForm();
  },
  computed: {
    productList() {
      return this.productsInfo.filter(v => v.data !== null);
    },
    getFullProductLink() {
      const params = [
        {
          param: `mesh_colour=${this.$store.state.meshColour}`,
          value: this.$store.state.meshColour
        },
        {
          param: `trim_colour=${this.$store.state.trimColour}`,
          value: this.$store.state.trimColour
        },
        {
          param: `horizontal=${this.$store.state.panelWidth}`,
          value: this.$store.state.panelWidth
        },
        {
          param: `vertical=${this.$store.state.panelHeight}`,
          value: this.$store.state.panelHeight
        }
      ];
      const includedParams = params.filter(v => v.value !== null);
      let result = "";
      for (let i in includedParams) {
        result += includedParams[i].param;
        if (i < includedParams.length - 1) {
          result = result.concat("&");
        }
      }

      let shopUrl = new URL(
        `${this.$store.getters.products[0].url.bmco}?${result}`
      );
      return shopUrl.href;
    }
  },
  methods: {
    setForm() {
      this.$refs.topProgress.start();
      this.isLoading = true;
      // if (
      //   this.$store.state.tenant === '4933348840' &&
      //   this.state.enquiry === 'get-a-quote-form'
      // ) {
      //   setTimeout(() => {
      //     this.$refs.topProgress.done();
      //     this.isLoading = false;
      //   }, 1000);
      //   // this.$refs.topProgress.done();
      //   return;
      // }
      const form = this.forms[this.$store.state.tenant];
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            formId: form[this.$store.state.hubspotName],
            portalId: "3779244",
            target: "#hubspotForm",
            css: "styles/hubspot.scss"
          });
        }
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormReady"
        ) {
          //  [...document.querySelectorAll('form input[type="hidden"].hs-input')].map(x => console.log(x.name));
          this.isLoading = false;
          this.$refs.topProgress.done();
          switch (this.state.enquiry) {
            //need help form

            case "need-help":
              this.needHelpForm.map(
                x =>
                  (document.querySelector(`input[name="${x.field}"]`).value =
                    x.data === "Standard Gutter"
                      ? "Standard"
                      : x.data === "Klip-Lok"
                      ? "Klip-Lok® or Flat Pan"
                      : x.data)
              );
              break;
            //email me my kit form
            case "email-form":
              this.emailForm.map(
                x =>
                  (document.querySelector(`input[name="${x.field}"]`).value =
                    x.data === "Standard Gutter"
                      ? "Standard"
                      : x.data === "Klip-Lok"
                      ? "Klip-Lok® or Flat Pan"
                      : x.data)
              );

              break;
            //contact our team form
            case "contact-us":
              this.contactForm.map(x => {
                document.querySelector(`input[name="${x.field}"]`).value =
                  x.data === "Standard Gutter"
                    ? "Standard"
                    : x.data === "Klip-Lok"
                    ? "Klip-Lok® or Flat Pan"
                    : x.data;
              });
              break;
            //get a quote form
            case "get-a-quote-form":
              this.contactForm.map(x => {
                const element = document.querySelector(
                  `input[name="${x.field}"]`
                );
                if (element) {
                  element.value =
                    x.data === "Standard Gutter"
                      ? "Standard"
                      : x.data === "Klip-Lok"
                      ? "Klip-Lok® or Flat Pan"
                      : x.data;
                }
              });
              document.querySelector(
                'input[name="mkb_kit_shopify_link"]'
              ).value = this.getFullProductLink;
              if (this.$store.state.tenant === "steeline") {
                document.querySelector(
                  'input[name="mkb___steeline_store"]'
                ).value = this.$store.state.storeSelected;
              }
              if (this.$store.state.tenant === "4933348840") {
                document.querySelector(
                  'input[name="mkb___stratco_stores"]'
                ).value = this.$store.state.storeSelected;

                document.querySelector(
                  'input[name="stratco___memo_field"]'
                ).value = `${this.$store.getters.products[0].description} Mesh colour = ${this.$store.state.meshColour} Trim colour = ${this.$store.state.trimColour}`;
              }
              break;
          }
        }
        if (event.data.eventName === "onFormSubmit") {
          this.isLoading = true;
          if (this.$store.state.tenant === "4933348840") {
            const formElement = document.querySelector("form");
            this.stratcoFormValues = this.getFormValues(formElement);
            this.submitForm();
          }
        }
        if (event.data.eventName === "onFormSubmitted") {
          this.isLoading = false;

          if (this.$store.state.enquiry !== null) {
            //alternative for remove event listener
            this.$store.commit("enquiryStep", "thank-you");
          }
        }
      });
    },
    getFormValues(formElement) {
      const formData = new FormData(formElement);
      let formValues = {};

      for (let [key, value] of formData.entries()) {
        formValues[key] = value;
      }

      return formValues;
    },
    async submitForm() {
      if (this.$store.state.tenant === "localhost") return;
      const API_ENDPOINT = `${process.env.VUE_APP_API_ENDPOINT}/mkb/stratco/leads`;
      try {
        await axios.post(API_ENDPOINT, this.stratcoFormValues, {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY_STRATCO
          }
        });
        // this.$store.commit('enquiryStep', 'thank-you');
      } catch (e) {
        console.error(e);
        this.isSubmitting = false;
      }
    },
    dynamicHeight() {
      if (
        (this.$store.state.type.includes("Valley") &&
          this.$store.state.roof !== "Klip-Lok") ||
        this.$store.state.type.includes("Box")
      ) {
        return "255px";
      } else if (
        this.$store.state.type.includes("Solar") &&
        (this.$store.state.roof === "Klip-Lok" ||
          this.$store.state.roof === "Topdek® 700" ||
          this.$store.state.roof === "Lokdek 680/700")
      ) {
        return "400px";
      } else return "310px";
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-form-inline-colour {
  line-height: 1.2;
}
.mkb-product-container {
  background: #fbfafb;
  .mkb-product-wrapper {
    width: 95%;
    margin: 0 auto;
  }
  .mkb-disclaimer-text {
    font-size: 0.45rem;
    color: #707070;
    width: 100%;
  }
  .mkb-image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }
  .mkb-title {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #98a4af;
  }
  .mkb-description {
    color: #002f6c;
    font-size: 1rem;
    // text-transform: full-width;
    font-weight: 500;
  }
  .mkb-product-info {
    width: 95%;
    margin: 0 auto;
  }
  .under-product-info {
    width: 85%;
  }
  .mkb-component {
    border: 1px solid #707070;
    padding: 20px !important;
    background: white;
  }
  .mkb-component-content {
    .mkb-info-value {
      color: #002f6c;
    }
    .mkb-info-value-description {
      font-size: 0.8rem;
      font-weight: 500;
    }
    @media (max-width: 767px) {
      padding-left: 0 !important;
      margin-top: 10px;
    }
  }
}

.mkb-form-label {
  color: #002f6c;
  font-weight: 500;
  font-size: 1.05rem;
}
</style>
