<template>
  <b-container>
    <div class="header">
      <page-header></page-header>
      <div class="mkb-mobile-menu d-lg-none">
        <horizontal-menu></horizontal-menu>
      </div>
    </div>
    <div class="d-flex">
      <div class="sidebar-menu d-none d-lg-block">
        <sidebar></sidebar>
      </div>
      <div class="mkb-step-container">
        <router-view></router-view>
        <div
          class="d-md-none mkb-mobile-footer-logo d-flex justify-content-center"
        >
          <div class="mkb-footer-logo">
            <logo></logo>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import PageHeader from "./PageHeader";
import Sidebar from "./Sidebar";
import HorizontalMenu from "./HorizontalMenu";
import Logo from "../assets/svg/logo.svg";

export default {
  name: "FormStep",
  components: {
    PageHeader,
    Sidebar,
    HorizontalMenu,
    Logo
  }
};
</script>

<style lang="scss">
.mkb-mobile-menu {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px !important;
}

.mkb-mobile-footer-logo {
  margin-top: 4rem;
}

.mkb-footer-logo {
  width: 60%;
  height: auto;
  path {
    fill: #9eaab3 !important;
  }
}
</style>
