export const stratcoStores = [
  {
    name: "Fyshwick",
    address: "325 Tennant St",
    suburb: "Fyshwick",
    state: "ACT",
    postcode: "2609",
    lat: -35.331696653608056,
    lng: 149.1828807124523
  },
  {
    name: "Huntingwood",
    address: "15 Liberty Rd",
    suburb: "Huntingwood",
    state: "NSW",
    postcode: "2148",
    lat: -33.793010908849226,
    lng: 150.87292934724198
  },

  {
    name: "Huntingwood Factory Pick Up",
    address: "19 Liberty Rd",
    suburb: "Huntingwood",
    state: "NSW",
    postcode: "2148",
    lat: -33.79339968511498,
    lng: 150.87466732579784
  },
  {
    name: "Mid North Coast",
    address: "20 Blackbutt Road",
    suburb: "Port Macquarie",
    state: "NSW",
    postcode: "2444",
    lat: -31.453203373787026,
    lng: 152.88868421221596
  },
  {
    name: "Newcastle",
    address: "2 Hartley Dr",
    suburb: "Thornton",
    state: "NSW",
    postcode: "2322",
    lat: -32.79061832725332,
    lng: 151.63768611226573
  },
  {
    name: "Penrith",
    address: "29 York Road",
    suburb: "Jamisontown",
    state: "NSW",
    postcode: "2750",
    lat: -33.7642931507834,
    lng: 150.68838358346773
  },
  {
    name: "Alice Springs",
    address: "6 Ghan Rd Alice",
    suburb: "Alice Springs",
    state: "NT",
    postcode: "0870",
    lat: -23.693631212196237,
    lng: 133.8733772062322
  },
  {
    name: "Darwin",
    address: "780 Stuart Hwy",
    suburb: "Berrimah",
    state: "NT",
    postcode: "0828",
    lat: -12.442382343552888,
    lng: 130.9457036828582
  },
  {
    name: "Archerfield",
    address: "17 Boniface St",
    suburb: "Archerfield ",
    state: "QLD",
    postcode: "4108",
    lat: -27.560155505547506,
    lng: 153.01995469673855
  },
  {
    name: "Arnel Fencing & Patios (Cairns)",
    address: "Arnel Fencing Warehouse 174/178 Mccoombe St",
    suburb: "Cairns City",
    state: "QLD",
    postcode: "4870",
    lat: -16.941315129395612,
    lng: 145.74978918110088
  },
  {
    name: "Brendale",
    address: "35 Kremzow Road",
    suburb: "Brendale",
    state: "QLD",
    postcode: "4500",
    lat: -27.317831672420713,
    lng: 152.98338436789516
  },
  {
    name: "Caboolture",
    address: "17 Concorde Pl",
    suburb: "Caboolture",
    state: "QLD",
    postcode: "4510",
    lat: -27.08202977465099,
    lng: 152.97866558322906
  },
  {
    name: "Capalaba",
    address: "2 Smith St",
    suburb: "Capalaba",
    state: "QLD",
    postcode: "4157",
    lat: -27.528247079308933,
    lng: 153.19630099673742
  },
  {
    name: "Gold Coast",
    address: "108 Eastlake St",
    suburb: "Carrara",
    state: "QLD",
    postcode: "4211",
    lat: -28.03453832720618,
    lng: 153.3531475409313
  },
  {
    name: "Hervey Bay",
    address: "128 Beach Rd",
    suburb: "Pialba",
    state: "QLD",
    postcode: "4655",
    lat: -25.28876656412418,
    lng: 152.83146154084284
  },
  {
    name: "Ormeau",
    address: "1 Mavis Ct",
    suburb: "Ormeau",
    state: "QLD",
    postcode: "4208",
    lat: -27.75664060582586,
    lng: 153.24503583585337
  },
  {
    name: "Proserpine",
    address: "Parnell's Metal Industries 2/155 Main Street",
    suburb: "Proserpine ",
    state: "QLD",
    postcode: "4800",
    lat: -20.403642734165835,
    lng: 148.59196126769132
  },
  {
    name: "Sunshine Coast",
    address: "152-154 Victoria St",
    suburb: "Kunda Park",
    state: "QLD",
    postcode: "4556",
    lat: -26.66083331439146,
    lng: 153.03576546787377
  },
  {
    name: "Toowoomba",
    address: "234 McDougall Street",
    suburb: "Toowoomba",
    state: "QLD",
    postcode: "4350",
    lat: -27.5511931724374,
    lng: 151.9095054832444
  },
  {
    name: "Gawler",
    address: "16 Main North Rd",
    suburb: "Willaston ",
    state: "SA",
    postcode: "5118",
    lat: -34.589555560262774,
    lng: 138.74292041048753
  },
  {
    name: "Gepps Cross",
    address: "59 Port Wakefield Rd",
    suburb: "Gepps Cross",
    state: "SA",
    postcode: "5094",
    lat: -34.83834620288371,
    lng: 138.5983684393328
  },
  {
    name: "Gepps Cross Factory Pick Up",
    address: "59 Port Wakefield Rd",
    suburb: "Gepps Cross",
    state: "SA",
    postcode: "5094",
    lat: -34.838460679180606,
    lng: 138.5983577104974
  },
  {
    name: "Goolwa",
    address: "29 Hutchinson St",
    suburb: "Goolwa",
    state: "SA",
    postcode: "5214",
    lat: -35.50515472773795,
    lng: 138.77932328353637
  },
  {
    name: "Kadina",
    address: "9 Kennett St",
    suburb: "Jerusalem",
    state: "SA",
    postcode: "5554",
    lat: -33.97119612330505,
    lng: 137.71356166813396
  },
  {
    name: "Lonsdale",
    address: "26 O'Sullivan Beach Rd",
    suburb: "Lonsdale ",
    state: "SA",
    postcode: "5160",
    lat: -35.11808204616014,
    lng: 138.48920967982514
  },
  {
    name: "Mount Barker",
    address: "7 Oborn Road",
    suburb: "Mount Barker",
    state: "SA",
    postcode: "5251",
    lat: -35.07750522029411,
    lng: 138.87540680870407
  },
  {
    name: "Murray Bridge",
    address: "15 Hindmarsh Rd",
    suburb: "Murray Bridge",
    state: "SA",
    postcode: "5253",
    lat: -35.142152393792905,
    lng: 139.271169881674
  },
  {
    name: "Port Augusta",
    address: "6-8 National Highway A1",
    suburb: "Port Augusta",
    state: "SA",
    postcode: "5700",
    lat: -32.506730444639125,
    lng: 137.78793629876128
  },
  {
    name: "Port Lincoln",
    address: "7 Bel-Air Drive",
    suburb: "Port Lincoln",
    state: "SA",
    postcode: "5606",
    lat: -34.737665020754704,
    lng: 135.8617952662893
  },
  {
    name: "Riverland",
    address: "55-59 Zante Rd",
    suburb: "Berri",
    state: "SA",
    postcode: "5343",
    lat: -34.27301548667018,
    lng: 140.59644388348735
  },
  {
    name: "St Marys",
    address: "1197 South Rd",
    suburb: "St Marys",
    state: "SA",
    postcode: "5042",
    lat: -34.997874394844466,
    lng: 138.5765685528611
  },
  {
    name: "Whyalla",
    address: "50 Ian Street",
    suburb: "Whyalla Norrie",
    state: "SA",
    postcode: "5608",
    lat: -33.03054427171155,
    lng: 137.53205806809797
  },
  {
    name: "Hobart",
    address: "Central Roofing Supplies 33 Mornington Road",
    suburb: "Mornington",
    state: "TAS",
    postcode: "7018",
    lat: -42.858885557178965,
    lng: 147.39686792618372
  },
  {
    name: "Dandenong",
    address: "14-16 Princes Highway",
    suburb: "Doveton ",
    state: "VIC",
    postcode: "3177",
    lat: -38.00053279330378,
    lng: 145.23047408363942
  },
  {
    name: "Deer Park",
    address: "1027 Western Hwy",
    suburb: "Deer Park",
    state: "VIC",
    postcode: "3023",
    lat: -37.76194561778802,
    lng: 144.75197419712316
  },
  {
    name: "Epping",
    address: "17 Scanlon Drive",
    suburb: "Epping",
    state: "VIC",
    postcode: "3076",
    lat: -37.644890081352635,
    lng: 144.99498363820908
  },
  {
    name: "Ferntree Gully",
    address: "881 Burwood Hwy",
    suburb: "Ferntree Gully",
    state: "VIC",
    postcode: "3156",
    lat: -37.88270390577118,
    lng: 145.28029235479903
  },
  {
    name: "Mildura",
    address: "251 Benetook Ave",
    suburb: "Mildura",
    state: "VIC",
    postcode: "3500",
    lat: -34.194387669974674,
    lng: 142.18223179882605
  },
  {
    name: "Broome",
    address: "20 Tanami Dr",
    suburb: "Broome",
    state: "WA",
    postcode: "6725",
    lat: -17.92584359093704,
    lng: 122.23681326762987
  },
  {
    name: "Bunbury",
    address: "30 McCombe Rd",
    suburb: "Bunbury",
    state: "WA",
    postcode: "6230",
    lat: -33.358555649297216,
    lng: 115.67456476811033
  },
  {
    name: "Busselton",
    address: "18 Wright St",
    suburb: "Busselton",
    state: "WA",
    postcode: "6280",
    lat: -33.66616122642929,
    lng: 115.34737924113466
  },
  {
    name: "Canning Vale",
    address: "183-189 Bannister Rd",
    suburb: "Canning Vale",
    state: "WA",
    postcode: "6155",
    lat: -32.06130973962653,
    lng: 115.90438232573213
  },
  {
    name: "Kalgoorlie",
    address: "8 Federal Rd",
    suburb: "South Kalgoorlie",
    state: "WA",
    postcode: "6430",
    lat: -30.759403745122516,
    lng: 121.48609749684914
  },
  {
    name: "Malaga",
    address: "759 Marshall Rd",
    suburb: "Malaga",
    state: "WA",
    postcode: "6090",
    lat: -31.8576509209178,
    lng: 115.89995852572457
  },
  {
    name: "Mandurah",
    address: "11 Fitzgerald Rd",
    suburb: "Greenfields",
    state: "WA",
    postcode: "6210",
    lat: -32.51698387038219,
    lng: 115.74913368342001
  }
];
