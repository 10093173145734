<template>
  <div class="mkb-solar-panel-components">
    <div class="mkb-solar-container">
      <b-row class="mkb-mesh-kit mx-auto">
        <b-col class="px-0 pr-2">
          <div class="d-flex" v-if="showLabels">
            <div class="align-items-start mr-1">
              <font-awesome-icon :icon="['fas', 'caret-down']" />
            </div>
            <div class="align-self-center" v-if="!domedValleyChecker()">
              Domed Mesh
            </div>
            <div class="align-self-center" v-else>
              Mesh Roll
            </div>
          </div>
          <div>
            <img :src="meshImage" alt="Mesh Roll" class="mesh-image" />
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex align-items-center mkb-fastener-container px-0 pr-2"
        >
          <b-row
            class="h-100"
            v-if="
              $store.state.roof === 'Tile' &&
                $store.state.type === 'Valley' &&
                $store.state.valley === 'Valley Roll' &&
                $store.state.mesh.includes('Aluminium')
            "
          >
          </b-row>
          <b-row class="h-100 pl-2" v-else>
            <b-col
              :class="{
                'mkb-small-profile':
                  $store.state.type.includes('Standard') &&
                  ($store.state.roof.includes('Trimdek') ||
                    $store.state.roof.includes('Superdek') ||
                    $store.state.roof.includes('Cooldek® Classic')),
                'my-auto':
                  (!$store.state.roof.includes('Tile') &&
                    !$store.state.roof.includes('Bullnose') &&
                    !$store.state.roof.includes('Spandek') &&
                    !$store.state.roof.includes('Longspan') &&
                    !$store.state.roof.includes('Decramastic') &&
                    !$store.state.roof.includes('Klip-Lok') &&
                    $store.state.mesh.includes('Aluminium')) ||
                  $store.state.roof.includes('Trimdek') ||
                  $store.state.roof.includes('Superdek') ||
                  $store.state.roof.includes('Cooldek® Classic'),
                'my-auto mkb-valley-roll':
                  $store.state.valley === 'Valley Roll' &&
                  $store.state.type.includes('Valley') &&
                  !$store.state.roof.includes('Klip-Lok'),
                'mkb-tile-aluminium ':
                  $store.state.valley === 'Domed Valley' &&
                  $store.state.type.includes('Valley'),
                'mkb-tile-aluminium text-center':
                  $store.state.type.includes('Standard') &&
                  $store.state.roof.includes('Tile') &&
                  $store.state.mesh.includes('Aluminium'),
                'mt-0':
                  $store.state.roof.includes('Klip-Lok') &&
                  $store.state.installationType === 'Saddles',
                'mt-auto':
                  $store.state.roof === 'Tile' ||
                  (($store.state.roof.includes('Klip-Lok') ||
                    $store.state.roof.includes('Prodek') ||
                    $store.state.roof.includes('Spacedek®')) &&
                    $store.state.installationType ===
                      'Traditional (screws only)')
              }"
              class="p-0"
            >
              <div class="mkb-clip-hooks">
                <div class="d-flex align-items-start mb-2">
                  <div class=" mr-1">
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                  <div>
                    Solar panel mesh clip hooks
                  </div>
                </div>
                <b-row cols="1" class="mkb-clip-hooks-img ml-1">
                  <b-img
                    class="w-100 p-1"
                    :src="
                      $store.state.solarImages['solar panel mesh clip hooks']
                    "
                  ></b-img>
                  <b-img
                    class="w-100 p-1"
                    :src="
                      $store.state.solarImages['solar panel mesh clip hooks']
                    "
                  ></b-img>
                  <b-img
                    class="w-100 p-1"
                    :src="
                      $store.state.solarImages['solar panel mesh clip hooks']
                    "
                  ></b-img>
                </b-row>
              </div>
              <div class="mkb-clip-plates">
                <div class="d-flex align-items-start mb-2">
                  <div class=" mr-1">
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                  <div>
                    Solar panel mesh clip locking plates
                  </div>
                </div>
                <div>
                  <b-row cols="4" class="mkb-clip-plates-img ml-1">
                    <b-img
                      class="w-25 m-1"
                      :src="
                        $store.state.solarImages[
                          'solar panel mesh clip locking plates - back'
                        ]
                      "
                    ></b-img
                    ><b-img
                      class="w-25 m-1"
                      :src="
                        $store.state.solarImages[
                          'solar panel mesh clip locking plates - back'
                        ]
                      "
                    ></b-img
                    ><b-img
                      class="w-25 m-1"
                      :src="
                        $store.state.solarImages[
                          'solar panel mesh clip locking plates - back'
                        ]
                      "
                    ></b-img>
                  </b-row>
                </div>
              </div>
              <div v-if="$store.state.roof === 'Tile'"></div>
              <div
                class="d-flex flex-column mkb-kit-fasteners align-items-start"
                v-else
              >
                <div class="d-flex">
                  <div
                    class="profile"
                    v-if="domedValleyChecker() && profileChecker()"
                  >
                    <div
                      class="d-flex"
                      v-if="
                        showLabels &&
                          $store.state.installationType !==
                            'Traditional (screws only)'
                      "
                    >
                      <div class="align-items-start mr-1">
                        <font-awesome-icon :icon="['fas', 'caret-down']" />
                      </div>
                      <div class="align-self-center">
                        <div>
                          {{ $store.state.roof
                          }}<span v-if="hasR($store.state.roof)">&reg;</span>

                          {{
                            $store.state.mesh.includes("Aluminium")
                              ? "Saddles"
                              : $store.state.roof.includes("Trimdek") ||
                                $store.state.roof.includes("Superdek") ||
                                $store.state.roof.includes("Cooldek® Classic")
                              ? "Saddles"
                              : $store.state.roof.includes("Klip-Lok")
                              ? "Saddles"
                              : "Profile"
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        $store.state.installationType !==
                          'Traditional (screws only)'
                      "
                      :class="{
                        'd-flex mkb-saddle-image w-75': $store.state.mesh.includes(
                          'Aluminium'
                        ),
                        'd-flex mkb-corro-image':
                          $store.state.roof.includes('Corrugated') &&
                          $store.state.type !== 'Valley' &&
                          $store.state.mesh.includes('Steel'),
                        'd-flex mkb-bullnose-image':
                          $store.state.roof.includes('Bullnose') &&
                          $store.state.mesh.includes('Steel'),
                        'd-flex mkb-spandek-image':
                          $store.state.roof.includes('Spandek') &&
                          $store.state.mesh.includes('Steel'),
                        'd-flex mkb-longspan-image':
                          ($store.state.roof.includes('Longspan') ||
                            $store.state.roof.includes('Smartspan')) &&
                          $store.state.mesh.includes('Steel'),
                        'd-flex mkb-decramastic-image':
                          $store.state.roof.includes('Decramastic') &&
                          $store.state.mesh.includes('Steel'),
                        'd-flex mkb-trimdek mkb-narrow-width w-75':
                          $store.state.roof.includes('Trimdek') ||
                          $store.state.roof.includes('Superdek') ||
                          $store.state.roof.includes('Cooldek® Classic'),
                        'd-flex mkb-klip-lok mkb-narrow-width w-75':
                          $store.state.roof.includes('Klip-Lok') ||
                          $store.state.roof.includes('Topdek® 700') ||
                          $store.state.roof.includes('Lokdek 680/700')
                      }"
                    >
                      <img
                        :src="profileImage"
                        alt="Profile"
                        class="profile-image"
                      />
                    </div>
                  </div>
                  <div v-if="!screwChecker()" class="text-center">
                    <div class="d-flex justify-content-center">
                      <div class="align-items-start mr-1">
                        <font-awesome-icon :icon="['fas', 'caret-down']" />
                      </div>
                      <div class="align-self-center">
                        {{ fastenerName }}
                      </div>
                    </div>
                    <div class="mkb-screws-rotated">
                      <img :src="fastenerImage" :alt="fastenerName" />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="d-flex pl-2 mkb-screws-container align-items-start"
                v-if="screwChecker()"
              >
                <div class="mt-auto">
                  <div class="screws ">
                    <div class="d-flex " v-if="showLabels">
                      <div class="align-items-start mr-1">
                        <font-awesome-icon :icon="['fas', 'caret-down']" />
                      </div>
                      <div class="align-self-center">
                        {{ fastenerName }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div>
                        <img
                          :src="fastenerImage"
                          :alt="fastenerName"
                          class="mkb-fastener-image w-50"
                        />
                      </div>
                      <div v-if="$store.state.type.includes('Box')">
                        <img
                          :src="fastenerImage"
                          :alt="fastenerName"
                          class="mkb-fastener-image-box"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- <b-col cols="6" v-else-if="$store.state.type === 'Box Gutter' "  > </b-col> -->
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mkb-trim-row">
        <b-col>
          <div class="d-flex mkb-trim-name">
            <div class=" mr-1">
              <font-awesome-icon :icon="['fas', 'caret-down']" />
            </div>
            <div class="align-self-start">
              Standard Gutter Trim
            </div>
          </div>
          <div class="">
            <div class="mkb-trim-image w-50 ">
              <img :src="trimImage" alt="Gutter trim" />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import roofTypes from "../data/roof-types";
import {
  rollImages,
  profileImages,
  screwImages,
  trimImages
} from "../data/rollImages";
import { domeImages } from "../data/domeImages";
export default {
  name: "MeshKitSolarPanel",
  props: {
    showLabels: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    hasR(roofType) {
      return roofTypes[roofType].rMark;
    },
    domedValleyChecker() {
      let valleyTypes = this.$store.getters.valleyTypes;
      if (
        valleyTypes.find(element => element === "Domed Valley") !== undefined &&
        valleyTypes.length === 1
      )
        return false;
      return true;
    },
    profileChecker() {
      if (
        this.$store.state.type === "Standard Gutter" &&
        this.$store.state.roof === "Tile" &&
        this.$store.state.mesh.includes("Aluminium")
      )
        return false;
      return true;
    },
    screwChecker() {
      if (
        this.$store.state.roof === "Trimdek" ||
        this.$store.state.roof === "Superdek" ||
        this.$store.state.roof === "Cooldek® Classic" ||
        ((this.$store.state.roof === "Klip-Lok" ||
          this.$store.state.roof === "Topdek® 700" ||
          this.$store.state.roof === "Lokdek 680/700") &&
          this.$store.state.installationType === "Saddles") ||
        ((this.$store.state.roof === "Corrugated" ||
          this.$store.state.roof === "CGI Corrugated" ||
          this.$store.state.roof === "Cooldek® Corrugated") &&
          this.$store.state.mesh.includes("Aluminium"))
      )
        return false;
      return true;
    }
  },
  computed: {
    meshImage() {
      // return dome if true, else roll
      const mesh = {
        "2mm Steel": "steel-2mm",
        "4mm Steel": "steel-4mm",
        "5.4mm Steel": "steel-5 4mm",
        "2mm Aluminium": "aluminium-2mm",
        "4mm Aluminium": "aluminium-4mm",
        "4mm Aluminium Ultra": "aluminium-4mm"
      };
      if (!this.domedValleyChecker())
        return domeImages("domed-valley-mesh-" + this.$store.state.meshColour);
      return rollImages(
        `${mesh[this.$store.state.mesh]}-${this.$store.state.meshColour}`,
        mesh[this.$store.state.mesh]
      );
    },
    profileImage() {
      const profile = {
        Corrugated: {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "CGI Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "Cooldek® Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        Trimdek: "trimdek-saddle",
        Superdek: "trimdek-saddle",
        "Cooldek® Classic": "trimdek-saddle",
        SteelClad: "trimdek-saddle",
        "Klip-Lok": "kliplok-saddle",
        "Lokdek 680/700": "kliplok-saddle",
        "Topdek® 700": "kliplok-saddle",
        Bullnose: "bullnose-profile",
        Spandek: "spandek-profile",
        Steelspan: "spandek-profile",
        Smartspan: "longspan-profile",
        Decramastic: "decramastic-profile",
        Longspan: "longspan-profile",
        Tile: "tile-clips"
      };
      const mesh = this.$store.state.mesh.includes("Aluminium")
        ? "Aluminium"
        : "Steel";
      return profileImages(
        profile[this.$store.state.roof],
        this.$store.state.meshColour,
        mesh
      );
      // return profileImages(
      //   this.$store.state.roof,
      //   this.$store.state.meshColour,
      //   this.$store.state
      // );
    },
    fastenerImage() {
      return screwImages(this.$store.state.meshColour, this.$store.state);
    },
    fastenerName() {
      return "Screws";
    },
    trimImage() {
      return trimImages(
        `standard-gutter-trim-${this.$store.state.meshColour}`,
        "standard-gutter-trim"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.mkb-kit-image-wrapper {
  .mkb-solar-panel-components {
    padding: 2% 5% 5% 5%;
  }
  .mkb-image-wrapper-solar {
    img[alt="Gutter trim"] {
      margin-left: 128px;
      margin-top: -120px !important;
      @media (width: 820px) {
        margin-left: 164px;
        margin-top: -171px !important;
      }
    }
  }
}

.mkb-trim-name {
  font-size: 10px !important;
}
.mkb-trim-image,
.mkb-trim-row {
  max-height: 15px;
}
img[alt="Gutter trim"] {
  width: 15% !important;
  transform: rotateZ(270deg);
  margin-left: 141px;
  margin-top: -140px;
}
.mkb-clip-hooks-img {
  width: 60%;
}
.mkb-clip-plates-img {
  width: 65%;
}
.mkb-screws-rotated {
  img {
    transform: rotateZ(270deg);
    width: 90%;
    margin-top: 38px;
    &.mkb-tile-screw {
      margin-left: -15px;
    }
  }
}
.screws.klip {
  img {
    margin-bottom: -80px;
  }
  .screw1 {
    margin-left: -60px;
  }
  .screw2 {
    width: 550px;
    margin-left: -190px;
  }
}
.mkb-screws-container {
  height: 15%;
}
.mkb-small-profile {
  margin-top: 3rem;
}
// .mkb-tile-aluminium,
// .mkb-traditional {
//   margin-top: 100%;
// }
.mkb-kit-fasteners {
  padding-bottom: 0px;
}
.mkb-corro-image img,
.mkb-bullnose-image img,
.mkb-spandek-image img,
.mkb-longspan-image img,
.mkb-decramastic-image img {
  transform: rotateZ(90deg);
}
.mkb-corro-image img {
  width: 35% !important;
  margin-left: 35px;
  margin-top: -40px;
  margin-bottom: -50px;
}
.mkb-spandek-image img {
  width: 35% !important;
  margin-left: 20px;
  margin-top: -30px;
  margin-bottom: -40px;
}
.mkb-longspan-image img {
  width: 33% !important;
  margin-left: 30px;
  margin-top: -30px;
  margin-bottom: -45px;
}
.mkb-bullnose-image img {
  width: 40%;
  margin-left: 38px;
  margin-top: -30px;
  margin-bottom: -50px;
}
.mkb-decramastic-image img {
  width: 45%;
  margin-left: 38px;
  margin-top: -30px;
  margin-bottom: -50px;
}
.mkb-mesh-kit {
  font-size: 0.65rem;

  img {
    max-width: 100%;
    height: auto;
  }
  .mkb-fastener-image[alt="Screws"] {
    height: auto;
    width: 100%;
  }
  .mkb-fastener-image-box[alt="Screws"] {
    height: auto;
    width: 500px;
    margin-bottom: -75px;
    margin-left: -80px;
  }
  .mkb-fastener-image-box-klip[alt="Screws"] {
    height: auto;
    width: 500px;
    margin-bottom: -75px;
    margin-left: -80px;
  }

  .mkb-trim-image-less-pad {
    padding-top: 12% !important;
  }

  .mkb-trim-image-wide {
    padding-top: 15% !important;
    width: 150px;
  }

  // .mkb-narrow-width {
  //   width: 70px;
  // }
}
@media (max-width: 767.98px) {
  .mkb-kit-image-wrapper {
    margin-bottom: 40px;
  }
  .mkb-image-wrapper-solar {
    img[alt="Gutter trim"] {
      margin-left: 135px;
      margin-top: -135px !important;
    }
  }
  img[alt="Gutter trim"] {
    width: 15% !important;
    transform: rotateZ(270deg) !important;
    margin-left: 155px;
    margin-top: -159px;
  }
  .mesh-image {
    width: 100%;
  }
  .mkb-mesh-kit {
    font-size: 0.65rem;
    max-width: 100%;

    img {
      max-width: 100%;
      height: auto;
    }

    .mkb-fastener-image[alt="Screws"] {
      width: 100%;
      height: auto;
    }

    .mkb-trim-image-less-pad {
      padding-top: 12% !important;
    }
    .mkb-trim-image {
      img {
        width: 95% !important;
      }
    }
    .mkb-trim-image-wide {
      padding-top: 15% !important;
      width: 150px;
    }

    .mkb-trim-name {
      font-size: 8px !important;
    }
  }
}
</style>
