<template>
  <b-modal
    ref="valleyInfoModal"
    size="xl"
    content-class="mkb-valley-info-modal"
    centered
    hide-header
    @hidden="clearModal"
    body-class="p-0"
    :hide-footer="this.$store.state.tenant === '4933348840'"
  >
    <b-link class="mkb-close-button" @click.prevent="hide">
      <close></close>
    </b-link>
    <div v-if="valleyType">
      <div
        class="d-md-flex flex-md-row px-5"
        v-show="hubspotActive"
        :class="hubspotActive ? 'mkb-info-columns' : ''"
      >
        <div class="w-100 text-center" v-if="isLoading">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="text-center"
          ></b-spinner>
        </div>
        <div id="modalHubspot" v-once></div>
      </div>
      <div
        class="d-md-flex flex-md-row px-5 mkb-info-columns"
        v-if="formSubmitted"
      >
        <div class="w-100 text-center">
          <div class="mkb-thank-you-wrapper">
            <div class="m-5">
              <h1 class="mkb-title text-center">
                Thank you.
              </h1>
            </div>
            <p class="mkb-description text-center ">
              Thank you our team will be in touch shortly.
            </p>
            <div>
              <div class="col-sm pt-5">
                <div>
                  <div
                    class="standard-selection mkb-clickable-container"
                    @click="hide"
                  >
                    <div class="description">
                      CONTINUE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!hubspotActive && !formSubmitted">
        <div class="mb-2 mkb-valley-image-container ">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <!-- Text slides with image -->
            <b-carousel-slide
              :class="{ 'mkb-loading': imgLoading }"
              v-for="(image, index) in $options.valleyTypes[valleyType].images"
              :key="index"
              :img-src="image.valleyImage"
            ></b-carousel-slide>
          </b-carousel>
          <!-- <img
          src="https://cdn.bfldr.com/57WHQJKO/at/5k2kkf7vb5zf4pk7gjmq69b/corro-valley-mpf-1.png?auto=webp&format=png"
          alt="Mesh Roll"
          class="w-100"
          :class="{ 'mkb-image': imgLoading }"
        /> -->
        </div>
        <div class="mkb-info-columns d-md-flex flex-md-row px-5">
          <b-row>
            <b-col>
              <div class="mkb-valley-content py-5 px-4">
                <h2 class="mb-2">{{ valleyType }}</h2>
                <p
                  class=" mkb-description my-1 mb-md-0"
                  v-html="$options.valleyTypes[valleyType].description"
                ></p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-center">
        <div class="mkb-chat-icon">
          <chat></chat>
        </div>
        <span v-if="$store.state.tenant === 'steeline'"
          >Need more information?</span
        >
        <span v-else
          >Do you have any questions about our gutter mesh kits?</span
        >
        <br />
        <b-link
          @click="setHubspotForm()"
          v-if="$store.state.tenant === 'steeline'"
          >Leave your contact details here</b-link
        >
        <b-link @click="setHubspotForm()" v-else
          >Please send your enquiries here</b-link
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import Chat from "./svg/icons/Chat";
import Close from "./svg/icons/Close";
import valleyTypes from "../data/valley-types";
import { forms } from "@/data/tenants";
export default {
  name: "MeshInfoModal",
  components: {
    Close,
    Chat
  },
  data() {
    return {
      forms,
      valleyType: null,
      imgUrl: null,
      imgLoading: false,
      slide: 0,
      sliding: null,
      hubspotActive: false,
      isLoading: false,
      formSubmitted: false
    };
  },
  valleyTypes: valleyTypes,
  methods: {
    show(valleyType) {
      this.imgLoading = true;
      this.valleyType = valleyType;
      this.$refs.valleyInfoModal.show();

      this.imgLoading = false;
    },
    setHubspotForm() {
      if (this.hubspotActive) return;
      this.isLoading = true;
      this.hubspotActive = true;
      this.formSubmitted = false;
      const form = this.forms[this.$store.state.tenant];
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            formId: form["valleyFormId"],
            portalId: "3779244",
            target: "#modalHubspot"
          });
        }
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormReady"
        ) {
          // this.$nuxt.$loading.start();
          document.querySelector(
            "input[name='mkb_roof_type']"
          ).value = this.$store.state.roof;
          document.querySelector(
            "input[name='mkb_install_type']"
          ).value = this.$store.state.type;
          document.querySelector(
            "input[name='mkb_mesh_type']"
          ).value = this.meshType;
          if (this.$store.state.tenant === "mitre-10-Strathalbyn") {
            document.querySelector(
              'input[name="mkb_request_help_page"]'
            ).value = this.valleyType + " Page";
          }

          if (this.$store.state.type === "Solar Panel") {
            document.querySelector(
              "input[name='mkb_solar_roof_type']"
            ).value = this.$store.state.roof;
            document.querySelector(
              "input[name='mkb_solar_mesh_type']"
            ).value = this.meshType;
          }
          if (this.$store.state.type === "Valley") {
            document.querySelector(
              "input[name='valley_install_type']"
            ).value = this.$store.state.valley;
          }
          this.isLoading = false;
        }
        if (event.data.eventName === "onFormSubmitted") {
          // this.$nuxt.$loading.finish();
          // this.submitted = true;
          this.hubspotActive = false;
          this.formSubmitted = true;
        }
      });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    hide() {
      this.$refs.valleyInfoModal.hide();
      Promise.resolve().then(this.clearModal());
    },
    clearModal() {
      this.valleyType = null;
      this.hubspotActive = false;
      this.formSubmitted = false;
    }
  },
  mounted() {
    // this.img.src = this.$options.meshTypes[this.meshType].modalImage
  }
};
</script>

<style lang="scss">
.mkb-valley-info-modal {
  .submitted-message {
    display: none !important;
  }
  .mkb-info-columns {
    padding: 24px;
  }
  .mkb-loading {
    filter: blur(50px);
  }
  .carousel-indicators {
    li {
      border: 2px;
      padding: 2px 5px;
      background: #ffffff;
      width: 5px;
      height: 10px;
      border-radius: 100px;
      transform: scale(0.5, 0.5);
    }
  }
  .mkb-image {
    height: 35vw;
    object-fit: cover;
    // filter: blur(8px);
  }
  .mkb-close-button {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
  .carousel-control-next,
  .carousel-control-prev {
    height: 50%;
    position: absolute;
    top: 130px;
  }

  .modal-body {
    padding: 0 !important;
  }

  p {
    font-size: 0.9rem;
    font-weight: 500;
    color: #404040;
    line-height: 145%;
  }

  h2 {
    color: #002f6c;
  }

  footer {
    background-color: #f0f0f0;
    span,
    a {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: #002f6c;
    }
    .mkb-chat-icon svg {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 767.98px) {
    .carousel-control-next,
    .carousel-control-prev {
      top: 70px;
    }
    h2 {
      margin-top: 20px !important;
      margin-bottom: 25px !important;
    }

    p {
      font-size: 0.9rem;
    }

    .mkb-valley-image-container {
      width: 100%;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
</style>
