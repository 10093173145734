<template>
  <div class="mkb-solar-panel-offset-page">
    <div class="w-100">
      <h3 class="text-primary">Panel Offset</h3>
      <p class="w-75 text-justify mb-5">
        What is the spacing between your roof and the underside of the solar
        panel? <br />
        Make your measurement from the high point of the corrugation or tile.
      </p>

      <b-row>
        <div class="col-sm-7 my-auto ">
          <div class="image-container h-75 w-100">
            <template>
              <img
                src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/Solar-Panel-Mesh-Flow-Panel-Offset.svg"
                alt=""
                class="w-100"
              />
            </template>
          </div>
        </div>
        <div class="col-sm pt-5">
          <div>
            <div
              class="standard-selection mkb-clickable-container"
              @click.prevent="setPanelOffset('0-200mm')"
            >
              <div class="description">
                Between 0mm and 200mm
              </div>
            </div>
            <div
              class="standard-selection mkb-clickable-container"
              @click.prevent="setPanelOffset('201-400mm')"
            >
              <div class="description">
                Between 201mm and 400mm
              </div>
            </div>
            <div
              class="standard-selection mkb-clickable-container"
              @click.prevent="setPanelOffset('More than 400mm')"
            >
              <div class="description">
                More than 400mm
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolarPanelOffset",
  computed: {},
  methods: {
    setPanelOffset(panelOffset) {
      if (panelOffset !== "0-200mm") {
        this.$store.commit("setcustomPanelOffset", true);
      } else {
        this.$store.commit("setcustomPanelOffset", false);
      }
      this.$store.commit("setPanelOffset", panelOffset);
      this.$store.commit("advance");
    }
  }
};
</script>

<style lang="scss" scoped>
.standard-selection {
  border: 2px solid #002f6c;
  background-color: #002f6c;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  padding: 1rem;
  font-weight: 400;

  .description {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .width {
    font-weight: 600;
    font-size: 1.6rem;
  }
}
.standard-selection:hover {
  background-color: #ffffff;
  color: #002f6c;
  border: 2px solid #002f6c;
}
.mkb-nonstandard-selection {
  font-weight: 500;
  font-size: 1rem;
  border: 2px solid #002f6c;
  color: #002f6c;
  text-align: center;
}

.mkb-nonstandard-selection:hover {
  background-color: #002f6c;
  color: #ffffff;
}
//end
.image-container {
  overflow: hidden;
  padding: 50px;
  max-width: 100%;
}
@media (min-width: 575.98px) {
  img {
    transform: scale(1.3);
  }
}
@media (max-width: 575.98px) {
  img {
    transform: scale(1.4);
  }
  label {
    font-size: 0.9rem !important;
  }
  p {
    width: 100% !important;
    font-size: 1.1rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
    width: 100%;
    text-align: center;
  }
}
</style>
