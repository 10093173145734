var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mkb-mesh-kit mx-auto"},[_c('b-col',{staticClass:"px-0 pr-2"},[(_vm.showLabels)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"align-items-start mr-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-down']}})],1),(!_vm.domedValleyChecker())?_c('div',{staticClass:"align-self-center"},[_vm._v(" Domed Mesh ")]):_c('div',{staticClass:"align-self-center"},[_vm._v(" Mesh Roll ")])]):_vm._e(),_c('div',[_c('img',{staticClass:"mesh-image",attrs:{"src":_vm.meshImage,"alt":"Mesh Roll"}})])]),_c('b-col',{staticClass:"d-flex align-items-center mkb-fastener-container px-0 pr-2",attrs:{"cols":"6"}},[(
        _vm.$store.state.roof === 'Tile' &&
          _vm.$store.state.type === 'Valley' &&
          _vm.$store.state.valley === 'Valley Roll' &&
          _vm.$store.state.mesh.includes('Aluminium')
      )?_c('b-row',{staticClass:"h-100"}):_c('b-row',{staticClass:"h-100"},[_c('b-col',{staticClass:"p-0",class:{
          'mkb-small-profile':
            _vm.$store.state.type.includes('Standard') &&
            (_vm.$store.state.roof.includes('Trimdek') ||
              _vm.$store.state.roof.includes('Superdek') ||
              _vm.$store.state.roof.includes('Cooldek® Classic')),
          'my-auto':
            (!_vm.$store.state.roof.includes('Tile') &&
              !_vm.$store.state.roof.includes('Bullnose') &&
              !_vm.$store.state.roof.includes('Spandek') &&
              !_vm.$store.state.roof.includes('Longspan') &&
              !_vm.$store.state.roof.includes('Decramastic') &&
              !_vm.$store.state.roof.includes('Klip-Lok') &&
              _vm.$store.state.mesh.includes('Aluminium')) ||
            _vm.$store.state.roof.includes('Trimdek') ||
            _vm.$store.state.roof.includes('Superdek') ||
            _vm.$store.state.roof.includes('Cooldek® Classic'),
          'my-auto mkb-valley-roll':
            _vm.$store.state.valley === 'Valley Roll' &&
            _vm.$store.state.type.includes('Valley') &&
            !_vm.$store.state.roof.includes('Klip-Lok'),
          'mkb-tile-aluminium ':
            _vm.$store.state.valley === 'Domed Valley' &&
            _vm.$store.state.type.includes('Valley'),
          'mkb-tile-aluminium text-center':
            _vm.$store.state.type.includes('Standard') &&
            _vm.$store.state.roof.includes('Tile') &&
            _vm.$store.state.mesh.includes('Aluminium'),
          'mt-4':
            (_vm.$store.state.roof.includes('Klip-Lok') ||
              _vm.$store.state.roof.includes('Topdek® 700')) &&
            _vm.$store.state.installationType === 'Saddles',
          'mkb-traditional text-center':
            (_vm.$store.state.roof.includes('Klip-Lok') ||
              _vm.$store.state.roof.includes('Prodek') ||
              _vm.$store.state.roof.includes('Spacedek®')) &&
            _vm.$store.state.installationType === 'Traditional (screws only)'
        }},[_c('div',{staticClass:"d-flex flex-column mkb-kit-fasteners align-items-start"},[_c('div',{staticClass:"pl-2 "},[(_vm.domedValleyChecker() && _vm.profileChecker())?_c('div',{staticClass:"profile"},[(
                  _vm.$store.state.roof === 'Tile' &&
                    _vm.$store.state.mesh.includes('Steel')
                )?_c('div',{staticClass:"mkb-tile-safety-clips mb-4"},[_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('div',{staticClass:" mr-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-down']}})],1),_c('div',[_vm._v(" Tile Safety Clips ")])]),_c('div',{class:{
                    'mb-2': _vm.$store.state.type.includes('Box')
                  }},[_c('img',{staticClass:"profile-image",attrs:{"src":"https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/tile-safety-clips.png","alt":"Profile"}})])]):_vm._e(),(
                  _vm.showLabels &&
                    _vm.$store.state.installationType !==
                      'Traditional (screws only)'
                )?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"align-items-start mr-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-down']}})],1),_c('div',{staticClass:"align-self-center"},[(
                      _vm.$store.state.type === 'Valley' &&
                        (_vm.$store.state.roof === 'Corrugated' ||
                          _vm.$store.state.roof === 'CGI Corrugated' ||
                          _vm.$store.state.roof === 'Cooldek® Corrugated') &&
                        _vm.$store.state.mesh.includes('Steel')
                    )?_c('div',{staticClass:"w-75"},[_vm._v(" "+_vm._s(_vm.$store.state.roof)+" Valley Multi-Pitch Fins ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$store.state.roof)),(_vm.hasR(_vm.$store.state.roof))?_c('span',[_vm._v("®")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$store.state.mesh.includes("Aluminium") ? "Saddles" : _vm.$store.state.roof.includes("Tile") ? "Clips" : _vm.$store.state.roof.includes("Trimdek") || _vm.$store.state.roof.includes("Superdek") || _vm.$store.state.roof.includes("Cooldek® Classic") ? "Saddles" : _vm.$store.state.roof.includes("Klip-Lok") ? "Saddles" : "Profile")+" ")])])]):_vm._e(),(
                  _vm.$store.state.installationType !==
                    'Traditional (screws only)'
                )?_c('div',{class:{
                  'd-flex mkb-saddle-image': _vm.$store.state.mesh.includes(
                    'Aluminium'
                  ),
                  'd-flex mkb-corro-image':
                    _vm.$store.state.roof.includes('Corrugated') &&
                    _vm.$store.state.type !== 'Valley' &&
                    _vm.$store.state.mesh.includes('Steel'),
                  'd-flex mkb-bullnose-image':
                    _vm.$store.state.roof.includes('Bullnose') &&
                    _vm.$store.state.mesh.includes('Steel'),
                  'd-flex mkb-spandek-image':
                    (_vm.$store.state.roof.includes('Spandek') ||
                      _vm.$store.state.roof.includes('Steelspan')) &&
                    _vm.$store.state.mesh.includes('Steel'),
                  'd-flex mkb-longspan-image':
                    (_vm.$store.state.roof.includes('Longspan') ||
                      _vm.$store.state.roof.includes('Smartspan')) &&
                    _vm.$store.state.mesh.includes('Steel'),
                  'd-flex mkb-decramastic-image':
                    _vm.$store.state.roof.includes('Decramastic') &&
                    _vm.$store.state.mesh.includes('Steel'),
                  'd-flex mkb-trimdek mkb-narrow-width':
                    _vm.$store.state.roof.includes('Trimdek') ||
                    _vm.$store.state.roof.includes('SteelClad') ||
                    _vm.$store.state.roof.includes('Superdek') ||
                    _vm.$store.state.roof.includes('Cooldek® Classic'),
                  'd-flex mkb-klip-lok mkb-narrow-width':
                    _vm.$store.state.roof.includes('Klip-Lok') ||
                    _vm.$store.state.roof.includes('Lokdek') ||
                    _vm.$store.state.roof.includes('Topdek® 700')
                }},[_c('div',{class:{
                    'mb-2': _vm.$store.state.type.includes('Box')
                  }},[_c('img',{staticClass:"profile-image",attrs:{"src":_vm.profileImage,"alt":"Profile"}})]),(_vm.$store.state.type.includes('Box'))?_c('div',[_c('img',{staticClass:"profile-image-box",attrs:{"src":_vm.profileImage,"alt":"Profile"}})]):_vm._e()]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"d-flex pl-2 mkb-screws-container align-items-start"},[_c('div',{staticClass:"mt-auto"},[(
                _vm.$store.state.type === 'Box Gutter' &&
                  _vm.$store.state.installationType ===
                    'Traditional (screws only)'
              )?_c('div',{staticClass:"screws klip"},[(_vm.showLabels)?_c('div',{staticClass:"d-flex "},[_c('div',{staticClass:"align-items-start mr-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-down']}})],1),_c('div',{staticClass:"align-self-center"},[_vm._v(" "+_vm._s(_vm.fastenerName)+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',[_c('img',{staticClass:"screw1",attrs:{"src":_vm.fastenerImage,"alt":_vm.fastenerName}})]),(_vm.$store.state.type.includes('Box'))?_c('div',[_c('img',{staticClass:"screw2",attrs:{"src":_vm.fastenerImage,"alt":_vm.fastenerName}})]):_vm._e()])]):_c('div',{staticClass:"screws "},[(_vm.showLabels)?_c('div',{staticClass:"d-flex "},[_c('div',{staticClass:"align-items-start mr-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-down']}})],1),_c('div',{staticClass:"align-self-center"},[_vm._v(" "+_vm._s(_vm.fastenerName)+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',[_c('img',{staticClass:"mkb-fastener-image",attrs:{"src":_vm.fastenerImage,"alt":_vm.fastenerName}})]),(_vm.$store.state.type.includes('Box'))?_c('div',[_c('img',{staticClass:"mkb-fastener-image-box",attrs:{"src":_vm.fastenerImage,"alt":_vm.fastenerName}})]):_vm._e()])])])])]),(_vm.$store.state.type === 'Standard Gutter')?_c('b-col',{staticClass:"p-0 h-100"},[_c('div',{staticClass:"d-inline-flex flex-row pt-2"},[(_vm.trimImage)?_c('div',{staticClass:"mkb-trim-image text-center w-50 ",class:{
              'mkb-trim-image-wide':
                _vm.trimImage &&
                _vm.$store.state.trim === 'Standard' &&
                _vm.$store.state.roof.includes('Decramastic'),
              'mkb-trim-image-less-pad':
                _vm.trimImage &&
                _vm.$store.state.trim === 'Standard' &&
                _vm.$store.state.roof.includes('Tile')
            }},[_c('img',{attrs:{"src":_vm.trimImage,"alt":"Gutter trim"}})]):_vm._e(),(
              _vm.$store.state.trim &&
                _vm.$store.state.trim != 'No Trim' &&
                _vm.showLabels
            )?_c('div',{staticClass:"d-flex mkb-trim-name pt-2"},[_c('div',{staticClass:"align-items-start mr-1 "},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'caret-left']}})],1),_c('div',{staticClass:"align-self-start"},[_vm._v(" "+_vm._s(_vm.$store.state.trim)+" Gutter Trim ")])]):_vm._e()])]):(
          _vm.$store.state.type === 'Valley' ||
            _vm.$store.state.type === 'Solar Panel'
        )?_c('b-col',{attrs:{"cols":"6"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }