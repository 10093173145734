import store from "../store";

// export const profileImages = (type, color, state) => {
//   switch (type) {
//     case "Corrugated":
//       if (state.mesh.includes("Aluminium")) {
//         console.log('here')
//         switch (color) {
//           case "woodland-grey":
//             return "https://drive.google.com/uc?export=view&id=1ARjC505V4RgrISbFjHoC1q1D5R9P0eQ5";
//           case "zincalume":
//             return "https://drive.google.com/uc?export=view&id=1QHXx15qhCcVM4oGxgtnkxyn4Hj4LiQTv";
//           case "windspray":
//             return "https://drive.google.com/uc?export=view&id=1AEAGmmGl3_puDCmSO85iJak8IHZxWiL4";
//           case "wallaby":
//             return "https://drive.google.com/uc?export=view&id=1OpNsK9RiaGSp9DJcgENxbctHn46j4U7j";
//           case "wilderness":
//             return "https://drive.google.com/uc?export=view&id=1efD3EE9IbG_qVAZDeJPIdItxACT-YIlf";
//           case "surfmist":
//             return "https://drive.google.com/uc?export=view&id=1Why6f65aEof1IF1b9m-Ye2n4TA6BmB30";
//           case "terrain":
//             return "https://drive.google.com/uc?export=view&id=17AFUMYkG8Majkog2Hf_p1yo5vq8jae9W";
//           case "sandbank":
//             return "https://drive.google.com/uc?export=view&id=1XZ0xxEqueEieILpw6dYP2Hn4UKG7ZM-c";
//           case "shale-grey":
//             return "https://drive.google.com/uc?export=view&id=1iwUX8npcS08ZZosPvqpfpAj5lV1p9pVg";
//           case "pale-terra":
//             return "https://drive.google.com/uc?export=view&id=1a5CCyR0M2AdXE2PN7IRMr8aJRqdwYLbd";
//           case "paperbark":
//             return "https://drive.google.com/uc?export=view&id=1EGPZ-SzuC1li6kUnjSc6fptwhPEmRoRE";
//           case "night-sky":
//             return "https://drive.google.com/uc?export=view&id=1Tg-LmBGMlPr0Fwv-AzjxM2Ewg28W2FBh";
//           case "pale-eucal":
//             return "https://drive.google.com/uc?export=view&id=1eQrmKvYJmDOeUyxEWOB7aPW_V_BoSrdJ";
//           case "mangrove":
//             return "https://drive.google.com/uc?export=view&id=1ss0Y3UYjuX4NfRZ66zz6oevNPAsf00ep";
//           case "manor-red":
//             return "https://drive.google.com/uc?export=view&id=1VnUVWtLOysd53dEDCw7RWMsPCVS-ZPvZ";
//           case "monument":
//             return "https://drive.google.com/uc?export=view&id=18_NO6ou3pxs3aU9qASX9VoZNV4uWPuHN";
//           case "jasper":
//             return "https://drive.google.com/uc?export=view&id=1ZaVEdfzOR2pQ1W9yu4nMkUvXp6KkM4oC";
//           case "loft":
//             return "https://drive.google.com/uc?export=view&id=1jZ4qSDldV7xpnLmDSg4x5P9YLiDO5NEB";
//           case "headland":
//             return "https://drive.google.com/uc?export=view&id=1ZE3zQ_x6_rMY9FyqB81aoy-VIr_f5ayT";
//           case "ironstone":
//             return "https://drive.google.com/uc?export=view&id=1Ob8SHGrJCL1gqjQOZgqKdS5tRbs5imbY";
//           case "galvanise":
//             return "https://drive.google.com/uc?export=view&id=1XItueOykC56-9_H_JjneHQeulkagyg8B";
//           case "gully":
//             return "https://drive.google.com/uc?export=view&id=1GhpQIfLbMupm-gHcjnR32VfRYsD1Csyu";
//           case "dune":
//             return "https://drive.google.com/uc?export=view&id=18E_G1BCw7dO_WiSB7NGqKXgiEHL8A7O8";
//           case "evening-haze":
//             return "https://drive.google.com/uc?export=view&id=1fjHbhWtMwKE1ZUKKLJgXinAq3l7nvn16";
//           case "deep-ocean":
//             return "https://drive.google.com/uc?export=view&id=1rI9uKZm07IPg-mRN8yCbIbpkm3DrAWTa";
//           case "classic-cream":
//             return "https://drive.google.com/uc?export=view&id=1B5uz9uLHpSkWiJnAJCXET7-jd8oapGcl";
//           case "cottage-green":
//             return "https://drive.google.com/uc?export=view&id=1E3lf5d23P3HWDPRKsbWKhkRWDJbLB4YO";
//           case "cove":
//             return "https://drive.google.com/uc?export=view&id=1HG5xfPTeluA2OjsiNY_S_gnveIaPwKZS";
//           case "basalt":
//             return "https://drive.google.com/uc?export=view&id=13Gy8CnKev2674F-C_DbvTnM1zNCUQR41";
//           case "bushland":
//             return "https://drive.google.com/uc?export=view&id=1ij2IJMgiiLtrL2pskvlkD0wgOmICBIIF";
//           default:
//             return "https://drive.google.com/uc?export=view&id=1XItueOykC56-9_H_JjneHQeulkagyg8B";
//         }
//       } else {
//         if (state.type === "Valley") {
//           switch (color) {
//             case "basalt":
//               return "https://drive.google.com/uc?export=view&id=1XnfvIx1-5xTX9g3ySu_zCNFyxaKNSq1o";
//             case "bushland":
//               return "https://drive.google.com/uc?export=view&id=1rYakh_zxLvASTT5-gPIjiQ-RV1KX2fkm";
//             case "classic-cream":
//               return "https://drive.google.com/uc?export=view&id=1KiWyqfCDWu6cs05nJqgqZ12gEz7EIhax";
//             case "cottage-green":
//               return "https://drive.google.com/uc?export=view&id=1cE7w6sMEWVPfJSQ5I_8Tl3P00kdtKZfg";
//             case "cove":
//               return "https://drive.google.com/uc?export=view&id=1CHFW1GFXuJ25_FH5gjj7LUJx3rzH_6XF";
//             case "deep-ocean":
//               return "https://drive.google.com/uc?export=view&id=1PeNwKugqPEE-UOKScB0lPQGfsumadX2B";
//             case "dune":
//               return "https://drive.google.com/uc?export=view&id=1VUNSKKzN2T9WkPJaXSEDqyPq1f_iXqYZ";
//             case "evening-haze":
//               return "https://drive.google.com/uc?export=view&id=1IxAlay7j9nmClTLrpIDliHTBXwD3HXC5";
//             case "galvanise":
//               return "https://drive.google.com/uc?export=view&id=1fuu6bwYdaU34jFVHRC092RkVKcdDb4ML";
//             case "gully":
//               return "https://drive.google.com/uc?export=view&id=1X3BMeCez_jXl6WJyk7b3vc5ZzRSo5Od0";
//             case "headland":
//               return "https://drive.google.com/uc?export=view&id=1Z36QDu9jH_hQtJunPMckSo_RUXYpxAY5";
//             case "ironstone":
//               return "https://drive.google.com/uc?export=view&id=1C7pTp7qJC9q77dXjmDNQEREOLl9XPM9S";
//             case "jasper":
//               return "https://drive.google.com/uc?export=view&id=1A9YLCqpu95wZWtcZ5zimYU9cBspJW_66";
//             case "loft":
//               return "https://drive.google.com/uc?export=view&id=1Mo--F4gXXbDUDnfTt_U6eCZnu69dHXn_";
//             case "mangrove":
//               return "https://drive.google.com/uc?export=view&id=1eOewsUVNsoRThXswFWXPAgiRD1y0wAUv";
//             case "manor-red":
//               return "https://drive.google.com/uc?export=view&id=1h2ewH_MV63UEN68TaHGM0FPlLRzFQkUA";
//             case "monument":
//               return "https://drive.google.com/uc?export=view&id=1DzyIuVhCPnKyB7Jq7luxxtBytsVtX2Ah";
//             case "night-sky":
//               return "https://drive.google.com/uc?export=view&id=1HceMh1H4iTPhtDZvbXuKvoQVn4795KjD";
//             case "pale-eucal":
//               return "https://drive.google.com/uc?export=view&id=14zTxICy5Fh7WlqLsaixbui4pS4qszrec";
//             case "pale-terra":
//               return "https://drive.google.com/uc?export=view&id=1B26h6eWqYxHpLruIUmVYJemLa6VXdH8N";
//             case "paperbark":
//               return "https://drive.google.com/uc?export=view&id=14KGwblYrPy2U3EnuQUPOnfbTNEHTCUID";
//             case "sandbank":
//               return "https://drive.google.com/uc?export=view&id=1ZPqhSC9HUdyZVl6TFbDp2OjHwnhhwHAv";
//             case "shale-grey":
//               return "https://drive.google.com/uc?export=view&id=1CGAyilUBGAQ8Kpa_DoDwHozghf7qiFfC";
//             case "surfmist":
//               return "https://drive.google.com/uc?export=view&id=1CGAyilUBGAQ8Kpa_DoDwHozghf7qiFfC";
//             case "terrain":
//               return "https://drive.google.com/uc?export=view&id=1mhQbPkQkEWL1vQkdnr_bq1TSnTE4MpgI";
//             case "wallaby":
//               return "https://drive.google.com/uc?export=view&id=1WoJAyxToLAJQVCO7ycZNII1FncVCgL_T";
//             case "wilderness":
//               return "https://drive.google.com/uc?export=view&id=1A6AcAUL6y4MtkLuv5frEnZh2LJBQf3jw";
//             case "windspray":
//               return "https://drive.google.com/uc?export=view&id=1-ZQEb3KC365Q-3tpYnHwfRiqfGu9o4Ss";
//             case "woodland-grey":
//               return "https://drive.google.com/uc?export=view&id=1idwtk_6mojOiiSSaqClE-FGle5oqXyYq";
//             case "zincalume":
//               return "https://drive.google.com/uc?export=view&id=1FI9ImT50UZNdUpVGLr12NjVDN1dOqKB5";
//             default:
//               return "https://drive.google.com/uc?export=view&id=1fuu6bwYdaU34jFVHRC092RkVKcdDb4ML";
//           }
//         } else {
//           switch (color) {
//             case "woodland-grey":
//               return "https://drive.google.com/uc?export=view&id=1A0ls4WgzgYcnLNHAWalgH6BVT6zSWRm-";
//             case "zincalume":
//               return "https://drive.google.com/uc?export=view&id=1DYIZhdE8BrJXIHL0ZrjR5ZQoO7zpLmzt";
//             case "windspray":
//               return "https://drive.google.com/uc?export=view&id=1A6RGoM9TrXSFyYmrObgWEc6EoRE7PJie";
//             case "wallaby":
//               return "https://drive.google.com/uc?export=view&id=13C7tuMmccRUvVJYIbQDR2OQqIpPTxOKz";
//             case "wilderness":
//               return "https://drive.google.com/uc?export=view&id=13edT82Vz0kptdLMrTsp355l7v9Tajbxh";
//             case "surfmist":
//               return "https://drive.google.com/uc?export=view&id=1iD_k2OFH0CmcNvXdYXb2LsdsMIiWGpRE";
//             case "terrain":
//               return "https://drive.google.com/uc?export=view&id=1oJfARQ5R8nQPI0W0e6hb3Kl-n32pGo6u";
//             case "sandbank":
//               return "https://drive.google.com/uc?export=view&id=1F88Zl7I5ymOj6OvjH5jLICR0qC-kAhWl";
//             case "shale-grey":
//               return "https://drive.google.com/uc?export=view&id=1tcVUlh5XvTy_CYeqlWMbMz4K1-0sKQxw";
//             case "pale-terra":
//               return "https://drive.google.com/uc?export=view&id=1iKZTPU_FLk9Z7V-aSnWASH5GCITORnXG";
//             case "paperbark":
//               return "https://drive.google.com/uc?export=view&id=1FF_nvnLrm5ER5_KGcD-6MrR2WTXp4OSO";
//             case "night-sky":
//               return "https://drive.google.com/uc?export=view&id=1F3kj4WMx5oC1LOvpgxawIuaTD85KalXb";
//             case "pale-eucal":
//               return "https://drive.google.com/uc?export=view&id=1bQau5AW2u96t1JAuipDfTlCGUDh2O1nT";
//             case "mangrove":
//               return "https://drive.google.com/uc?export=view&id=1BNwtIrjj-pGGAUJbr-5X1CTdWWwM5FLe";
//             case "manor-red":
//               return "https://drive.google.com/uc?export=view&id=1ihaqX9O5MBed1gPYikR4_LaKdQciecHR";
//             case "monument":
//               return "https://drive.google.com/uc?export=view&id=1cHbFgej6IjrsYWPW-EDyOqvYfYd6d-Yb";
//             case "jasper":
//               return "https://drive.google.com/uc?export=view&id=1SSxOCChLWCtVoC6K5irFQhDakdHLFRU3";
//             case "loft":
//               return "https://drive.google.com/uc?export=view&id=1z6NNjxhLLbS-09mPKvE5NEIgnYNMdrOO";
//             case "headland":
//               return "https://drive.google.com/uc?export=view&id=1nIJi4bRAh-bJeRsDPc39w_RVZwE9MH-U";
//             case "ironstone":
//               return "https://drive.google.com/uc?export=view&id=1ABDkCCXXebHrtBs4O8B9YWjm2-qyuzWv";
//             case "galvanise":
//               return "https://drive.google.com/uc?export=view&id=1kY91Yy47YsBSeFimDNTchlOgRKnizLRY";
//             case "gully":
//               return "https://drive.google.com/uc?export=view&id=1PEDQ8C9_QfTS718i_zr04jePUJNwAPSR";
//             case "dune":
//               return "https://drive.google.com/uc?export=view&id=1WYR_7Zp72RnDgyRsEcNOntwa4VCrf2Vj";
//             case "evening-haze":
//               return "https://drive.google.com/uc?export=view&id=1i1c05mq7pI2gWmxQQi82Qcf4VEE-8cK0";
//             case "deep-ocean":
//               return "https://drive.google.com/uc?export=view&id=1lBiM9oo268tq_w3LyVlV_d4FoEFOzUaE";
//             case "classic-cream":
//               return "https://drive.google.com/uc?export=view&id=1TdRbjFmMyNDT-MMio77H3BNNDhG7Buzw";
//             case "cottage-green":
//               return "https://drive.google.com/uc?export=view&id=1-vVyq0eTdNdHBb19jUzQ1CDa69J-B6eG";
//             case "cove":
//               return "https://drive.google.com/uc?export=view&id=1tw76zH2rPKYGRsYzfGCNf7egqV14PIvb";
//             case "basalt":
//               return "https://drive.google.com/uc?export=view&id=11hNATwSna00nzV6sIiLdTaZNxMHCDqSv";
//             case "bushland":
//               return "https://drive.google.com/uc?export=view&id=1Vsvj-bOzq095zEhmHUzFOfx-zz7d_S_n";
//             default:
//               return "https://drive.google.com/uc?export=view&id=1kY91Yy47YsBSeFimDNTchlOgRKnizLRY";
//           }
//         }
//       }
//     case "Tile":
//       if (state.mesh.includes("Aluminium")) {

//         switch (color) {

//           case "woodland-grey":
//             return "https://drive.google.com/uc?export=view&id=1ARjC505V4RgrISbFjHoC1q1D5R9P0eQ5";
//           case "zincalume":
//             return "https://drive.google.com/uc?export=view&id=1QHXx15qhCcVM4oGxgtnkxyn4Hj4LiQTv";
//           case "windspray":
//             return "https://drive.google.com/uc?export=view&id=1AEAGmmGl3_puDCmSO85iJak8IHZxWiL4";
//           case "wallaby":
//             return "https://drive.google.com/uc?export=view&id=1OpNsK9RiaGSp9DJcgENxbctHn46j4U7j";
//           case "wilderness":
//             return "https://drive.google.com/uc?export=view&id=1efD3EE9IbG_qVAZDeJPIdItxACT-YIlf";
//           case "surfmist":
//             return "https://drive.google.com/uc?export=view&id=1Why6f65aEof1IF1b9m-Ye2n4TA6BmB30";
//           case "terrain":
//             return "https://drive.google.com/uc?export=view&id=17AFUMYkG8Majkog2Hf_p1yo5vq8jae9W";
//           case "sandbank":
//             return "https://drive.google.com/uc?export=view&id=1XZ0xxEqueEieILpw6dYP2Hn4UKG7ZM-c";
//           case "shale-grey":
//             return "https://drive.google.com/uc?export=view&id=1iwUX8npcS08ZZosPvqpfpAj5lV1p9pVg";
//           case "pale-terra":
//             return "https://drive.google.com/uc?export=view&id=1a5CCyR0M2AdXE2PN7IRMr8aJRqdwYLbd";
//           case "paperbark":
//             return "https://drive.google.com/uc?export=view&id=1EGPZ-SzuC1li6kUnjSc6fptwhPEmRoRE";
//           case "night-sky":
//             return "https://drive.google.com/uc?export=view&id=1Tg-LmBGMlPr0Fwv-AzjxM2Ewg28W2FBh";
//           case "pale-eucal":
//             return "https://drive.google.com/uc?export=view&id=1eQrmKvYJmDOeUyxEWOB7aPW_V_BoSrdJ";
//           case "mangrove":
//             return "https://drive.google.com/uc?export=view&id=1ss0Y3UYjuX4NfRZ66zz6oevNPAsf00ep";
//           case "manor-red":
//             return "https://drive.google.com/uc?export=view&id=1VnUVWtLOysd53dEDCw7RWMsPCVS-ZPvZ";
//           case "monument":
//             return "https://drive.google.com/uc?export=view&id=18_NO6ou3pxs3aU9qASX9VoZNV4uWPuHN";
//           case "jasper":
//             return "https://drive.google.com/uc?export=view&id=1ZaVEdfzOR2pQ1W9yu4nMkUvXp6KkM4oC";
//           case "loft":
//             return "https://drive.google.com/uc?export=view&id=1jZ4qSDldV7xpnLmDSg4x5P9YLiDO5NEB";
//           case "headland":
//             return "https://drive.google.com/uc?export=view&id=1ZE3zQ_x6_rMY9FyqB81aoy-VIr_f5ayT";
//           case "ironstone":
//             return "https://drive.google.com/uc?export=view&id=1Ob8SHGrJCL1gqjQOZgqKdS5tRbs5imbY";
//           case "galvanise":
//             return "https://drive.google.com/uc?export=view&id=1XItueOykC56-9_H_JjneHQeulkagyg8B";
//           case "gully":
//             return "https://drive.google.com/uc?export=view&id=1GhpQIfLbMupm-gHcjnR32VfRYsD1Csyu";
//           case "dune":
//             return "https://drive.google.com/uc?export=view&id=18E_G1BCw7dO_WiSB7NGqKXgiEHL8A7O8";
//           case "evening-haze":
//             return "https://drive.google.com/uc?export=view&id=1fjHbhWtMwKE1ZUKKLJgXinAq3l7nvn16";
//           case "deep-ocean":
//             return "https://drive.google.com/uc?export=view&id=1rI9uKZm07IPg-mRN8yCbIbpkm3DrAWTa";
//           case "classic-cream":
//             return "https://drive.google.com/uc?export=view&id=1B5uz9uLHpSkWiJnAJCXET7-jd8oapGcl";
//           case "cottage-green":
//             return "https://drive.google.com/uc?export=view&id=1E3lf5d23P3HWDPRKsbWKhkRWDJbLB4YO";
//           case "cove":
//             return "https://drive.google.com/uc?export=view&id=1HG5xfPTeluA2OjsiNY_S_gnveIaPwKZS";
//           case "basalt":
//             return "https://drive.google.com/uc?export=view&id=13Gy8CnKev2674F-C_DbvTnM1zNCUQR41";
//           case "bushland":
//             return "https://drive.google.com/uc?export=view&id=1ij2IJMgiiLtrL2pskvlkD0wgOmICBIIF";
//           default:
//             return "https://drive.google.com/uc?export=view&id=1XItueOykC56-9_H_JjneHQeulkagyg8B";
//         }
//       } else {
//         return "https://drive.google.com/uc?export=view&id=1lqP7nJJYxQl78rtxC8Ls4NJpJURFYHyb";
//       }
//     case "Klip-Lok":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1HKmx9EV-gZdMSnQ6SBS__-fVkdcvpCq-";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1nNoAUOe0VDwGZEf7jf0wox5GKKvjDZxP";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1fuEaHulEJ5ft0eLTK5Hn1wH9m1bjJf0B";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1v8k7Q4RvKYfASk1uZTa1zT9wkBmNEYPH";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1rEhFJvGlRKepI3L8chLH0MyjiPktQVwZ";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1r-4-arBRCJ_3JG_bQNdxBqqtnQ9i_pv4";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=166AiiSKhVUoGk_QeqUq9uheK7nAJCnR5";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1HQ2fvVEWfJF6gj_KUtcMgacdFXIn3jvk";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1tIzUq2XVLmzg1Lnt27m20UwuopBc9EAn";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=13sbaCajIRZBoIyUGEekLYR1hP9E8pl8z";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=11ytYIwMp49hT9y_jwEAdME47rAzro6YY";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=1lFXNKR9wvvz0i6CuNXrwtMEkZUx9Afl4";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1pxCm_z0zEg4sFLsyq1rbkpxm4IATGOuR";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=1D_vj5dbBhvT851XbcYc-hFeHLOMw7HcT";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=1D-qGHX-VpYNrPGyEwFs8LaiQ31wNFE2j";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=153UY2GlE9dJnht3I2RIBBszWsWs88nDag";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1oMnaxdaN1iwYpNy-yJ_GMDkmciM9e3i_";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=12ciaK3W9RmvDlFQ7l3PQlfeenldX60ED";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1euWzQAU-scw_ciyNU0kVCKv012vJ90ii";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1fLFbsNxPpFnLW8qhMA8WTzyjO-N04Bza";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1CXDEvB0wPZ_612e0lwnkqRZ49MvrR6lS";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=1gTKwNplHtfua8bgouPjyVmNG0rIb042s";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1fswHsT6IFpzn-QtzXtOCWh5W7naEl2d3";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1VKX4PiI_cWf-sI47svnfllsGuBA2nT_z";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1kleQpsTgmkQTFj7cuJoGWcvWvHMIgTBd";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=15kd52wUTMy7O_lJ7vRFUiAcGtaJM3Z21";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1I0sqkIWk1HChscmlzG3w51oUjyLvGcOl";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=1-GHuLANFv2Xto_gGwU5iFSX6PuccOoyj";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=1VI8PxaoSSsAPxZx1cfQfdBd5FO5wLBHD";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1NE9AYRgPyMY8eiJY0JPYiRcT5XIpjfzv";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1CXDEvB0wPZ_612e0lwnkqRZ49MvrR6lS";
//       }
//     case "Lokdek 680/700":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1HKmx9EV-gZdMSnQ6SBS__-fVkdcvpCq-";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1nNoAUOe0VDwGZEf7jf0wox5GKKvjDZxP";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1fuEaHulEJ5ft0eLTK5Hn1wH9m1bjJf0B";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1v8k7Q4RvKYfASk1uZTa1zT9wkBmNEYPH";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1rEhFJvGlRKepI3L8chLH0MyjiPktQVwZ";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1r-4-arBRCJ_3JG_bQNdxBqqtnQ9i_pv4";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=166AiiSKhVUoGk_QeqUq9uheK7nAJCnR5";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1HQ2fvVEWfJF6gj_KUtcMgacdFXIn3jvk";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1tIzUq2XVLmzg1Lnt27m20UwuopBc9EAn";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=13sbaCajIRZBoIyUGEekLYR1hP9E8pl8z";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=11ytYIwMp49hT9y_jwEAdME47rAzro6YY";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=1lFXNKR9wvvz0i6CuNXrwtMEkZUx9Afl4";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1pxCm_z0zEg4sFLsyq1rbkpxm4IATGOuR";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=1D_vj5dbBhvT851XbcYc-hFeHLOMw7HcT";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=1D-qGHX-VpYNrPGyEwFs8LaiQ31wNFE2j";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=153UY2GlE9dJnht3I2RIBBszWsWs88nDag";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1oMnaxdaN1iwYpNy-yJ_GMDkmciM9e3i_";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=12ciaK3W9RmvDlFQ7l3PQlfeenldX60ED";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1euWzQAU-scw_ciyNU0kVCKv012vJ90ii";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1fLFbsNxPpFnLW8qhMA8WTzyjO-N04Bza";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1CXDEvB0wPZ_612e0lwnkqRZ49MvrR6lS";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=1gTKwNplHtfua8bgouPjyVmNG0rIb042s";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1fswHsT6IFpzn-QtzXtOCWh5W7naEl2d3";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1VKX4PiI_cWf-sI47svnfllsGuBA2nT_z";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1kleQpsTgmkQTFj7cuJoGWcvWvHMIgTBd";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=15kd52wUTMy7O_lJ7vRFUiAcGtaJM3Z21";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1I0sqkIWk1HChscmlzG3w51oUjyLvGcOl";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=1-GHuLANFv2Xto_gGwU5iFSX6PuccOoyj";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=1VI8PxaoSSsAPxZx1cfQfdBd5FO5wLBHD";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1NE9AYRgPyMY8eiJY0JPYiRcT5XIpjfzv";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1CXDEvB0wPZ_612e0lwnkqRZ49MvrR6lS";
//       }
//     case "Trimdek":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1H18m0hZnzxIE4hIfdlQlvdursmYz4uQN";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1UqzhIWPmATc75D54I5FPNRs_12eNHQj7";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1RekpET2BuA9JnWET0VRedIiRwwhTwCdO";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1sEJcMLKcrO12DtjPJ1VqRvREbNwdDgtV";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1h6i1mw70f_pSpgjBQ1dbDKfs_lPO2l_w";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1cyx2IanBXpB4bILUwDhSmp0d7_CT2-gl";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=14td7lgk_D32U8Ljn46QHuNBlncSqkSME";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=12AK19n2X5ztDou5fRaoR2G5P5ZRpuADn";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1bTxfxK_Cl8hY66Lcqpoe8sqD1xVe9oxE";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1Zcp7NbpHi4oSVT8tANUGsLvKwWQ4l2CL";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=1HdL0cq_l7kxV5ZF3TanLw1xBcNtqdRga";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=1JcrHH2nf2Ozt05owVj7mJbN_KNaBaxfh";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1t_oFfLK8MqfEA2Js-PCS3IrBrdgCgqlK";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=12TRk8lYHwnHks1f0zxPA3ScQy49hwsv3";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=12bNjFUj50ZLYRlS6pO9xbxacPXEGzfu0";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=14Sz6xNioWjkNNuYwps8f1Hd1rEu9ORUj";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1tb1MYonHd0MKRDwSW5hMHuT3r_t5kCNz";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=1VM52Dujic5s3MNOGXQHxOXwZs_6Ip0vP";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1icbfocjs7xD0j3qQGJLOrUrVdKw6HiFz";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1rZbwVyR-u7CVpeqtFmoksAxSd_lU29mS";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=12OdU5FJSWYfRyHPK5c9VMvfIFsqVce1d";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=137sMUzOCO8f7CnjxNQouma8fpTVA5JHq";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1P5bJVZ_MV40aW4ztw0pK1iBMuJ5kHLdS";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=11iRLcysAlY8X8S7GswpWDmaM-11oyv4E";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1ML2MMC2OxScmaBWFI8XqZRxZukhI14yT";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=11BOu0igIGBTxd6CxUHIlBQyCaetOWdB_";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1FkK3mSzLXbuGhUWG7eDnqXODaAzpelQ6";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=1pv-Vpm9-x2C9TJroZiHKpOkRzRkD66TP";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=1J7ccGtyo0QZ_943AEqpZEVaSjP06i1iO";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1_3Ck9WdWdccXrWLcN03my5VYnfgw-r9n";
//         default:
//           return "https://drive.google.com/uc?export=view&id=12OdU5FJSWYfRyHPK5c9VMvfIFsqVce1d";
//       }
//     case "SteelClad":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1H18m0hZnzxIE4hIfdlQlvdursmYz4uQN";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1UqzhIWPmATc75D54I5FPNRs_12eNHQj7";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1RekpET2BuA9JnWET0VRedIiRwwhTwCdO";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1sEJcMLKcrO12DtjPJ1VqRvREbNwdDgtV";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1h6i1mw70f_pSpgjBQ1dbDKfs_lPO2l_w";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1cyx2IanBXpB4bILUwDhSmp0d7_CT2-gl";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=14td7lgk_D32U8Ljn46QHuNBlncSqkSME";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=12AK19n2X5ztDou5fRaoR2G5P5ZRpuADn";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1bTxfxK_Cl8hY66Lcqpoe8sqD1xVe9oxE";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1Zcp7NbpHi4oSVT8tANUGsLvKwWQ4l2CL";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=1HdL0cq_l7kxV5ZF3TanLw1xBcNtqdRga";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=1JcrHH2nf2Ozt05owVj7mJbN_KNaBaxfh";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1t_oFfLK8MqfEA2Js-PCS3IrBrdgCgqlK";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=12TRk8lYHwnHks1f0zxPA3ScQy49hwsv3";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=12bNjFUj50ZLYRlS6pO9xbxacPXEGzfu0";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=14Sz6xNioWjkNNuYwps8f1Hd1rEu9ORUj";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1tb1MYonHd0MKRDwSW5hMHuT3r_t5kCNz";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=1VM52Dujic5s3MNOGXQHxOXwZs_6Ip0vP";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1icbfocjs7xD0j3qQGJLOrUrVdKw6HiFz";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1rZbwVyR-u7CVpeqtFmoksAxSd_lU29mS";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=12OdU5FJSWYfRyHPK5c9VMvfIFsqVce1d";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=137sMUzOCO8f7CnjxNQouma8fpTVA5JHq";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1P5bJVZ_MV40aW4ztw0pK1iBMuJ5kHLdS";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=11iRLcysAlY8X8S7GswpWDmaM-11oyv4E";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1ML2MMC2OxScmaBWFI8XqZRxZukhI14yT";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=11BOu0igIGBTxd6CxUHIlBQyCaetOWdB_";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1FkK3mSzLXbuGhUWG7eDnqXODaAzpelQ6";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=1pv-Vpm9-x2C9TJroZiHKpOkRzRkD66TP";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=1J7ccGtyo0QZ_943AEqpZEVaSjP06i1iO";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1_3Ck9WdWdccXrWLcN03my5VYnfgw-r9n";
//         default:
//           return "https://drive.google.com/uc?export=view&id=12OdU5FJSWYfRyHPK5c9VMvfIFsqVce1d";
//       }
//     case "Bullnose":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1SbS9Cq3tze0oucAM2SfAalX9agfbPwoz";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1Zmc_excVOsw754iVnNzYpkh7EWFFHxaX";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1Pk2azsRyUMZ7dcDagzWT_hOR8WgSceO-";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1fcMrWUFO1m8E54Ro84j9jgJ8KlzrtTQj";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1kx662-w1aW6BUNltrynJjBvqZ1FihJBd";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1rarUisTE_lzuLaUt7KMJCNZHeSJ1A19-";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=1Hsmy26gZUFEG73tAUXxBl6Rn8EhNwUyV";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1hGVh28WivF61AJ_vw_Y1dusiDdMhy1af";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1bjhhNv3FpvWy1PfezpVNTasnACm9J-DI";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1Knu9I4BDuatgvisbaDhr-gexcpQhmlmS";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=1BTgE9Yh8MUYTQNP5mNeRZeZGkqdhC_oR";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=1k98qeRabFmS_OjkOP0x07IlJqqhyVwLk";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1Z8B42pKiY0Zf0yP7V1u2ZamBFkm0CyVs";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=1mKTfXX_o9T815fTcIqZkPrUvEfyuvJik";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=1ZG7N0nTYWxCpExi_LgnoUB172sD3TEpc";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=1KPzMjiK_atGYXR18G7cMnesLsJtkedCq";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1S7lRrmEi-xeMgNFsqozh0encZGyfwuuf";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=157OSqDpQTH_DmG9Mkq-r28wR-EBUnQai";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1eTk1tUjpEfOgqjVmPddaJDbApm3xOpe_";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1SqE5XIcfGaQeddLiRU7UNsUkvgxMSwXa";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1xjdzW7V4d6T-cVncZXRTJH-UadMBvbSi";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=1TVX7UyVOgXXOG5CSs2-8cIT7f8BCraaw";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1clbfMIzqb4bVyZ57G_rEHoId-TJEorju";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1XYwcO0IGF18M_bYbns1Di_Z9glAa_uRS";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1NVtAesjif3ZM9SFQ3XbAUeUpzxSQVdIj";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=1etNinZ_9NIN8sb6F77Srui4QqP80WIlj";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1sp6KSrj0FLPVMLfD4laiRub3iEli2isz";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=161rydis-8YC3OY9Z0Ja0tfWxwaFEVNvy";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=1zStPqzLFmW2fA_OQ9iZTwPhEziEMPMlng";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1jcDCQB0YQH8o0pteNgTfXpIp4gPnxZZg";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1xjdzW7V4d6T-cVncZXRTJH-UadMBvbSi";
//       }
//     case "Spandek":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=15sIwathtQDRUmIg5_zP9gtScp25ps9sW";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=16be_3R7wStnb1IxLLEE5-EI9F7TwGrqw";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1QXO6HVHNOYR_vFgRcGxopbwZJj8_JIWV";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1M_3VMcpST2EpRbdx1T85K860a8N5yq1E";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1-T3H8AXo7gDoRpDO7ylTbocYrD6-UwcZ";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1TPdk9wBpDWWW7DqOJU9qYpFRuNgPDiBB";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=1jXw61rKJs4xHWt3IZ855U8mqWvk5HP9_";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1aOKlUv1PNjglZRDOOI_RFfkLw1LL-CuJ";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=11DZTF6UnCqUkpsAI6LuHU-JtXY36SNrq";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1uZ8R33m7No7b5am8WGiTZFMPKeuABv24";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=1H_ozHfZHnFyZVfCFwfZo0bRP-6hfer-E";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=19oO7A62qc7UUZcjJCb2xuChqDUKiqoQC";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1UbQSNMGPT3mDOlSJaJMxdgpqPFlky4pY";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=1BvKd1F5ykb1I1elwdmlAlYAhdpTXgkSO";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=12TsHIX6WOQZgYjergQt15OHmzhTov0l6";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=17RiuYhsUMeMsq1MWg5PXWxZt4yUXg1zj";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1t3FxIgH0QBKQEZKj-cOElvl4in58K8sm";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=1BMeIjJe2hLK4kcinZpnOKHcZo2duyTog";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1PlamuhByCoidK56wu3yKlPg4zGcesjtu";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1GjVyWm5vFy9Qu7TsFH2EIke6masL5OzM";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1trpY-M1vp46XXVsvmSLpDJYPA_agUKsd";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=1cS5W-Sq4KDjkbsGkhHdfXUybj4UK9cNh";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1U2mU_6tH5swVqyuzWwMDTyZUmGrts8fn";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1U2mU_6tH5swVqyuzWwMDTyZUmGrts8fn";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=14stiTP2xcpgSmMDQq64u4v-v6WHGlKZc";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=1DQMHH6PFYbaSqggQ5ccSy-3aIXZu4H8j";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1dkqDs42oI3rnmPk0xRG-2FERJGBrU9CO";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=174hGGi544jvb-kJW823R_oK9fj3osP6S";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=173G4jal4TJtsoZu51VXRljV3RELJaS3e";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1GEQlodmXi0DGKu_J1rEox-IIu_0SQAeq";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1trpY-M1vp46XXVsvmSLpDJYPA_agUKsd";
//       }
//     case "Steelspan":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=15sIwathtQDRUmIg5_zP9gtScp25ps9sW";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=16be_3R7wStnb1IxLLEE5-EI9F7TwGrqw";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1QXO6HVHNOYR_vFgRcGxopbwZJj8_JIWV";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1M_3VMcpST2EpRbdx1T85K860a8N5yq1E";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1-T3H8AXo7gDoRpDO7ylTbocYrD6-UwcZ";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1TPdk9wBpDWWW7DqOJU9qYpFRuNgPDiBB";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=1jXw61rKJs4xHWt3IZ855U8mqWvk5HP9_";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1aOKlUv1PNjglZRDOOI_RFfkLw1LL-CuJ";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=11DZTF6UnCqUkpsAI6LuHU-JtXY36SNrq";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1uZ8R33m7No7b5am8WGiTZFMPKeuABv24";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=1H_ozHfZHnFyZVfCFwfZo0bRP-6hfer-E";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=19oO7A62qc7UUZcjJCb2xuChqDUKiqoQC";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1UbQSNMGPT3mDOlSJaJMxdgpqPFlky4pY";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=1BvKd1F5ykb1I1elwdmlAlYAhdpTXgkSO";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=12TsHIX6WOQZgYjergQt15OHmzhTov0l6";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=17RiuYhsUMeMsq1MWg5PXWxZt4yUXg1zj";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1t3FxIgH0QBKQEZKj-cOElvl4in58K8sm";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=1BMeIjJe2hLK4kcinZpnOKHcZo2duyTog";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1PlamuhByCoidK56wu3yKlPg4zGcesjtu";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1GjVyWm5vFy9Qu7TsFH2EIke6masL5OzM";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1trpY-M1vp46XXVsvmSLpDJYPA_agUKsd";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=1cS5W-Sq4KDjkbsGkhHdfXUybj4UK9cNh";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1U2mU_6tH5swVqyuzWwMDTyZUmGrts8fn";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1U2mU_6tH5swVqyuzWwMDTyZUmGrts8fn";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=14stiTP2xcpgSmMDQq64u4v-v6WHGlKZc";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=1DQMHH6PFYbaSqggQ5ccSy-3aIXZu4H8j";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1dkqDs42oI3rnmPk0xRG-2FERJGBrU9CO";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=174hGGi544jvb-kJW823R_oK9fj3osP6S";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=173G4jal4TJtsoZu51VXRljV3RELJaS3e";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1GEQlodmXi0DGKu_J1rEox-IIu_0SQAeq";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1trpY-M1vp46XXVsvmSLpDJYPA_agUKsd";
//       }
//     case "Longspan":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1d9SmzXZE64kPNhc9aG9GDccWPDHIlaxi";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1sVVbnfpgaaFQqPsXaLfUvNYWgFi39_e0";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1cqM7n54qXGxuQ2zg8tGGbT4wd6CKX33D";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1u8VuC5LZgfuse7igzeSM-DJHAZ6PqNK4";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1kWu_Wzf_nyMcRYPKTiqYS9qamYsYNoSy";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1ZLJMr3Zzm7HZ4Wk6m-tQ03ksazEJQuj_";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=1Q5k-WkM8qD5fYNva7eyF4aRJ5snsL6Bc";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1u0-nmmd2PBHjABsDDRGLCLVkbNPjMfAv";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1gralHwqQaBzYaforHLBeD2yKt-Teucjc";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1Z4Wi-edWudB54a_kYc-NSxS1oFMRJHQz";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=1n9IhnvUZm8g_u6vLc5AEj-wuisxbVrn9";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=10SxlN4t6SP3NcdJ_0-ta2f1awzVjnlFb";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1Bo3AfFFdiSuRRlyV7MeMNdnTW-5R7pA-";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=10_watwppmRj_0u0-gSSxbicy_eLIGkuP";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=1m_V8W69HtqRsvLO0b3lipIEn4EPnsZBP";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=1I1dJ4kKfLA7lM_ZPHktzpgQIE2r-sbwn";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=10xeDcaP4ts7IxLfgmiXQSfePY3uFThhc";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=13mj6K9xnIaVr-EIGLHa0HP3pdvs-iNDZ";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1UbQEubkk6-kUZnJf1IHqzclZQTfSAY7t";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=12VB8rTmgWnHvyxsXpPiYy6op4xLomS-Z";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1s1wEQmZmGMagp59GuUX1vEUfrMQcElP0";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=14D-ZnIfArxlgUQTbVFx-o3Wbvnuy2Lxk";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1fzXACb0TmxeRsbaTZqzgfFuyzl8zmuzD";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1xgo2r3kDZeTafonc4QxYTCYYl7xc3zcG";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1Z8klBtSW7azns2Tao4pYTKhrO0K6yE-f";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=155S7tz6GJ2nG_gcrKdS4dO3Pqyea-yM3";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1hSBJjo5xVC33D4wvBW3pIfw92v5GDxG1";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=1v0SyWAfovbvmmZ3626ldiqqZLD0jR5K0";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=1EUZ8yVYdE5eFYndgj7TczN3cgkvqKJz2";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1fiJAUVUS2wDzFFgDzZv6RJuZvNvViZwY";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1s1wEQmZmGMagp59GuUX1vEUfrMQcElP0";
//       }
//     case "Decramastic":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1-xH7OwcwcPdOCMUNwWTvC6jbGKh-ehbX";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1gMr8M52Ee4EfQYYNg36h4h6LHXDPWzRW";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=12H3YCbANgOMIyxl_Nts8OqimaX-YthpA";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=1jwui_bx01dH6WZRFf7fVJ4TJA5sKWWAP";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=1Qnh9eYvDHRsAuh5RIzNZ196CO9UhV2Jd";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1cLnr_fn1fAuDBdtdAlhqgTB1f-Ish_wC";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=1SC8h00dCvFj3UmSL810RhICgWKdS90Uj";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1zFQSrjvspz5xKHlVdY2GCvUlTiRmrg_B";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1SEltinV6eXHRw7amO4HhcoWhA54P2MSb";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1dFwNEBij9CTwUKLU2iflneZjGxBZMxR9";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=13Fa4z3xRWpZWXUSqbYDmxOe4mwHHFtjX";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=1d3oAS9esl0lDOTR-lW6TrkHv-YLQdmo-";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1UUOf24tMXFhhc-uEoNMHa7w0F6gLHZJ-";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=1ag2qbKb3U_opVDOuuIfqQnOxqGtOiE2Y";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=1JhWsJvuP3ng5gMr8aXFx0ZDA82UcRk6r";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=1hjsTThIoNcWmbCxwWhXMT1ztV3Qr6Dei";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1lTpfRl81DlI4neJ5iA0gMPeItnG9mdki";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=1weMYBkAPtVu2zcBisT4XiJ-IC9q2B7il";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1dqEKJAA_mOTa5fqWb2Dit50JLUFzgD7q";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1mjIW7yIzc1n3-sA6-itLAGvLLRKviCdb";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1I8SRXIckvx5a2YJMvvPEXnmrLPNNYbrz";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=10msjAIzzartxedqo879YkIfkjjOKvpgj";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1WFO6B7sPTLZ9qkV7M7lTJDkXt7jeX2zS";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1L2XV__J5vPfMstWi5wZoRjMFKRWoCWJm";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1BtjZlUgDusn6oLIEFiPLoJnLZXABv0-X";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=1YqgJr3y_8PrU93eIzDSLAGGizVEMA1x_";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1M2mZsMWzwmGu6-OmRfvgp7HG1dERBLDxg";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=17Qfy5INFJenTnd3dwdyJIXCUlr6TzQFe";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=1Lek82UAwnIFFCKLZVZQ-X1-lx1PqeUYv";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1q9EtI10ODy-Q-9mNuElkU5b4jLpMHFsX";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1I8SRXIckvx5a2YJMvvPEXnmrLPNNYbrz";
//       }
//     case "Ember Guard":
//       switch (color) {
//         case "woodland-grey":
//           return "https://drive.google.com/uc?export=view&id=1A0ls4WgzgYcnLNHAWalgH6BVT6zSWRm-";
//         case "zincalume":
//           return "https://drive.google.com/uc?export=view&id=1DYIZhdE8BrJXIHL0ZrjR5ZQoO7zpLmzt";
//         case "windspray":
//           return "https://drive.google.com/uc?export=view&id=1A6RGoM9TrXSFyYmrObgWEc6EoRE7PJie";
//         case "wallaby":
//           return "https://drive.google.com/uc?export=view&id=13C7tuMmccRUvVJYIbQDR2OQqIpPTxOK";
//         case "wilderness":
//           return "https://drive.google.com/uc?export=view&id=13edT82Vz0kptdLMrTsp355l7v9Tajbxh";
//         case "surfmist":
//           return "https://drive.google.com/uc?export=view&id=1iD_k2OFH0CmcNvXdYXb2LsdsMIiWGpRE";
//         case "terrain":
//           return "https://drive.google.com/uc?export=view&id=1oJfARQ5R8nQPI0W0e6hb3Kl-n32pGo6u";
//         case "sandbank":
//           return "https://drive.google.com/uc?export=view&id=1F88Zl7I5ymOj6OvjH5jLICR0qC-kAhWl";
//         case "shale-grey":
//           return "https://drive.google.com/uc?export=view&id=1tcVUlh5XvTy_CYeqlWMbMz4K1-0sKQxw";
//         case "pale-terra":
//           return "https://drive.google.com/uc?export=view&id=1iKZTPU_FLk9Z7V-aSnWASH5GCITORnXG";
//         case "paperbark":
//           return "https://drive.google.com/uc?export=view&id=1FF_nvnLrm5ER5_KGcD-6MrR2WTXp4OSO";
//         case "night-sky":
//           return "https://drive.google.com/uc?export=view&id=1F3kj4WMx5oC1LOvpgxawIuaTD85KalXb";
//         case "pale-eucal":
//           return "https://drive.google.com/uc?export=view&id=1bQau5AW2u96t1JAuipDfTlCGUDh2O1nT";
//         case "mangrove":
//           return "https://drive.google.com/uc?export=view&id=1BNwtIrjj-pGGAUJbr-5X1CTdWWwM5FLe";
//         case "manor-red":
//           return "https://drive.google.com/uc?export=view&id=1ihaqX9O5MBed1gPYikR4_LaKdQciecHR";
//         case "monument":
//           return "https://drive.google.com/uc?export=view&id=1cHbFgej6IjrsYWPW-EDyOqvYfYd6d-Yb";
//         case "jasper":
//           return "https://drive.google.com/uc?export=view&id=1SSxOCChLWCtVoC6K5irFQhDakdHLFRU3";
//         case "loft":
//           return "https://drive.google.com/uc?export=view&id=1z6NNjxhLLbS-09mPKvE5NEIgnYNMdrOO";
//         case "headland":
//           return "https://drive.google.com/uc?export=view&id=1nIJi4bRAh-bJeRsDPc39w_RVZwE9MH-U";
//         case "ironstone":
//           return "https://drive.google.com/uc?export=view&id=1ABDkCCXXebHrtBs4O8B9YWjm2-qyuzWv";
//         case "galvanise":
//           return "https://drive.google.com/uc?export=view&id=1kY91Yy47YsBSeFimDNTchlOgRKnizLRY";
//         case "gully":
//           return "https://drive.google.com/uc?export=view&id=1PEDQ8C9_QfTS718i_zr04jePUJNwAPSR";
//         case "dune":
//           return "https://drive.google.com/uc?export=view&id=1WYR_7Zp72RnDgyRsEcNOntwa4VCrf2Vj";
//         case "evening-haze":
//           return "https://drive.google.com/uc?export=view&id=1i1c05mq7pI2gWmxQQi82Qcf4VEE-8cK0";
//         case "deep-ocean":
//           return "https://drive.google.com/uc?export=view&id=1lBiM9oo268tq_w3LyVlV_d4FoEFOzUaE";
//         case "classic-cream":
//           return "https://drive.google.com/uc?export=view&id=1TdRbjFmMyNDT-MMio77H3BNNDhG7Buzw";
//         case "cottage-green":
//           return "https://drive.google.com/uc?export=view&id=1-vVyq0eTdNdHBb19jUzQ1CDa69J-B6eG";
//         case "cove":
//           return "https://drive.google.com/uc?export=view&id=1tw76zH2rPKYGRsYzfGCNf7egqV14PIvb";
//         case "basalt":
//           return "https://drive.google.com/uc?export=view&id=11hNATwSna00nzV6sIiLdTaZNxMHCDqSv";
//         case "bushland":
//           return "https://drive.google.com/uc?export=view&id=1Vsvj-bOzq095zEhmHUzFOfx-zz7d_S_n";
//         default:
//           return "https://drive.google.com/uc?export=view&id=1kY91Yy47YsBSeFimDNTchlOgRKnizLRY";
//       }
//     default:
//       return null;
//   }
// };

export const profileImages = (profile, color, mesh) => {
  if (color === null) {
    // if color is default
    if (typeof profile === "object") {
      // if profile is corrugated
      if (store.state.type === "Valley") {
        if (store.state.valley === "Corrugated Valley Multi-Pitch Fin") {
          // if valley is Corrugated Valley Multi-Pitch Fin
          return store.state.profileImages[
            "corro-valley-multi-pitch-fin-profile-galvanise"
          ];
        } else {
          // if valley is valley roll
          return store.state.profileImages[profile[mesh] + "-galvanise"];
        }
      } else {
        return store.state.profileImages[profile[mesh] + "-galvanise"];
      }
    } else {
      return store.state.profileImages[profile + "-galvanise"];
    }
  }
  //end of default
  if (typeof profile === "object") {
    // if profile is corrugated
    if (store.state.type === "Valley") {
      // if type is valley
      if (store.state.valley === "Corrugated Valley Multi-Pitch Fin") {
        // if valley is Corrugated Valley Multi-Pitch Fin
        return store.state.profileImages[
          "corro-valley-multi-pitch-fin-profile-" + color
        ];
      } else {
        // if valley is valley roll
        return store.state.profileImages[profile[mesh] + "-" + color];
      }
    } else {
      return store.state.profileImages[profile[mesh] + "-" + color];
    }
  } else if (profile === "tile-clips") {
    return store.state.profileImages[profile + "-galvanise"];
  }
  return store.state.profileImages[profile + "-" + color];
};

export const rollImages = (meshWithColor, mesh) => {
  if (meshWithColor.includes("null"))
    return store.state.rollImages[mesh + "-galvanise"];
  return store.state.rollImages[meshWithColor];
};

export const screwImages = color => {
  if (color === null) return store.state.screwImages["screws-galvanise"];
  return store.state.screwImages["screws-" + color];
};

export const trimImages = (trimWithColor, trim) => {
  if (trimWithColor.includes("null"))
    return store.state.trimImages[trim + "-galvanise"];
  return store.state.trimImages[trimWithColor];
};
