import { render, staticRenderFns } from "./ArrowLeft.vue?vue&type=template&id=12ea0a74&scoped=true&"
var script = {}
import style0 from "./ArrowLeft.vue?vue&type=style&index=0&id=12ea0a74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ea0a74",
  null
  
)

export default component.exports