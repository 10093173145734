<template>
  <div class="mkb-container">
    <div class="mkb-kit-wrapper">
      <b-col class="p-0" md="6" cols="6">
        <img :src="meshImage" alt="Mesh Roll" class="mkb-mesh-image " />
      </b-col>
      <b-col class="mkb-profile-kit">
        <div
          class="d-flex"
          :class="$store.state.trim === 'No Trim' ? 'h-100' : ''"
        >
          <img
            v-if="$store.state.trim != 'No Trim'"
            :src="trimImage"
            class="mkb-trim-image"
            alt="Gutter trim"
          />
          <div class="d-flex flex-column">
            <img
              v-show="
                $store.state.installationType === null ||
                  $store.state.installationType === 'Saddles'
              "
              :src="profileImage"
              alt="Profile"
              class="mkb-profile-image my-auto"
              :class="{
                'mkb-long-profile':
                  $store.state.roof === 'Decramastic' ||
                  $store.state.roof === 'Bullnose'
              }"
            />
            <img
              :src="screwImage"
              alt="Screw"
              class="mkb-screw-image mb-auto"
              :style="{
                'margin-top':
                  $store.state.installationType === 'Traditional (screws only)'
                    ? '90px'
                    : 'auto'
              }"
            />
          </div>
        </div>
      </b-col>
    </div>
  </div>
</template>
<script>
import {
  rollImages,
  trimImages,
  profileImages,
  screwImages
} from "../../data/rollImages";
export default {
  computed: {
    meshImage() {
      const mesh = {
        "2mm Steel": "steel-2mm",
        "4mm Steel": "steel-4mm",
        "5.4mm Steel": "steel-5 4mm",
        "2mm Aluminium": "aluminium-2mm",
        "4mm Aluminium": "aluminium-4mm",
        "4mm Aluminium Ultra": "aluminium-4mm"
      };
      return rollImages(
        `${mesh[this.$store.state.mesh]}-${this.$store.state.meshColour}`,
        mesh[this.$store.state.mesh]
      );
      // return rollImages(
      //   this.$store.state.mesh,
      //   this.$store.state.meshColour,
      //   this.$store.state
      // );
    },
    trimImage() {
      const trim = {
        Standard: "standard-gutter-trim",
        "Mesh Lock": "mesh-lock-gutter-trim"
      };
      return trimImages(
        `${trim[this.$store.state.trim]}-${this.$store.state.trimColour}`,
        trim[this.$store.state.trim]
      );
      // return trimImages(
      //   this.$store.state.trim,
      //   this.$store.state.trimColour,
      //   this.$store.state
      // );
    },
    profileImage() {
      const profile = {
        Corrugated: {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "CGI Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "Cooldek® Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        Trimdek: "trimdek-saddle",
        Superdek: "trimdek-saddle",
        "Cooldek® Classic": "trimdek-saddle",
        SteelClad: "trimdek-saddle",
        "Klip-Lok": "kliplok-saddle",
        "Lokdek 680/700": "kliplok-saddle",
        "Topdek® 700": "kliplok-saddle",
        Bullnose: "bullnose-profile",
        Spandek: "spandek-profile",
        Steelspan: "spandek-profile",
        Smartspan: "spandek-profile",
        Decramastic: "decramastic-profile",
        Longspan: "longspan-profile",
        Tile: "tile-clips"
      };
      const mesh = this.$store.state.mesh.includes("Aluminium")
        ? "Aluminium"
        : "Steel";
      return profileImages(
        profile[this.$store.state.roof],
        this.$store.state.meshColour,
        mesh
      );
    },
    screwImage() {
      return screwImages(this.$store.state.meshColour);
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .mkb-kit-wrapper {
    display: flex;
    flex-wrap: wrap;
    .mkb-profile-kit {
      padding: 0 13px 0 13px;
    }
    img.mkb-mesh-image,
    .mkb-profile-image,
    .mkb-screw-image {
      width: 100%;
    }
    img.mkb-trim-image {
      width: 25%;
    }
    img.mkb-screw-image {
      width: 100%;
      // margin-top:30px;
      transform: rotateZ(90deg);
    }
    img.mkb-long-profile {
      width: 50%;
      margin-left: 10px;
    }
    @media (max-width: 767px) {
      .mkb-profile-image,
      .mkb-screw-image:not(.mkb-long-profile) {
        width: 80%;
      }
      img.mkb-long-profile {
        width: 40%;
      }
      .mkb-profile-image {
        margin: 0 auto !important;
      }
      .mkb-screw-image {
        margin-bottom: 0 !important;
        margin-top: 50px !important;
      }
      img.mkb-trim-image {
        width: 20%;
      }
    }
  }
}
</style>
