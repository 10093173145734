var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mkb-container"},[_c('div',{staticClass:"mkb-kit-wrapper"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"6","cols":"6"}},[_c('img',{staticClass:"mkb-mesh-image ",attrs:{"src":_vm.meshImage,"alt":"Mesh Roll"}})]),_c('b-col',{staticClass:"mkb-profile-kit"},[_c('div',{staticClass:"d-flex",class:_vm.$store.state.trim === 'No Trim' ? 'h-100' : ''},[(_vm.$store.state.trim != 'No Trim')?_c('img',{staticClass:"mkb-trim-image",attrs:{"src":_vm.trimImage,"alt":"Gutter trim"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.$store.state.installationType === null ||
                _vm.$store.state.installationType === 'Saddles'
            ),expression:"\n              $store.state.installationType === null ||\n                $store.state.installationType === 'Saddles'\n            "}],staticClass:"mkb-profile-image my-auto",class:{
              'mkb-long-profile':
                _vm.$store.state.roof === 'Decramastic' ||
                _vm.$store.state.roof === 'Bullnose'
            },attrs:{"src":_vm.profileImage,"alt":"Profile"}}),_c('img',{staticClass:"mkb-screw-image mb-auto",style:({
              'margin-top':
                _vm.$store.state.installationType === 'Traditional (screws only)'
                  ? '90px'
                  : 'auto'
            }),attrs:{"src":_vm.screwImage,"alt":"Screw"}})])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }