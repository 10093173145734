<template>
  <div class="mkb-sidebar-menu">
    <ul class="step-menu mb-0 pl-0">
      <li
        class="step"
        v-for="(step, index) in dataSteps.steps"
        :key="step.name"
        :class="step.name === $route.name ? 'active' : ''"
      >
        <div
          class="d-flex"
          @click="goBackSpecificStep(index)"
          :style="$store.state.step > index ? 'cursor: pointer' : ''"
        >
          <div
            :class="$store.state.step > index ? 'completed' : ''"
            class="mkb-step-number d-flex align-self-center justify-content-center"
          >
            <span
              class="d-block align-self-center"
              v-if="$store.state.step <= index"
              >{{ index + 1 }}</span
            >
            <span class="d-block align-self-center" v-else>
              <div style="width: 26px; height: auto;">
                <check></check>
              </div>
            </span>
          </div>
          <div class="mkb-step-name align-self-center text-uppercase">
            {{ step.title }}
          </div>
        </div>
        <div class="mkb-step-selection">
          <template
            v-if="$store.state[step.sets] && step.title.includes('Colour')"
          >
            <div
              class="d-flex"
              v-if="$store.getters.colours[$store.state[step.sets]]"
            >
              <div class="align-self-center mr-2">
                {{ $store.getters.colours[$store.state[step.sets]].name
                }}<span
                  v-if="$store.getters.colours[$store.state[step.sets]].hasTm"
                  >&#8482;</span
                ><span
                  v-if="$store.getters.colours[$store.state[step.sets]].hasR"
                  >&#174;</span
                >
              </div>
              <div
                class="mkb-color-swatch align-self-center"
                :style="
                  'background-color: ' +
                    $store.getters.colours[$store.state[step.sets]].code +
                    ';'
                "
              ></div>
            </div>
            <!-- this is for ticket https://git.nxt.work/rh/tools/mesh-kit-builder/-/issues/175 -->
            <div v-else>Not available</div>
          </template>
          <template
            v-else-if="$store.state[step.sets] && step.title.includes('Width')"
          >
            {{ $store.state[step.sets].standard ? "Standard" : "Non-Standard" }}
            {{ $store.state[step.sets].width }}mm
          </template>
          <template
            v-else-if="
              $store.state[step.sets] && step.title.includes('Measurement')
            "
          >
            {{ $store.state[step.sets] }}m
          </template>
          <template v-else-if="$store.state[step.sets]">
            <span v-if="$store.state[step.sets] === 'Klip-Lok'"
              >{{ $store.state[step.sets] }}&reg; or Flat Pan</span
            >
            <span
              v-else-if="
                $store.state[step.sets] === 'Trimdek' ||
                  $store.state[step.sets] === 'Spandek' ||
                  $store.state[step.sets] === 'Longspan'
              "
            >
              {{ $store.state[step.sets] }}&reg;
            </span>
            <span v-else>
              {{ $store.state[step.sets] }}
            </span>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Check from "./svg/icons/Check";
export default {
  data() {
    return {
      dataSteps: this.$store.getters,
      states: this.$store.state
    };
  },
  components: {
    Check
  },
  methods: {
    goBackSpecificStep(index) {
      if (this.$store.state.step < index) return;
      index + 1;
      // this.$router.push({name: "standard.meshcolour"});
      this.$store.commit("goBackSpecificStep", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.mkb-sidebar-menu {
  width: 210px !important;
  margin-right: 30px;
  color: #758892;

  .mkb-color-swatch {
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  .mkb-step-number {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #758892;
    color: #ffffff;
    margin-right: 15px;
    font-size: 0.75rem;
    line-height: 1;
  }

  .mkb-step-number.completed {
    background-color: #ffffff !important;
  }

  .active {
    .mkb-step-number {
      background-color: #002f6c;
    }
    .mkb-step-name {
      color: #002f6c;
    }
  }

  .mkb-step-selection {
    padding-top: 10px;
    min-height: 28px;
    margin-left: 38px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .mkb-step-name {
    font-size: 0.75rem;
    line-height: 1;
  }

  ul {
    li {
      position: relative;
      list-style: none;
    }

    li:not(:last-of-type) {
      margin-bottom: 26px;
    }

    li:not(:last-of-type):after {
      content: "";
      position: absolute;
      top: 32px;
      left: 10px;
      width: 2px;
      height: calc(100% - 14px);
      background-color: #98a4af;
    }
  }
}
</style>
