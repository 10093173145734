<template>
  <b-modal
    ref="infoModal"
    size="lg"
    content-class="mkb-mesh-info-modal"
    centered
    hide-header
    @hidden="hide"
    :hide-footer="this.$store.state.tenant === '4933348840'"
  >
    <b-link class="mkb-close-button" @click.prevent="hide">
      <close></close>
    </b-link>
    <div
      class="d-md-flex flex-md-row px-5"
      v-show="hubspotActive"
      :class="hubspotActive ? 'mkb-mesh-lock-gutter-info-columns' : ''"
    >
      <div class="w-100 text-center" v-if="isLoading">
        <b-spinner
          variant="primary"
          label="Spinning"
          class="text-center"
        ></b-spinner>
      </div>
      <div id="modalHubspot" v-once></div>
    </div>
    <div class="mkb-mesh-lock-gutter-info-columns" v-if="formSubmitted">
      <div class="w-100 text-center">
        <div class="mkb-thank-you-wrapper">
          <div class="m-5">
            <h1 class="mkb-title text-center">
              Thank you.
            </h1>
          </div>
          <p class="mkb-description text-center ">
            Thank you our team will be in touch shortly.
          </p>
          <div>
            <div class="col-sm pt-5">
              <div>
                <div
                  class="standard-selection mkb-clickable-container"
                  @click="hide"
                >
                  <div class="description">
                    CONTINUE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mkb-mesh-lock-gutter-info-columns "
      v-if="!hubspotActive && !formSubmitted"
    >
      <div>
        <h1 class="text-center mb-2">Mesh Lock Gutter Trim</h1>
        <div class="d-flex flex-column">
          <div class="mkb-mesh-lock-gutter">
            <img
              src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/popup-ml-trim.png"
              class="w-100"
              alt=""
            />
          </div>
        </div>
        <b-row class="px-4 py-3">
          <b-col class="m-auto" sm="12" md="6"
            ><div>
              <b-img
                class="w-100"
                src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/mesh-lock-trim-graph.svg"
                alt=""
              >
              </b-img></div
          ></b-col>
          <b-col>
            <p class="p-1">
              The Mesh Lock Gutter Trim has a series of “Locking” teeth, which
              helps to distribute the load on the mesh. The teeth are angled
              backwards so that if there is any pulling force of the mesh in the
              gutter, they lock in.
            </p>
            <div>
              <b-embed
                type="iframe"
                aspect="16by9"
                src="https://www.youtube.com/embed/UVHZv_kRW6g"
                allowfullscreen
              ></b-embed>
            </div>
            <p class="p-1 mt-3">
              Our lab testing demonstrated that the Mesh Lock Gutter Trim's
              design increases the load-bearing capacity and tear resistance of
              all our gutter mesh by over 200%
            </p>
            <p class="p-1">
              Using a Mesh Lock Gutter Trim will reduce the likelihood of your
              mesh collapsing when heavy hail, snow. animals or debris land on
              it.
            </p></b-col
          >
        </b-row>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100 text-center">
        <div class="mkb-chat-icon">
          <chat></chat>
        </div>
        <span v-if="$store.state.tenant === 'steeline'"
          >Need more information?</span
        >
        <span v-else>Want more information?</span>
        <br />
        <b-link
          @click="setHubspotForm()"
          v-if="$store.state.tenant === 'steeline'"
          >Leave your contact details here</b-link
        >
        <b-link @click="setHubspotForm()" v-else
          >Please send your enquiries here</b-link
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import Close from "./svg/icons/Close";
import Chat from "./svg/icons/Chat";
import { forms } from "@/data/tenants";
export default {
  name: "MeshInfoModal",
  components: {
    Close,
    Chat
  },
  data() {
    return {
      forms,
      hubspotActive: false,
      isLoading: false,
      formSubmitted: false
    };
  },
  methods: {
    setHubspotForm() {
      if (this.hubspotActive) return;
      this.isLoading = true;
      this.hubspotActive = true;
      this.formSubmitted = false;
      const form = this.forms[this.$store.state.tenant];
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            formId: form["meshLockFormId"],
            portalId: "3779244",
            target: "#modalHubspot"
          });
        }
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormReady"
        ) {
          // this.$nuxt.$loading.start();
          document.querySelector(
            "input[name='mkb_roof_type']"
          ).value = this.$store.state.roof;
          document.querySelector(
            "input[name='mkb_install_type']"
          ).value = this.$store.state.type;
          if (this.$store.state.tenant === "mitre-10-Strathalbyn") {
            document.querySelector(
              'input[name="mkb_request_help_page"]'
            ).value = "ML Trim Page";
          }
          this.isLoading = false;
        }
        if (event.data.eventName === "onFormSubmitted") {
          // this.$nuxt.$loading.finish();
          // this.submitted = true;
          this.hubspotActive = false;
          this.formSubmitted = true;
        }
      });
    },
    show() {
      this.$refs.infoModal.show();
    },
    hide() {
      this.$refs.infoModal.hide();
      this.hubspotActive = false;
      this.formSubmitted = false;
    }
  }
};
</script>

<style lang="scss">
.mkb-mesh-info-modal {
  h1 {
    color: #002f6c;
  }
  .mkb-mesh-lock-gutter-info-columns {
    span {
      font-size: 12px;
      line-height: normal;
    }
    padding: 30px 0 0 0 !important;
  }
  .mkb-mesh-lock-gutter svg {
    .c,
    .e,
    .f,
    .h {
      stroke: #002f6c !important;
    }
    .g {
      fill: #002f6c !important;
    }
  }
  footer {
    background-color: #f0f0f0;
    span,
    a {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: #002f6c;
    }
    .mkb-chat-icon svg {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
