<template>
  <b-row class="mkb-mesh-kit mx-auto">
    <b-col class="px-0 pr-2">
      <div class="d-flex" v-if="showLabels">
        <div class="align-items-start mr-1">
          <font-awesome-icon :icon="['fas', 'caret-down']" />
        </div>
        <div class="align-self-center" v-if="!domedValleyChecker()">
          Domed Mesh
        </div>
        <div class="align-self-center" v-else>
          Mesh Roll
        </div>
      </div>
      <div>
        <img :src="meshImage" alt="Mesh Roll" class="mesh-image" />
      </div>
    </b-col>
    <b-col
      cols="6"
      class="d-flex align-items-center mkb-fastener-container px-0 pr-2"
    >
      <b-row
        class="h-100"
        v-if="
          $store.state.roof === 'Tile' &&
            $store.state.type === 'Valley' &&
            $store.state.valley === 'Valley Roll' &&
            $store.state.mesh.includes('Aluminium')
        "
      >
      </b-row>
      <b-row class="h-100" v-else>
        <b-col
          :class="{
            'mkb-small-profile':
              $store.state.type.includes('Standard') &&
              ($store.state.roof.includes('Trimdek') ||
                $store.state.roof.includes('Superdek') ||
                $store.state.roof.includes('Cooldek® Classic')),
            'my-auto':
              (!$store.state.roof.includes('Tile') &&
                !$store.state.roof.includes('Bullnose') &&
                !$store.state.roof.includes('Spandek') &&
                !$store.state.roof.includes('Longspan') &&
                !$store.state.roof.includes('Decramastic') &&
                !$store.state.roof.includes('Klip-Lok') &&
                $store.state.mesh.includes('Aluminium')) ||
              $store.state.roof.includes('Trimdek') ||
              $store.state.roof.includes('Superdek') ||
              $store.state.roof.includes('Cooldek® Classic'),
            'my-auto mkb-valley-roll':
              $store.state.valley === 'Valley Roll' &&
              $store.state.type.includes('Valley') &&
              !$store.state.roof.includes('Klip-Lok'),
            'mkb-tile-aluminium ':
              $store.state.valley === 'Domed Valley' &&
              $store.state.type.includes('Valley'),
            'mkb-tile-aluminium text-center':
              $store.state.type.includes('Standard') &&
              $store.state.roof.includes('Tile') &&
              $store.state.mesh.includes('Aluminium'),
            'mt-4':
              ($store.state.roof.includes('Klip-Lok') ||
                $store.state.roof.includes('Topdek® 700')) &&
              $store.state.installationType === 'Saddles',
            'mkb-traditional text-center':
              ($store.state.roof.includes('Klip-Lok') ||
                $store.state.roof.includes('Prodek') ||
                $store.state.roof.includes('Spacedek®')) &&
              $store.state.installationType === 'Traditional (screws only)'
          }"
          class="p-0"
        >
          <div class="d-flex flex-column mkb-kit-fasteners align-items-start">
            <div class="pl-2 ">
              <div
                class="profile"
                v-if="domedValleyChecker() && profileChecker()"
              >
                <div
                  v-if="
                    $store.state.roof === 'Tile' &&
                      $store.state.mesh.includes('Steel')
                  "
                  class="mkb-tile-safety-clips mb-4"
                >
                  <div class="d-flex align-items-start mb-2">
                    <div class=" mr-1">
                      <font-awesome-icon :icon="['fas', 'caret-down']" />
                    </div>
                    <div>
                      Tile Safety Clips
                    </div>
                  </div>
                  <div
                    :class="{
                      'mb-2': $store.state.type.includes('Box')
                    }"
                  >
                    <img
                      src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/tile-safety-clips.png"
                      alt="Profile"
                      class="profile-image"
                    />
                  </div>
                </div>

                <div
                  class="d-flex"
                  v-if="
                    showLabels &&
                      $store.state.installationType !==
                        'Traditional (screws only)'
                  "
                >
                  <div class="align-items-start mr-1">
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                  <div class="align-self-center">
                    <div
                      v-if="
                        $store.state.type === 'Valley' &&
                          ($store.state.roof === 'Corrugated' ||
                            $store.state.roof === 'CGI Corrugated' ||
                            $store.state.roof === 'Cooldek® Corrugated') &&
                          $store.state.mesh.includes('Steel')
                      "
                      class="w-75"
                    >
                      {{ $store.state.roof }} Valley Multi-Pitch Fins
                    </div>
                    <div v-else>
                      {{ $store.state.roof
                      }}<span v-if="hasR($store.state.roof)">&reg;</span>

                      {{
                        $store.state.mesh.includes("Aluminium")
                          ? "Saddles"
                          : $store.state.roof.includes("Tile")
                          ? "Clips"
                          : $store.state.roof.includes("Trimdek") ||
                            $store.state.roof.includes("Superdek") ||
                            $store.state.roof.includes("Cooldek® Classic")
                          ? "Saddles"
                          : $store.state.roof.includes("Klip-Lok")
                          ? "Saddles"
                          : "Profile"
                      }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    $store.state.installationType !==
                      'Traditional (screws only)'
                  "
                  :class="{
                    'd-flex mkb-saddle-image': $store.state.mesh.includes(
                      'Aluminium'
                    ),
                    'd-flex mkb-corro-image':
                      $store.state.roof.includes('Corrugated') &&
                      $store.state.type !== 'Valley' &&
                      $store.state.mesh.includes('Steel'),
                    'd-flex mkb-bullnose-image':
                      $store.state.roof.includes('Bullnose') &&
                      $store.state.mesh.includes('Steel'),
                    'd-flex mkb-spandek-image':
                      ($store.state.roof.includes('Spandek') ||
                        $store.state.roof.includes('Steelspan')) &&
                      $store.state.mesh.includes('Steel'),
                    'd-flex mkb-longspan-image':
                      ($store.state.roof.includes('Longspan') ||
                        $store.state.roof.includes('Smartspan')) &&
                      $store.state.mesh.includes('Steel'),
                    'd-flex mkb-decramastic-image':
                      $store.state.roof.includes('Decramastic') &&
                      $store.state.mesh.includes('Steel'),
                    'd-flex mkb-trimdek mkb-narrow-width':
                      $store.state.roof.includes('Trimdek') ||
                      $store.state.roof.includes('SteelClad') ||
                      $store.state.roof.includes('Superdek') ||
                      $store.state.roof.includes('Cooldek® Classic'),
                    'd-flex mkb-klip-lok mkb-narrow-width':
                      $store.state.roof.includes('Klip-Lok') ||
                      $store.state.roof.includes('Lokdek') ||
                      $store.state.roof.includes('Topdek® 700')
                  }"
                >
                  <div
                    :class="{
                      'mb-2': $store.state.type.includes('Box')
                    }"
                  >
                    <img
                      :src="profileImage"
                      alt="Profile"
                      class="profile-image"
                    />
                  </div>
                  <div v-if="$store.state.type.includes('Box')">
                    <img
                      :src="profileImage"
                      alt="Profile"
                      class="profile-image-box"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex pl-2 mkb-screws-container align-items-start">
            <div class="mt-auto">
              <!-- <div
                class="screws mt-auto"
                v-if="
                  $store.getters.method !== 'Traditional (screws only)' &&
                    $store.getters.method !== null
                "
              >
                <div class="d-flex ">
                  <div class="align-items-start mr-1">
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                  <div class="align-self-center">
                    <div>
                      {{ $store.state.installationType }}
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    v-if="$store.getters.method === 'with saddles'"
                    :class="{ 'mb-2': $store.state.type.includes('Box') }"
                  >
                    <img
                      :src="saddleImage"
                      :alt="$store.getters.method"
                      class="mkb-fastener-image"
                    />
                  </div>
                </div>
              </div> -->
              <div
                class="screws klip"
                v-if="
                  $store.state.type === 'Box Gutter' &&
                    $store.state.installationType ===
                      'Traditional (screws only)'
                "
              >
                <div class="d-flex " v-if="showLabels">
                  <div class="align-items-start mr-1">
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                  <div class="align-self-center">
                    {{ fastenerName }}
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <img
                      :src="fastenerImage"
                      :alt="fastenerName"
                      class="screw1"
                    />
                  </div>
                  <div v-if="$store.state.type.includes('Box')">
                    <img
                      :src="fastenerImage"
                      :alt="fastenerName"
                      class="screw2"
                    />
                  </div>
                </div>
              </div>
              <div class="screws " v-else>
                <div class="d-flex " v-if="showLabels">
                  <div class="align-items-start mr-1">
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                  <div class="align-self-center">
                    {{ fastenerName }}
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <img
                      :src="fastenerImage"
                      :alt="fastenerName"
                      class="mkb-fastener-image"
                    />
                  </div>
                  <div v-if="$store.state.type.includes('Box')">
                    <img
                      :src="fastenerImage"
                      :alt="fastenerName"
                      class="mkb-fastener-image-box"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="p-0 h-100" v-if="$store.state.type === 'Standard Gutter'">
          <div class="d-inline-flex flex-row pt-2">
            <div
              class="mkb-trim-image text-center w-50 "
              :class="{
                'mkb-trim-image-wide':
                  trimImage &&
                  $store.state.trim === 'Standard' &&
                  $store.state.roof.includes('Decramastic'),
                'mkb-trim-image-less-pad':
                  trimImage &&
                  $store.state.trim === 'Standard' &&
                  $store.state.roof.includes('Tile')
              }"
              v-if="trimImage"
            >
              <img :src="trimImage" alt="Gutter trim" />
            </div>
            <div
              class="d-flex mkb-trim-name pt-2"
              v-if="
                $store.state.trim &&
                  $store.state.trim != 'No Trim' &&
                  showLabels
              "
            >
              <div class="align-items-start mr-1 ">
                <font-awesome-icon :icon="['fas', 'caret-left']" />
              </div>
              <div class="align-self-start">
                {{ $store.state.trim }} Gutter Trim
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          cols="6"
          v-else-if="
            $store.state.type === 'Valley' ||
              $store.state.type === 'Solar Panel'
          "
        >
        </b-col>
        <!-- <b-col cols="6" v-else-if="$store.state.type === 'Box Gutter' "  > </b-col> -->
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import roofTypes from "../data/roof-types";
import {
  rollImages,
  profileImages,
  screwImages,
  trimImages
} from "../data/rollImages";
import { domeImages } from "../data/domeImages";
export default {
  name: "MeshKit",
  props: {
    showLabels: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    hasR(roofType) {
      return roofTypes[roofType].rMark;
    },
    domedValleyChecker() {
      let valleyTypes = this.$store.getters.valleyTypes;
      if (
        valleyTypes.find(element => element === "Domed Valley") !== undefined &&
        valleyTypes.length === 1
      )
        return false;
      return true;
    },
    profileChecker() {
      if (
        this.$store.state.type === "Standard Gutter" &&
        this.$store.state.roof === "Tile" &&
        this.$store.state.mesh.includes("Aluminium")
      )
        return false;
      return true;
    }
  },
  computed: {
    meshImage() {
      // return dome if true, else roll
      const mesh = {
        "2mm Steel": "steel-2mm",
        "4mm Steel": "steel-4mm",
        "5.4mm Steel": "steel-5 4mm",
        "2mm Aluminium": "aluminium-2mm",
        "4mm Aluminium": "aluminium-4mm",
        "4mm Aluminium Ultra": "aluminium-4mm"
      };
      if (!this.domedValleyChecker())
        return domeImages("domed-valley-mesh-" + this.$store.state.meshColour);
      return rollImages(
        `${mesh[this.$store.state.mesh]}-${this.$store.state.meshColour}`,
        mesh[this.$store.state.mesh]
      );
    },
    profileImage() {
      const profile = {
        Corrugated: {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "CGI Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "Cooldek® Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        Trimdek: "trimdek-saddle",
        Superdek: "trimdek-saddle",
        "Cooldek® Classic": "trimdek-saddle",
        SteelClad: "trimdek-saddle",
        "Klip-Lok": "kliplok-saddle",
        "Lokdek 680/700": "kliplok-saddle",
        "Topdek® 700": "kliplok-saddle",
        Bullnose: "bullnose-profile",
        Spandek: "spandek-profile",
        Steelspan: "spandek-profile",
        Smartspan: "spandek-profile",
        Decramastic: "decramastic-profile",
        Longspan: "longspan-profile",
        Tile: "tile-clips"
      };
      const mesh = this.$store.state.mesh.includes("Aluminium")
        ? "Aluminium"
        : "Steel";
      return profileImages(
        profile[this.$store.state.roof],
        this.$store.state.meshColour,
        mesh
      );
      // return profileImages(
      //   this.$store.state.roof,
      //   this.$store.state.meshColour,
      //   this.$store.state
      // );
    },
    fastenerImage() {
      return screwImages(this.$store.state.meshColour);
    },
    fastenerName() {
      return "Screws";
    },
    trimImage() {
      const trim = {
        Standard: "standard-gutter-trim",
        "Mesh Lock": "mesh-lock-gutter-trim"
      };
      return trimImages(
        `${trim[this.$store.state.trim]}-${this.$store.state.trimColour}`,
        trim[this.$store.state.trim]
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.screws.klip {
  img {
    transform: rotateZ(90deg);
    width: 100%;
    margin-bottom: -80px;
  }
  .screw1 {
    margin-left: -60px;
  }
  .screw2 {
    width: 440px;
    margin-left: -190px;
  }
}
.mkb-screws-container {
  height: 15%;
}
.mkb-small-profile {
  margin-top: 3rem;
}
.mkb-tile-aluminium,
.mkb-traditional {
  margin-top: 100%;
}
// .mkb-corro-image img,
// .mkb-bullnose-image img,
// .mkb-spandek-image img,
// .mkb-longspan-image img,
// .mkb-decramastic-image img {
//   transform: rotateZ(90deg);
// }
.mkb-corro-image img {
  width: 80%;
  // margin-left: 20px;
  // margin-top: -30px;
  // margin-bottom: -50px;
}
.mkb-spandek-image img {
  width: 90%;
  // margin-left: 20px;
  // margin-top: -30px;
  // margin-bottom: -50px;
}
.mkb-longspan-image img {
  width: 80%;
  // margin-left: 30px;
  // margin-top: -30px;
  // margin-bottom: -50px;
}
.mkb-bullnose-image img {
  width: 40%;
  // margin-left: 38px;
  // margin-top: -30px;
  // margin-bottom: -50px;
}
.mkb-decramastic-image img {
  width: 45%;
  // margin-left: 38px;
  // margin-top: -30px;
  // margin-bottom: -50px;
}
.mkb-mesh-kit {
  font-size: 0.65rem;

  img {
    max-width: 100%;
    height: auto;
  }
  .mkb-fastener-container {
    // margin-bottom: 70px
  }
  .mkb-fastener-image[alt="Screws"] {
    height: auto;
    transform: rotateZ(90deg);
    width: 100%;
    margin-bottom: -75px;
    margin-left: -20px;
  }
  .mkb-fastener-image-box[alt="Screws"] {
    height: auto;
    transform: rotateZ(90deg);
    width: 360px;
    margin-bottom: -75px;
    margin-left: -80px;
  }
  .mkb-fastener-image-box-klip[alt="Screws"] {
    height: auto;
    transform: rotateZ(90deg);
    width: 500px;
    margin-bottom: -75px;
    margin-left: -80px;
  }
  .profile-image-box {
    // width: 268px;
    // margin-left: -25px
  }

  .mkb-kit-fasteners {
    // padding-bottom: 20px;
  }

  .mkb-trim-image {
    img {
      width: 90%;
    }
    margin: auto 0;
  }

  .mkb-trim-image-less-pad {
    padding-top: 12% !important;
  }

  .mkb-trim-image-wide {
    padding-top: 15% !important;
    width: 150px;
  }

  // .mkb-narrow-width {
  //   width: 70px;
  // }
}
@media (max-width: 767.98px) {
  .mesh-image {
    width: 100%;
  }
  .mkb-mesh-kit {
    font-size: 0.65rem;
    max-width: 100%;

    img {
      max-width: 100%;
      height: auto;
    }

    .mkb-fastener-image[alt="Screws"] {
      width: 100%;
      margin-bottom: -75px;
      margin-left: -20px;
      height: auto;
      transform: rotateZ(90deg);
    }

    .mkb-kit-fasteners {
      padding-bottom: 20px;
    }

    .mkb-trim-image-less-pad {
      padding-top: 12% !important;
    }
    .mkb-trim-image {
      img {
        width: 95% !important;
      }
    }
    .mkb-trim-image-wide {
      padding-top: 15% !important;
      width: 150px;
    }

    .mkb-trim-name {
      padding-top: 36px;
    }
  }
}
</style>
