var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mkb-container"},[_c('div',{staticClass:"mkb-kit-wrapper"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"6","cols":"6"}},[_c('img',{staticClass:"mkb-mesh-image ",attrs:{"src":_vm.meshImage,"alt":"Mesh Roll"}})]),_c('b-col',{staticClass:"mkb-profile-kit"},[_c('div',{staticClass:"d-flex flex-column h-100 justify-content-end"},[_c('div',{staticClass:"mkb-clip-hooks mt-1"},[_c('b-row',{staticClass:"mkb-clip-hooks-img ml-1",attrs:{"cols":"1"}},[_c('b-img',{staticClass:"w-100 mb-1",attrs:{"src":_vm.$store.state.solarImages['solar panel mesh clip hooks']}}),_c('b-img',{staticClass:"w-100 mb-1",attrs:{"src":_vm.$store.state.solarImages['solar panel mesh clip hooks']}}),_c('b-img',{staticClass:"w-100 mb-1",attrs:{"src":_vm.$store.state.solarImages['solar panel mesh clip hooks']}})],1)],1),_c('div',{staticClass:"mkb-clip-plates mt-1"},[_c('b-row',{staticClass:"mkb-clip-plates-img ml-1",attrs:{"cols":"4"}},[_c('b-img',{staticClass:"w-100 m-0 mr-1",attrs:{"src":_vm.$store.state.solarImages[
                  'solar panel mesh clip locking plates - back'
                ]}}),_c('b-img',{staticClass:"w-100 m-0 mr-1",attrs:{"src":_vm.$store.state.solarImages[
                  'solar panel mesh clip locking plates - back'
                ]}}),_c('b-img',{staticClass:"w-100 m-0 mr-1",attrs:{"src":_vm.$store.state.solarImages[
                  'solar panel mesh clip locking plates - back'
                ]}})],1)],1),_c('img',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.hasProfile(_vm.$store.state.roof, _vm.$store.state.installationType)
          ),expression:"\n            hasProfile($store.state.roof, $store.state.installationType)\n          "}],staticClass:"mkb-profile-image ",class:{
            'mkb-long-profile':
              ((_vm.$store.state.roof === 'Corrugated' ||
                _vm.$store.state.roof === 'CGI Corrugated') &&
                _vm.$store.state.mesh.includes('Steel')) ||
              _vm.$store.state.roof === 'Spandek' ||
              _vm.$store.state.roof === 'Longspan',
            'mkb-saddle-image':
              _vm.$store.state.roof === 'Corrugated' &&
              _vm.$store.state.mesh.includes('Aluminium')
          },attrs:{"src":_vm.profileImage,"alt":"Profile"}}),_c('img',{staticClass:"mkb-screw-image",style:({
            'margin-top':
              ((_vm.$store.state.roof === 'Corrugated' ||
                _vm.$store.state.roof === 'CGI Corrugated') &&
                _vm.$store.state.mesh.includes('Steel')) ||
              _vm.$store.state.roof === 'Longspan'
                ? '-15px'
                : _vm.$store.state.roof === 'Spandek'
                ? '-10px'
                : '1px'
          }),attrs:{"src":_vm.screwImage,"alt":"Screw"}})])])],1),_c('div',[_c('img',{staticClass:"mkb-trim-image",attrs:{"src":_vm.trimImage,"alt":"Gutter trim"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }