<template>
  <b-modal
    ref="infoModal"
    size="xl"
    content-class="mkb-mesh-info-modal"
    centered
    hide-header
    @hidden="clearModal"
    body-class="p-0"
    :hide-footer="hubspotActive || this.$store.state.tenant === '4933348840'"
  >
    <b-link class="mkb-close-button" @click.prevent="hide">
      <close></close>
    </b-link>
    <div v-if="meshType">
      <div
        class="d-md-flex flex-md-row px-5"
        v-show="hubspotActive"
        :class="hubspotActive ? 'mkb-info-columns' : ''"
      >
        <div class="w-100 text-center" v-if="isLoading">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="text-center"
          ></b-spinner>
        </div>
        <div id="modalHubspot" v-once></div>
      </div>
      <div
        class="d-md-flex flex-md-row px-5 mkb-info-columns"
        v-if="formSubmitted"
      >
        <div class="w-100 text-center">
          <div class="mkb-thank-you-wrapper">
            <div class="m-5">
              <h1 class="mkb-title text-center">
                Thank you.
              </h1>
            </div>
            <p class="mkb-description text-center ">
              Thank you our team will be in touch shortly.
            </p>
            <div>
              <div class="col-sm pt-5">
                <div>
                  <div
                    class="standard-selection mkb-clickable-container"
                    @click="hide"
                  >
                    <div class="description">
                      CONTINUE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!hubspotActive && !formSubmitted">
        <div class="mb-2 mkb-mesh-image-container ">
          <img
            :src="imgUrl"
            alt="Mesh Roll"
            class="w-100"
            :class="{ 'mkb-image': imgLoading }"
          />
        </div>
        <div class="mkb-info-columns d-md-flex flex-md-row px-5">
          <b-row>
            <b-col>
              <div class="mkb-left-column">
                <h2 class="mb-2">{{ meshType }}</h2>
                <p v-html="$options.meshTypes[meshType].description"></p>
                <p
                  class=" mkb-disclaimer my-4 mb-md-0"
                  v-if="
                    $options.meshTypes[meshType].features.includes('ember') &&
                      meshType.includes('Aluminium')
                  "
                >
                  <sup>*</sup> Compliant for use on new homes as an ember guard
                  in bushfire-prone areas with bushfire attack levels (BAL) of
                  12.5, 19 and 29, as per AS3959:2018 Australian Standard:
                  Construction of buildings in bushfire-prone areas.
                </p>
                <p
                  class=" mkb-disclaimer my-4 mb-md-0"
                  v-if="
                    $options.meshTypes[meshType].features.includes('ember') &&
                      meshType.includes('Steel')
                  "
                >
                  <sup>*</sup> Compliant for use on new homes in bushfire-prone
                  areas with a bushfire attack level (BAL) of 12.5 or higher, as
                  per AS3959:2018 Australian Standard: Construction of buildings
                  in bushfire-prone areas.
                </p>
              </div>
            </b-col>
            <b-col md="7">
              <div class="mkb-right-column d-flex flex-column">
                <b-row class="mb-3 mb-md-0 mx-auto">
                  <b-col
                    class="d-flex mkb-feature-block px-0 my-1"
                    cols="6"
                    v-for="option in $options.meshTypes[meshType].features"
                    :key="option"
                  >
                    <template v-if="option === 'bushfire'">
                      <div class="mkb-feature-icon">
                        <bushfire></bushfire>
                      </div>
                      <div class="mkb-feature-description">
                        Bushfire compliant
                      </div>
                    </template>
                    <template v-else-if="option === 'colours'">
                      <div class="mkb-feature-icon">
                        <colours></colours>
                      </div>
                      <div class="mkb-feature-description">
                        {{ meshType.includes("Aluminium") ? "27" : "30" }}
                        Colours
                      </div>
                    </template>
                    <template v-else-if="option === 'ember'">
                      <div class="mkb-feature-icon">
                        <ember></ember>
                      </div>
                      <div class="mkb-feature-description">
                        <div>Ember guard compliant<sup>*</sup></div>

                        <div class="small" v-if="meshType.includes('Steel')">
                          (BAL 12.5, 19, 29, 40 &amp; FZ)
                        </div>
                        <div class="small" v-else>
                          (BAL 12.5, 19, &amp; 29)
                        </div>
                      </div>
                    </template>
                    <template v-else-if="option === 'leaves-large'">
                      <div class="mkb-feature-icon">
                        <leaves-large></leaves-large>
                      </div>
                      <div class="mkb-feature-description">
                        Keeps out large leaves
                      </div>
                    </template>
                    <template v-else-if="option === 'leaves-medium'">
                      <div class="mkb-feature-icon">
                        <leaves-medium></leaves-medium>
                      </div>
                      <div class="mkb-feature-description">
                        Keeps out medium leaves
                      </div>
                    </template>
                    <template v-else-if="option === 'leaves-small'">
                      <div class="mkb-feature-icon">
                        <leaves-small></leaves-small>
                      </div>
                      <div class="mkb-feature-description">
                        Keeps out small leaves
                      </div>
                    </template>
                    <template v-else-if="option === 'needles'">
                      <div class="mkb-feature-icon">
                        <needles></needles>
                      </div>
                      <div class="mkb-feature-description">
                        Keeps out most needles
                      </div>
                    </template>
                    <template v-else-if="option === 'pests'">
                      <div class="mkb-feature-icon">
                        <pests></pests>
                      </div>
                      <div class="mkb-feature-description">
                        Keeps out pests &amp; vermin
                      </div>
                    </template>
                    <template v-else-if="option === 'snow'">
                      <div class="mkb-feature-icon">
                        <snow></snow>
                      </div>
                      <div class="mkb-feature-description">
                        Withstands hail &amp; snow
                      </div>
                    </template>
                    <template v-else-if="option === 'strength'">
                      <div class="mkb-feature-icon">
                        <strength></strength>
                      </div>
                      <div class="mkb-feature-description">
                        {{ meshType.includes("Aluminium") ? "Medium" : "High" }}
                        strength
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-center">
        <div class="mkb-chat-icon">
          <chat></chat>
        </div>
        <span v-if="$store.state.tenant === 'steeline'"
          >Need more information?</span
        >
        <span v-else
          >Do you have any questions about our gutter mesh kits?</span
        >
        <br />
        <b-link
          @click="setHubspotForm()"
          v-if="$store.state.tenant === 'steeline'"
          >Leave your contact details here</b-link
        >
        <b-link @click="setHubspotForm()" v-else
          >Please send your enquiries here</b-link
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import Chat from "./svg/icons/Chat";
import Close from "./svg/icons/Close";
import Needles from "./svg/icons/Needles";
import Bushfire from "./svg/icons/Bushfire";
import Colours from "./svg/icons/Colours";
import Ember from "./svg/icons/Ember";
import LeavesLarge from "./svg/icons/LeavesLarge";
import LeavesMedium from "./svg/icons/LeavesMedium";
import LeavesSmall from "./svg/icons/LeavesSmall";
import Snow from "./svg/icons/Snow";
import Pests from "./svg/icons/Pests";
import Strength from "./svg/icons/Strength";
import meshTypes from "../data/mesh-types";
import { forms } from "@/data/tenants";
export default {
  name: "MeshInfoModal",
  components: {
    Close,
    Needles,
    Bushfire,
    Colours,
    Ember,
    LeavesLarge,
    LeavesMedium,
    LeavesSmall,
    Pests,
    Snow,
    Strength,
    Chat
  },
  data() {
    return {
      forms,
      meshType: null,
      imgUrl: null,
      imgLoading: true,
      hubspotActive: false,
      isLoading: false,
      formSubmitted: false
    };
  },
  meshTypes: meshTypes,
  methods: {
    show(meshType) {
      this.imgLoading = true;
      this.meshType = meshType;
      this.$refs.infoModal.show();
      let img = new Image();

      img.onload = () => {
        this.imgUrl = img.src;
        this.showImg = true;
      };
      img.src = this.$options.meshTypes[this.meshType].modalImage;
      this.imgLoading = false;
    },
    setHubspotForm() {
      if (this.hubspotActive) return;
      this.isLoading = true;
      this.hubspotActive = true;
      this.formSubmitted = false;
      const form = this.forms[this.$store.state.tenant];
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            formId: form["meshTypeFormId"],
            portalId: "3779244",
            target: "#modalHubspot"
          });
        }
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormReady"
        ) {
          // this.$nuxt.$loading.start();
          document.querySelector(
            "input[name='mkb_roof_type']"
          ).value = this.$store.state.roof;
          document.querySelector(
            "input[name='mkb_install_type']"
          ).value = this.$store.state.type;
          document.querySelector(
            "input[name='mkb_mesh_type']"
          ).value = this.meshType;
          if (this.$store.state.tenant === "mitre-10-Strathalbyn") {
            document.querySelector(
              'input[name="mkb_request_help_page"]'
            ).value = this.meshType + " Page";
          }
          if (this.$store.state.roof === "Klip-Lok") {
            document.querySelector(
              "input[name='mkb_kliplok_method']"
            ).value = this.$store.state.installationType;
          }
          if (this.$store.state.type === "Solar Panel") {
            document.querySelector(
              "input[name='mkb_solar_roof_type']"
            ).value = this.$store.state.roof;
            document.querySelector(
              "input[name='mkb_solar_mesh_type']"
            ).value = this.meshType;
          }
          if (this.$store.state.type === "Valley") {
            document.querySelector(
              "input[name='valley_install_type']"
            ).value = this.$store.state.valley;
          }
          this.isLoading = false;
        }
        if (event.data.eventName === "onFormSubmitted") {
          // this.$nuxt.$loading.finish();
          // this.submitted = true;
          this.hubspotActive = false;
          this.formSubmitted = true;
        }
      });
    },
    hide() {
      this.$refs.infoModal.hide();
      this.clearModal();
    },
    clearModal() {
      this.meshType = null;
      this.hubspotActive = false;
      this.formSubmitted = false;
    }
  },
  mounted() {
    // this.img.src = this.$options.meshTypes[this.meshType].modalImage
  }
};
</script>

<style lang="scss">
.mkb-mesh-info-modal {
  .submitted-message {
    display: none !important;
  }
  .mkb-image {
    filter: blur(8px);
  }
  .mkb-close-button {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .mkb-info-columns {
    padding: 24px;
  }

  .mkb-left-column {
    // width: 291px !important;
    margin-right: 35px;
  }

  .mkb-right-column {
    flex: 1;
    .mkb-feature-block {
      height: 41px;
    }
  }

  .modal-body {
    padding: 0 !important;
  }

  h4,
  p {
    font-size: 0.75rem;
    color: #707070;
  }

  h2 {
    color: #002f6c;
  }

  p {
    line-height: 145%;
  }
  .mkb-learn-more-link {
    display: block;
    color: #ffffff;
    background-color: #002f6c;
    font-size: 0.95rem;
  }

  .mkb-link-icon {
    margin-top: -1px;
    width: 20px;
    height: 20px;
  }

  .mkb-feature-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    align-self: center !important;
  }

  .mkb-feature-description {
    font-weight: 500;
    color: #1a3768;
    align-self: center !important;

    .small {
      font-size: 0.7rem;
    }
  }

  .mkb-disclaimer {
    font-size: 0.6rem;
  }
  footer {
    background-color: #f0f0f0;
    span,
    a {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: #002f6c;
    }
    .mkb-chat-icon svg {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 767.98px) {
    .mkb-feature-description {
      color: #1a3768;
      font-size: 0.7rem;
      align-self: center !important;

      .small {
        font-size: 0.6rem;
      }
    }
    h2 {
      margin-top: 20px !important;
      margin-bottom: 25px !important;
    }

    p {
      font-size: 0.9rem;
    }

    .mkb-info-columns {
      padding: 15px !important;
    }

    .mkb-left-column {
      width: 100% !important;
      margin: 0 0px 25px 0px !important;
    }

    .mkb-mesh-image-container {
      width: 100%;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
</style>
