<template>
  <div class="mkb-mesh-width-page">
    <b-row
      :class="{
        'justify-content-center':
          $store.state.type === 'Valley' &&
          $store.state.valley !== 'Domed Valley'
      }"
    >
      <b-col
        :md="$store.state.valley === 'Domed Valley' ? 6 : 5"
        class="pr-md-3 mb-3 mb-md-0 mkb-mesh-kit-image-container"
        style="margin-top: -75px;"
        v-if="$store.state.valley === 'Domed Valley'"
      >
        <div>
          <sheet-width></sheet-width>
        </div>
        <div class="gap-width-text">
          <div class="mb-2">
            Your width selection should be calculated by measuring the gap width
            plus adding 100mm for the form of the dome.
          </div>
          <div>
            We always recommend rounding up to the closest standard widths
            available <font-awesome-icon :icon="['fas', 'caret-right']" />
          </div>
        </div>
      </b-col>
      <b-col
        md="5"
        v-else
        class=" d-md-block pr-3 mkb-mesh-kit-image-container"
      >
        <Kit />
      </b-col>
      <b-col :md="$store.state.valley === 'Domed Valley' ? 6 : 7">
        <div>
          <h4 class="text-primary mb-5">
            Choose the width of your
            {{ $store.state.type === "Valley" ? "valley" : "gutter" }} mesh
          </h4>
        </div>
        <div class="mkb-standard-widths" v-if="standard.length">
          <div
            @click="setWidth(width)"
            v-for="width in standard"
            class="standard-selection mkb-clickable-container"
            :key="width.width"
          >
            <div class="description">Standard</div>
            <div class="width">{{ width.width }}mm</div>
          </div>
        </div>
        <div v-if="nonStandard.length">
          <div v-if="$store.state.valley !== 'Domed Valley'">
            <h4 class="text-primary text-left text-md-center mb-3">
              Non-Standard Widths
            </h4>
            <p class="text-left text-md-center mb-4 px-md-5">
              If you have a custom or unusual gutter or roof design, we have
              non-standard widths available.
            </p>
          </div>
          <b-row class="d-flex flex-wrap nonstandard-widths">
            <b-col
              :md="nonStandardCols"
              v-for="width in nonStandard"
              :key="width.width"
              @click="setWidth(width)"
            >
              <div
                class="mkb-nonstandard-selection mkb-clickable-container py-3 mb-3"
              >
                {{ width.width }}mm
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import SheetWidth from "../components/svg/valley/SheetWidth";
import Kit from "../components/Kit";
export default {
  name: "MeshWidth",
  components: {
    Kit,
    SheetWidth
  },
  data() {
    return {
      otherOptions: [
        { standard: true, width: 300 },
        { standard: false, width: 450 },
        { standard: false, width: 600 },
        { standard: false, width: 915 }
      ],
      otherOptionsDomedValley: [
        { standard: false, width: 190 },
        { standard: false, width: 240 },
        { standard: false, width: 300 }
      ]
    };
  },
  computed: {
    otherRoofOptions() {
      if (this.$store.state.valley === "Domed Valley")
        return this.otherOptionsDomedValley;
      return this.otherOptions;
    },
    standard() {
      if (
        this.$store.state.otherRoof &&
        this.$store.state.valley !== "Domed Valley"
      )
        return _.filter(this.otherRoofOptions, { standard: true });
      return _.filter(this.$store.getters.meshWidths, { standard: true });
    },
    nonStandard() {
      if (this.$store.state.otherRoof)
        return _.filter(this.otherRoofOptions, { standard: false });
      return _.filter(this.$store.getters.meshWidths, { standard: false });
    },
    nonStandardCols() {
      if (
        this.$store.state.valley === "Domed Valley" ||
        this.nonStandard.length === 1
      ) {
        return 12;
      } else if (this.nonStandard.length > 2) {
        return 4;
      }
      return 6;
    }
  },
  methods: {
    setWidth(width) {
      this.$store.commit("setWidth", width);
      this.$store.commit("advance");
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 1.4rem;
}

p.gap-text {
  font-size: 0.9rem;
}
.gap-width-text {
  color: #707070;
  text-align: left;
  font-size: 0.65rem;
  padding: 0px 60px;
}
.mkb-standard-widths {
  padding-bottom: 60px;
  border-bottom: 1px solid #b5b5b5;
  margin-bottom: 60px;
}

.standard-selection {
  border: 2px solid #002f6c;
  background-color: #002f6c;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  padding: 1.25rem;
  font-weight: 400;

  .description {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .width {
    font-weight: 600;
    font-size: 1.6rem;
  }
}

.standard-selection:hover {
  background-color: #ffffff;
  color: #002f6c;
  border: 2px solid #002f6c;
}

.mkb-nonstandard-selection {
  font-weight: 500;
  font-size: 1.5rem;
  border: 2px solid #002f6c;
  color: #002f6c;
  text-align: center;
}

.mkb-nonstandard-selection:hover {
  background-color: #002f6c;
  color: #ffffff;
}

.standard-selection:last-child {
  margin-bottom: 0;
}
</style>
