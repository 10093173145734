<template>
  <b-container>
    <div class="header">
      <page-header></page-header>
    </div>
    <div class="">
      <div class="mkb-thank-you-wrapper">
        <div class="m-5">
          <h1 class="mkb-title text-center">
            Thank you.
          </h1>
        </div>
        <div
          class="mkb-description text-center"
          v-if="
            $store.state.tenant === 'steeline' ||
              $store.state.tenant === '4933348840'
          "
          v-html="
            thankyouContent[$store.state.tenant][$store.state.hubspotName]
          "
        ></div>
        <div
          class="mkb-description text-center"
          v-else
          v-html="thankyouContent[$store.state.tenant]"
        ></div>
        <div>
          <div class="col-sm pt-5">
            <div>
              <div class="standard-selection mkb-clickable-container">
                <div class="description" @click="restart">
                  MAKE ANOTHER KIT
                </div>
              </div>
              <div
                @click="redirect()"
                class="mkb-nonstandard-selection mkb-clickable-container py-3 mb-3"
              >
                BROWSE GUTTER MESH PRODUCT RANGE
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import PageHeader from "../../components/PageHeader";
import { browse, thankyouContent } from "../../data/tenants";
export default {
  data() {
    return {
      browse,
      thankyouContent
    };
  },
  components: {
    PageHeader
  },
  methods: {
    restart() {
      this.$store.commit("reset");
      this.$router.push({ name: "installationtype" });
    },
    redirect() {
      let tenant = browse.find(
        item => item.tenant === this.$store.state.tenant
      );
      window.open(tenant.url, "_blank");
    }
  }
};
</script>
<style lang="scss">
// mesh-kit-builder[tenant='bmco'] {
//   .mkb-thank-you-wrapper {
//     margin: 0 auto;
//     max-width: 50%;
//   }
// }
// mesh-kit-builder[tenant='bunnings'] {
//   .mkb-thank-you-wrapper {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -60%);
//     margin: 0 auto;
//     max-width: 50%;
//   }
// }
.mkb-thank-you-wrapper {
  margin: 0 auto;
  max-width: 50%;
  h1 {
    color: #002f6c;
    font-weight: 500;
  }

  .mkb-description {
    font-size: 14px;
    color: #98a4af;
    font-weight: 500;
  }
  .standard-selection {
    border: 2px solid #002f6c;
    background-color: #002f6c;
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
    padding: 1rem;
    font-weight: 400;

    .description {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .width {
      font-weight: 600;
      font-size: 1.6rem;
    }
  }
  .standard-selection:hover {
    background-color: #ffffff;
    color: #002f6c;
    border: 2px solid #002f6c;
  }
  .mkb-nonstandard-selection {
    font-weight: 500;
    font-size: 1rem;
    border: 2px solid #002f6c;
    color: #002f6c;
    text-align: center;
  }

  .mkb-nonstandard-selection:hover {
    background-color: #002f6c;
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .mkb-thank-you-wrapper {
    max-width: 85% !important;
    width: 85%;
  }
}
</style>
