import AluminiumTwoImage from "../components/svg/mesh/AluminiumTwo";
import AluminiumFourUltraImage from "../components/svg/mesh/AluminiumUltraFour";
import AluminiumFourImage from "../components/svg/mesh/AluminiumFour";
import SteelTwoImage from "../components/svg/mesh/SteelTwo";
import SteelFourImage from "../components/svg/mesh/SteelFour";
import SteelFiveFourImage from "../components/svg/mesh/SteelFiveFour";

const meshTypes = {
  "2mm Steel": {
    image: SteelTwoImage,
    description:
      "For superior bushfire and ember guard protection, our 2mm steel mesh is ember guard compliant to <u> all BALs</u><sup>*</sup>, and its fine aperture keeps small leaves and pests out of your gutters.",
    features: [
      "needles",
      "bushfire",
      "leaves-small",
      "ember",
      "leaves-medium",
      "snow",
      "leaves-large",
      "strength",
      "pests",
      "colours"
    ],
    link: "https://bluemountainmesh.com.au/products/steel/2mm-steel/",
    modalImage:
      "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/mesh-steel-2mm.png"
  },
  "4mm Steel": {
    image: SteelFourImage,
    description:
      "Strong and versatile, our 4mm steel gutter mesh is bushfire compliant and keeps pests and medium and large leaves out of your gutters.",
    features: [
      "leaves-medium",
      "snow",
      "leaves-large",
      "strength",
      "pests",
      "colours",
      "bushfire"
    ],
    link: "https://bluemountainmesh.com.au/products/steel/4mm-steel/",
    modalImage:
      "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/mesh-steel-4mm.png"
  },
  "5.4mm Steel": {
    image: SteelFiveFourImage,
    description:
      "This super-durable gutter mesh keeps large leaves, debris and wildlife out of your gutters, is bushfire compliant and is superior to withstanding hail.",
    features: [
      "leaves-large",
      "snow",
      "pests",
      "strength",
      "bushfire",
      "colours"
    ],
    link: "https://bluemountainmesh.com.au/products/steel/5-4mm-steel/",
    modalImage:
      "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/mesh-steel-5-4mm.png"
  },
  "2mm Aluminium": {
    image: AluminiumTwoImage,
    description:
      "Featuring a woven edge for extra durability, our 2mm aluminium is bushfire and ember guard compliant to AS3959:2018 for BAL 12.5, 19 and 29, and its fine aperture keeps small leaves, debris and pests out of your gutters.",
    features: [
      "needles",
      "bushfire",
      "leaves-small",
      "ember",
      "leaves-medium",
      "strength",
      "leaves-large",
      "colours",
      "pests"
    ],
    link: "https://bluemountainmesh.com.au/products/aluminium/2mm-aluminium/",
    modalImage:
      "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/mesh-alum-2mm.png"
  },
  "4mm Aluminium": {
    image: AluminiumFourImage,
    description:
      "A classic, flexible gutter mesh that keeps animals and medium-sized leaves out of your gutters and roof.",
    features: [
      "leaves-medium",
      "bushfire",
      "leaves-large",
      "strength",
      "pests",
      "colours"
    ],
    link: "https://bluemountainmesh.com.au/products/aluminium/4mm-aluminium/",
    modalImage:
      "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/mesh-alum-4mm.png"
  },
  "4mm Aluminium Ultra": {
    image: AluminiumFourUltraImage,
    description:
      "Thicker and stronger than the classic 4mm Aluminium, 4mm Aluminium Ultra keeps animals and medium-sized leaves out of your gutters and roof.",
    features: [
      "leaves-medium",
      "bushfire",
      "leaves-large",
      "strength",
      "pests",
      "colours"
    ],
    link:
      "https://bluemountainmesh.com.au/products/aluminium/4mm-aluminium-ultra/",
    modalImage:
      "https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/mesh-alum-4mm-ultra.png"
  }
};

export default meshTypes;
