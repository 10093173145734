<template>
  <div class="mkb-container">
    <div class="mkb-kit-wrapper">
      <b-col class="p-0" md="6" cols="6">
        <img :src="meshImage" alt="Mesh Roll" class="mkb-mesh-image " />
      </b-col>
      <b-col class="mkb-profile-kit">
        <div class="d-flex flex-column h-100">
          <img
            v-if="
              $store.state.installationType === null ||
                $store.state.installationType === 'Saddles'
            "
            :src="profileImage"
            alt="Profile"
            class="mkb-profile-image"
            :class="{
              'mkb-long-profile':
                ($store.state.roof === 'Corrugated' &&
                  $store.state.mesh.includes('Steel')) ||
                $store.state.roof === 'Spandek' ||
                $store.state.roof === 'Longspan',
              'mkb-saddle-image':
                $store.state.roof === 'Corrugated' &&
                $store.state.mesh.includes('Aluminium')
            }"
          />
          <img
            v-if="
              $store.state.installationType === null ||
                $store.state.installationType === 'Saddles'
            "
            :src="profileImage"
            alt="Profile"
            class="mkb-profile-image"
            :class="{
              'mkb-long-profile':
                ($store.state.roof === 'Corrugated' &&
                  $store.state.mesh.includes('Steel')) ||
                $store.state.roof === 'Spandek' ||
                $store.state.roof === 'Longspan',
              'mkb-saddle-image':
                $store.state.roof === 'Corrugated' &&
                $store.state.mesh.includes('Aluminium')
            }"
          />
          <img
            :src="screwImage"
            alt="Screw"
            class="mkb-screw-image"
            :class="{
              'mt-auto':
                $store.state.installationType === 'Traditional (screws only)'
            }"
            :style="{
              'margin-top':
                ($store.state.roof === 'Corrugated' &&
                  $store.state.mesh.includes('Steel')) ||
                $store.state.roof === 'Longspan'
                  ? '-20px'
                  : $store.state.roof === 'Spandek'
                  ? '-8px'
                  : ''
            }"
          />
          <img :src="screwImage" alt="Screw" class="mkb-screw-image" />
        </div>
      </b-col>
    </div>
  </div>
</template>
<script>
import { rollImages, profileImages, screwImages } from "../../data/rollImages";
export default {
  computed: {
    meshImage() {
      const mesh = {
        "2mm Steel": "steel-2mm",
        "4mm Steel": "steel-4mm",
        "5.4mm Steel": "steel-5 4mm",
        "2mm Aluminium": "aluminium-2mm",
        "4mm Aluminium": "aluminium-4mm",
        "4mm Aluminium Ultra": "aluminium-4mm"
      };
      return rollImages(
        `${mesh[this.$store.state.mesh]}-${this.$store.state.meshColour}`,
        mesh[this.$store.state.mesh]
      );
      // return rollImages(
      //   this.$store.state.mesh,
      //   this.$store.state.meshColour,
      //   this.$store.state
      // );
    },
    profileImage() {
      const profile = {
        Corrugated: {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "CGI Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "Cooldek® Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        Trimdek: "trimdek-saddle",
        Superdek: "trimdek-saddle",
        "Cooldek® Classic": "trimdek-saddle",
        SteelClad: "trimdek-saddle",
        "Klip-Lok": "kliplok-saddle",
        "Lokdek 680/700": "kliplok-saddle",
        "Topdek® 700": "kliplok-saddle",
        Bullnose: "bullnose-profile",
        Spandek: "spandek-profile",
        Steelspan: "spandek-profile",
        Smartspan: "spandek-profile",
        Decramastic: "decramastic-profile",
        Longspan: "longspan-profile",
        Tile: "tile-clips"
      };
      const mesh = this.$store.state.mesh.includes("Aluminium")
        ? "Aluminium"
        : "Steel";
      return profileImages(
        profile[this.$store.state.roof],
        this.$store.state.meshColour,
        mesh
      );
      // return profileImages(
      //   this.$store.state.roof,
      //   this.$store.state.meshColour,
      //   this.$store.state
      // );
    },
    screwImage() {
      return screwImages(this.$store.state.meshColour);
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .mkb-kit-wrapper {
    display: flex;
    flex-wrap: wrap;
    .mkb-profile-kit {
      padding: 0 13px 0 13px;
    }
    img.mkb-mesh-image,
    img.mkb-saddle-image,
    .mkb-screw-image {
      width: 100%;
    }
    .mkb-profile-image:not(.mkb-saddle-image) {
      width: 80%;
    }
    img.mkb-long-profile {
      transform: rotateZ(90deg);
      margin-left: 10px;
      + img.mkb-long-profile {
        margin-top: -35px;
      }
    }
    @media (max-width: 767px) {
      .mkb-profile-image:not(.mkb-saddle-image) {
        width: 55% !important;
      }
      img.mkb-long-profile {
        margin-left: 25px !important;
        + img.mkb-long-profile {
          margin-top: -75px !important;
        }
      }
      .mkb-screw-image {
        width: 80%;
      }
    }
  }
}
</style>
