<template>
  <div class="mkb-mesh-colour-page">
    <b-row>
      <b-col
        md="5"
        class="d-md-block pr-3 mkb-mesh-kit-image-container"
        :class="$store.state.type === 'Solar Panel' ? 'mb-5' : ''"
      >
        <Kit />
        <div class="text-center mt-3 d-none d-lg-block">
          <div
            class="mkb-next-button py-1 px-4 btn btn-primary"
            @click.prevent="setColour()"
            :class="{
              disabled: selectedColour === null,
              'mt-4': $store.state.type === 'Solar Panel'
            }"
          >
            <div class="description">
              NEXT
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="pl-3 pr-2">
        <h3 class="text-primary">Choose your mesh colour</h3>
        <div class="text-center my-2 d-lg-none">
          <div
            class="mkb-next-button py-2 px-4 btn btn-primary btn-block"
            @click.prevent="setColour()"
            :class="selectedColour === null ? 'disabled' : ''"
          >
            <div class="description">
              NEXT
            </div>
          </div>
        </div>
        <b-row align-h="between">
          <b-col>
            <div>
              <p>Select to suit your roof.</p>
            </div>
          </b-col>
          <b-col class="sort">
            <ColourFilter />
          </b-col>
        </b-row>

        <div class="mkb-colour-listing">
          <div
            class="mkb-colour-item"
            v-for="(colour, key) in newColours"
            :key="colour.name"
          >
            <colour
              :colour="colour"
              @click="selectColour(colour, key)"
              @hover="hoverColour(key)"
              @mouseleave="mouseLeave"
              :selectedColour="selectedColour"
            ></colour>
          </div>
        </div>

        <div class="mkb-colour-info">
          <b-row cols="1" cols-md="2" align-v="center">
            <b-col class="mkb-table-description" md="5">
              <p class="m-0 mb-1" v-if="$store.state.tenant === '4933348840'">
                <span class="mkb-double-asterisk">*</span> You will receive the
                following mesh and component combinations when you select these
                colours <ArrowLeft />
              </p>
              <p class="m-0 mb-1" v-else>
                <span class="mkb-double-asterisk">*</span> You will receive the
                following mesh and component combinations when you select these
                swatches <ArrowLeft />
              </p>

              <p>
                <span class="mkb-double-asterisk">**</span> Only available to
                suit tile gutter and valleys in 10m and 30m rolls.
              </p>
            </b-col>
            <b-col class="position-relative mkb-table-col" md="7">
              <b-table
                small
                :items="
                  $store.state.mesh && $store.state.mesh.includes('Aluminium')
                    ? aluminiumItems
                    : steelItems
                "
                :fields="fields"
                :class="{
                  'mkb-aluminium-table': $store.state.mesh.includes(
                    'Aluminium'
                  ),
                  'mkb-steel-table': $store.state.mesh.includes('Steel')
                }"
              >
              </b-table>
              <!-- https://git.nxt.work/rh/tools/mesh-kit-builder/-/issues/183 -->
              <!-- <div
                class="galvanised-form"
                v-if="
                  $store.state.mesh.includes('Aluminium') &&
                    this.$store.state.tenant !== 'bunnings'
                "
              >
                <p class="galvanised-desc">
                  If you have a Galvanised roof or gutters please contact our
                  team so that we can provide you with the best solution
                </p>
                <b-button @click="showContactModal()">Contact Us</b-button>
              </div> -->
            </b-col>
          </b-row>
          <div
            v-if="$store.state.mesh && $store.state.mesh.includes('Aluminium')"
          >
            <p class="mb-1">&#42; Colour-matched aluminium mesh:</p>
            <ul class="d-flex flex-wrap pb-3 mb-3">
              <li class="w-50">
                Zinc componentry with Shale Grey&#8482; mesh
              </li>
              <li class="w-50">
                Wilderness&#174; componentry with Pale Eucalypt&#8482; mesh
              </li>
              <li class="w-50">
                Bushland&#174; componentry with Windspray&#8482; mesh
              </li>
              <li class="w-50">
                Evening Haze&#174; componentry with Paperbark&#8482; mesh
              </li>
            </ul>
            <p v-if="$store.state.tenant !== '4933348840'">
              &#42;&#42; Only available to suit tile gutter and valleys in 10m
              and 30m rolls.
            </p>
          </div>
          <div v-if="$store.state.mesh && $store.state.mesh.includes('Steel')">
            <!-- <p class="mkb-colour-note">
              NOTE: Zinc and Galvanised are supplied with Shale Grey&#8482;
            </p> -->
          </div>
          <p class="mkb-small-colour-text mt-4">
            COLORBOND&#174; and all colour names used are registered trade marks
            and &#8482; colour names are trade marks of BlueScope Steel Limited.
            &copy; 2022 BlueScope Steel Limited ABN 16 000 011 058. All rights
            registered.
          </p>
        </div>
      </b-col>
    </b-row>
    <!-- <b-modal id="hubspotModal" title="BootstrapVue">
    <p class="my-4">Hello from modal!</p>
  </b-modal> -->
    <hubspot-modal ref="hubspotModal"></hubspot-modal>
    <galvanised-modal ref="mkbGalvanisedModal"></galvanised-modal>
  </div>
</template>

<script>
import Colour from "../components/Colour";
import ColourFilter from "../components/ColourFilter";
import Kit from "../components/Kit";
import ArrowLeft from "../components/svg/icons/ArrowLeft";
import HubspotModal from "../components/HubspotModal";
import GalvanisedModal from "../components/GalvanisedModal.vue";
import { steelColoursGalvanisedNotAvailable } from "../data/steel-colours";
export default {
  name: "MeshColour",
  components: {
    Colour,
    ColourFilter,
    Kit,
    ArrowLeft,
    HubspotModal,
    GalvanisedModal
  },
  data() {
    return {
      selectedColour: null,
      allColours: null,
      fields: [
        {
          key: this.$store.state.tenant === "4933348840" ? "colour" : "swatch",
          thClass: "px-0"
        },
        { key: "mesh_colour", thClass: "px-0" },
        { key: "components_colour", thClass: "px-0" }
      ],
      steelItems: [
        {
          components_colour: "Zinc",
          swatch: "Zinc",
          colour: "Zinc",
          mesh_colour: "Shale Grey ™"
        }
      ],
      aluminiumItems: [
        {
          components_colour: "Bushland ®",
          swatch: "Bushland ®",
          colour: "Bushland ®",
          mesh_colour: "Windspray ™"
        },
        {
          components_colour: "Evening Haze ®",
          swatch: "Evening Haze ®",
          colour: "Evening Haze ®",
          mesh_colour: "Paperbark ™"
        },
        {
          components_colour: "Wilderness ®",
          swatch: "Wilderness ®",
          colour: "Wilderness ®",
          mesh_colour: "Pale Eucalypt ™"
        },
        {
          components_colour: "Zinc",
          swatch: "Zinc",
          colour: "Zinc",
          mesh_colour: "Shale Grey ™"
        }
      ]
    };
  },
  computed: {
    newColours() {
      let newColours = { ...this.allColours };
      //sorting
      if (this.$store.state.sort) {
        newColours = Object.keys(newColours)
          .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: newColours[key]
            }),
            {}
          );
      }
      //end of sorting

      // reducing...
      //pale-terra colour only available on Tile roof
      if (this.$store.state.roof !== "Tile") {
        delete newColours["pale-terra"];
        return newColours;
      }
      return newColours;
    }
  },
  mounted() {
    // this is for ticket https://git.nxt.work/rh/tools/mesh-kit-builder/-/issues/175
    if (
      this.$store.state.type !== "Valley" &&
      (this.$store.state.roof === "Corrugated" ||
        this.$store.state.roof === "CGI Corrugated" ||
        this.$store.state.roof === "Cooldek® Corrugated") &&
      this.$store.state.mesh.includes("Steel")
    ) {
      this.allColours = steelColoursGalvanisedNotAvailable;
      this.$store.commit("setSortColour", false);
    } else {
      this.allColours = this.$store.getters.colours;
      this.$store.commit("setSortColour", true);
    }
  },
  methods: {
    mouseLeave() {
      if (!this.selectedColour) return;
      this.$store.commit("setMeshColour", this.selectedColour.key);
    },
    setColour() {
      this.$store.commit("setMeshColour", this.selectedColour.key);
      this.$store.commit("advance");
    },
    selectColour(colour, key) {
      if (key === "galvanise") {
        this.showGalvanisedModal();
        if (this.$store.state.mesh.includes("Aluminium")) {
          this.selectedColour = null;
        } else {
          const selected = {
            hasR: true,
            name: "Windspray",
            code: "#7b8181"
          };
          this.selectedColour = {
            colour: selected,
            key: "shale-grey"
          };
        }
      } else {
        this.selectedColour = { colour, key };
      }
    },
    hoverColour(colour) {
      this.$store.commit("setMeshColour", colour);
    },
    showContactModal() {
      this.$refs.hubspotModal.show();
    },
    showGalvanisedModal() {
      this.$refs.mkbGalvanisedModal.show();
    }
  }
};
</script>

<style lang="scss">
.mkb-next-button {
  border: 2px solid #002f6c;
  background-color: #002f6c;
  color: #ffffff;
  text-align: center;
  // margin-bottom: 10px;
  padding: 0.7rem;
  font-weight: 400;

  .description {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .width {
    font-weight: 600;
    font-size: 1.6rem;
  }
}
.mkb-next-button:hover {
  background-color: #ffffff;
  color: #002f6c;
  border: 2px solid #002f6c;
}
.mkb-next-button.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
table.mkb-steel-table,
table.mkb-aluminium-table {
  width: 90% !important;
  thead th {
    font-weight: 500;
    border-top: none !important;
    border-bottom: 2px solid #707070 !important;
    font-size: 0.5rem !important;
  }
}

.mkb-double-asterisk {
  color: red;
  font-size: 0.55rem;
  font-weight: 500;
  margin-left: 3px;
}
.mkb-mesh-colour-page {
  .mkb-table-description {
    p {
      font-size: 9px;
      font-weight: 500;
      @media (max-width: 767px) {
        width: 90%;
      }
    }
  }
  td {
    padding: 0.3rem 0px;
  }
  td[aria-colindex="1"] {
    font-weight: 500;
  }
  td[aria-colindex="2"]::after {
    content: "+";
    position: absolute;
  }

  .mkb-colour-info {
    font-size: 0.55rem !important;
  }

  .mkb-colour-item {
    display: inline-block;
    margin: 10px 0 0 2%;
    // flex-grow: 1;
    width: calc(100% * (1 / 5) - 10px - 1px);
    // width: 17%;
    font-size: 0.7rem;
    text-align: center;
    color: #707070;
    // margin-bottom: 15px;
    // margin: 0 8px 0 5px;
    @media (min-width: 768px) and (max-width: 1200px) {
      width: calc(100% * (1 / 4) - 10px - 1px);
    }
    @media (max-width: 500px) {
      width: calc(100% * (1 / 4) - 10px - 1px);
    }
    @media (max-width: 400px) {
      width: calc(100% * (1 / 3) - 10px - 1px);
    }
  }

  ul {
    padding-inline-start: 25px;
  }

  li {
    padding-right: 8px;
  }

  @media (max-width: 767.98px) {
    td[aria-colindex="2"]::after {
      right: 122px !important;
    }
    .mkb-table-description {
      font-size: 10px;
    }
    .mkb-colour-info {
      p:not(.galvanised-desc) {
        margin: 15px 5px 15px 5px !important;
      }
    }
  }
  .mkb-table-col {
    .galvanised-form {
      width: 90% !important;
      border: 1px solid #002f6c;
      padding: 5px;
      font-size: 10px;
      position: relative;
      padding-bottom: 1rem;
      p.galvanised-desc {
        margin-bottom: 0 !important;
      }
      button {
        background: #002f6c;
        border-radius: 50px;
        position: absolute;
        left: 0;
        right: 0;
        font-size: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
        bottom: -15px;
      }
    }
  }
  @media (min-width: 767.98px) {
    .mkb-table-col {
      padding: 0px;
    }
  }
}
mesh-kit-builder[tenant="bmco"] {
  table.mkb-steel-table td[aria-colindex="2"]::after {
    content: "+";
    position: absolute;
    right: 126px;
  }
  .mkb-aluminium-table td[aria-colindex="2"]::after {
    right: 125px !important;
  }
  @media (max-width: 767.98px) {
    .mkb-aluminium-table td[aria-colindex="2"]::after {
      right: 150px !important;
    }
    .mkb-steel-table td[aria-colindex="2"]::after {
      right: 173px !important;
    }
  }
}
mesh-kit-builder[tenant="bunnings"] {
  table.mkb-steel-table td[aria-colindex="2"]::after {
    content: "+";
    position: absolute;
    right: 184px;
  }
  .mkb-aluminium-table td[aria-colindex="2"]::after {
    right: 146px !important;
  }
  @media (max-width: 767.98px) {
    .mkb-aluminium-table td[aria-colindex="2"]::after {
      right: 190px !important;
    }
    .mkb-steel-table td[aria-colindex="2"]::after {
      right: 204px !important;
    }
  }
}
</style>
