var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mkb-clickable-selection",on:{"click":_vm.emitClick,"mouseover":_vm.emitHover,"mouseleave":_vm.emitMouseLeave}},[_c('div',{staticClass:"mkb-color-swatch"},[_c('div',{staticClass:"mkb-swatch ",style:({
        backgroundColor: _vm.colour.code,
        border:
          _vm.selectedColour === null
            ? ''
            : _vm.selectedColour.colour.name === _vm.colour.name
            ? '3px solid #002f6c'
            : ''
      })})]),_c('div',{staticClass:"mkb-colour-name"},[_vm._v(" "+_vm._s(_vm.colour.name)),(_vm.colourSuffix.length)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.colourSuffix)}}):_vm._e(),(_vm.doubleAsterisk && _vm.displayAsterisk)?_c('span',{staticClass:"mkb-double-asterisk"},[_vm._v("**")]):_vm._e(),(_vm.singleAsterisk && _vm.displayAsterisk)?_c('span',{staticClass:"mkb-double-asterisk"},[_vm._v("*")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }