<template>
  <b-modal
    ref="mkbFindStoreModal"
    size="lg"
    content-class="mkb-find-store-modal"
    centered
    hide-header
    body-class="p-0"
    @hidden="hide"
    hide-footer
  >
    <b-link class="mkb-close-button" @click.prevent="hide">
      <close></close>
    </b-link>
    <div
      class="mkb-store-info d-md-flex px-5 justify-content-between flex-md-row-reverse"
    >
      <div
        class="mkb-store-actions d-md-flex justify-content-between flex-md-column"
      >
        <div>
          <b-input-group>
            <b-form-input
              type="number"
              min="0.00"
              placeholder="Enter postcode"
              v-model="searchTerm"
            ></b-form-input>
            <b-input-group-append>
              <b-button class="mkb-search-store" @click="search"
                >Search</b-button
              >
            </b-input-group-append>
          </b-input-group>
          <span v-if="notFound">Postal code not found.</span>
        </div>

        <div>
          <p v-if="selected" class="mkb-store-selected-text">
            Store selected: <span>{{ selected.name }}</span>
          </p>
          <b-button
            @click="confirm()"
            block
            variant="primary"
            size="lg"
            :disabled="selected === null ? true : false"
            >CONFIRM</b-button
          >
        </div>
      </div>
      <div class="w-50 text-center" style="height: 500px" v-if="isLoading">
        <b-spinner
          variant="primary"
          label="Spinning"
          class="text-center"
        ></b-spinner>
      </div>

      <b-list-group v-else>
        <b-list-group-item
          @click="selectStore(store)"
          v-for="(store, index) in sortedLocations"
          :key="index"
          :active="store === selected ? true : false"
          class="flex-column align-items-start"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ store.name }}</h5>
            <small>{{ store.postcode }}</small>
          </div>
          <p class="mb-1"><StoreIcon /> {{ store.address }}</p>
          <small>{{ store.suburb }}, {{ store.state }}</small>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-modal>
</template>

<script>
import Close from "./svg/icons/Close";
import StoreIcon from "./svg/icons/Store";
import { steelineStores } from "../data/steeline-stores";
import { stratcoStores } from "../data/stratco-stores";
export default {
  name: "FindAStoreModal",
  components: {
    Close,
    StoreIcon
  },
  data() {
    return {
      searchTerm: "",
      selected: null,
      notFound: false,
      isLoading: false
    };
  },
  // computed: {
  //   filteredStores() {
  //     if (this.filterPostcode === '') {
  //       return this.stores;
  //     } else {
  //       return this.stores.filter((item) =>
  //         item.postcode.includes(this.filterPostcode)
  //       );
  //     }
  //   },
  // },
  computed: {
    stores() {
      if (this.$store.state.tenant === "steeline") {
        return steelineStores;
      } else {
        return stratcoStores;
      }
    },
    sortedLocations() {
      if (!this.searchTerm || this.notFound) {
        return this.stores;
      }

      const sorted = this.stores
        .map(location => {
          return {
            ...location,
            distance: this.getDistance(
              location.lat,
              location.lng,
              this.searchTerm.lat,
              this.searchTerm.lng
            )
          };
        })
        .sort((a, b) => a.distance - b.distance);

      const limitedStores = sorted.slice(0, 5);

      return limitedStores;
    }
  },
  methods: {
    show() {
      this.$refs.mkbFindStoreModal.show();
    },
    hide() {
      this.$refs.mkbFindStoreModal.hide();
      this.hubspotActive = false;
      this.formSubmitted = false;
    },
    confirm() {
      if (this.$store.state.type === "Solar Panel") {
        this.$store.commit("setHubspotForm", "solarPanelQuoteFormId");
      } else {
        this.$store.commit("setHubspotForm", "quoteFormId");
      }
      this.$store.commit("setStoreSelected", this.selected.name);
      this.$store.commit("enquiryStep", "get-a-quote-form");
    },
    selectStore(store) {
      this.selected = store;
    },
    search() {
      this.isLoading = true;
      fetch(
        `https://secure.geonames.org/postalCodeSearchJSON?postalcode=${this.searchTerm}&country=AU&maxRows=1&username=sumiliholden`
      )
        .then(response => response.json())
        .then(data => {
          if (data.postalCodes.length > 0) {
            this.notFound = false;
            this.searchTerm = data.postalCodes[0].postalCode;
            this.searchTerm = {
              lat: data.postalCodes[0].lat,
              lng: data.postalCodes[0].lng
            };
            this.isLoading = false;
          } else {
            this.notFound = true;
            this.isLoading = false;
          }
        })
        .catch(error => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    }
  }
};
</script>

<style lang="scss">
.mkb-find-store-modal {
  .mkb-no-store {
    height: 500px;
    width: 400px;
    text-align: center;
    color: #002f6c;
  }
  .list-group {
    height: 500px;
    overflow-y: auto;
    width: 400px;
    @media (max-width: 767px) {
      width: 100%;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #002f6c;
    }
    div.list-group-item {
      :hover {
        cursor: pointer;
      }
      color: #002f6c;
      fill: #002f6c;
      &.active {
        color: #fff;
        fill: #fff;
      }
    }
  }
  .mkb-store-actions {
    width: 280px;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
    input[type="number"] {
      -moz-appearance: textfield;
      box-shadow: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .mkb-search-store {
      background: #002f6c;
      color: #fff;
    }
    .mkb-store-selected-text {
      width: 280px;
      color: #002f6c;
      margin: 1rem 0;
      span {
        font-weight: 500;
      }
    }
  }

  .mkb-close-button {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 12px;
  }

  .mkb-store-info {
    padding: 50px;
  }

  @media (max-width: 767.98px) {
    p {
      font-size: 0.9rem;
    }
  }
}
</style>
