<template>
  <div class="mkb-solar-panel-height-page">
    <div class="w-100">
      <h3 class="text-primary">Panel Measurements - Vertical</h3>
      <p class="text-justify mb-2 mkb-m-desc">
        Measure each edge as shown below, and then add 600mm to the length of
        each edge to allow for overlapping in the corners.
      </p>
      <p class="text-justify mb-5 mkb-m-desc">
        It's also best practice to add 10% to the total result to allow for any
        wastage.
      </p>

      <b-row>
        <b-col md="6" cols="12" class="pt-5">
          <template>
            <b-img
              src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/Solar-Panel-Mesh-Flow-Measurement-Vertical-1.svg"
              class="w-100"
            ></b-img>
          </template>
        </b-col>
        <b-col md="6" cols="12" class="pt-5">
          <div class="mkb-table-wrapper mx-auto">
            <b-table
              small
              :items="ex1Items"
              :fields="fields"
              class="mb-1 mkb-panel-size-table"
            >
            </b-table>
            <div class="d-flex justify-content-between">
              <span class="mr-5 mkb-total-measurement"
                >Total Vertical Measurement</span
              >
              <span>5.2m</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" cols="12" class="pt-5">
          <template>
            <b-img
              src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/Solar-Panel-Mesh-Flow-Measurement-Vertical-2.svg"
              class="w-100"
            ></b-img>
          </template>
        </b-col>
        <b-col md="6" cols="12" class="pt-5">
          <div class="mkb-table-wrapper mx-auto">
            <b-table
              small
              :items="ex2Items"
              :fields="fields"
              class="mb-1 mkb-panel-size-table"
            >
            </b-table>
            <div class="d-flex justify-content-between">
              <span class="mr-5 mkb-total-measurement"
                >Total Vertical Measurement</span
              >
              <span>8.4m</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col offset-md="6">
          <div class="mkb-handler-wrapper mx-auto">
            <label for="horizontaWidth">TOTAL VERTICAL MEASUREMENT</label>
            <!-- <input
            v-model="$store.state.panelHeight"
            id="horizontaWidth"
            class="form-control form-control-lg"
            type="number"
            placeholder="Meters"
            min="1"
            oninput="validity.valid||(value='');"
          /> -->
            <div class="metres w-100 mb-3">
              <b-input
                type="number"
                @keypress="isNumber($event)"
                @keyup.enter="setPanelHeight($store.state.panelHeight)"
                v-model="$store.state.panelHeight"
                @wheel.prevent
                maxlength="10"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                size="lg"
              />
            </div>
            <div
              class="standard-selection mkb-clickable-container mb-4 py-3"
              @click.prevent="setPanelHeight($store.state.panelHeight)"
              :class="'btn btn-primary btn-lg btn-block ' + isDisableComputed"
            >
              <div class="description">
                NEXT
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolarPanelHeight",
  data() {
    return {
      fields: ["edge", "length", "including_extra_0.6m"],
      ex1Items: [
        {
          edge: "A",
          length: "2m",
          "including_extra_0.6m": "2.6m"
        },
        {
          edge: "B",
          length: "2m",
          "including_extra_0.6m": "2.6m"
        }
      ],
      ex2Items: [
        {
          edge: "A",
          length: "2m",
          "including_extra_0.6m": "2.6m"
        },
        {
          edge: "B",
          length: "1m",
          "including_extra_0.6m": "1.6m"
        },
        {
          edge: "C",
          length: "1m",
          "including_extra_0.6m": "1.6m"
        },
        {
          edge: "D",
          length: "2m",
          "including_extra_0.6m": "2.6m"
        }
      ]
    };
  },
  computed: {
    isDisableComputed() {
      if (this.$store.state.panelHeight > 0) return "";
      return "disabled";
    }
  },
  methods: {
    setPanelHeight(panelHeight) {
      if (this.isDisableComputed === "disabled") return;

      this.$store.commit("setPanelHeight", panelHeight);
      this.$store.commit("advance");
    },
    isNumber(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "."
      ];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss">
@media (max-width: 767px) {
  .mkb-m-desc {
    width: 95% !important;
  }
}
.standard-selection {
  border: 2px solid #002f6c;
  background-color: #002f6c;
  color: #ffffff;
  text-align: center;
  // margin-bottom: 10px;
  padding: 0.7rem;
  font-weight: 400;

  .description {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .width {
    font-weight: 600;
    font-size: 1.6rem;
  }
}
.standard-selection:hover {
  background-color: #ffffff;
  color: #002f6c;
  border: 2px solid #002f6c;
}
.standard-selection.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.mkb-handler-wrapper {
  label,
  .metres::after {
    color: #002f6c;
    font-weight: 500;
  }
  input[type="number"] {
    font-weight: 500 !important;
    color: #002f6c;
  }
}
.metres::after {
  content: "metres";
}
.metres {
  display: inline-block;
  position: relative;
}

/* position the unit to the right of the wrapper */
.metres::after {
  position: absolute;
  right: 1em;
  bottom: 11px;
  transition: all 0.05s ease-in-out;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 575.98px) {
  label {
    font-size: 0.9rem !important;
  }
  p {
    width: 100% !important;
    font-size: 1.1rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
  }
  .standard-selection {
    margin-top: 30px;
  }
}
</style>
