<template>
  <b-container>
    <div class="header">
      <page-header></page-header>
    </div>
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <div class="w-100 text-center">
      <b-spinner
        variant="primary"
        label="Spinning"
        v-if="isLoading"
      ></b-spinner>
    </div>
    <div class="enquiry-container" v-show="!isLoading">
      <b-row cols="1" cols-md="2" class="mkb-row-enquiry">
        <b-col md="5">
          <div class="mkb-info-container mkb-product">
            <div class="mt-4 mb-2 mx-3">
              <h4 class="mkb-title">
                We will need to provide you with a custom kit for your
                <span class="text-lowercase">{{ $store.state.type }} type</span>
              </h4>
              <p class="mkb-description" v-if="$store.state.otherRoof">
                Your roof is unique! Please leave your details and one of our
                team will be in touch to discuss the roof with you to ensure you
                get the perfect kit for your property.
              </p>
              <p class="mkb-description " v-else>
                The position of your solar panel/s is unique! Please leave your
                details and one of our team will be in touch to discuss the
                panels with you to ensure you get the perfect kit for your
                property.
              </p>
            </div>
            <div
              class="d-flex flex-column align-items-start flex-wrap mkb-product-wrapper mt-3 mx-3"
              :style="{ height: dynamicHeight() }"
            >
              <div
                class="px-0 py-2 w-50"
                v-for="(item, index) in productList"
                :key="index"
              >
                <span class="mkb-title">{{ item.name }}</span>
                <h6
                  class="mkb-description"
                  v-if="typeof item.data === 'object'"
                >
                  {{ item.data.standard ? "Standard" : "Non-Standard" }}
                  {{ item.data.width }}mm
                </h6>
                <div v-else-if="item.name.includes('colour')">
                  <inline-colour
                    class="mkb-description mb-2 mkb-form-inline-colour"
                    :colour="item.data"
                  ></inline-colour>
                </div>

                <h6 class="mkb-description" v-else>
                  {{
                    item.data === "Klip-Lok"
                      ? item.data + "® or Flat Pan"
                      : item.data === "Traditional (screws only)"
                      ? "Traditional"
                      : item.data === "Standard Gutter"
                      ? "Standard"
                      : item.data
                  }}{{ item.name.includes("measurement") ? "m" : "" }}
                </h6>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="mkb-form" md="7">
          <div class="mkb-info-container">
            <div class="my-4">
              <h4 class="mkb-sub-title">
                Please enter your contact information.
              </h4>
              <p
                class="mkb-description"
                v-if="$store.state.tenant === 'mitre-10-Strathalbyn'"
              >
                The BMCo Team will be in touch as soon as possible to help you
                choose the right kit to order from Mitre 10 Strathalbyn.
              </p>
              <p class="mkb-description" v-else>
                Our team will be in touch as soon as possible.
              </p>

              <b-row cols="1" align-h="between">
                <div id="hubspotForm" v-once></div>
              </b-row>
            </div>
          </div>
        </b-col>
        <!-- end test form -->
      </b-row>
    </div>
  </b-container>
</template>
<script>
import PageHeader from "../../components/PageHeader";
import InlineColour from "../../components/InlineColour";
import { forms } from "@/data/tenants";
import axios from "axios";
export default {
  components: {
    PageHeader,
    InlineColour
  },
  name: "EnquiryForm",
  data() {
    return {
      forms,
      isLoading: false,
      productsInfo: [
        {
          name: "roof installation type",
          data: this.$store.state.type
        },
        { name: "roof type", data: this.$store.state.roof },
        {
          name: "valley installation type",
          data: this.$store.state.valley
        },
        {
          name: "installation method",
          data: this.$store.state.installationType
        },
        { name: "mesh type", data: this.$store.state.mesh },
        { name: "mesh colour", data: this.$store.state.meshColour },
        { name: "mesh width", data: this.$store.state.width },
        { name: "trim type", data: this.$store.state.trim },
        { name: "trim colour", data: this.$store.state.trimColour },
        {
          name: "horizontal measurement",
          data: this.$store.state.panelWidth
        },
        {
          name: "vertical measurement",
          data: this.$store.state.panelHeight
        },
        { name: "panel offset", data: this.$store.state.panelOffset },
        {
          name: "panel position",
          data: this.$store.state.panelPosition
        }
      ],
      state: this.$store.state
    };
  },
  computed: {
    productList() {
      return this.productsInfo.filter(v => v.data !== null);
    }
  },
  async mounted() {
    await this.setForm();
    this.completedEngagement();
  },
  methods: {
    async completedEngagement() {
      if (this.$store.state.tenant === "localhost") return;
      const API_ENDPOINT = `${process.env.VUE_APP_API_ENDPOINT}/tool/add-engagement`;

      try {
        const payloads = {
          tenant: this.$store.state.tenant,
          engagement: 3,
          tool: "mkb"
        };

        await axios.post(API_ENDPOINT, payloads, {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    setForm() {
      this.$refs.topProgress.start();
      this.isLoading = true;
      const form = this.forms[this.$store.state.tenant];
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            formId: this.$store.state.otherRoof
              ? form["otherRoofFormId"]
              : form["solarPanelFormId"],
            portalId: "3779244",
            target: "#hubspotForm",
            css: "styles/hubspot.scss"
          });
        }
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormReady"
        ) {
          this.isLoading = false;
          this.$refs.topProgress.done();
          if (this.$store.state.otherRoof) {
            document.querySelector(
              "input[name='mkb_install_type']"
            ).value = this.$store.state.type;
            document.querySelector(
              "input[name='mkb_other_roof']"
            ).value = this.$store.state.roof;
            document.querySelector(
              "input[name='valley_install_type']"
            ).value = this.$store.state.valley;
            document.querySelector(
              "input[name='mkb_mesh_type']"
            ).value = this.$store.state.mesh;
            document.querySelector("input[name='mesh_colour']").value = this
              .$store.getters.colours[this.$store.state.meshColour].hasR
              ? this.$store.getters.colours[this.$store.state.meshColour].name +
                "®"
              : this.$store.getters.colours[this.$store.state.meshColour].hasTm
              ? this.$store.getters.colours[this.$store.state.meshColour].name +
                "™"
              : this.$store.getters.colours[this.$store.state.meshColour].name;

            if (this.$store.state.trimColour !== null) {
              document.querySelector(
                "input[name='mkb_trim_type']"
              ).value = this.$store.state.trim;
              document.querySelector(
                "input[name='mkb_trim_colour']"
              ).value = this.$store.getters.colours[
                this.$store.state.trimColour
              ].hasR
                ? this.$store.getters.colours[this.$store.state.trimColour]
                    .name + "®"
                : this.$store.getters.colours[this.$store.state.trimColour]
                    .hasTm
                ? this.$store.getters.colours[this.$store.state.trimColour]
                    .name + "™"
                : this.$store.getters.colours[this.$store.state.trimColour]
                    .name;
            }
            document.querySelector(
              "input[name='mkb_solar_h_length']"
            ).value = this.$store.state.panelWidth;
            document.querySelector(
              "input[name='mkb_solar_v_length']"
            ).value = this.$store.state.panelHeight;
            document.querySelector(
              "input[name='mkb_solar_panel_offset']"
            ).value = this.$store.state.panelOffset;
            document.querySelector(
              "input[name='mkb_solar_panel_position']"
            ).value = this.$store.state.panelPosition;
          } else {
            document.querySelector(
              "input[name='mkb_roof_type']"
            ).value = this.$store.state.roof;
            document.querySelector(
              "input[name='mkb_mesh_type']"
            ).value = this.$store.state.mesh;
            document.querySelector("input[name='mesh_colour']").value = this
              .$store.getters.colours[this.$store.state.meshColour].hasR
              ? this.$store.getters.colours[this.$store.state.meshColour].name +
                "®"
              : this.$store.getters.colours[this.$store.state.meshColour].hasTm
              ? this.$store.getters.colours[this.$store.state.meshColour].name +
                "™"
              : this.$store.getters.colours[this.$store.state.meshColour].name;
            document.querySelector(
              "input[name='mkb_solar_h_length']"
            ).value = this.$store.state.panelWidth;
            document.querySelector(
              "input[name='mkb_solar_v_length']"
            ).value = this.$store.state.panelHeight;
            document.querySelector(
              "input[name='mkb_solar_panel_offset']"
            ).value = this.$store.state.panelOffset;
            document.querySelector(
              "input[name='valley_install_type']"
            ).value = this.$store.state.valley;
            document.querySelector(
              "input[name='mkb_solar_panel_position']"
            ).value = this.$store.state.panelPosition;
          }
        }
        if (event.data.eventName === "onFormSubmit") {
          this.isLoading = true;
        }
        if (event.data.eventName === "onFormSubmitted") {
          this.isLoading = false;
          if (this.$store.state.enquiry !== null) {
            //alternative for removeEventListener
            this.$store.commit("enquiryStep", "thank-you");
          }
          if (this.$store.state.otherRoof) {
            //alternative for removeEventListener
            this.$store.commit("enquiryStep", "thank-you");
          }
        }
      });
    },
    dynamicHeight() {
      if (
        (this.$store.state.type.includes("Valley") &&
          this.$store.state.roof !== "Klip-Lok") ||
        this.$store.state.type.includes("Box")
      ) {
        return "260px";
      } else if (
        this.$store.state.type.includes("Solar") &&
        (this.$store.state.roof === "Klip-Lok" ||
          this.$store.state.roof === "Lokdek 680/700" ||
          this.$store.state.roof === "Steel Rib 500" ||
          this.$store.state.roof === "Steel Rib 675" ||
          this.$store.state.roof === "Steeline Hi Dek")
      ) {
        return "400px";
      } else return "341px";
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-form-inline-colour {
  line-height: 1.2;
}
.enquiry-container {
  .mkb-product-wrapper {
    .mkb-title {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: #002f6c;
      font-weight: 400;
    }
    .mkb-description {
      font-size: 1.1rem;
      color: #002f6c;
      text-transform: capitalize;
      padding-bottom: 0 !important;
    }
  }
  .mkb-title {
    width: 75%;
  }
  .mkb-asterisk {
    font-size: 14px;
    color: red;
  }
  h5 {
    font-size: 19px !important;
  }
  h4,
  h5 {
    color: #002f6c;
    font-weight: 500;
  }
  .mkb-info-container {
    background: #fbfafb;
    &.mkb-product {
      padding: 1rem;
    }
    padding: 3rem;
    label {
      text-transform: uppercase;
    }
  }
  .mkb-form .mkb-info-container {
    height: 95%;
    background: white !important;
  }
  .mkb-sub-title {
    font-size: 18px;
  }
  .mkb-description {
    font-size: 14px;
    color: #98a4af;
    font-weight: 500;
  }
  .mkb-details span,
  label {
    font-size: 12px;
    font-weight: 500;
    color: #98a4af;
  }
  max-width: 100% !important;
}
@media (max-width: 767.98px) {
  .mkb-row-enquiry {
    width: 100% !important;
  }
  .mkb-info-container {
    height: 98% !important;
    .row {
      text-align: center;
    }
  }
  .mkb-inline-colour {
    justify-content: center !important;
  }
  .mkb-title {
    width: 100% !important;
  }
  .mkb-info-container {
    padding: 1rem !important;
  }
}
</style>
