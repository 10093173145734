<template>
  <div class="mkb-container">
    <div class="mkb-kit-wrapper">
      <b-col class="p-0" md="6" cols="6">
        <img :src="meshImage" alt="Mesh Roll" class="mkb-mesh-image " />
      </b-col>
      <b-col class="mkb-profile-kit">
        <div class="d-flex flex-column h-100 justify-content-end">
          <div class="mkb-clip-hooks mt-1">
            <b-row cols="1" class="mkb-clip-hooks-img ml-1">
              <b-img
                class="w-100 mb-1"
                :src="$store.state.solarImages['solar panel mesh clip hooks']"
              ></b-img>
              <b-img
                class="w-100 mb-1"
                :src="$store.state.solarImages['solar panel mesh clip hooks']"
              ></b-img>
              <b-img
                class="w-100 mb-1"
                :src="$store.state.solarImages['solar panel mesh clip hooks']"
              ></b-img>
            </b-row>
          </div>
          <div class="mkb-clip-plates mt-1">
            <b-row cols="4" class="mkb-clip-plates-img ml-1">
              <b-img
                class="w-100 m-0 mr-1"
                :src="
                  $store.state.solarImages[
                    'solar panel mesh clip locking plates - back'
                  ]
                "
              ></b-img
              ><b-img
                class="w-100 m-0 mr-1"
                :src="
                  $store.state.solarImages[
                    'solar panel mesh clip locking plates - back'
                  ]
                "
              ></b-img
              ><b-img
                class="w-100 m-0 mr-1"
                :src="
                  $store.state.solarImages[
                    'solar panel mesh clip locking plates - back'
                  ]
                "
              ></b-img>
            </b-row>
          </div>

          <img
            v-show="
              hasProfile($store.state.roof, $store.state.installationType)
            "
            :src="profileImage"
            alt="Profile"
            class="mkb-profile-image "
            :class="{
              'mkb-long-profile':
                (($store.state.roof === 'Corrugated' ||
                  $store.state.roof === 'CGI Corrugated') &&
                  $store.state.mesh.includes('Steel')) ||
                $store.state.roof === 'Spandek' ||
                $store.state.roof === 'Longspan',
              'mkb-saddle-image':
                $store.state.roof === 'Corrugated' &&
                $store.state.mesh.includes('Aluminium')
            }"
          />
          <img
            :src="screwImage"
            alt="Screw"
            class="mkb-screw-image"
            :style="{
              'margin-top':
                (($store.state.roof === 'Corrugated' ||
                  $store.state.roof === 'CGI Corrugated') &&
                  $store.state.mesh.includes('Steel')) ||
                $store.state.roof === 'Longspan'
                  ? '-15px'
                  : $store.state.roof === 'Spandek'
                  ? '-10px'
                  : '1px'
            }"
          />
        </div>
        <!-- <div class="d-flex">
          <img :src="trimImage" class="mkb-trim-image" alt="Gutter trim" />
          <div class="d-flex flex-column">
            <img v-show="$store.state.installationType === null || $store.state.installationType === 'Saddles'" :src="profileImage" alt="Profile" class="mkb-profile-image my-auto" :class="{'mkb-long-profile' : $store.state.roof === 'Decramastic' || $store.state.roof === 'Bullnose'}" />
            <img :src="screwImage" alt="Screw" class="mkb-screw-image mb-auto" :style="{'margin-top' : $store.state.installationType === 'Traditional (screws only)' ? '90px' : 'auto'}"/>
          </div>
        </div> -->
      </b-col>
    </div>
    <div>
      <img :src="trimImage" alt="Gutter trim" class="mkb-trim-image" />
    </div>
  </div>
</template>
<script>
import {
  rollImages,
  trimImages,
  profileImages,
  screwImages
} from "../../data/rollImages";
export default {
  computed: {
    meshImage() {
      const mesh = {
        "2mm Steel": "steel-2mm",
        "4mm Steel": "steel-4mm",
        "5.4mm Steel": "steel-5 4mm",
        "2mm Aluminium": "aluminium-2mm",
        "4mm Aluminium": "aluminium-4mm",
        "4mm Aluminium Ultra": "aluminium-4mm"
      };
      return rollImages(
        `${mesh[this.$store.state.mesh]}-${this.$store.state.meshColour}`,
        mesh[this.$store.state.mesh]
      );
    },
    trimImage() {
      return trimImages(
        `standard-gutter-trim-${this.$store.state.meshColour}`,
        "standard-gutter-trim"
      );
    },
    profileImage() {
      const profile = {
        Corrugated: {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "CGI Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        "Cooldek® Corrugated": {
          Steel: "corro-profile",
          Aluminium: "corro-saddle"
        },
        Trimdek: "trimdek-saddle",
        Superdek: "trimdek-saddle",
        "Cooldek® Classic": "trimdek-saddle",
        SteelClad: "trimdek-saddle",
        "Klip-Lok": "kliplok-saddle",
        "Topdek® 700": "kliplok-saddle",
        "Lokdek 680/700": "kliplok-saddle",
        Bullnose: "bullnose-profile",
        Spandek: "spandek-profile",
        Steelspan: "spandek-profile",
        Decramastic: "decramastic-profile",
        Longspan: "longspan-profile",
        Tile: "tile-clips"
      };
      const mesh = this.$store.state.mesh.includes("Aluminium")
        ? "Aluminium"
        : "Steel";
      return profileImages(
        profile[this.$store.state.roof],
        this.$store.state.meshColour,
        mesh
      );
    },
    screwImage() {
      return screwImages(this.$store.state.meshColour);
    }
  },
  methods: {
    hasProfile(profile, installationType) {
      if (
        profile === "Tile" ||
        installationType === "Traditional (screws only)"
      ) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .mkb-kit-wrapper {
    display: flex;
    flex-wrap: wrap;
    .mkb-profile-kit {
      padding: 0 13px 0 13px;
    }
    img.mkb-mesh-image,
    .mkb-profile-image,
    .mkb-screw-image {
      width: 100%;
    }
    .mkb-profile-image:not(.mkb-saddle-image) {
      width: 80%;
    }
    img.mkb-long-profile {
      transform: rotateZ(90deg);
      margin-left: 10px;
      margin-top: -10px;
    }
    img.mkb-screw-image {
      width: 100%;
      // transform: rotateZ(90deg);
    }
    @media (max-width: 767px) {
      .mkb-profile-image:not(.mkb-saddle-image) {
        width: 55% !important;
      }
      img.mkb-long-profile {
        margin-left: 35px !important;
        margin-top: -40px;
        + .mkb-screw-image {
          margin-top: 40px;
        }
      }
      .mkb-screw-image {
        width: 80%;
      }
    }
  }
  img.mkb-trim-image {
    width: 8%;
    transform: rotateZ(270deg);
    margin-top: -50px;
    margin-left: 55px;
    @media (min-width: 576px) and (max-width: 767px) {
      margin-left: 183px;
      margin-top: -183px;
    }
    @media (min-width: 415px) and (max-width: 575px) {
      margin-left: 167px;
      margin-top: -167px;
    }
    @media (width: 375px) {
      margin-left: 117px;
      margin-top: -117px;
    }
    @media (width: 414px) {
      margin-left: 137px;
      margin-top: -137px;
    }
  }
}
</style>
