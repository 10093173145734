export const steelineStores = [
  {
    name: "R&R Fencing",
    address: "36 Owen Road",
    suburb: "Kelmscott",
    state: "WA",
    postcode: "6111",
    lat: -32.13208699779434,
    lng: 116.00493717414803
  },
  {
    name: "Steeline Dubbo - Custom Steel Frames",
    address: "110 Fitzroy Street,",
    suburb: "Dubbo",
    state: "NSW",
    postcode: "2830",
    lat: -32.235651829334884,
    lng: 148.61688872871832
  },

  {
    name: "Steeline Bendigo Building Products",
    address: "49 McDowalls Road",
    suburb: "East Bendigo",
    state: "VIC",
    postcode: "3550",
    lat: -36.74528853918316,
    lng: 144.3174257134733
  },
  {
    name: "Steeline Burnie",
    address: "21 Queen Street",
    suburb: "Burnie",
    state: "TAS",
    postcode: "7320",
    lat: -41.049149483932254,
    lng: 145.90179699823392
  },
  {
    name: "Steeline Cairns Hardware (Atherton)",
    address: "79 - 85 Grove Street",
    suburb: "Atherton",
    state: "QLD",
    postcode: "4883",
    lat: -17.256425132713918,
    lng: 145.47875132663373
  },
  {
    name: "Steeline Cairns Hardware (Innisfail)",
    address: "3 Shell Street",
    suburb: "Innisfail",
    state: "QLD",
    postcode: "4860",
    lat: -17.52318277461654,
    lng: 146.01846208056685
  },
  {
    name: "Steeline Canberra",
    address: "64 - 78 Vicars Street",
    suburb: "Mitchell",
    state: "ACT",
    postcode: "2911",
    lat: -35.20893904158722,
    lng: 149.14188810235194
  },
  {
    name: "Steeline Dandenong",
    address: "28 Thomas Murrell Crescent",
    suburb: "Dandenong South",
    state: "VIC",
    postcode: "3175",
    lat: -38.01994609775467,
    lng: 145.19555371350256
  },
  {
    name: "Steeline Coffs Harbour",
    address: "24 Cook Drive",
    suburb: "Coffs Harbour",
    state: "NSW",
    postcode: "2450",
    lat: -30.1485417975669,
    lng: 153.08577095649525
  },
  {
    name: "Steeline FNW Grafton",
    address: "56 Through Street",
    suburb: "Grafton",
    state: "NSW",
    postcode: "2460",
    lat: -29.663115246205848,
    lng: 152.94092819054717
  },
  {
    name: "Steeline FNW Inverell",
    address: "84 Oliver St",
    suburb: "Inverell",
    state: "NSW",
    postcode: "2360",
    lat: -29.768571412578645,
    lng: 151.1203739268638
  },
  {
    name: "Steeline FNW Toowoomba",
    address: "300 Anzac Ave",
    suburb: "Harristown",
    state: "QLD",
    postcode: "4350",
    lat: -27.583227757779483,
    lng: 151.91904305565217
  },
  {
    name: "Steeline GRP Darwin",
    address: "84 Pruen Road",
    suburb: "Berrimah",
    state: "NT",
    postcode: "828",
    lat: -12.439313605750604,
    lng: 130.92637096889874
  },
  {
    name: "Steeline Mid North Coast",
    address: "20 Blackbutt Road",
    suburb: "Port Macquarie",
    state: "NSW",
    postcode: "2444",
    lat: -31.453193928522783,
    lng: 152.88823466923137
  },
  {
    name: "Steeline Frames & Sheds",
    address: "4-10 Westerway Street",
    suburb: "Slacks Creek",
    state: "QLD",
    postcode: "4127",
    lat: -27.631318715103188,
    lng: 153.12998172681802
  },
  {
    name: "Steeline JH Stephenson Truganina",
    address: "59-63 Permas Way",
    suburb: "Truganina",
    state: "VIC",
    postcode: "3029",
    lat: -37.81747549914452,
    lng: 144.73519465589354
  },
  {
    name: "Steeline JH Stephenson Geelong",
    address: "152-154 Victoria St",
    suburb: "Geelong",
    state: "VIC",
    postcode: "3215",
    lat: -38.12276045506042,
    lng: 144.344384713572
  },
  {
    name: "Steeline Mildura",
    address: "730 Benetook Avenue",
    suburb: "Mildura",
    state: "VIC",
    postcode: "3500",
    lat: -34.21787397847029,
    lng: 142.15303205395617
  },
  {
    name: "Steeline Moruya",
    address: "25 Shelley Road, Moruya Business Park",
    suburb: "North Moruya",
    state: "NSW",
    postcode: "2537",
    lat: -35.87733999492533,
    lng: 150.085926362744
  },
  {
    name: "Steeline JH Stephenson Corio",
    address: "75 Heales Road",
    suburb: "Corio",
    state: "VIC",
    postcode: "3214",
    lat: -38.05156616776612,
    lng: 144.38233549822948
  },
  {
    name: "Steeline Hunter",
    address: "29 Firebrick Drive",
    suburb: "Thornton",
    state: "NSW",
    postcode: "2322",
    lat: -32.785368565682695,
    lng: 151.6327924404272
  },
  {
    name: "Steeline Albury",
    address: "111 Union Road",
    suburb: "North Albury",
    state: "NSW",
    postcode: "2640",
    lat: -36.054544599377316,
    lng: 146.94722174420008
  },
  {
    name: "Steeline Pambula",
    address: "4 Bullara Street",
    suburb: "Pambula",
    state: "NSW",
    postcode: "2549",
    lat: -36.93294549226053,
    lng: 149.8824629982002
  },
  {
    name: "Steeline Riverstone",
    address: "68 Princes Street",
    suburb: "Riverstone",
    state: "NSW",
    postcode: "2765",
    lat: -33.66117708144952,
    lng: 150.8593069134594
  },
  {
    name: "Steeline Bathurst",
    address: "49 Hampden Park Road",
    suburb: "Kelso",
    state: "NSW",
    postcode: "2795",
    lat: -33.426719076378255,
    lng: 149.62402583859622
  },
  {
    name: "Steeline Bendigo",
    address: "6 Harrien Court",
    suburb: "Bendigo",
    state: "VIC",
    postcode: "3551",
    lat: -36.70234784119056,
    lng: 144.31474765586472
  },
  {
    name: "Steeline Corowa",
    address: "5 Bow Street",
    suburb: "Corowa",
    state: "NSW",
    postcode: "2646",
    lat: -35.99397390992781,
    lng: 146.39005464050598
  },
  {
    name: "Steeline Roofing Centre Mackay",
    address: "281 Nebo Road",
    suburb: "West Mackay",
    state: "QLD",
    postcode: "4740",
    lat: -21.164168403232324,
    lng: 149.15700774018944
  },
  {
    name: "Steeline Shepparton",
    address: "60-62 Mitchell Street",
    suburb: "Shepparton",
    state: "VIC",
    postcode: "3630",
    lat: -36.38282449180674,
    lng: 145.4196562270939
  },
  {
    name: "Steeline Wagga",
    address: "13 Schiller Street",
    suburb: "Wagga Wagga",
    state: "NSW",
    postcode: "2650",
    lat: -35.12705710805726,
    lng: 147.41524880000074
  },
  {
    name: "Steeline Roofing Hobart",
    address: "1 Whitestone Drive",
    suburb: "Austins Ferry, Hobart",
    state: "TAS",
    postcode: "7011",
    lat: -42.7656087326508,
    lng: 147.24489981185332
  },
  {
    name: "Steeline Roofing Mount Gambier",
    address: "6 Calula Drive",
    suburb: "Mt Gambier",
    state: "SA",
    postcode: "5290",
    lat: -37.810512860086504,
    lng: 140.75213660006935
  },
  {
    name: "Steeline Roofing Products",
    address: "36-54 Badu Court",
    suburb: "Meadowbrook",
    state: "QLD",
    postcode: "4131",
    lat: -27.662260634965683,
    lng: 153.12911979798346
  },
  {
    name: "Steeline Sydney CRT",
    address: "No 1-3 Lone Pine Place",
    suburb: "Smeaton Grange",
    state: "NSW",
    postcode: "2567",
    lat: -34.03872605083959,
    lng: 150.7630892827871
  },
  {
    name: "Steeline Dahlsens Gippsland",
    address: "9 Fosters Street,",
    suburb: "Sale",
    state: "VIC",
    postcode: "3850",
    lat: -38.18194401142699,
    lng: 146.56457381144182
  }
];
