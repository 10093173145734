<template>
  <ul class="mkb-horizontal-menu d-flex" id="mkb-horizontal-menu">
    <li
      v-for="(step, index) in $store.getters.steps"
      :key="step.name"
      :class="{ active: step.name === $route.name }"
    >
      <div class="d-flex" @click="goBackSpecificStep(index)">
        <div
          class="mkb-step-number d-flex align-self-center justify-content-center"
          :class="{ completed: $store.state.step > index }"
        >
          <div class="align-self-center">
            <template v-if="$store.state.step <= index">
              {{ index + 1 }}
            </template>
            <template v-else>
              <font-awesome-icon :icon="['fas', 'check']" />
            </template>
          </div>
        </div>
        <div class="mkb-step-name align-self-center text-nowrap">
          {{ step.title }}
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  methods: {
    goBackSpecificStep(index) {
      if (this.$store.state.step < index) return;
      index + 1;
      this.$store.commit("goBackSpecificStep", index);
    }
  }
};
</script>
<style lang="scss">
.mkb-horizontal-menu li {
  margin-bottom: 10px;
}
.mkb-horizontal-menu {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  color: #758892;
  margin: 0px;
  padding: 0px 15px;

  .mkb-step-number {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #758892;
    color: #ffffff;
    font-size: 0.9rem;
    margin-right: 6px;
  }

  .mkb-step-name {
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .active {
    .mkb-step-number {
      background-color: #002f6c;
    }
    .mkb-step-name {
      color: #002f6c;
    }
  }

  li {
    list-style: none;
    position: relative;
    margin-right: 50px;
  }

  .mkb-step-number.completed {
    background-color: #14cd8b !important;
  }

  li::before {
    content: "";
    position: absolute;
    top: 13px;
    left: -40px;
    width: 30px;
    height: 1px;
    background-color: #98a4af;
    z-index: -1;
  }

  li:first-child::before {
    display: none;
  }

  li:last-child {
    padding-right: 15px;
  }
}
</style>
