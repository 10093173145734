<template>
  <div class="mkb-inline-colour d-flex">
    <div class="mr-2 align-self-center">
      {{ colourObject.name
      }}<span v-if="colourSuffix.length" v-html="colourSuffix"></span>
    </div>
    <div
      class="mkb-swatch align-self-center"
      :style="'background-color: ' + colourObject.code + ';'"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "InlineColour",
  props: {
    colour: {
      type: String,
      required: true
    }
  },
  computed: {
    colourObject() {
      return this.$store.getters.colours[this.colour];
    },
    colourSuffix() {
      if (_.get(this.colourObject, "hasTm", false)) return "&#8482;";
      if (_.get(this.colourObject, "hasR", false)) return "&#174;";
      return "";
    }
  }
};
</script>

<style lang="scss">
.mkb-inline-colour {
  .mkb-swatch {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  @media (max-width: 767.98px) {
    .mkb-swatch {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
