<template>
  <div class="roof-type-page">
    <h3 class="text-primary">Choose your roof type</h3>
    <b-row class="d-flex">
      <b-col
        :key="roofTypeKey"
        @click.prevent="setRoof(roofTypeKey, false)"
        v-for="(roofType, roofTypeKey) in orderedRoofTypes"
        :class="{
          'mkb-clickable-selection':
            roofTypeKey !== 'Maximus 22' && roofTypeKey !== 'Maximus 33',
          'mkb-unavailable-selection':
            roofTypeKey === 'Maximus 22' || roofTypeKey === 'Maximus 33',
          'mkb-roof-column': true
        }"
        md="3"
        cols="6"
      >
        <div class="mkb-roof-image-container">
          <component :is="roofType.image"></component>
        </div>
        <strong>
          {{ roofTypeKey }}<span v-if="roofType.rMark">&reg; </span
          ><span v-if="roofTypeKey === 'Klip-Lok'">or Flat Pan</span>
        </strong>
        <strong
          class="mkb-coming-soon"
          v-if="roofTypeKey === 'Maximus 22' || roofTypeKey === 'Maximus 33'"
          >Coming soon</strong
        >
      </b-col>
      <b-col
        v-if="
          $store.state.tenant !== 'steeline' &&
            $store.state.tenant !== '4933348840'
        "
        class="mkb-clickable-selection mkb-roof-column"
        md="3"
        cols="6"
        :class="isOther ? 'mkb-other-active' : ''"
      >
        <div class="d-flex flex-column">
          <div @click="isOther = !isOther">
            <div class="mkb-roof-image-container">
              <CustomRoof />
            </div>
            <strong>
              Other
            </strong>
          </div>
          <div v-if="isOther" class="mt-1">
            <b-input type="text" v-model="otherRoof"></b-input>
            <b-button
              class="py-1 mt-1 w-100"
              @click="setRoof(otherRoof, true)"
              :class="isDisableComputed"
              >Submit</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import roofTypes from "../data/roof-types";
import CustomRoof from "../assets/img/roof-types/roof-custom.svg";
import { orderedRoofTypes } from "@/data/tenants";
export default {
  data() {
    return {
      isOther: false,
      otherRoof: ""
    };
  },
  components: {
    CustomRoof
  },
  name: "RoofType",
  computed: {
    orderedRoofTypes() {
      let ordered = {};
      orderedRoofTypes[this.$store.state.tenant].forEach(roofType => {
        if (this.$store.getters.roofTypes.includes(roofType)) {
          ordered[roofType] = roofTypes[roofType];
        }
      });

      return ordered;
    },
    isDisableComputed() {
      if (this.otherRoof !== "") return "";
      return "disabled";
    }
  },
  methods: {
    setRoof(roofType, other) {
      if (roofType === "Maximus 22" || roofType === "Maximus 33") return;
      if (roofType === "Topdek® 700") {
        this.$store.commit("setInstallationType", "Saddles");
      }
      let type = { roofType, other };
      this.$store.commit("setRoof", type);
      this.$store.commit("advance");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #002f6c;
.mkb-roof-image-container {
  padding: 3px 30px;
}

.mkb-roof-column {
  margin-bottom: 40px;
}
input {
  font-weight: 500 !important;
  color: #002f6c;
}
button {
  border: 2px solid #002f6c;
  background-color: #002f6c;
  color: #ffffff;
  text-align: center;
  // margin-bottom: 10px;
  font-weight: 400;

  .description {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .width {
    font-weight: 600;
    font-size: 1.6rem;
  }
  &:hover {
    background-color: #ffffff;
    color: #002f6c;
    border: 2px solid #002f6c;
  }
  &.disabled {
    pointer-events: none;
  }
  &.btn-secondary {
    border: 2px solid #002f6c;
    background-color: #002f6c;
    color: #ffffff;
  }
}
</style>
