export const steeline = [
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM112-300",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM112-450",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM112-600",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM112-915",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM112ML-300",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-450",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-600",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-915",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM212-450",
    description: "BMM Steel 2mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM212-600",
    description: "BMM Steel 2mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM212-915",
    description: "BMM Steel 2mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM212ML-450",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM212ML-600",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM212ML-915",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM312-300",
    description: "BMM Steel 2mm SteelClad Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM312-450",
    description: "BMM Steel 2mm SteelClad Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM312-600",
    description: "BMM Steel 2mm SteelClad Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM312-915",
    description: "BMM Steel 2mm SteelClad Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM312ML-300",
    description: "BMM Steel 2mm SteelClad Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-450",
    description: "BMM Steel 2mm SteelClad Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-600",
    description: "BMM Steel 2mm SteelClad Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-915",
    description: "BMM Steel 2mm SteelClad Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM411-300",
    description:
      "BMM Steel 2mm Valley Roll Corrugated Flashed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-450",
    description: "BMM Steel 2mm Corrugated Flashed Valley Roll (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-600",
    description: "BMM Steel 2mm Corrugated Flashed Valley Roll (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-915",
    description: "BMM Steel 2mm Corrugated Flashed Valley Roll (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM412-300",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM412-450",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM412-600",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM412-915",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM412ML-300",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM412ML-450",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM412ML-600",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM412ML-915",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM413-300",
    description: "BMM Steel 2mm Steelspan Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM413-450",
    description: "BMM Steel 2mm Steelspan Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM413-600",
    description: "BMM Steel 2mm Steelspan Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM413-915",
    description: "BMM Steel 2mm Steelspan Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM413ML-300",
    description: "BMM Steel 2mm Steelspan Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-450",
    description: "BMM Steel 2mm Steelspan Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-600",
    description: "BMM Steel 2mm Steelspan Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-915",
    description: "BMM Steel 2mm Steelspan Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM415-300",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM415-450",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM415-600",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM415-915",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-915",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-600",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-450",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM422-300",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM481-300",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM481-450",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM481-600",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM481-915",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-915",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM481ML-300",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-600",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-450",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },

  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM106-300",
    description: "BMM Steel 4mm Corrugated Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-450",
    description: "BMM Steel 4mm Corrugated Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-600",
    description: "BMM Steel 4mm Corrugated Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-915",
    description: "BMM Steel 4mm Corrugated Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM107-300",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM107-450",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM107-600",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM107-915",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM107ML-300",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-450",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-600",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-915",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "No Trim",
    code: "BM206-450",
    description: "BMM Steel 4mm Tile Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM206-600",
    description: "BMM Steel 4mm Tile Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM206-915",
    description: "BMM Steel 4mm Tile Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM207-450",
    description: "BMM Steel 4mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM207-600",
    description: "BMM Steel 4mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM207-915",
    description: "BMM Steel 4mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM207ML-450",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM207ML-600",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM207ML-915",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM306-300",
    description: "BMM Steel 4mm SteelClad Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-450",
    description: "BMM Steel 4mm SteelClad Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-600",
    description: "BMM Steel 4mm SteelClad Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-915",
    description: "BMM Steel 4mm SteelClad Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM307-300",
    description: "BMM Steel 4mm SteelClad Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM307-450",
    description: "BMM Steel 4mm SteelClad Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM307-600",
    description: "BMM Steel 4mm SteelClad Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM307-915",
    description: "BMM Steel 4mm SteelClad Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM307ML-300",
    description: "BMM Steel 4mm SteelClad Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-450",
    description: "BMM Steel 4mm SteelClad Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-600",
    description: "BMM Steel 4mm SteelClad Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-915",
    description: "BMM Steel 4mm SteelClad Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM401-300",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-450",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-600",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-915",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM406-300",
    description: "BMM Steel 4mm Bullnose Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM406-450",
    description: "BMM Steel 4mm Bullnose Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM406-600",
    description: "BMM Steel 4mm Bullnose Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM406-915",
    description: "BMM Steel 4mm Bullnose Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM407-300",
    description: "BMM Steel 4mm Steelspan Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-450",
    description: "BMM Steel 4mm Steelspan Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-600",
    description: "BMM Steel 4mm Steelspan Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-915",
    description: "BMM Steel 4mm Steelspan Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM409-300",
    description: "BMM Steel 4mm Decramastic Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM409-450",
    description: "BMM Steel 4mm Decramastic Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM409-600",
    description: "BMM Steel 4mm Decramastic Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM409-915",
    description: "BMM Steel 4mm Decramastic Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM421-300",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-450",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-600",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-915",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM487-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM486-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM486-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM486-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM486-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM486ML-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM456-300",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM456-450",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM456-600",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM456-915",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM456ML-300",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM456ML-450",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM456ML-600",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM456ML-915",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM457-300",
    description: "BMM Steel 4mm Steelspan Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM457-450",
    description: "BMM Steel 4mm Steelspan Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM457-600",
    description: "BMM Steel 4mm Steelspan Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM457-915",
    description: "BMM Steel 4mm Steelspan Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM457ML-300",
    description: "BMM Steel 4mm Steelspan Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-450",
    description: "BMM Steel 4mm Steelspan Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-600",
    description: "BMM Steel 4mm Steelspan Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-915",
    description: "BMM Steel 4mm Steelspan Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM459-300",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM459-450",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM459-600",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM459-915",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM100-300",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-450",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-600",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-915",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM101-300",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM101-450",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM101-600",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM101-915",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM101ML-300",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-450",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-600",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-915",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "No Trim",
    code: "BM200-450",
    description: "BMM Steel 5.4mm Tile Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM200-600",
    description: "BMM Steel 5.4mm Tile Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM200-915",
    description: "BMM Steel 5.4mm Tile Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM201-450",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM201-600",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM201-915",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM201ML-450",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM201ML-600",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM201ML-915",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM300-300",
    description: "BMM Steel 5.4mm SteelClad Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-450",
    description: "BMM Steel 5.4mm SteelClad Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-600",
    description: "BMM Steel 5.4mm SteelClad Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-915",
    description: "BMM Steel 5.4mm SteelClad Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM301-300",
    description: "BMM Steel 5.4mm SteelClad Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM301-450",
    description: "BMM Steel 5.4mm SteelCladRoll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM301-600",
    description: "BMM Steel 5.4mm SteelClad Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM301-915",
    description: "BMM Steel 5.4mm SteelClad Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM301ML-300",
    description: "BMM Steel 5.4mm SteelClad Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-450",
    description: "BMM Steel 5.4mm SteelClad Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-600",
    description: "BMM Steel 5.4mm SteelClad Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-915",
    description: "BMM Steel 5.4mm SteelClad Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM402-300",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM402-450",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM402-600",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM402-915",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM403-300",
    description: "BMM Steel 5.4mm Steelspan Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-450",
    description: "BMM Steel 5.4mm Steelspan Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-600",
    description: "BMM Steel 5.4mm Steelspan Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-915",
    description: "BMM Steel 5.4mm Steelspan Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM405-300",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM405-450",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM405-600",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM405-915",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM420-300",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-450",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-600",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-915",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM492-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM491-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM491-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM491-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM491-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM491ML-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM452-300",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM452-450",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM452-600",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM452-915",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM452ML-300",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM452ML-450",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM452ML-600",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM452ML-915",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM453-300",
    description: "BMM Steel 5.4mm Steelspan Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM453-450",
    description: "BMM Steel 5.4mm Steelspan Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM453-600",
    description: "BMM Steel 5.4mm Steelspan Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM453-915",
    description: "BMM Steel 5.4mm Steelspan Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM453ML-300",
    description: "BMM Steel 5.4mm Steelspan Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-450",
    description: "BMM Steel 5.4mm Steelspan Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-600",
    description: "BMM Steel 5.4mm Steelspan Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-915",
    description: "BMM Steel 5.4mm Steelspan Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM455-300",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM455-450",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM455-600",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM455-915",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM521-250",
    description: "BMM Aluminium 2mm Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM521-500",
    description: "BMM Aluminium 2mm Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM521-750",
    description: "BMM Aluminium 2mm Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM521ML-250",
    description:
      "BMM Aluminium 2mm Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-500",
    description:
      "BMM Aluminium 2mm Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-750",
    description:
      "BMM Aluminium 2mm Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM5211-750",
    description: "BMM Aluminium 2mm Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5211-500NONSTAND",
    description: "BMM Aluminium 2mm Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5212-250",
    description: "BMM Aluminium 2mm SteelClad Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-500",
    description: "BMM Aluminium 2mm SteelClad Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM560-250",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Saddles Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM560-500",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Saddles Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM560-750",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Saddles Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-750",
    description: "BMM Aluminium 2mm SteelClad Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM560ML-250",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM560ML-500",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM560ML-750",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-500",
    description: "BMM Aluminium 2mm Lokdek 680/700 Valley with Saddles (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Lokdek 680/700 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Steel Rib 500 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Steel Rib 675 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Steeline Hi Dek Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM523-250",
    description: "BMM Aluminium 2mm Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-500",
    description: "BMM Aluminium 2mm Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-750",
    description: "BMM Aluminium 2mm Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM525-500",
    description: "BMM Aluminium 2mm Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM525-750",
    description: "BMM Aluminium 2mm Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM525ML-500",
    description: "BMM Aluminium 2mm Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM525ML-750",
    description: "BMM Aluminium 2mm Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM528-250",
    description: "BMM Aluminium 2mm SteelClad Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM528-750",
    description: "BMM Aluminium 2mm SteelClad Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM528-500",
    description: "BMM Aluminium 2mm SteelClad Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-500",
    description: "BMM Aluminium 2mm SteelClad Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-750",
    description: "BMM Aluminium 2mm SteelClad Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM528ML-250",
    description: "BMM Aluminium 2mm SteelClad Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM501-250",
    description: "BMM Aluminium 4mm Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM501-500",
    description: "BMM Aluminium 4mm Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM501-750",
    description: "BMM Aluminium 4mm Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-500",
    description:
      "BMM Aluminium 4mm Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM501ML-250",
    description:
      "BMM Aluminium 4mm Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-750",
    description:
      "BMM Aluminium 4mm Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM503-250",
    description: "BMM Aluminium 4mm Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-500",
    description: "BMM Aluminium 4mm Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-750",
    description: "BMM Aluminium 4mm Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM505-500",
    description: "BMM Aluminium 4mm Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM505-750",
    description: "BMM Aluminium 4mm Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM505ML-750",
    description: "BMM Aluminium 4mm Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM505ML-500",
    description: "BMM Aluminium 4mm Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM508-250",
    description: "BMM Aluminium 4mm SteelClad Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM508-500",
    description: "BMM Aluminium 4mm SteelClad Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-500",
    description: "BMM Aluminium 4mm SteelClad Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-750",
    description: "BMM Aluminium 4mm SteelClad Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM508ML-250",
    description: "BMM Aluminium 4mm SteelClad Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM511-750",
    description: "BMM Aluminium 4mm Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM511-500NONSTAND",
    description: "BMM Aluminium 4mm Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM512-250",
    description: "BMM Aluminium 4mm SteelClad Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-750",
    description: "BMM Aluminium 4mm SteelClad Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-500",
    description: "BMM Aluminium 4mm SteelClad Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM508-750",
    description: "BMM Aluminium 4mm SteelClad Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM564-500",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Saddles Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM564-750",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Saddles Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM564ML-250",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM564ML-500",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM564ML-750",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-500",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Valley with Saddles (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-750",
    description: "BMM Aluminium 4mm Lokdek 680/700 Valley with Saddles (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Steel Rib 500 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Steel Rib 675 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Steeline Hi Dek Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM541-250",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM541-500",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM541-750",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM5411-750",
    description: "BMM Aluminium 4mm Ultra Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5411-500",
    description: "BMM Aluminium 4mm Ultra Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5412-250",
    description: "BMM Aluminium 4mm Ultra SteelClad Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-500",
    description: "BMM Aluminium 4mm Ultra SteelClad Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-750",
    description: "BMM Aluminium 4mm Ultra SteelClad Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM566-250",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Saddles Metal Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM566-500",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Saddles Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM566-750",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Saddles Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM566ML-250",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Saddles Mesh Lock Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-mesh-lock-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM566ML-500",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM566ML-750",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-mesh-lock-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM567-250",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Valley with Saddles (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM567-500",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Valley with Saddles (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM567-750",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Valley with Saddles (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Lokdek 680/700 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 500 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Steel Rib 675 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Steeline Hi Dek Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-750",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-500",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM541ML-250",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM543-250",
    description: "BMM Aluminium 4mm Ultra Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-500",
    description: "BMM Aluminium 4mm Ultra Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-750",
    description: "BMM Aluminium 4mm Ultra Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM545-500",
    description: "BMM Aluminium 4mm Ultra Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM545-750",
    description: "BMM Aluminium 4mm Ultra Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM545ML-750",
    description:
      "BMM Aluminium 4mm Ultra Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM545ML-500",
    description:
      "BMM Aluminium 4mm Ultra Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM548-250",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM548-750",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM548-500",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-500",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-750",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM548ML-250",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },

  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM313-300",
    description: "BMM Steel 2mm SteelClad Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM417-300",
    description: "BMM Steel 2mm Steelspan Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM480-300",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM308-300",
    description: "BMM Steel 4mm SteelClad Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM310-300",
    description: "BMM Steel 4mm Steelspan Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM485-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM302-300",
    description: "BMM Steel 5.4mm SteelClad Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM304-300",
    description: "BMM Steel 5.4mm Steelspan Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM490-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5215-250",
    description: "BMM Aluminium 2mm Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5216-250",
    description: "BMM Aluminium 2mm SteelClad Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM515-250",
    description: "BMM Aluminium 4mm Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM516-250",
    description: "BMM Aluminium 4mm SteelClad Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5415-250",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5416-250",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-450",
    description: "BMM Steel 2mm SteelClad Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-450",
    description: "BMM Steel 2mm Steelspan Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-450",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-450",
    description: "BMM Steel 4mm SteelClad Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-450",
    description: "BMM Steel 4mm Steelspan Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-450",
    description: "BMM Steel 5.4mm SteelClad Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-450",
    description: "BMM Steel 5.4mm Steelspan Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-500",
    description: "BMM Aluminium 2mm Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-500",
    description: "BMM Aluminium 2mm SteelClad Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-500",
    description: "BMM Aluminium 4mm Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-500",
    description: "BMM Aluminium 4mm SteelClad Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-500",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-500",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-600",
    description: "BMM Steel 2mm SteelClad Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-600",
    description: "BMM Steel 2mm Steelspan Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-600",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-600",
    description: "BMM Steel 4mm SteelClad Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-600",
    description: "BMM Steel 4mm Steelspan Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-600",
    description: "BMM Steel 5.4mm SteelClad Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-600",
    description: "BMM Steel 5.4mm Steelspan Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-750",
    description: "BMM Aluminium 2mm Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-750",
    description: "BMM Aluminium 2mm SteelClad Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-750",
    description: "BMM Aluminium 4mm Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-750",
    description: "BMM Aluminium 4mm SteelClad Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-750",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-750",
    description:
      "BMM Aluminium 4mm Ultra SteelClad Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-915",
    description: "BMM Steel 2mm SteelClad Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-915",
    description: "BMM Steel 2mm Steelspan Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-915",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Lokdek 680/700 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Steel Rib 500 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Steel Rib 675 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Steeline Hi Dek Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-915",
    description: "BMM Steel 4mm SteelClad Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-915",
    description: "BMM Steel 4mm Steelspan Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Lokdek 680/700 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Steel Rib 500 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Steel Rib 675 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-915",
    description: "BMM Steel 5.4mm SteelClad Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-915",
    description: "BMM Steel 5.4mm Steelspan Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Lokdek 680/700 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Steel Rib 500 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Steel Rib 675 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8112-300",
    description: "Steel 2mm Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8212-450",
    description: "Steel 2mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "SteelClad",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8312-300",
    description: "Steel 2mm SteelClad Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Steelspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8413-300",
    description: "Steel 2mm Steelspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Lokdek 680/700 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Steel Rib 500 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Steel Rib 675 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Steeline Hi Dek with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8481-300",
    description:
      "Steel 2mm Lokdek 680/700 with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9521-250",
    description: "Aluminium 2mm Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Lokdek 680/700 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Steel Rib 500 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Steel Rib 675 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Steeline Hi Dek with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9525-500",
    description: "Aluminium 2mm Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9528-250",
    description: "Aluminium 2mm SteelClad Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9560-250",
    description:
      "Aluminium 2mm Lokdek 680/700 with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-saddles-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8107-300",
    description: "Steel 4mm Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8207-450",
    description: "Steel 4mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8307-300",
    description: "Steel 4mm SteelClad Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Lokdek 680/700 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Steel Rib 500 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Steel Rib 675 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Steeline Hi Dek with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8457-300",
    description: "Steel 4mm Steelspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8486-300",
    description:
      "Steel 4mm Lokdek 680/700 with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9501-250",
    description: "Aluminium 4mm Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9505-500",
    description: "Aluminium 4mm Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "SteelClad",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9508-250",
    description: "Aluminium 4mm SteelClad Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Lokdek 680/700 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 500",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Steel Rib 500 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Steel Rib 675",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Steel Rib 675 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Steeline Hi Dek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Steeline Hi Dek with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9564-250",
    description:
      "Aluminium 4mm Lokdek 680/700 with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-saddles-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9541-250",
    description: "Aluminium 4mm Ultra Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Lokdek 680/700 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 500",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Steel Rib 500 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Steel Rib 675",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Steel Rib 675 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Steeline Hi Dek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Steeline Hi Dek with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9545-500",
    description: "Aluminium 4mm Ultra Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "SteelClad",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9548-250",
    description: "Aluminium 4mm Ultra SteelClad Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9566-250",
    description:
      "Aluminium 4mm Ultra Lokdek 680/700 with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-Lokdek 680/700-with-saddles-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8101-300",
    description: "Steel 5.4mm Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8201-450",
    description: "Steel 5.4mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "SteelClad",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8301-300",
    description: "Steel 5.4mm SteelClad Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Lokdek 680/700 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 500",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Steel Rib 500 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Steel Rib 675",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Steel Rib 675 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Steeline Hi Dek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Steeline Hi Dek with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Steelspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8453-300",
    description: "Steel 5.4mm Steelspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Lokdek 680/700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8491-300",
    description:
      "Steel 5.4mm Lokdek 680/700 with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-250",
    description: "BMM Aluminium 4mm Lokdek 680/700 Valley with Saddles (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Lokdek 680/700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM564-250",
    description:
      "BMM Aluminium 4mm Lokdek 680/700 Roll with Saddles Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-500",
    description: "BMM Aluminium 2mm Kliplok Valley with Saddles (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Lokdek 680/700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-750",
    description: "BMM Aluminium 2mm Kliplok Valley with Saddles (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  }
];
export const non_steeline = [
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM112-300",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM112-450",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM112-600",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM112-915",
    description: "BMM Steel 2mm Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM112ML-300",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-450",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-600",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-915",
    description: "BMM Steel 2mm Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM212-450",
    description: "BMM Steel 2mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM212-600",
    description: "BMM Steel 2mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM212-915",
    description: "BMM Steel 2mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM212ML-450",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM212ML-600",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM212ML-915",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM312-300",
    description: "BMM Steel 2mm Trimdek® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM312-450",
    description: "BMM Steel 2mm Trimdek® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM312-600",
    description: "BMM Steel 2mm Trimdek® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM312-915",
    description: "BMM Steel 2mm Trimdek® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM312ML-300",
    description: "BMM Steel 2mm Trimdek® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-450",
    description: "BMM Steel 2mm Trimdek® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-600",
    description: "BMM Steel 2mm Trimdek® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-915",
    description: "BMM Steel 2mm Trimdek® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM411-300",
    description:
      "BMM Steel 2mm Valley Roll Corrugated Flashed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-450",
    description: "BMM Steel 2mm Corrugated Flashed Valley Roll (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-600",
    description: "BMM Steel 2mm Corrugated Flashed Valley Roll (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-915",
    description: "BMM Steel 2mm Corrugated Flashed Valley Roll (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM412-300",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM412-450",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM412-600",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM412-915",
    description: "BMM Steel 2mm Bullnose Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM412ML-300",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM412ML-450",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM412ML-600",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM412ML-915",
    description: "BMM Steel 2mm Bullnose Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-bullnose-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM413-300",
    description: "BMM Steel 2mm Spandek® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM413-450",
    description: "BMM Steel 2mm Spandek® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM413-600",
    description: "BMM Steel 2mm Spandek® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM413-915",
    description: "BMM Steel 2mm Spandek® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM413ML-300",
    description: "BMM Steel 2mm Spandek® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-450",
    description: "BMM Steel 2mm Spandek® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-600",
    description: "BMM Steel 2mm Spandek® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-915",
    description: "BMM Steel 2mm Spandek® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM414-300",
    description: "BMM Steel 2mm Longspan® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM414-450",
    description: "BMM Steel 2mm Longspan® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM414-600",
    description: "BMM Steel 2mm Longspan® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM414-915",
    description: "BMM Steel 2mm Longspan® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM414ML-300",
    description: "BMM Steel 2mm Longspan® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM414ML-450",
    description: "BMM Steel 2mm Longspan® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM414ML-600",
    description: "BMM Steel 2mm Longspan® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM414ML-915",
    description: "BMM Steel 2mm Longspan® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM415-300",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM415-450",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM415-600",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM415-915",
    description: "BMM Steel 2mm Decramastic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-decramastic-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-915",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-600",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-450",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM422-300",
    description: "BMM Steel 2mm Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM481-300",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM481-450",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM481-600",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM481-915",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-915",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM481ML-300",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-600",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-450",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Klip-Lok® Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },

  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM106-300",
    description: "BMM Steel 4mm Corrugated Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-450",
    description: "BMM Steel 4mm Corrugated Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-600",
    description: "BMM Steel 4mm Corrugated Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-915",
    description: "BMM Steel 4mm Corrugated Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM107-300",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM107-450",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM107-600",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM107-915",
    description: "BMM Steel 4mm Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM107ML-300",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-450",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-600",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-915",
    description: "BMM Steel 4mm Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "No Trim",
    code: "BM206-450",
    description: "BMM Steel 4mm Tile Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM206-600",
    description: "BMM Steel 4mm Tile Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM206-915",
    description: "BMM Steel 4mm Tile Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM207-450",
    description: "BMM Steel 4mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM207-600",
    description: "BMM Steel 4mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM207-915",
    description: "BMM Steel 4mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM207ML-450",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM207ML-600",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM207ML-915",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM306-300",
    description: "BMM Steel 4mm Trimdek® Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-450",
    description: "BMM Steel 4mm Trimdek® Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-600",
    description: "BMM Steel 4mm Trimdek® Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-915",
    description: "BMM Steel 4mm Trimdek® Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM307-300",
    description: "BMM Steel 4mm Trimdek® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM307-450",
    description: "BMM Steel 4mm Trimdek® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM307-600",
    description: "BMM Steel 4mm Trimdek® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM307-915",
    description: "BMM Steel 4mm Trimdek® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM307ML-300",
    description: "BMM Steel 4mm Trimdek® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-450",
    description: "BMM Steel 4mm Trimdek® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-600",
    description: "BMM Steel 4mm Trimdek® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-915",
    description: "BMM Steel 4mm Trimdek® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM401-300",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-450",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-600",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-915",
    description: "BMM Steel 4mm Corrugated Flashed Valley Roll (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM406-300",
    description: "BMM Steel 4mm Bullnose Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM406-450",
    description: "BMM Steel 4mm Bullnose Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM406-600",
    description: "BMM Steel 4mm Bullnose Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM406-915",
    description: "BMM Steel 4mm Bullnose Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM407-300",
    description: "BMM Steel 4mm Spandek® Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-450",
    description: "BMM Steel 4mm Spandek® Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-600",
    description: "BMM Steel 4mm Spandek® Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-915",
    description: "BMM Steel 4mm Spandek® Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM408-300",
    description: "BMM Steel 4mm Longspan® Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM408-450",
    description: "BMM Steel 4mm Longspan® Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM408-600",
    description: "BMM Steel 4mm Longspan® Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM408-915",
    description: "BMM Steel 4mm Longspan® Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM409-300",
    description: "BMM Steel 4mm Decramastic Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM409-450",
    description: "BMM Steel 4mm Decramastic Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM409-600",
    description: "BMM Steel 4mm Decramastic Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM409-915",
    description: "BMM Steel 4mm Decramastic Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM421-300",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-450",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-600",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-915",
    description: "BMM Steel 4mm Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM487-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM486-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM486-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM486-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM486-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM486ML-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM456-300",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM456-450",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM456-600",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM456-915",
    description: "BMM Steel 4mm Bullnose Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM456ML-300",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM456ML-450",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM456ML-600",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM456ML-915",
    description: "BMM Steel 4mm Bullnose Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-bullnose-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM457-300",
    description: "BMM Steel 4mm Spandek® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM457-450",
    description: "BMM Steel 4mm Spandek® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM457-600",
    description: "BMM Steel 4mm Spandek® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM457-915",
    description: "BMM Steel 4mm Spandek® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM457ML-300",
    description: "BMM Steel 4mm Spandek® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-450",
    description: "BMM Steel 4mm Spandek® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-600",
    description: "BMM Steel 4mm Spandek® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-915",
    description: "BMM Steel 4mm Spandek® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM459-300",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM459-450",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM459-600",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM459-915",
    description: "BMM Steel 4mm Decramastic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-decramastic-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM469-300",
    description: "BMM Steel 4mm Longspan® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM469-450",
    description: "BMM Steel 4mm Longspan® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM469-600",
    description: "BMM Steel 4mm Longspan® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM469-915",
    description: "BMM Steel 4mm Longspan® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM469ML-300",
    description: "BMM Steel 4mm Longspan® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM469ML-450",
    description: "BMM Steel 4mm Longspan® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM469ML-600",
    description: "BMM Steel 4mm Longspan® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM469ML-915",
    description: "BMM Steel 4mm Longspan® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM100-300",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-450",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-600",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-915",
    description: "BMM Steel 5.4mm Corrugated Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM101-300",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM101-450",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM101-600",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM101-915",
    description: "BMM Steel 5.4mm Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM101ML-300",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-450",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-600",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-915",
    description: "BMM Steel 5.4mm Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "No Trim",
    code: "BM200-450",
    description: "BMM Steel 5.4mm Tile Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM200-600",
    description: "BMM Steel 5.4mm Tile Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM200-915",
    description: "BMM Steel 5.4mm Tile Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM201-450",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM201-600",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM201-915",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM201ML-450",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM201ML-600",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM201ML-915",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM300-300",
    description: "BMM Steel 5.4mm Trimdek® Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-450",
    description: "BMM Steel 5.4mm Trimdek® Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-600",
    description: "BMM Steel 5.4mm Trimdek® Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-915",
    description: "BMM Steel 5.4mm Trimdek® Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM301-300",
    description: "BMM Steel 5.4mm Trimdek® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM301-450",
    description: "BMM Steel 5.4mm TrimdekRoll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM301-600",
    description: "BMM Steel 5.4mm Trimdek® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM301-915",
    description: "BMM Steel 5.4mm Trimdek® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM301ML-300",
    description: "BMM Steel 5.4mm Trimdek® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-450",
    description: "BMM Steel 5.4mm Trimdek® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-600",
    description: "BMM Steel 5.4mm Trimdek® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-915",
    description: "BMM Steel 5.4mm Trimdek® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM402-300",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM402-450",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM402-600",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM402-915",
    description: "BMM Steel 5.4mm Bullnose Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM403-300",
    description: "BMM Steel 5.4mm Spandek® Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-450",
    description: "BMM Steel 5.4mm Spandek® Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-600",
    description: "BMM Steel 5.4mm Spandek® Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-915",
    description: "BMM Steel 5.4mm Spandek® Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM404-300",
    description: "BMM Steel 5.4mm Longspan® Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM404-450",
    description: "BMM Steel 5.4mm Longspan® Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM404-600",
    description: "BMM Steel 5.4mm Longspan® Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM404-915",
    description: "BMM Steel 5.4mm Longspan® Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM405-300",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM405-450",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM405-600",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM405-915",
    description: "BMM Steel 5.4mm Decramastic Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM420-300",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-450",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-600",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-915",
    description: "BMM Steel 5.4mm Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM492-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM491-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM491-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM491-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM491-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM491ML-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM452-300",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM452-450",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM452-600",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM452-915",
    description: "BMM Steel 5.4mm Bullnose Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM452ML-300",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM452ML-450",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM452ML-600",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Bullnose",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM452ML-915",
    description: "BMM Steel 5.4mm Bullnose Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-bullnose-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM453-300",
    description: "BMM Steel 5.4mm Spandek® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM453-450",
    description: "BMM Steel 5.4mm Spandek® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM453-600",
    description: "BMM Steel 5.4mm Spandek® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM453-915",
    description: "BMM Steel 5.4mm Spandek® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM453ML-300",
    description: "BMM Steel 5.4mm Spandek® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-450",
    description: "BMM Steel 5.4mm Spandek® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-600",
    description: "BMM Steel 5.4mm Spandek® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-915",
    description: "BMM Steel 5.4mm Spandek® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM454-300",
    description: "BMM Steel 5.4mm Longspan® Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM454-450",
    description: "BMM Steel 5.4mm Longspan® Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM454-600",
    description: "BMM Steel 5.4mm Longspan® Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM454-915",
    description: "BMM Steel 5.4mm Longspan® Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM454ML-300",
    description: "BMM Steel 5.4mm Longspan® Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM454ML-450",
    description: "BMM Steel 5.4mm Longspan® Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM454ML-600",
    description: "BMM Steel 5.4mm Longspan® Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM454ML-915",
    description: "BMM Steel 5.4mm Longspan® Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM455-300",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM455-450",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM455-600",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Decramastic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM455-915",
    description: "BMM Steel 5.4mm Decramastic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-decramastic-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM521-250",
    description: "BMM Aluminium 2mm Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM521-500",
    description: "BMM Aluminium 2mm Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM521-750",
    description: "BMM Aluminium 2mm Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM521ML-250",
    description:
      "BMM Aluminium 2mm Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-500",
    description:
      "BMM Aluminium 2mm Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-750",
    description:
      "BMM Aluminium 2mm Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM5211-750",
    description: "BMM Aluminium 2mm Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5211-500NONSTAND",
    description: "BMM Aluminium 2mm Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5212-250",
    description: "BMM Aluminium 2mm Trimdek® Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-500",
    description: "BMM Aluminium 2mm Trimdek® Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM560-250",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Saddles Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM560-500",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Saddles Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM560-750",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Saddles Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Klip-lok® Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-750",
    description: "BMM Aluminium 2mm Trimdek® Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM560ML-250",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Saddles Mesh Lock Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM560ML-500",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM560ML-750",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Klip-Lok® Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-500",
    description: "BMM Aluminium 2mm Klip-Lok® Valley with Saddles (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Klip-Lok® Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Klip-Lok® Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM523-250",
    description: "BMM Aluminium 2mm Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-500",
    description: "BMM Aluminium 2mm Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-750",
    description: "BMM Aluminium 2mm Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM525-500",
    description: "BMM Aluminium 2mm Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM525-750",
    description: "BMM Aluminium 2mm Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM525ML-500",
    description: "BMM Aluminium 2mm Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM525ML-750",
    description: "BMM Aluminium 2mm Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM528-250",
    description: "BMM Aluminium 2mm Trimdek® Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM528-750",
    description: "BMM Aluminium 2mm Trimdek® Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM528-500",
    description: "BMM Aluminium 2mm Trimdek® Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-500",
    description: "BMM Aluminium 2mm Trimdek® Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-750",
    description: "BMM Aluminium 2mm Trimdek® Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM528ML-250",
    description: "BMM Aluminium 2mm Trimdek® Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM501-250",
    description: "BMM Aluminium 4mm Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM501-500",
    description: "BMM Aluminium 4mm Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM501-750",
    description: "BMM Aluminium 4mm Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-500",
    description:
      "BMM Aluminium 4mm Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM501ML-250",
    description:
      "BMM Aluminium 4mm Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-750",
    description:
      "BMM Aluminium 4mm Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM503-250",
    description: "BMM Aluminium 4mm Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-500",
    description: "BMM Aluminium 4mm Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-750",
    description: "BMM Aluminium 4mm Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM505-500",
    description: "BMM Aluminium 4mm Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM505-750",
    description: "BMM Aluminium 4mm Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM505ML-750",
    description: "BMM Aluminium 4mm Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM505ML-500",
    description: "BMM Aluminium 4mm Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM508-250",
    description: "BMM Aluminium 4mm Trimdek® Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM508-500",
    description: "BMM Aluminium 4mm Trimdek® Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-500",
    description: "BMM Aluminium 4mm Trimdek® Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-750",
    description: "BMM Aluminium 4mm Trimdek® Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM508ML-250",
    description: "BMM Aluminium 4mm Trimdek® Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM511-750",
    description: "BMM Aluminium 4mm Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM511-500NONSTAND",
    description: "BMM Aluminium 4mm Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM512-250",
    description: "BMM Aluminium 4mm Trimdek® Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-750",
    description: "BMM Aluminium 4mm Trimdek® Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-500",
    description: "BMM Aluminium 4mm Trimdek® Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM508-750",
    description: "BMM Aluminium 4mm Trimdek® Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM564-500",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Saddles Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM564-750",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Saddles Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM564ML-250",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM564ML-500",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM564ML-750",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Klip-Lok® Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-500",
    description: "BMM Aluminium 4mm Klip-Lok® Valley with Saddles (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Klip-Lok® Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-750",
    description: "BMM Aluminium 4mm Klip-Lok® Valley with Saddles (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Klip-Lok® Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM541-250",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM541-500",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM541-750",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM5411-750",
    description: "BMM Aluminium 4mm Ultra Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5411-500",
    description: "BMM Aluminium 4mm Ultra Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5412-250",
    description: "BMM Aluminium 4mm Ultra Trimdek® Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-500",
    description: "BMM Aluminium 4mm Ultra Trimdek® Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-750",
    description: "BMM Aluminium 4mm Ultra Trimdek® Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM566-250",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Saddles Metal Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM566-500",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Saddles Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM566-750",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Saddles Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM566ML-250",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Saddles Mesh Lock Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-mesh-lock-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM566ML-500",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM566ML-750",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-mesh-lock-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM567-250",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Valley with Saddles (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM567-500",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Valley with Saddles (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM567-750",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Valley with Saddles (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Klip-Lok® Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-750",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-500",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM541ML-250",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM543-250",
    description: "BMM Aluminium 4mm Ultra Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-500",
    description: "BMM Aluminium 4mm Ultra Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-750",
    description: "BMM Aluminium 4mm Ultra Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM545-500",
    description: "BMM Aluminium 4mm Ultra Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM545-750",
    description: "BMM Aluminium 4mm Ultra Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM545ML-750",
    description:
      "BMM Aluminium 4mm Ultra Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM545ML-500",
    description:
      "BMM Aluminium 4mm Ultra Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM548-250",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM548-750",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM548-500",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-500",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-750",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM548ML-250",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Decramastic",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },

  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM313-300",
    description: "BMM Steel 2mm Trimdek® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM416-300",
    description: "BMM Steel 2mm Longspan® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM417-300",
    description: "BMM Steel 2mm Spandek® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM480-300",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM308-300",
    description: "BMM Steel 4mm Trimdek® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM309-300",
    description: "BMM Steel 4mm Longspan® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM310-300",
    description: "BMM Steel 4mm Spandek® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM485-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM302-300",
    description: "BMM Steel 5.4mm Trimdek® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM303-300",
    description: "BMM Steel 5.4mm Longspan® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-longspan-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM304-300",
    description: "BMM Steel 5.4mm Spandek® Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM490-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5215-250",
    description: "BMM Aluminium 2mm Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5216-250",
    description: "BMM Aluminium 2mm Trimdek® Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM515-250",
    description: "BMM Aluminium 4mm Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM516-250",
    description: "BMM Aluminium 4mm Trimdek® Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5415-250",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5416-250",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-450",
    description: "BMM Steel 2mm Trimdek® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM416-450",
    description: "BMM Steel 2mm Longspan® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-450",
    description: "BMM Steel 2mm Spandek® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-450",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-450",
    description: "BMM Steel 4mm Trimdek® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM309-450",
    description: "BMM Steel 4mm Longspan® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-450",
    description: "BMM Steel 4mm Spandek® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-450",
    description: "BMM Steel 5.4mm Trimdek® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM303-450",
    description: "BMM Steel 5.4mm Longspan® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-450",
    description: "BMM Steel 5.4mm Spandek® Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-500",
    description: "BMM Aluminium 2mm Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-500",
    description: "BMM Aluminium 2mm Trimdek® Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-500",
    description: "BMM Aluminium 4mm Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-500",
    description: "BMM Aluminium 4mm Trimdek® Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-500",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-500",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-600",
    description: "BMM Steel 2mm Trimdek® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM416-600",
    description: "BMM Steel 2mm Longspan® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-600",
    description: "BMM Steel 2mm Spandek® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-600",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-600",
    description: "BMM Steel 4mm Trimdek® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM309-600",
    description: "BMM Steel 4mm Longspan® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-600",
    description: "BMM Steel 4mm Spandek® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-600",
    description: "BMM Steel 5.4mm Trimdek® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM303-600",
    description: "BMM Steel 5.4mm Longspan® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-600",
    description: "BMM Steel 5.4mm Spandek® Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-750",
    description: "BMM Aluminium 2mm Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-750",
    description: "BMM Aluminium 2mm Trimdek® Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-750",
    description: "BMM Aluminium 4mm Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-750",
    description: "BMM Aluminium 4mm Trimdek® Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-750",
    description:
      "BMM Aluminium 4mm Ultra Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-750",
    description:
      "BMM Aluminium 4mm Ultra Trimdek® Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-915",
    description: "BMM Steel 2mm Trimdek® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM416-915",
    description: "BMM Steel 2mm Longspan® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-longspan-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-915",
    description: "BMM Steel 2mm Spandek® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-915",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Klip-Lok® Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-915",
    description: "BMM Steel 4mm Trimdek® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM309-915",
    description: "BMM Steel 4mm Longspan® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-longspan-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-915",
    description: "BMM Steel 4mm Spandek® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Klip-Lok® Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-915",
    description: "BMM Steel 5.4mm Trimdek® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM303-915",
    description: "BMM Steel 5.4mm Longspan® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-longspan-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-915",
    description: "BMM Steel 5.4mm Spandek® Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Klip-Lok® Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8112-300",
    description: "Steel 2mm Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8212-450",
    description: "Steel 2mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Trimdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8312-300",
    description: "Steel 2mm Trimdek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Spandek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8413-300",
    description: "Steel 2mm Spandek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Longspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8414-300",
    description: "Steel 2mm Longspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-longspan-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Klip-Lok® with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8481-300",
    description: "Steel 2mm Klip-Lok® with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9521-250",
    description: "Aluminium 2mm Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Klip-Lok® with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9525-500",
    description: "Aluminium 2mm Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9528-250",
    description: "Aluminium 2mm Trimdek Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9560-250",
    description:
      "Aluminium 2mm Klip-Lok® with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-saddles-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8107-300",
    description: "Steel 4mm Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8207-450",
    description: "Steel 4mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8307-300",
    description: "Steel 4mm Trimdek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Klip-Lok® with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Spandek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8457-300",
    description: "Steel 4mm Spandek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Longspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8462-300",
    description: "Steel 4mm Longspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-longspan-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8486-300",
    description: "Steel 4mm Klip-Lok® with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9501-250",
    description: "Aluminium 4mm Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9505-500",
    description: "Aluminium 4mm Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Trimdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9508-250",
    description: "Aluminium 4mm Trimdek Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Klip-Lok® with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9564-250",
    description:
      "Aluminium 4mm Klip-Lok® with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-saddles-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9541-250",
    description: "Aluminium 4mm Ultra Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Klip-Lok® with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9545-500",
    description: "Aluminium 4mm Ultra Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Trimdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9548-250",
    description: "Aluminium 4mm Ultra Trimdek Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9566-250",
    description:
      "Aluminium 4mm Ultra Klip-Lok® with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-saddles-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8101-300",
    description: "Steel 5.4mm Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8201-450",
    description: "Steel 5.4mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Trimdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8301-300",
    description: "Steel 5.4mm Trimdek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Klip-Lok® with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Spandek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8453-300",
    description: "Steel 5.4mm Spandek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Longspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8454-300",
    description: "Steel 5.4mm Longspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-longspan-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Klip-Lok",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8491-300",
    description:
      "Steel 5.4mm Klip-Lok® with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-250",
    description: "BMM Aluminium 4mm Klip-lok Valley with Saddles (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Klip-Lok",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM564-250",
    description:
      "BMM Aluminium 4mm Klip-lok Roll with Saddles Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-500",
    description: "BMM Aluminium 2mm Kliplok Valley with Saddles (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Klip-Lok",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-750",
    description: "BMM Aluminium 2mm Kliplok Valley with Saddles (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  }
];
export const stratco = [
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM112-300",
    description: "BMM Steel 2mm CGI Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM112-450",
    description: "BMM Steel 2mm CGI Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM112-600",
    description: "BMM Steel 2mm CGI Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM112-915",
    description: "BMM Steel 2mm CGI Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM112ML-300",
    description:
      "BMM Steel 2mm CGI Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-450",
    description:
      "BMM Steel 2mm CGI Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-600",
    description:
      "BMM Steel 2mm CGI Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM112ML-915",
    description:
      "BMM Steel 2mm CGI Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM212-450",
    description: "BMM Steel 2mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM212-600",
    description: "BMM Steel 2mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM212-915",
    description: "BMM Steel 2mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM212ML-450",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM212ML-600",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM212ML-915",
    description: "BMM Steel 2mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM312-300",
    description: "BMM Steel 2mm Superdek Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM312-450",
    description: "BMM Steel 2mm Superdek Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM312-600",
    description: "BMM Steel 2mm Superdek Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM312-915",
    description: "BMM Steel 2mm Superdek Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM312ML-300",
    description: "BMM Steel 2mm Superdek Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-450",
    description: "BMM Steel 2mm Superdek Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-600",
    description: "BMM Steel 2mm Superdek Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-915",
    description: "BMM Steel 2mm Superdek Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM312-300",
    description: "BMM Steel 2mm Cooldek® Classic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM312-450",
    description: "BMM Steel 2mm Cooldek® Classic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM312-600",
    description: "BMM Steel 2mm Cooldek® Classic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM312-915",
    description: "BMM Steel 2mm Cooldek® Classic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM312ML-300",
    description:
      "BMM Steel 2mm Cooldek® Classic Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-450",
    description:
      "BMM Steel 2mm Cooldek® Classic Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-600",
    description:
      "BMM Steel 2mm Cooldek® Classic Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM312ML-915",
    description:
      "BMM Steel 2mm Cooldek® Classic Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM411-300",
    description:
      "BMM Steel 2mm Valley Roll CGI Corrugated Flashed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-450",
    description:
      "BMM Steel 2mm CGI Corrugated Flashed Valley Roll (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-600",
    description:
      "BMM Steel 2mm CGI Corrugated Flashed Valley Roll (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM411-915",
    description:
      "BMM Steel 2mm CGI Corrugated Flashed Valley Roll (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-corrugated-flashed-valley-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM413-300",
    description: "BMM Steel 2mm Smartspan Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM413-450",
    description: "BMM Steel 2mm Smartspan Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM413-600",
    description: "BMM Steel 2mm Smartspan Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM413-915",
    description: "BMM Steel 2mm Smartspan Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM413ML-300",
    description: "BMM Steel 2mm Smartspan Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-450",
    description: "BMM Steel 2mm Smartspan Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-600",
    description: "BMM Steel 2mm Smartspan Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM413ML-915",
    description: "BMM Steel 2mm Smartspan Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-915",
    description: "BMM Steel 2mm CGI Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-600",
    description: "BMM Steel 2mm CGI Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM422-450",
    description: "BMM Steel 2mm CGI Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM422-300",
    description: "BMM Steel 2mm CGI Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/2mm-steel-corrugated-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM481-300",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },

  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM445-300",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM481-450",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM445-450",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM481-600",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM445-600",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM481-915",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM445-915",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-915",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-915",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM481ML-300",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM445ML-300",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-300mm-standard-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-600",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-600",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM481ML-450",
    description:
      "BMM Steel 2mm Topdek® 700 Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Prodek Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Maximus 22 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Maximus 33 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM445ML-450",
    description:
      "BMM Steel 2mm Spacedek® Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },

  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM106-300",
    description: "BMM Steel 4mm CGI Corrugated Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-450",
    description: "BMM Steel 4mm CGI Corrugated Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-600",
    description: "BMM Steel 4mm CGI Corrugated Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM106-915",
    description: "BMM Steel 4mm CGI Corrugated Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM107-300",
    description: "BMM Steel 4mm CGI Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM107-450",
    description: "BMM Steel 4mm CGI Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM107-600",
    description: "BMM Steel 4mm CGI Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM107-915",
    description: "BMM Steel 4mm CGI Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM107ML-300",
    description:
      "BMM Steel 4mm CGI Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-450",
    description:
      "BMM Steel 4mm CGI Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-600",
    description:
      "BMM Steel 4mm CGI Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM107ML-915",
    description:
      "BMM Steel 4mm CGI Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "No Trim",
    code: "BM206-450",
    description: "BMM Steel 4mm Tile Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM206-600",
    description: "BMM Steel 4mm Tile Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM206-915",
    description: "BMM Steel 4mm Tile Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM207-450",
    description: "BMM Steel 4mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM207-600",
    description: "BMM Steel 4mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM207-915",
    description: "BMM Steel 4mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM207ML-450",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM207ML-600",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM207ML-915",
    description: "BMM Steel 4mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM306-300",
    description: "BMM Steel 4mm Superdek Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM306-300",
    description: "BMM Steel 4mm Cooldek® Classic Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-450",
    description: "BMM Steel 4mm Superdek Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-450",
    description: "BMM Steel 4mm Cooldek® Classic Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-600",
    description: "BMM Steel 4mm Superdek Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-600",
    description: "BMM Steel 4mm Cooldek® Classic Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-915",
    description: "BMM Steel 4mm Superdek Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM306-915",
    description: "BMM Steel 4mm Cooldek® Classic Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM307-300",
    description: "BMM Steel 4mm Superdek Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM307-300",
    description: "BMM Steel 4mm Cooldek® Classic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM307-450",
    description: "BMM Steel 4mm Superdek Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM307-450",
    description: "BMM Steel 4mm Cooldek® Classic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM307-600",
    description: "BMM Steel 4mm Superdek Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM307-600",
    description: "BMM Steel 4mm Cooldek® Classic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM307-915",
    description: "BMM Steel 4mm Superdek Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM307-915",
    description: "BMM Steel 4mm Cooldek® Classic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM307ML-300",
    description: "BMM Steel 4mm Superdek Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM307ML-300",
    description:
      "BMM Steel 4mm Cooldek® Classic Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-450",
    description: "BMM Steel 4mm Superdek Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-450",
    description:
      "BMM Steel 4mm Cooldek® Classic Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-600",
    description: "BMM Steel 4mm Superdek Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-600",
    description:
      "BMM Steel 4mm Cooldek® Classic Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-915",
    description: "BMM Steel 4mm Superdek Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM307ML-915",
    description:
      "BMM Steel 4mm Cooldek® Classic Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM401-300",
    description:
      "BMM Steel 4mm CGI Corrugated Flashed Valley Roll (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-450",
    description:
      "BMM Steel 4mm CGI Corrugated Flashed Valley Roll (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-600",
    description:
      "BMM Steel 4mm CGI Corrugated Flashed Valley Roll (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: "Corrugated Valley Multi-Pitch Fin",
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM401-915",
    description:
      "BMM Steel 4mm CGI Corrugated Flashed Valley Roll (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-corrugated-flashed-valley-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM407-300",
    description: "BMM Steel 4mm Smartspan Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-450",
    description: "BMM Steel 4mm Smartspan Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-600",
    description: "BMM Steel 4mm Smartspan Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM407-915",
    description: "BMM Steel 4mm Smartspan Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM421-300",
    description: "BMM Steel 4mm CGI Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-450",
    description: "BMM Steel 4mm CGI Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-600",
    description: "BMM Steel 4mm CGI Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM421-915",
    description: "BMM Steel 4mm CGI Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/4mm-steel-corrugated-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM487-300",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM443-300",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-450",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-450",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-600",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-no-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-600",
    description:
      "BMM Steel 4mm Steeline Hi Dek Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM487-915",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM443-915",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM486-300",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM444-300",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM486-450",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM444-450",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM486-600",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM444-600",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM486-915",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM444-915",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM486ML-300",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM444ML-300",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-450",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-450",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-600",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-600",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM486ML-915",
    description:
      "BMM Steel 4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Prodek Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Maximus 22 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Maximus 33 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM444ML-915",
    description:
      "BMM Steel 4mm Spacedek® Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM457-300",
    description: "BMM Steel 4mm Smartspan Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM457-450",
    description: "BMM Steel 4mm Smartspan Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM457-600",
    description: "BMM Steel 4mm Smartspan Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM457-915",
    description: "BMM Steel 4mm Smartspan Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM457ML-300",
    description: "BMM Steel 4mm Smartspan Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-450",
    description: "BMM Steel 4mm Smartspan Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-600",
    description: "BMM Steel 4mm Smartspan Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM457ML-915",
    description: "BMM Steel 4mm Smartspan Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM100-300",
    description: "BMM Steel 5.4mm CGI Corrugated Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-450",
    description: "BMM Steel 5.4mm CGI Corrugated Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-600",
    description: "BMM Steel 5.4mm CGI Corrugated Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM100-915",
    description: "BMM Steel 5.4mm CGI Corrugated Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM101-300",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM101-450",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM101-600",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM101-915",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM101ML-300",
    description:
      "BMM Steel 5.4mm CGI Corrugated Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-450",
    description:
      "BMM Steel 5.4mm CGI Corrugated Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-600",
    description:
      "BMM Steel 5.4mm CGI Corrugated Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM101ML-915",
    description:
      "BMM Steel 5.4mm CGI Corrugated Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "No Trim",
    code: "BM200-450",
    description: "BMM Steel 5.4mm Tile Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM200-600",
    description: "BMM Steel 5.4mm Tile Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM200-915",
    description: "BMM Steel 5.4mm Tile Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Standard",
    code: "BM201-450",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM201-600",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM201-915",
    description: "BMM Steel 5.4mm Tile Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "450",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM201ML-450",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM201ML-600",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM201ML-915",
    description: "BMM Steel 5.4mm Tile Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-tile-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM300-300",
    description: "BMM Steel 5.4mm Superdek Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM300-300",
    description: "BMM Steel 5.4mm Cooldek® Classic Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-450",
    description: "BMM Steel 5.4mm Superdek Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-450",
    description: "BMM Steel 5.4mm Cooldek® Classic Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-600",
    description: "BMM Steel 5.4mm Superdek Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-600",
    description: "BMM Steel 5.4mm Cooldek® Classic Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-915",
    description: "BMM Steel 5.4mm Superdek Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM300-915",
    description: "BMM Steel 5.4mm Cooldek® Classic Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM301-300",
    description: "BMM Steel 5.4mm Superdek Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM301-300",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM301-450",
    description: "BMM Steel 5.4mm Superdek Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM301-450",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM301-600",
    description: "BMM Steel 5.4mm Superdek Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM301-600",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM301-915",
    description: "BMM Steel 5.4mm Superdek Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM301-915",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM301ML-300",
    description: "BMM Steel 5.4mm Superdek Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM301ML-300",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-450",
    description: "BMM Steel 5.4mm Superdek Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-450",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-600",
    description: "BMM Steel 5.4mm Superdek Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-600",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-915",
    description: "BMM Steel 5.4mm Superdek Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM301ML-915",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM403-300",
    description: "BMM Steel 5.4mm Smartspan Roll No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-450",
    description: "BMM Steel 5.4mm Smartspan Roll No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-600",
    description: "BMM Steel 5.4mm Smartspan Roll No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM403-915",
    description: "BMM Steel 5.4mm Smartspan Roll No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM420-300",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-300mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-450",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-450mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-600",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-600mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM420-915",
    description: "BMM Steel 5.4mm CGI Corrugated Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-corrugated-box-gutter-915mm-no-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM492-300",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM441-300",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only No Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-450",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "PRodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm PRodek Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-450",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only No Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-600",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-600",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only No Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM492-915",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-no-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM441-915",
    description:
      "BMM Steel 5.4mm Steeline Hi Dek Roll with Screws only No Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM491-300",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM442-300",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM491-450",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM442-450",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM491-600",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM442-600",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM491-915",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-metal-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM442-915",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM491ML-300",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM442ML-300",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-450",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-450mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-450",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-600",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-600mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-600",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM491ML-915",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-steel-5-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-915mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Prodek Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM442ML-915",
    description:
      "BMM Steel 5.4mm Spacedek® Roll with Screws only Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Standard",
    code: "BM453-300",
    description: "BMM Steel 5.4mm Smartspan Roll Metal Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Standard",
    code: "BM453-450",
    description: "BMM Steel 5.4mm Smartspan Roll Metal Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Standard",
    code: "BM453-600",
    description: "BMM Steel 5.4mm Smartspan Roll Metal Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Standard",
    code: "BM453-915",
    description: "BMM Steel 5.4mm Smartspan Roll Metal Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM453ML-300",
    description: "BMM Steel 5.4mm Smartspan Roll Mesh Lock Trim (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-300mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-450",
    description: "BMM Steel 5.4mm Smartspan Roll Mesh Lock Trim (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-450mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-600",
    description: "BMM Steel 5.4mm Smartspan Roll Mesh Lock Trim (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-600mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM453ML-915",
    description: "BMM Steel 5.4mm Smartspan Roll Mesh Lock Trim (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-915mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM521-250",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM521-250",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM521-500",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM521-500",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM521-750",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM521-750",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM521ML-250",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM521ML-250",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-500",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-500",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-750",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM521ML-750",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM5211-750",
    description: "BMM Aluminium 2mm Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5211-500NONSTAND",
    description: "BMM Aluminium 2mm Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5212-250",
    description: "BMM Aluminium 2mm Superdek Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5212-250",
    description: "BMM Aluminium 2mm Cooldek® Classic Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-500",
    description: "BMM Aluminium 2mm Superdek Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-500",
    description: "BMM Aluminium 2mm Cooldek® Classic Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM560-250",
    description:
      "BMM Aluminium 2mm Topdek® 700 Roll with Saddles Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Prodek Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Maximus 22 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Maximus 33 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5213-250",
    description:
      "BMM Aluminium 2mm Spacedek® Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM560-500",
    description:
      "BMM Aluminium 2mm Topdek® 700 Roll with Saddles Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Prodek Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Maximus 22 Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Maximus 33 Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-500",
    description:
      "BMM Aluminium 2mm Spacedek® Roll with Screws only Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM560-750",
    description:
      "BMM Aluminium 2mm Topdek® 700 Roll with Saddles Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Prodek Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Maximus 22 Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Maximus 33 Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5213-750",
    description:
      "BMM Aluminium 2mm Spacedek® Roll with Screws only Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-750",
    description: "BMM Aluminium 2mm Superdek Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5212-750",
    description: "BMM Aluminium 2mm Cooldek® Classic Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM560ML-250",
    description:
      "BMM Aluminium 2mm Topdek® 700 Roll with Saddles Mesh Lock Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Prodek Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Maximus 22 Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Maximus 33 Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5213ML-250",
    description:
      "BMM Aluminium 2mm Spacedek® Roll with Screws only Mesh Lock Trim (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM560ML-500",
    description:
      "BMM Aluminium 2mm Topdek® 700 Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Prodek Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Maximus 22 Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Maximus 33 Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-500",
    description:
      "BMM Aluminium 2mm Spacedek® Roll with Screws only Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM560ML-750",
    description:
      "BMM Aluminium 2mm Topdek® 700 Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-2mm-klip-lok-roll-with-saddles-mesh-lock-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Prodek Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Maximus 22 Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Maximus 33 Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5213ML-750",
    description:
      "BMM Aluminium 2mm Spacedek® Roll with Screws only Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-500",
    description: "BMM Aluminium 2mm Topdek® 700 Valley with Saddles (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Prodek Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Maximus 22 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Maximus 33 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-500",
    description:
      "BMM Aluminium 2mm Spacedek® Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-500mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Prodek Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Maximus 22 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Maximus 33 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5214-750",
    description:
      "BMM Aluminium 2mm Spacedek® Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-kliplok-750mm-no-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM523-250",
    description: "BMM Aluminium 2mm CGI Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM523-250",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-500",
    description: "BMM Aluminium 2mm CGI Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-500",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-750",
    description: "BMM Aluminium 2mm CGI Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM523-750",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM525-500",
    description: "BMM Aluminium 2mm Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM525-750",
    description: "BMM Aluminium 2mm Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM525ML-500",
    description: "BMM Aluminium 2mm Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM525ML-750",
    description: "BMM Aluminium 2mm Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM528-250",
    description: "BMM Aluminium 2mm Superdek Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM528-250",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },

  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM528-500",
    description: "BMM Aluminium 2mm Superdek Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM528-500",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM528-750",
    description: "BMM Aluminium 2mm Superdek Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM528-750",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-500",
    description: "BMM Aluminium 2mm Superdek Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-500",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-750",
    description: "BMM Aluminium 2mm Superdek Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM528ML-750",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM528ML-250",
    description: "BMM Aluminium 2mm Superdek Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM528ML-250",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM501-250",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM501-250",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM501-500",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM501-500",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM501-750",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM501-750",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-500",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-500",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM501ML-250",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM501ML-250",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-750",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM501ML-750",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM503-250",
    description: "BMM Aluminium 4mm CGI Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM503-250",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-500",
    description: "BMM Aluminium 4mm CGI Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-500",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-750",
    description: "BMM Aluminium 4mm CGI Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM503-750",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM505-500",
    description: "BMM Aluminium 4mm Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM505-750",
    description: "BMM Aluminium 4mm Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM505ML-750",
    description: "BMM Aluminium 4mm Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM505ML-500",
    description: "BMM Aluminium 4mm Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM508-250",
    description: "BMM Aluminium 4mm Superdek Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM508-250",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM508-500",
    description: "BMM Aluminium 4mm Superdek Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM508-500",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-500",
    description: "BMM Aluminium 4mm Superdek Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-500",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-750",
    description: "BMM Aluminium 4mm Superdek Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM508ML-750",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM508ML-250",
    description: "BMM Aluminium 4mm Superdek Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM508ML-250",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM511-750",
    description: "BMM Aluminium 4mm Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM511-500NONSTAND",
    description: "BMM Aluminium 4mm Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM512-250",
    description: "BMM Aluminium 4mm Superdek Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM512-250",
    description: "BMM Aluminium 4mm Cooldek® Classic Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-750",
    description: "BMM Aluminium 4mm Superdek Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-750",
    description: "BMM Aluminium 4mm Cooldek® Classic Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-500",
    description: "BMM Aluminium 4mm Superdek Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM512-500",
    description: "BMM Aluminium 4mm Cooldek® Classic Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM508-750",
    description: "BMM Aluminium 4mm Superdek Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM508-750",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Prodek Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Maximus 22 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Maximus 33 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM513-250",
    description:
      "BMM Aluminium 4mm Spacedek® Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM564-500",
    description:
      "BMM Aluminium 4mm Topdek® 700 Roll with Saddles Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Prodek Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Maximus 22 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Maximus 33 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM513-500",
    description:
      "BMM Aluminium 4mm Spacedek® Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM564-750",
    description:
      "BMM Aluminium 4mm Topdek® 700 Roll with Saddles Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Prodek Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Maximus 22 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Maximus 33 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM513-750",
    description:
      "BMM Aluminium 4mm Spacedek® Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM564ML-250",
    description:
      "BMM Aluminium 4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Prodek Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Maximus 22 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Maximus 33 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM513ML-250",
    description:
      "BMM Aluminium 4mm Spacedek® Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM564ML-500",
    description:
      "BMM Aluminium 4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-mesh-lock-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Prodek Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Maximus 22 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Maximus 33 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-500",
    description:
      "BMM Aluminium 4mm Spacedek® Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM564ML-750",
    description:
      "BMM Aluminium 4mm Topdek® 700 Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Prodek Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Maximus 22 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Maximus 33 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM513ML-750",
    description:
      "BMM Aluminium 4mm Spacedek® Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-500",
    description:
      "BMM Aluminium 4mm Topdek® 700 Valley with Saddles (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Prodek Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Maximus 22 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Maximus 33 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-500",
    description:
      "BMM Aluminium 4mm Spacedek® Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-750",
    description: "BMM Aluminium 4mm Topdek® 700 Valley with Saddles (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Prodek Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Maximus 22 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Maximus 33 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM514-750",
    description:
      "BMM Aluminium 4mm Spacedek® Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM541-250",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM541-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM541-500",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM541-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM541-750",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM541-750",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: true
    },
    trim: "No Trim",
    code: "BM5411-750",
    description: "BMM Aluminium 4mm Ultra Tile Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5411-500",
    description: "BMM Aluminium 4mm Ultra Tile Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5412-250",
    description: "BMM Aluminium 4mm Ultra Superdek Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5412-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-500",
    description: "BMM Aluminium 4mm Ultra Superdek Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-750",
    description: "BMM Aluminium 4mm Ultra Superdek Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5412-750",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM566-250",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Roll with Saddles Metal Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Prodek Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM5413-250",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Roll with Screws only Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM566-500",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Roll with Saddles Metal Trim (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Prodek Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-500",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Roll with Screws only Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM566-750",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Roll with Saddles Metal Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-metal-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Prodek Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM5413-750",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Roll with Screws only Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM566ML-250",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Roll with Saddles Mesh Lock Trim (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-mesh-lock-trim-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Prodek Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM5413ML-250",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Roll with Screws only Mesh Lock Trim (250mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM566ML-500",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Roll with Saddles Mesh Lock Trim (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Prodek Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-500",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Roll with Screws only Mesh Lock Trim (500mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM566ML-750",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Roll with Saddles Mesh Lock Trim (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-ultra-klip-lok-roll-with-saddles-mesh-lock-trim-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Prodek Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM5413ML-750",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Roll with Screws only Mesh Lock Trim (750mm wide)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM567-250",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Valley with Saddles (250mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Prodek Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5414-250",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Valley with Screws Only (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM567-500",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Valley with Saddles (500mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Prodek Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-500",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Valley with Screws Only (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-500mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM567-750",
    description:
      "BMM Aluminium 4mm Ultra Topdek® 700 Valley with Saddles (750mm)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Prodek Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Maximus 22 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Maximus 33 Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5414-750",
    description:
      "BMM Aluminium 4mm Ultra Spacedek® Valley with Screws Only (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-kliplok-valley-750mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-750",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-750",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-500",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM541ML-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM541ML-250",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM541ML-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM543-250",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM543-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Valley Roll (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-500",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Valley Roll (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-750",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM543-750",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Valley Roll (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-valley-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Standard",
    code: "BM545-500",
    description: "BMM Aluminium 4mm Ultra Tile Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM545-750",
    description: "BMM Aluminium 4mm Ultra Tile Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM545ML-750",
    description:
      "BMM Aluminium 4mm Ultra Tile Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: {
      width: "500",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM545ML-500",
    description:
      "BMM Aluminium 4mm Ultra Tile Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-tile-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM548-250",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM548-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM548-750",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Standard",
    code: "BM548-750",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Metal Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM548-500",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Standard",
    code: "BM548-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Metal Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-standard-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-500",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Mesh Lock Trim (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-500mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-750",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "Mesh Lock",
    code: "BM548ML-750",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Mesh Lock Trim (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-750mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM548ML-250",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Mesh Lock",
    code: "BM548ML-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Mesh Lock Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-250mm-mesh-lock-trim",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Kilp-Lok",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: "Domed Valley",
    width: {
      width: "190",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-190",
    description: "BMM Steel 2mm Domed Valley (190mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-190mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: "Domed Valley",
    width: {
      width: "240",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-240",
    description: "BMM Steel 2mm Domed Valley (240mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-240mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Klip-Lok",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Valley",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: "Domed Valley",
    width: {
      width: "300",
      standard: false
    },
    trim: "No Trim",
    code: "BM410-300",
    description: "BMM Steel 2mm Domed Valley (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-domed-valley-300mm",
      bunnings: null,
      mitre10: null
    }
  },

  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM313-300",
    description: "BMM Steel 2mm Superdek Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM313-300",
    description: "BMM Steel 2mm Cooldek® Classic Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM417-300",
    description: "BMM Steel 2mm Smartspan Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM480-300",
    description:
      "BMM Steel 2mm Topdek® 700 Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Prodek Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Maximus 22 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Maximus 33 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM446-300",
    description:
      "BMM Steel 2mm Spacedek® Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM308-300",
    description: "BMM Steel 4mm Superdek Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM308-300",
    description: "BMM Steel 4mm Cooldek® Classic Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM310-300",
    description: "BMM Steel 4mm Smartspan Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM485-300",
    description:
      "BMM Steel 4mm Topdek® 700 Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Prodek Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Maximus 22 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Maximus 33 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM447-300",
    description:
      "BMM Steel 4mm Spacedek® Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM302-300",
    description: "BMM Steel 5.4mm Superdek Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM302-300",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-trimdek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM304-300",
    description: "BMM Steel 5.4mm Smartspan Roll Box Gutter (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM490-300",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll Box Gutter with Saddles (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Prodek Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "300",
      standard: true
    },
    trim: "No Trim",
    code: "BM448-300",
    description:
      "BMM Steel 5.4mm Spacedek® Roll Box Gutter with Screws only (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-300mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5215-250",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5215-250",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5216-250",
    description: "BMM Aluminium 2mm Superdek Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5216-250",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM515-250",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM515-250",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM516-250",
    description: "BMM Aluminium 4mm Superdek Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM516-250",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5415-250",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5415-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5416-250",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "No Trim",
    code: "BM5416-250",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Box Gutter (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-250mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-450",
    description: "BMM Steel 2mm Superdek Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-450",
    description: "BMM Steel 2mm Cooldek® Classic Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-450",
    description: "BMM Steel 2mm Smartspan Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-450",
    description:
      "BMM Steel 2mm Topdek® 700 Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Prodek Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Maximus 22 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Maximus 33 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-450",
    description:
      "BMM Steel 2mm Spacedek® Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-450",
    description: "BMM Steel 4mm Superdek Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-450",
    description: "BMM Steel 4mm Cooldek® Classic Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-450",
    description: "BMM Steel 4mm Smartspan Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-450",
    description:
      "BMM Steel 4mm Topdek® 700 Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Prodek Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Maximus 22 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Maximus 33 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-450",
    description:
      "BMM Steel 4mm Spacedek® Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-450",
    description: "BMM Steel 5.4mm Superdek Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-450",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-450",
    description: "BMM Steel 5.4mm Smartspan Roll Box Gutter (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-450",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll Box Gutter with Saddles (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Prodek Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "450",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-450",
    description:
      "BMM Steel 5.4mm Spacedek® Roll Box Gutter with Screws only (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-450mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-500",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-500",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-500",
    description: "BMM Aluminium 2mm Superdek Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-500",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-500",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-500",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-500",
    description: "BMM Aluminium 4mm Superdek Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-500",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-500",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Corrugated Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-500",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-500",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Box Gutter (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-600",
    description: "BMM Steel 2mm Superdek Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-600",
    description: "BMM Steel 2mm Cooldek® Classic Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-600",
    description: "BMM Steel 2mm Smartspan Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-600",
    description:
      "BMM Steel 2mm Topdek® 700 Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Prodek Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Maximus 22 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Maximus 33 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-600",
    description:
      "BMM Steel 2mm Spacedek® Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-600",
    description: "BMM Steel 4mm Superdek Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-600",
    description: "BMM Steel 4mm Cooldek® Classic Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-600",
    description: "BMM Steel 4mm Smartspan Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-600",
    description:
      "BMM Steel 4mm Topdek® 700 Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Prodek Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Maximus 22 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Maximus 33 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-600",
    description:
      "BMM Steel 4mm Spacedek® Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-600",
    description: "BMM Steel 5.4mm Superdek Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-600",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-600",
    description: "BMM Steel 5.4mm Smartspan Roll Box Gutter (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-600",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll Box Gutter with Saddles (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Prodek Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "600",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-600",
    description:
      "BMM Steel 5.4mm Spacedek® Roll Box Gutter with Screws only (600mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-600mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-750",
    description:
      "BMM Aluminium 2mm CGI Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5215-750",
    description:
      "BMM Aluminium 2mm Cooldek® Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-750",
    description: "BMM Aluminium 2mm Superdek Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5216-750",
    description:
      "BMM Aluminium 2mm Cooldek® Classic Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-aluminium-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-750",
    description:
      "BMM Aluminium 4mm CGI Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM515-750",
    description:
      "BMM Aluminium 4mm Cooldek® Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-750",
    description: "BMM Aluminium 4mm Superdek Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM516-750",
    description:
      "BMM Aluminium 4mm Cooldek® Classic Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-500mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-750",
    description:
      "BMM Aluminium 4mm Ultra CGI Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Classic Corrugated",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5415-750",
    description:
      "BMM Aluminium 4mm Ultra Classic Corrugated Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-corrugated-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-750",
    description:
      "BMM Aluminium 4mm Ultra Superdek Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM5416-750",
    description:
      "BMM Aluminium 4mm Ultra Cooldek® Classic Roll Box Gutter (750mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-aluminium-ultra-trimdek-box-gutter-750mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-915",
    description: "BMM Steel 2mm Superdek Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM313-915",
    description: "BMM Steel 2mm Cooldek® Classic Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM417-915",
    description: "BMM Steel 2mm Smartspan Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM480-915",
    description:
      "BMM Steel 2mm Topdek® 700 Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Prodek Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Maximus 22 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Maximus 33 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM446-915",
    description:
      "BMM Steel 2mm Spacedek® Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/2mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-915",
    description: "BMM Steel 4mm Superdek Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM308-915",
    description: "BMM Steel 4mm Cooldek® Classic Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM310-915",
    description: "BMM Steel 4mm Smartspan Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM485-915",
    description:
      "BMM Steel 4mm Topdek® 700 Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Prodek Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Maximus 22 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Maximus 33 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM447-915",
    description:
      "BMM Steel 4mm Spacedek® Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/4mm-steel-kliplok-box-gutter-15mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-915",
    description: "BMM Steel 5.4mm Superdek Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM302-915",
    description:
      "BMM Steel 5.4mm Cooldek® Classic Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-trimdek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM304-915",
    description: "BMM Steel 5.4mm Smartspan Roll Box Gutter (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-spandek-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM490-915",
    description:
      "BMM Steel 5.4mm Topdek® 700 Roll Box Gutter with Saddles (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Prodek Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Maximus 22 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Maximus 33 Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Box Gutter",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: {
      width: "915",
      standard: false
    },
    trim: "No Trim",
    code: "BM448-915",
    description:
      "BMM Steel 5.4mm Spacedek® Roll Box Gutter with Screws only (915mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/5-4mm-steel-kliplok-box-gutter-915mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8112-300",
    description: "Steel 2mm CGI Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8212-450",
    description: "Steel 2mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Superdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8312-300",
    description: "Steel 2mm Superdek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8312-300",
    description: "Steel 2mm Cooldek® Classic Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Smartspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8413-300",
    description: "Steel 2mm Smartspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Prodek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Prodek with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Maximus 22 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Maximus 33 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8445-300",
    description:
      "Steel 2mm Spacedek® with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8481-300",
    description:
      "Steel 2mm Topdek® 700 with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-2mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9521-250",
    description: "Aluminium 2mm CGI Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Prodek with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Maximus 22 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Maximus 33 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95213-250",
    description:
      "Aluminium 2mm Spacedek® with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9525-500",
    description: "Aluminium 2mm Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9528-250",
    description: "Aluminium 2mm Superdek Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9528-250",
    description: "Aluminium 2mm Cooldek® Classic Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9560-250",
    description:
      "Aluminium 2mm Topdek® 700 with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-2mm-klip-lok-with-saddles-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8107-300",
    description: "Steel 4mm CGI Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8207-450",
    description: "Steel 4mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Superdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8307-300",
    description: "Steel 4mm Superdek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8307-300",
    description: "Steel 4mm Cooldek® Classic Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Prodek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Prodek with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Maximus 22 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Maximus 33 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8444-300",
    description:
      "Steel 4mm Spacedek® with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8457-300",
    description: "Steel 4mm Smartspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8486-300",
    description:
      "Steel 4mm Topdek® 700 with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-4mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "CGI Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9501-250",
    description: "Aluminium 4mm CGI Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9501-250",
    description:
      "Aluminium 4mm Cooldek® Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9505-500",
    description: "Aluminium 4mm Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Superdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9508-250",
    description: "Aluminium 4mm Superdek Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Cooldek® Classic",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9508-250",
    description: "Aluminium 4mm Cooldek® Classic Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Prodek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Prodek with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Maximus 22",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Maximus 22 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Maximus 33",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Maximus 33 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Spacedek®",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9513-250",
    description:
      "Aluminium 4mm Spacedek® with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-screws-only-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9564-250",
    description:
      "Aluminium 4mm Topdek® 700 with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-klip-lok-with-saddles-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "CGI Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9541-250",
    description:
      "Aluminium 4mm Ultra CGI Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9541-250",
    description:
      "Aluminium 4mm Ultra Cooldek® Corrugated Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-corro-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Prodek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Prodek with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 22",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Maximus 22 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Maximus 33",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Maximus 33 with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Spacedek®",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL95413-250",
    description:
      "Aluminium 4mm Ultra Spacedek® with Screws only Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-klip-lok-with-screws-only-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9545-500",
    description: "Aluminium 4mm Ultra Tile Solar Mesh Kit (500mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-tile-solar-mesh-kit-500mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Superdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9548-250",
    description: "Aluminium 4mm Ultra Superdek Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Cooldek® Classic",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9548-250",
    description:
      "Aluminium 4mm Ultra Cooldek® Classic Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-trimdek-solar-mesh-kit-250mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "4mm Aluminium Ultra",
    roof: "Topdek® 700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL9566-250",
    description:
      "Aluminium 4mm Ultra Topdek® 700 with Saddles Solar Mesh Kit (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/aluminium-4mm-ultra-Lokdek 680/700-with-saddles-solar-mesh-kit-250mm",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "CGI Corrugated",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8101-300",
    description: "Steel 5.4mm CGI Corrugated Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-corro-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Tile",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8201-450",
    description: "Steel 5.4mm Tile Solar Mesh Kit (450mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-tile-solar-mesh-kit-450mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Superdek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8301-300",
    description: "Steel 5.4mm Superdek Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Cooldek® Classic",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8301-300",
    description: "Steel 5.4mm Cooldek® Classic Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-trimdek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Prodek",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Prodek with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Maximus 22",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Maximus 22 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Maximus 33",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Maximus 33 with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Spacedek®",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8442-300",
    description:
      "Steel 5.4mm Spacedek® with Screws only Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-screws-only-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Traditional (screws only)"
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Smartspan",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8453-300",
    description: "Steel 5.4mm Smartspan Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-spandek-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    }
  },
  {
    type: "Solar Panel",
    mesh: "5.4mm Steel",
    roof: "Topdek® 700",
    valley: null,
    width: "Standard",
    trim: "No Trim",
    code: "SOL8491-300",
    description:
      "Steel 5.4mm Topdek® 700 with Saddles Solar Mesh Kit (300mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/products/steel-5-4mm-klip-lok-with-saddles-solar-mesh-kit-300mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "250",
      standard: false
    },
    trim: "No Trim",
    code: "BM565-250",
    description: "BMM Aluminium 4mm Topdek® 700 Valley with Saddles (250mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Standard Gutter",
    mesh: "4mm Aluminium",
    roof: "Topdek® 700",
    valley: null,
    width: {
      width: "250",
      standard: true
    },
    trim: "Standard",
    code: "BM564-250",
    description:
      "BMM Aluminium 4mm Topdek® 700 Roll with Saddles Metal Trim (250mm wide)",
    url: {
      bmco:
        "https://aumarketplace.bluemountainco.com/collections/gutter-mesh/products/bmm-aluminium-4mm-klip-lok-roll-with-saddles-metal-trim-250mm-wide",
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "500",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-500",
    description: "BMM Aluminium 2mm Kliplok Valley with Saddles (500mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  },
  {
    type: "Valley",
    mesh: "2mm Aluminium",
    roof: "Topdek® 700",
    valley: "Valley Roll",
    width: {
      width: "750",
      standard: false
    },
    trim: "No Trim",
    code: "BM561-750",
    description: "BMM Aluminium 2mm Kliplok Valley with Saddles (750mm)",
    url: {
      bmco: null,
      bunnings: null,
      mitre10: null
    },
    installationType: "Saddles"
  }
];
