<template>
  <div
    class="mkb-clickable-selection"
    @click="emitClick"
    @mouseover="emitHover"
    @mouseleave="emitMouseLeave"
  >
    <div class="mkb-color-swatch">
      <div
        class="mkb-swatch "
        :style="{
          backgroundColor: colour.code,
          border:
            selectedColour === null
              ? ''
              : selectedColour.colour.name === colour.name
              ? '3px solid #002f6c'
              : ''
        }"
      ></div>
    </div>
    <div class="mkb-colour-name">
      {{ colour.name
      }}<span v-if="colourSuffix.length" v-html="colourSuffix"></span>
      <span class="mkb-double-asterisk" v-if="doubleAsterisk && displayAsterisk"
        >**</span
      >
      <span class="mkb-double-asterisk" v-if="singleAsterisk && displayAsterisk"
        >*</span
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Colour",
  props: {
    colour: {
      type: Object,
      required: true
    },
    selectedColour: {
      type: Object,
      default: null
    },
    displayAsterisk: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      colours: this.colour
    };
  },
  computed: {
    colourSuffix() {
      if (_.get(this.colour, "hasTm", false)) return "&#8482;";
      if (_.get(this.colour, "hasR", false)) return "&#174;";
      return "";
    },
    doubleAsterisk() {
      if (_.get(this.colour, "hasTwoAsterisks", false)) return true;
      return false;
    },
    singleAsterisk() {
      if (_.get(this.colour, "hasAsterisk", false)) return true;
      return false;
    }
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
    emitHover() {
      this.$emit("hover");
    },
    emitMouseLeave() {
      this.$emit("mouseleave");
    }
  }
};
</script>

<style lang="scss" scoped>
.mkb-clickable-selection {
  margin-bottom: 10px;
}
.mkb-color-swatch {
  position: relative;
  width: 96px;
  height: 46px;
  // margin-left: auto;
  margin-right: auto;
  // margin-bottom: 6px;
}

.mkb-swatch {
  width: 92px;
  height: 44px;
  border-radius: 5px;
}

.mkb-colour-name {
  font-size: 0.55rem;
  text-align: left;
  font-weight: 500;
}
mesh-kit-builder[tenant="bmco"] {
  .mkb-colour-name {
    line-height: 1.2;
    height: 15px;
  }
}
</style>
