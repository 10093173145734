import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueTopprogress from "vue-top-progress";
import VueHtml2pdf from "vue-html2pdf";
import { BootstrapVue } from "bootstrap-vue";
import Toasted from "vue-toasted";
import "./styles/custom.scss";
import GlobalImports from "./imports";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faInfo,
  faArrowLeft,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faUndoAlt,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import { faRegistered } from "@fortawesome/free-regular-svg-icons";

library.add(
  faInfo,
  faArrowLeft,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faUndoAlt,
  faRegistered,
  faInfoCircle
);

import vueCustomElement from "vue-custom-element";
Vue.use(vueCustomElement);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(GlobalImports);
Vue.use(Toasted);
Vue.use(vueTopprogress);
Vue.use(VueHtml2pdf);
Vue.prototype.$toolContext = "homeowner";
App.store = store;
App.router = router;
Vue.customElement("mesh-kit-builder", App);

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount("#mesh-matcher");
