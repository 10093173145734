var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mkb-container"},[_c('div',{staticClass:"mkb-kit-wrapper"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"6","cols":"6"}},[_c('img',{staticClass:"mkb-mesh-image ",attrs:{"src":_vm.meshImage,"alt":"Mesh Roll"}})]),_c('b-col',{staticClass:"mkb-profile-kit"},[_c('div',{staticClass:"d-flex flex-column h-100"},[(
            _vm.$store.state.installationType === null ||
              _vm.$store.state.installationType === 'Saddles'
          )?_c('img',{staticClass:"mkb-profile-image",class:{
            'mkb-long-profile':
              (_vm.$store.state.roof === 'Corrugated' &&
                _vm.$store.state.mesh.includes('Steel')) ||
              _vm.$store.state.roof === 'Spandek' ||
              _vm.$store.state.roof === 'Longspan',
            'mkb-saddle-image':
              _vm.$store.state.roof === 'Corrugated' &&
              _vm.$store.state.mesh.includes('Aluminium')
          },attrs:{"src":_vm.profileImage,"alt":"Profile"}}):_vm._e(),(
            _vm.$store.state.installationType === null ||
              _vm.$store.state.installationType === 'Saddles'
          )?_c('img',{staticClass:"mkb-profile-image",class:{
            'mkb-long-profile':
              (_vm.$store.state.roof === 'Corrugated' &&
                _vm.$store.state.mesh.includes('Steel')) ||
              _vm.$store.state.roof === 'Spandek' ||
              _vm.$store.state.roof === 'Longspan',
            'mkb-saddle-image':
              _vm.$store.state.roof === 'Corrugated' &&
              _vm.$store.state.mesh.includes('Aluminium')
          },attrs:{"src":_vm.profileImage,"alt":"Profile"}}):_vm._e(),_c('img',{staticClass:"mkb-screw-image",class:{
            'mt-auto':
              _vm.$store.state.installationType === 'Traditional (screws only)'
          },style:({
            'margin-top':
              (_vm.$store.state.roof === 'Corrugated' &&
                _vm.$store.state.mesh.includes('Steel')) ||
              _vm.$store.state.roof === 'Longspan'
                ? '-20px'
                : _vm.$store.state.roof === 'Spandek'
                ? '-8px'
                : ''
          }),attrs:{"src":_vm.screwImage,"alt":"Screw"}}),_c('img',{staticClass:"mkb-screw-image",attrs:{"src":_vm.screwImage,"alt":"Screw"}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }