import _ from "lodash";
import Vue from "vue";
import Vuex from "vuex";

import { standardRoutes, standardRoutesNoKlipLok } from "../router/standard";
import { valleyRoutes, valleyRoutesNoKlipLok } from "../router/valley";
import { boxGutterRoutes, boxGutterRoutesNoKlipLok } from "../router/box";
import {
  solarPanelRoutes,
  solarPanelRoutesNoKlipLok
} from "../router/solarpanel";

import { installationTypes } from "../data/tenants";
import { steeline, non_steeline, stratco } from "../data/products/index";
// import products from "../data/products.json";
import { steelColours } from "../data/steel-colours";
import { aluminiumColours } from "../data/aluminium-colours";
import VueScrollTo from "vue-scrollto";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    step: null,
    type: null,
    mesh: null,
    meshColour: null,
    roof: null,
    valley: null,
    width: null,
    trim: null,
    trimColour: null,
    installationType: null,
    tenant: null,
    panelWidth: null,
    panelHeight: null,
    panelOffset: null,
    panelPosition: null,
    enquiry: null,
    customPanelOffset: false,
    customPanelPosition: false,
    sort: true,
    hubspotName: null,
    hubspotToken: null,
    otherRoof: false,
    products: null,
    steelineStore: null,
    domeImages: null,
    rollImages: null,
    profileImages: null,
    screwImages: null,
    trimImages: null,
    solarImages: null
  },

  getters: {
    steps: state => {
      switch (state.type) {
        case "Standard Gutter":
          return state.roof === "Klip-Lok" ||
            state.roof === "Lokdek 680/700" ||
            state.roof === "Steel Rib 500" ||
            state.roof === "Steel Rib 675" ||
            state.roof === "Prodek" ||
            state.roof === "Maximus 22" ||
            state.roof === "Maximus 33" ||
            state.roof === "Spacedek®"
            ? standardRoutes
            : standardRoutesNoKlipLok;
        case "Valley":
          return (state.roof === "Klip-Lok" ||
            state.roof === "Lokdek 680/700" ||
            state.roof === "Steel Rib 500" ||
            state.roof === "Steel Rib 675" ||
            state.roof === "Steeline Hi Dek" ||
            state.roof === "Prodek" ||
            state.roof === "Maximus 22" ||
            state.roof === "Maximus 33" ||
            state.roof === "Spacedek®") &&
            state.valley !== "Domed Valley"
            ? valleyRoutes
            : valleyRoutesNoKlipLok;
        case "Box Gutter":
          return state.roof === "Klip-Lok" ||
            state.roof === "Lokdek 680/700" ||
            state.roof === "Steel Rib 500" ||
            state.roof === "Steel Rib 675" ||
            state.roof === "Steeline Hi Dek" ||
            state.roof === "Prodek" ||
            state.roof === "Maximus 22" ||
            state.roof === "Maximus 33" ||
            state.roof === "Spacedek®"
            ? boxGutterRoutes
            : boxGutterRoutesNoKlipLok;
        case "Solar Panel":
          return state.roof === "Klip-Lok" ||
            state.roof === "Lokdek 680/700" ||
            state.roof === "Steel Rib 500" ||
            state.roof === "Steel Rib 675" ||
            state.roof === "Steeline Hi Dek" ||
            state.roof === "Prodek" ||
            state.roof === "Maximus 22" ||
            state.roof === "Maximus 33" ||
            state.roof === "Spacedek®"
            ? solarPanelRoutes
            : solarPanelRoutesNoKlipLok;
        default:
          return [];
      }
    },

    products: state => {
      return _.filter(state.products, function(product) {
        let match = true;
        const matchFields = [
          "mesh",
          "roof",
          "width",
          "trim",
          "type",
          "valley",
          "installationType"
        ];
        _.each(matchFields, function(matchField) {
          if (_.isNull(state[matchField])) {
            match = match && true;
          } else {
            if (matchField === "width") {
              match =
                match &&
                product.width.width == state[matchField].width &&
                product.width.standard == state[matchField].standard;
            } else {
              match = match && product[matchField] == state[matchField];
            }
          }
        });

        return match;
      });
    },

    colours: state => {
      if (!state.mesh) return [];
      if (state.mesh.includes("Aluminium")) return aluminiumColours;
      return steelColours;
    },

    tenant: state => {
      return state.tenant;
    },
    roof: state => {
      return state.roof;
    },
    standard: state => {
      return state.standard;
    },

    roofTypes: (state, getters) => {
      // eslint-disable-line no-unused-vars
      return _.uniq(_.map(getters.products, "roof"));
    },

    meshTypes: (state, getters) => {
      // eslint-disable-line no-unused-vars
      return _.uniq(_.map(getters.products, "mesh"));
    },

    meshWidths: (state, getters) => {
      return _.map(
        _.uniq(
          _.map(getters.products, function(product) {
            return JSON.stringify(product.width);
          })
        ),
        function(obj) {
          return JSON.parse(obj);
        }
      );
    },

    trimTypes: (state, getters) => {
      // eslint-disable-line no-unused-vars
      return _.uniq(_.map(getters.products, "trim"));
    },

    valleyTypes: (state, getters) => {
      // eslint-disable-line no-unused-vars
      return _.uniq(_.map(getters.products, "valley"));
    },
    hubspotToken: state => {
      let name = "hubspotutk" + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return (state.hubspotToken = c.substring(name.length, c.length));
        }
      }
      return "";
    },
    getInstallationTypes: state => {
      return installationTypes[state.tenant];
    }
  },

  mutations: {
    setTenant(state, val) {
      state.tenant = val;
    },
    setProducts(state) {
      if (state.tenant === "steeline") return (state.products = steeline);
      if (state.tenant === "4933348840") return (state.products = stratco);
      else return (state.products = non_steeline);
    },
    advance(state) {
      if (_.isNull(state.step)) {
        state.step = 0;
      } else {
        ++state.step;
      }
      if (state.tenant !== "bunnings") {
        VueScrollTo.scrollTo("mesh-kit-builder", {
          container: "body",
          offset: 0,
          duration: 500,
          x: false,
          y: true
        });
      }
      // window.scrollTo(0, 0);
    },
    enquiryStep(state, enquiry) {
      if (_.isNull(state.step)) {
        state.step = 0;
      } else {
        ++state.step;
        state.enquiry = enquiry;
      }
    },
    goBack(state) {
      state.enquiry = null;
      --state.step;
    },
    goBackSpecificStep(state, index) {
      state.step = index;
    },

    setType(state, type) {
      state.type = type;
    },
    setSortColour(state, value) {
      state.sort = value;
    },

    setMesh(state, mesh) {
      state.mesh = mesh;
    },
    setcustomPanelOffset(state, custom) {
      state.customPanelOffset = custom;
    },
    setcustomPanelPosition(state, custom) {
      state.customPanelPosition = custom;
    },

    setRoof(state, roof) {
      state.roof = roof.roofType;
      state.otherRoof = roof.other;
    },

    setInstallationType(state, type) {
      state.installationType = type;
    },

    setMeshColour(state, colour) {
      state.meshColour = colour;
    },
    setPanelWidth(state, panelWidth) {
      state.panelWidth = panelWidth;
    },
    setPanelHeight(state, panelHeight) {
      state.panelHeight = panelHeight;
    },
    setPanelOffset(state, panelOffset) {
      state.panelOffset = panelOffset;
    },
    setPanelPosition(state, panelPosition) {
      state.panelPosition = panelPosition;
    },

    setWidth(state, width) {
      state.width = width;
    },

    setValley(state, valley) {
      state.valley = valley;
    },

    setTrim(state, trim) {
      state.trim = trim;
    },

    setTrimColour(state, colour) {
      state.trimColour = colour;
    },

    setStep(state, stepIndex) {
      state.step = stepIndex;
    },
    setHubspotForm(state, value) {
      state.hubspotName = value;
    },
    setStoreSelected(state, val) {
      state.storeSelected = val;
    },
    setDomeImages(state, val) {
      state.domeImages = val;
    },
    setRollImages(state, val) {
      state.rollImages = val;
    },
    setScrewImages(state, val) {
      state.screwImages = val;
    },
    setTrimImages(state, val) {
      state.trimImages = val;
    },
    setProfileImages(state, val) {
      state.profileImages = val;
    },
    setSolarImages(state, val) {
      state.solarImages = val;
    },

    clearStepSelection(state, data) {
      if (_.has(state, data)) {
        if (data === "roof") {
          state.otherRoof = false;
        }
        if (data === "panelWidth" || data === "panelHeight") {
          return;
        }
        state[data] = null;
      }
    },

    reset(state) {
      state.step = null;
      state.type = null;
      state.mesh = null;
      state.meshColour = null;
      state.roof = null;
      state.valley = null;
      state.width = null;
      state.trim = null;
      state.trimColour = null;
      state.installationType = null;
      state.panelWidth = null;
      state.panelHeight = null;
      state.panelOffset = null;
      state.panelPosition = null;
      state.enquiry = null;
      state.hubspotName = null;
      state.storeSelected = null;
    }
  },

  actions: {}
});
