<template>
  <b-modal
    ref="hubspotModal"
    size="md"
    content-class="mkb-hubspot-modal"
    centered
    hide-header
    body-class="p-0"
    :hide-footer="this.$store.state.tenant === '4933348840'"
  >
    <b-link class="mkb-close-button" @click.prevent="hide">
      <close></close>
    </b-link>
    <div class="mkb-info-columns d-md-flex flex-md-row px-5">
      <div class="w-100 text-center" v-if="isLoading">
        <b-spinner
          variant="primary"
          label="Spinning"
          class="text-center"
        ></b-spinner>
      </div>

      <div id="modalHubspot" v-once></div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-center">
        <div class="mkb-chat-icon">
          <b-img
            src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/svg/talk-bubble.svg"
          ></b-img>
        </div>
        <span>Do you have any questions about our gutter mesh kits?</span>
        <br />
        <b-link href="mailto:info@bmco.com.au"
          >Please send your enquiries here</b-link
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import Close from "./svg/icons/Close";

export default {
  name: "HubspotModal",
  components: {
    Close
  },
  data() {
    return {
      meshType: null,
      imgUrl: null,
      isLoading: true
    };
  },
  mounted() {},
  methods: {
    async show() {
      await this.$refs.hubspotModal.show();
      this.setForm();
    },
    hide() {
      this.$refs.hubspotModal.hide();
    },
    setForm() {
      this.isLoading = true;
      const form = {
        "bmco-www": {
          formId: "7fdd866a-f46c-4a49-831d-0c3c696a8010"
        },
        bunnings: {
          formId: "bacc62b6-96fa-48af-b1d8-c67ef46cda48"
        },
        demo: {
          formId: "482215c7-04ff-470d-b1f8-985bd68fc9af"
        },
        "mitre-10-Strathalbyn": {
          formId: "482215c7-04ff-470d-b1f8-985bd68fc9af"
        }
      };
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            formId: form[this.$store.state.tenant].formId,
            portalId: "3779244",
            target: "#modalHubspot"
          });
        }
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormReady"
        ) {
          //  [...document.querySelectorAll('form input[type="hidden"].hs-input')].map(x => console.log(x.name));
          // this.$nuxt.$loading.finish();
          this.isLoading = false;
        }
        if (event.data.eventName === "onFormSubmit") {
          // this.$nuxt.$loading.start();
          document.querySelector(
            "input[name='mkb_roof_type']"
          ).value = this.$store.state.roof;
          document.querySelector(
            "input[name='mkb_install_type']"
          ).value = this.$store.state.type;
          // document.querySelector(
          //   "input[name='is_you_property_affected_by_hail_or_snow_']"
          // ).value = this.$store.state.hailAffected;
          // document.querySelector(
          //   "input[name='is_you_property_in_a_bushfire_prone_area_']"
          // ).value = this.$store.state.bushFireProneArea;
          // document.querySelector(
          //   "input[name='what_is_the_bal_rating_in_your_area_']"
          // ).value = this.$store.state.BALRating = null
          //   ? ""
          //   : this.$store.state.BALRating;
          // document.querySelector(
          //   "input[name='do_you_need_ember_guard_compliant_mesh_']"
          // ).value = this.$store.state.emberGuard = null
          //   ? ""
          //   : this.$store.state.emberGuard;
          // document.querySelector(
          //   "input[name='how_important_is_mesh_strength_to_you_']"
          // ).value = this.$store.state.meshStrength;
        }
        if (event.data.eventName === "onFormSubmitted") {
          // this.$nuxt.$loading.finish();
          // this.submitted = true;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.mkb-hubspot-modal {
  .mkb-image {
    filter: blur(8px);
  }
  .mkb-close-button {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .mkb-info-columns {
    padding: 24px;
  }

  .mkb-left-column {
    // width: 291px !important;
    margin-right: 35px;
  }

  .mkb-right-column {
    flex: 1;
    .mkb-feature-block {
      height: 41px;
    }
  }

  .modal-body {
    padding: 0 !important;
  }

  h4,
  p {
    font-size: 0.75rem;
    color: #707070;
  }

  h2 {
    color: #002f6c;
  }

  p {
    line-height: 145%;
  }
  .mkb-learn-more-link {
    display: block;
    color: #ffffff;
    background-color: #002f6c;
    font-size: 0.95rem;
  }

  .mkb-link-icon {
    margin-top: -1px;
    width: 20px;
    height: 20px;
  }

  .mkb-feature-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    align-self: center !important;
  }

  .mkb-feature-description {
    font-weight: 500;
    color: #1a3768;
    align-self: center !important;

    .small {
      font-size: 0.7rem;
    }
  }

  .mkb-disclaimer {
    font-size: 0.6rem;
  }
  footer {
    background-color: #f0f0f0;
    span,
    a {
      font-size: 12px;
      font-weight: bold;
    }
    a {
      color: #002f6c;
    }
    .mkb-chat-icon svg {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 767.98px) {
    .mkb-feature-description {
      color: #1a3768;
      font-size: 0.7rem;
      align-self: center !important;

      .small {
        font-size: 0.6rem;
      }
    }
    h2 {
      margin-top: 20px !important;
      margin-bottom: 25px !important;
    }

    p {
      font-size: 0.9rem;
    }

    .mkb-info-columns {
      padding: 15px !important;
    }

    .mkb-left-column {
      width: 100% !important;
      margin: 0 0px 25px 0px !important;
    }

    .mkb-mesh-image-container {
      width: 100%;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
</style>
