import { render, staticRenderFns } from "./AluminiumUltraFour.vue?vue&type=template&id=584af39a&scoped=true&"
var script = {}
import style0 from "./AluminiumUltraFour.vue?vue&type=style&index=0&id=584af39a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584af39a",
  null
  
)

export default component.exports