<template>
  <div>
    <div class="mkb-mesh-type-page">
      <h3 class="text-primary">Choose your mesh type</h3>
      <b-row class="d-flex">
        <b-col
          :key="meshTypeKey"
          @click.prevent="setMesh(meshTypeKey)"
          v-for="(meshType, meshTypeKey) in orderedMeshTypes"
          class="mkb-clickable-selection mb-4"
          md="4"
          cols="6"
        >
          <div class="mb-2">
            <component :is="meshType.image"></component>
          </div>
          <p class="w-100 m-0 text-center font-weight-bold">
            {{ meshTypeKey }}
            <b-link
              v-if="$store.state.tenant !== 'steeline'"
              class="mkb-info-link"
              @click.prevent.stop="showInfoModal(meshTypeKey)"
            >
              <tooltip></tooltip>
            </b-link>
          </p>
        </b-col>
      </b-row>
    </div>
    <mesh-info-modal ref="infoModal"></mesh-info-modal>
  </div>
</template>

<script>
import MeshInfoModal from "../components/MeshInfoModal";
import meshTypes from "../data/mesh-types";
import Tooltip from "../components/svg/icons/Tooltip";

export default {
  name: "MeshType",
  components: {
    MeshInfoModal,
    Tooltip
  },
  computed: {
    orderedMeshTypes() {
      let ordered = {};

      const orderedMeshTypes = [
        "2mm Steel",
        "4mm Steel",
        "5.4mm Steel",
        "2mm Aluminium",
        "4mm Aluminium Ultra",
        "4mm Aluminium"
      ];

      orderedMeshTypes.forEach(meshType => {
        if (!this.$store.state.otherRoof) {
          if (this.$store.getters.meshTypes.includes(meshType)) {
            ordered[meshType] = meshTypes[meshType];
          }
        } else {
          if (
            this.$store.state.type === "Valley" &&
            this.$store.state.valley === "Domed Valley"
          ) {
            // for ticket https://git.nxt.work/rh/tools/mesh-kit-builder/-/issues/191#note_38305
            ordered["2mm Steel"] = meshTypes["2mm Steel"];
          } else {
            ordered[meshType] = meshTypes[meshType];
          }
        }
      });

      return ordered;
    }
  },
  methods: {
    setMesh(meshType) {
      this.$store.commit("setMesh", meshType);
      this.$store.commit("advance");
    },
    showInfoModal(meshType) {
      this.$refs.infoModal.show(meshType);
    }
  }
};
</script>

<style lang="scss">
.mkb-mesh-type-page {
  .mkb-info-link svg {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #ffffff;
    padding-bottom: 5px;
  }
  .mkb-info-link:hover {
    .st0 {
      fill: #002f6c !important;
    }
  }

  .mkb-clickable-selection {
    .c {
      fill: #707070;
    }
  }

  .mkb-clickable-selection:hover {
    .g {
      stroke: #002f6c;
    }
    .d,
    .e {
      fill: #002f6c;
    }
    .c {
      fill: #002f6c;
    }
  }
}
</style>
