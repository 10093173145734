const RoofType = () =>
  import(/* webpackChunkName: "roof-type" */ "../views/RoofType.vue");
const MeshType = () =>
  import(/* webpackChunkName: "mesh-type" */ "../views/MeshType.vue");
const MeshColour = () =>
  import(/* webpackChunkName: "mesh-colour" */ "../views/MeshColour.vue");
const MeshWidth = () =>
  import(/* webpackChunkName: "mesh-width" */ "../views/MeshWidth.vue");
const InstallationMethod = () =>
  import(
    /* webpackChunkName: "installation-method" */ "../views/InstallationMethod.vue"
  );

export const boxGutterRoutes = [
  {
    path: "roof-type",
    name: "box.rooftype",
    component: RoofType,
    sets: "roof",
    title: "Roof Type"
  },
  {
    path: "installation-method",
    name: "box.installationMethod",
    component: InstallationMethod,
    sets: "installationType",
    title: "Installation Method"
  },
  {
    path: "mesh-type",
    name: "box.meshtype",
    component: MeshType,
    sets: "mesh",
    title: "Mesh Type"
  },
  {
    path: "mesh-colour",
    name: "box.meshcolour",
    component: MeshColour,
    sets: "meshColour",
    title: "Mesh Colour"
  },
  {
    path: "mesh-width",
    name: "box.meshwidth",
    component: MeshWidth,
    sets: "width",
    title: "Mesh Width"
  }
];

export const boxGutterRoutesNoKlipLok = [
  {
    path: "roof-type",
    name: "box.rooftype",
    component: RoofType,
    sets: "roof",
    title: "Roof Type"
  },
  {
    path: "mesh-type",
    name: "box.meshtype",
    component: MeshType,
    sets: "mesh",
    title: "Mesh Type"
  },
  {
    path: "mesh-colour",
    name: "box.meshcolour",
    component: MeshColour,
    sets: "meshColour",
    title: "Mesh Colour"
  },
  {
    path: "mesh-width",
    name: "box.meshwidth",
    component: MeshWidth,
    sets: "width",
    title: "Mesh Width"
  }
];
