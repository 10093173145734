<template>
  <div class="mkb-trim-page">
    <b-row>
      <b-col md="5" class="d-md-block pr-3 mkb-mesh-kit-image-container">
        <Kit />
      </b-col>
      <b-col>
        <div>
          <div class="mb-4" v-if="$store.getters.trimTypes.includes('No Trim')">
            <h3 class="text-primary mb-1">
              Do you want to add trims to your kit?
            </h3>
            <p>
              Gutter trims are not required for your selected mesh type.
            </p>
          </div>
          <div class="mb-4" v-else>
            <h3 class="text-primary mb-3">
              Choose your type of gutter trim
            </h3>
            <p class="mr-md-5">
              Gutter trims are recommended and included in all kits with
              Aluminium gutter mesh and 2mm Steel.
            </p>
          </div>
        </div>
        <div class="mkb-trim-listing">
          <div
            class="mb-4"
            v-if="
              $store.getters.trimTypes.includes('Mesh Lock') ||
                $store.state.otherRoof
            "
          >
            <div
              @mouseover="setHover('meshlock')"
              @mouseout="setHover(null)"
              class="mkb-clickable-container mkb-trim-option-best mb-1 mesh-lock-button"
              @click="setTrim('Mesh Lock')"
            >
              <div class="mt-3 px-3">
                Mesh Lock Gutter Trim
              </div>
              <p class="mkb-info-link px-3 py-1 w-50 m-0">
                Features locking teeth that anchors the mesh to the roof.
                <b-link
                  class="mkb-info-link"
                  v-if="$store.state.tenant !== 'steeline'"
                  @click.prevent.stop="showInfoModal()"
                >
                  <tooltip></tooltip>
                </b-link>
              </p>
              <img
                src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/popup-ml-trim.png"
                class="w-100"
                alt=""
              />
              <div class="additional mx-auto">
                <span class="ad">200%</span>
                <p class="p-0 m-0 ads">STRONGER</p>
              </div>
            </div>
          </div>
          <div
            @mouseover="setHover('standard')"
            @mouseout="setHover(null)"
            class="mkb-clickable-container mkb-trim-option-best mb-5"
            v-if="
              $store.getters.trimTypes.includes('Standard') ||
                $store.state.otherRoof
            "
            @click="setTrim('Standard')"
          >
            <div class="mt-3 mb-1 px-3 position-absolute">
              Standard Gutter Trim
            </div>
            <img
              src="https://rh-tools.s3.ap-southeast-2.amazonaws.com/images/btn-std-trim.png"
              class="w-100"
              alt=""
            />
          </div>

          <div
            class="mkb-clickable-container mkb-trim-option"
            v-if="$store.getters.trimTypes.includes('No Trim')"
            @click="setTrim('No Trim')"
          >
            <div class="my-3">
              No Trims
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <mesh-lock-gutter-info-modal ref="infoModal"></mesh-lock-gutter-info-modal>
  </div>
</template>

<script>
import MeshLockGutterInfoModal from "../components/MeshLockGutterInfoModal";
import Tooltip from "../components/svg/icons/Tooltip";
import Kit from "../components/Kit";
export default {
  name: "Trim",
  components: {
    Kit,
    MeshLockGutterInfoModal,
    Tooltip
  },
  data() {
    return {
      hovering: null
    };
  },
  computed: {
    trimOptional() {
      return this.$store.getters.trimTypes.includes("No Trim");
    }
  },
  methods: {
    setHover(hover) {
      this.hovering = hover;
    },
    setTrim(trim) {
      this.$store.commit("setTrim", trim);
      this.$store.commit("advance");
    },
    showInfoModal() {
      this.$refs.infoModal.show();
    }
  }
};
</script>

<style lang="scss">
.mkb-trim-page {
  .mkb-info-link {
    color: #ffffff !important;
    font-size: 12px;
  }
  .mkb-info-link svg {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #002f6c;
    .st0 {
      fill: gray !important;
    }
  }
  .mkb-info-link svg:hover {
    svg {
      background-color: #ffffff;
    }
    .st0 {
      fill: #002f6c !important;
    }
  }
  .mkb-trim-option-best:hover {
    background-color: #ffffff;
    color: #002f6c;
    .mkb-info-link {
      color: #002f6c !important;
      svg {
        background-color: #ffffff;
      }
    }
    .additional {
      background-color: #002f6c !important;
      color: #ffffff;
    }
  }
  .mesh-lock-button {
    position: relative;
  }
  .additional {
    background-color: #efefef !important;
    padding: 20px 0 5px 0;
    width: 35%;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 20px;
    line-height: 100%;
  }
  .ad {
    font-size: 50px;
    font-weight: bolder;
    @media (max-width: 500px) {
      font-size: 35px;
    }
  }
  .ads {
    font-size: 25px;
    font-weight: bolder;
    @media (max-width: 500px) {
      font-size: 15px;
    }
  }
  .mkb-trim-option:hover {
    background-color: #002f6c;
    color: #ffffff;
  }

  .mkb-small-text {
    font-size: 0.7rem;
  }

  @media (max-width: 767.98px) {
    .additional {
      width: 40%;
    }
  }
}
</style>
