var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roof-type-page"},[_c('h3',{staticClass:"text-primary"},[_vm._v("Choose your roof type")]),_c('b-row',{staticClass:"d-flex"},[_vm._l((_vm.orderedRoofTypes),function(roofType,roofTypeKey){return _c('b-col',{key:roofTypeKey,class:{
        'mkb-clickable-selection':
          roofTypeKey !== 'Maximus 22' && roofTypeKey !== 'Maximus 33',
        'mkb-unavailable-selection':
          roofTypeKey === 'Maximus 22' || roofTypeKey === 'Maximus 33',
        'mkb-roof-column': true
      },attrs:{"md":"3","cols":"6"},on:{"click":function($event){$event.preventDefault();return _vm.setRoof(roofTypeKey, false)}}},[_c('div',{staticClass:"mkb-roof-image-container"},[_c(roofType.image,{tag:"component"})],1),_c('strong',[_vm._v(" "+_vm._s(roofTypeKey)),(roofType.rMark)?_c('span',[_vm._v("® ")]):_vm._e(),(roofTypeKey === 'Klip-Lok')?_c('span',[_vm._v("or Flat Pan")]):_vm._e()]),(roofTypeKey === 'Maximus 22' || roofTypeKey === 'Maximus 33')?_c('strong',{staticClass:"mkb-coming-soon"},[_vm._v("Coming soon")]):_vm._e()])}),(
        _vm.$store.state.tenant !== 'steeline' &&
          _vm.$store.state.tenant !== '4933348840'
      )?_c('b-col',{staticClass:"mkb-clickable-selection mkb-roof-column",class:_vm.isOther ? 'mkb-other-active' : '',attrs:{"md":"3","cols":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{on:{"click":function($event){_vm.isOther = !_vm.isOther}}},[_c('div',{staticClass:"mkb-roof-image-container"},[_c('CustomRoof')],1),_c('strong',[_vm._v(" Other ")])]),(_vm.isOther)?_c('div',{staticClass:"mt-1"},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.otherRoof),callback:function ($$v) {_vm.otherRoof=$$v},expression:"otherRoof"}}),_c('b-button',{staticClass:"py-1 mt-1 w-100",class:_vm.isDisableComputed,on:{"click":function($event){return _vm.setRoof(_vm.otherRoof, true)}}},[_vm._v("Submit")])],1):_vm._e()])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }