const RoofType = () =>
  import(/* webpackChunkName: "roof-type" */ "../views/RoofType.vue");
const MeshType = () =>
  import(/* webpackChunkName: "mesh-type" */ "../views/MeshType.vue");
const MeshColour = () =>
  import(/* webpackChunkName: "mesh-colour" */ "../views/MeshColour.vue");
const SolarPanelHeight = () =>
  import(
    /* webpackChunkName: "solar-panel-height" */ "../views/panel/SolarPanelHeight.vue"
  );
const SolarPanelWidth = () =>
  import(
    /* webpackChunkName: "solar-panel-width" */ "../views/panel/SolarPanelWidth.vue"
  );
const SolarPanelOffset = () =>
  import(
    /* webpackChunkName: "solar-panel-offset" */ "../views/panel/SolarPanelOffset.vue"
  );
const SolarPanelPosition = () =>
  import(
    /* webpackChunkName: "solar-panel-position" */ "../views/panel/SolarPanelPosition.vue"
  );
const InstallationMethod = () =>
  import(
    /* webpackChunkName: "installation-method" */ "../views/InstallationMethod.vue"
  );

export const solarPanelRoutes = [
  {
    path: "roof-type",
    name: "solar-panel.rooftype",
    component: RoofType,
    sets: "roof",
    title: "Roof Type"
  },
  {
    path: "installation-method",
    name: "solar-panel.installationMethod",
    component: InstallationMethod,
    sets: "installationType",
    title: "Installation Method"
  },
  {
    path: "mesh-type",
    name: "solar-panel.meshtype",
    component: MeshType,
    sets: "mesh",
    title: "Mesh Type"
  },
  {
    path: "mesh-colour",
    name: "solar-panel.meshcolour",
    component: MeshColour,
    sets: "meshColour",
    title: "Mesh Colour"
  },
  {
    path: "panel-width",
    name: "solar-panel.panelwidth",
    component: SolarPanelWidth,
    sets: "panelWidth",
    title: "Horizontal Measurement"
  },
  {
    path: "panel-height",
    name: "solar-panel.panelheight",
    component: SolarPanelHeight,
    sets: "panelHeight",
    title: "Vertical Measurement"
  },
  {
    path: "panel-offset",
    name: "solar-panel.paneloffset",
    component: SolarPanelOffset,
    sets: "panelOffset",
    title: "Panel Offset"
  },
  {
    path: "panel-position",
    name: "solar-panel.panelposition",
    component: SolarPanelPosition,
    sets: "panelPosition",
    title: "Panel Position"
  }
];
export const solarPanelRoutesNoKlipLok = [
  {
    path: "roof-type",
    name: "solar-panel.rooftype",
    component: RoofType,
    sets: "roof",
    title: "Roof Type"
  },
  {
    path: "mesh-type",
    name: "solar-panel.meshtype",
    component: MeshType,
    sets: "mesh",
    title: "Mesh Type"
  },
  {
    path: "mesh-colour",
    name: "solar-panel.meshcolour",
    component: MeshColour,
    sets: "meshColour",
    title: "Mesh Colour"
  },
  {
    path: "panel-width",
    name: "solar-panel.panelwidth",
    component: SolarPanelWidth,
    sets: "panelWidth",
    title: "Horizontal Measurement"
  },
  {
    path: "panel-height",
    name: "solar-panel.panelheight",
    component: SolarPanelHeight,
    sets: "panelHeight",
    title: "Vertical Measurement"
  },
  {
    path: "panel-offset",
    name: "solar-panel.paneloffset",
    component: SolarPanelOffset,
    sets: "panelOffset",
    title: "Panel Offset"
  },
  {
    path: "panel-position",
    name: "solar-panel.panelposition",
    component: SolarPanelPosition,
    sets: "panelPosition",
    title: "Panel Position"
  }
];
