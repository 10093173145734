<template>
  <div>
    <b-row class="mkb-mesh-kit mx-auto">
      <b-col class="px-0 pr-2">
        <div class="d-flex">
          <div class="align-items-start mr-1">
            <font-awesome-icon :icon="['fas', 'caret-down']" />
          </div>
          <div
            class="align-self-center"
            v-if="$store.state.valley === 'Domed Valley'"
          >
            Domed Mesh
          </div>
          <div class="align-self-center" v-else>
            Mesh Roll
          </div>
        </div>
        <div>
          <img :src="meshImage" alt="Mesh Roll" class="mesh-image" />
        </div>
      </b-col>
      <b-col
        v-if="$store.state.type.includes('Solar')"
        class="px-0 pr-2 mt-auto"
      >
        <div class="mkb-clip-hooks">
          <div class="d-flex align-items-start mb-2">
            <div class=" mr-1">
              <font-awesome-icon :icon="['fas', 'caret-down']" />
            </div>
            <div>
              Solar panel mesh clip hooks
            </div>
          </div>
          <b-row cols="1" class="mkb-clip-hooks-img ml-1">
            <b-img
              class="w-100 p-1"
              :src="$store.state.solarImages['solar panel mesh clip hooks']"
            ></b-img>
            <b-img
              class="w-100 p-1"
              :src="$store.state.solarImages['solar panel mesh clip hooks']"
            ></b-img>
            <b-img
              class="w-100 p-1"
              :src="$store.state.solarImages['solar panel mesh clip hooks']"
            ></b-img>
          </b-row>
        </div>
        <div class="mkb-clip-plates">
          <div class="d-flex align-items-start mb-2">
            <div class=" mr-1">
              <font-awesome-icon :icon="['fas', 'caret-down']" />
            </div>
            <div>
              Solar panel mesh clip locking plates
            </div>
          </div>
          <div>
            <b-row cols="4" class="mkb-clip-plates-img ml-1">
              <b-img
                class="w-25 m-1"
                :src="
                  $store.state.solarImages[
                    'solar panel mesh clip locking plates - back'
                  ]
                "
              ></b-img
              ><b-img
                class="w-25 m-1"
                :src="
                  $store.state.solarImages[
                    'solar panel mesh clip locking plates - back'
                  ]
                "
              ></b-img
              ><b-img
                class="w-25 m-1"
                :src="
                  $store.state.solarImages[
                    'solar panel mesh clip locking plates - back'
                  ]
                "
              ></b-img>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col v-else>
        <div class="d-inline-flex flex-column pt-2 ">
          <div class="d-flex mkb-trim-name pt-2" v-if="$store.state.trim">
            <div class="align-items-start mr-1 ">
              <font-awesome-icon :icon="['fas', 'caret-down']" />
            </div>
            <div class="align-self-start">
              {{ $store.state.trim }}
              Gutter Trim
            </div>
          </div>
          <img :src="trimImage" :alt="trimImage" class="trim-image " />
        </div>
      </b-col>
    </b-row>
    <b-row class="mkb-trim-row" v-if="$store.state.type.includes('Solar')"
      ><b-col>
        <div class="d-flex mkb-trim-name">
          <div class=" mr-1">
            <font-awesome-icon :icon="['fas', 'caret-down']" />
          </div>
          <div class="align-self-start">
            Standard Gutter Trim
          </div>
        </div>
        <div class="">
          <div class="mkb-trim-image w-50 ">
            <img :src="trimImage" alt="Gutter trim" />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { rollImages, trimImages } from "../data/rollImages";
import { domeImages } from "../data/domeImages";
export default {
  computed: {
    meshImage() {
      // return dome if true, else roll
      const mesh = {
        "2mm Steel": "steel-2mm",
        "4mm Steel": "steel-4mm",
        "5.4mm Steel": "steel-5 4mm",
        "2mm Aluminium": "aluminium-2mm",
        "4mm Aluminium": "aluminium-4mm",
        "4mm Aluminium Ultra": "aluminium-4mm"
      };
      if (this.$store.state.valley === "Domed Valley")
        return domeImages("domed-valley-mesh-" + this.$store.state.meshColour);
      return rollImages(
        `${mesh[this.$store.state.mesh]}-${this.$store.state.meshColour}`,
        mesh[this.$store.state.mesh]
      );
    },
    trimImage() {
      const trim = {
        Standard: "standard-gutter-trim",
        "Mesh Lock": "mesh-lock-gutter-trim"
      };
      if (this.$store.state.type.includes("Solar"))
        return trimImages(
          `${trim.Standard}-${this.$store.state.meshColour}`,
          trim.Standard
        );
      return trimImages(
        `${trim[this.$store.state.trim]}-${this.$store.state.trimColour}`,
        trim[this.$store.state.trim]
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.mkb-mesh-kit {
  font-size: 0.65rem;

  img {
    max-width: 100%;
    height: auto;
  }

  .trim-image {
    width: 18%;
  }
  .mkb-clip-hooks-img {
    width: 60%;
  }
  .mkb-clip-plates-img {
    width: 70%;
  }
}
.mkb-trim-image,
.mkb-trim-row {
  max-height: 15px;
  .mkb-trim-name {
    font-size: 10px !important;
  }
  img[alt="Gutter trim"] {
    width: 15% !important;
    transform: rotateZ(270deg) !important;
    margin-left: 141px;
    margin-top: -140px;
  }
}
</style>
