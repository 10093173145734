<template>
  <div>
    <OtherMeshKit v-if="$store.state.otherRoof" />
    <MeshKitSolarPanel v-else-if="$store.state.type === 'Solar Panel'" />
    <MeshKit v-else />
  </div>
</template>
<script>
import MeshKit from "./MeshKit.vue";
import MeshKitSolarPanel from "./MeshKitSolarPanel.vue";
import OtherMeshKit from "./OtherMeshKit.vue";
export default {
  components: {
    MeshKit,
    MeshKitSolarPanel,
    OtherMeshKit
  }
};
</script>
