import { render, staticRenderFns } from "./Ember.vue?vue&type=template&id=343b1356&scoped=true&"
var script = {}
import style0 from "./Ember.vue?vue&type=style&index=0&id=343b1356&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343b1356",
  null
  
)

export default component.exports