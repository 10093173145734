<template>
  <b-container class="mkb-step-container mkb-product-code-page">
    <div class="header">
      <page-header></page-header>
    </div>
    <b-row class="d-flex justify-content-center">
      <b-col md="12">
        <p class="title m-0 px-2 p-0">Here's your kit:</p>
        <div class="d-lg-flex mb-3">
          <div class="mkb-kit-image-wrapper p-2">
            <div
              :class="{
                'mkb-image-wrapper-solar': $store.state.type.includes('Solar'),
                'mkb-image-wrapper':
                  $store.state.type.includes('Standard') ||
                  $store.state.type.includes('Valley') ||
                  $store.state.type.includes('Gutter')
              }"
            >
              <Kit />
            </div>
          </div>
          <div class="w-100 col-8 mkb-product-container ">
            <div class="mkb-product-information d-md-flex h-100">
              <div class="w-100 pr-md-5">
                <div class="mkb-info-row">
                  <div class="mkb-info-label">Product Code</div>
                  <h1 class="mkb-info-value mkb-info-value-code">
                    {{ $store.getters.products[0].code }}
                  </h1>
                </div>
                <div class="mkb-info-row">
                  <div class="mkb-info-label">Description</div>
                  <div class="mkb-info-value mkb-info-value-description">
                    {{ $store.getters.products[0].description }}
                  </div>
                </div>
                <div class="mkb-info-row" v-if="$store.getters.method">
                  <div class="mkb-info-label">
                    Installation Method
                  </div>
                  <div class="mkb-info-value mkb-info-value-meshcolour">
                    {{ $store.getters.method }}
                    <span v-if="$store.getters.method === 'Klip-Lok'">®</span>
                  </div>
                </div>

                <div class="mkb-colour-rows mb-3 mb-md-0">
                  <div class="mkb-info-row">
                    <div class="mkb-info-label-colour align-self-center">
                      Mesh Colour
                    </div>
                    <div class="mkb-info-value mkb-info-value-meshcolour">
                      <inline-colour
                        :colour="$store.state.meshColour"
                      ></inline-colour>
                    </div>
                  </div>
                  <div
                    class="mkb-info-row"
                    v-if="
                      $store.state.trim !== 'No Trim' &&
                        $store.state.trim !== null
                    "
                  >
                    <div class="mkb-info-label-colour align-self-center">
                      Trim Colour
                    </div>
                    <div
                      class="mkb-info-value info-value-trimcolour align-self-center"
                    >
                      <inline-colour
                        :colour="$store.state.trimColour"
                      ></inline-colour>
                    </div>
                  </div>
                </div>
                <div
                  class="mkb-length-rows my-3 mb-md-0"
                  v-if="$store.state.type.includes('Solar')"
                >
                  <div class="mkb-info-row">
                    <div class="mkb-info-label-length align-self-center">
                      Horizontal Measurement
                    </div>
                    <div
                      class="mkb-info-value mkb-info-value-horizontal-length"
                    >
                      {{ $store.state.panelWidth }} metres
                    </div>
                  </div>
                  <div class="mkb-info-row">
                    <div class="mkb-info-label-length align-self-center">
                      Vertical Measurement
                    </div>
                    <div class="mkb-info-value mkb-info-value-vertical-length">
                      {{ $store.state.panelHeight }} metres
                    </div>
                  </div>
                </div>
                <div class="w-100 mt-4">
                  <div
                    class="under-product-info d-flex flex-column-reverse flex-md-row"
                  >
                    <div class="mkb-disclaimer-text align-self-center">
                      <div class="text-justify text-md-left small-colour-text">
                        COLORBOND&#174; and all colour names used are registered
                        trade marks and &#8482; colour names are trade marks of
                        BlueScope Steel Limited. &copy; 2022 BlueScope Steel
                        Limited ABN 16 000 011 058. All rights registered.
                      </div>
                    </div>
                  </div>
                  <!-- buy now condition
                  v-if="!!$store.getters.products[0].url.bmco" -->
                  <div
                    v-if="
                      $store.state.tenant === 'bmco-www' ||
                        $store.state.tenant === 'bmco'
                    "
                    class="mkb-copy-button mkb-clickable-container mb-2 mkb-standard mt-4"
                    @click="buy"
                  >
                    <div class="text-uppercase text-nowrap">
                      Buy now
                    </div>
                  </div>
                  <div
                    v-if="
                      $store.state.tenant === 'mitre-10-Strathalbyn' ||
                        $store.state.tenant === 'steeline' ||
                        $store.state.tenant === 'display-mkb' ||
                        $store.state.tenant === '4933348840'
                    "
                    class="mkb-copy-button mkb-clickable-container mb-2 mkb-standard mt-4"
                    @click="getAQuote()"
                  >
                    <div class="text-uppercase text-nowrap">
                      Get a Quote
                    </div>
                  </div>
                  <!-- <div
                    v-if="
                      $store.state.tenant === 'bmco' ||
                        $store.state.tenant === 'bunnings'
                    "
                    class="mkb-copy-button mkb-clickable-container mb-2 mkb-standard mt-4 test"
                    @click="buy"
                  >
                    <div class="text-uppercase text-nowrap">
                      {{
                        $store.state.tenant === "bmco2"
                          ? "Get a Quote"
                          : "Buy now"
                      }}
                    </div>
                  </div> -->
                  <div>
                    <div
                      class="d-md-none mobile-footer-logo mt-1 pb-1 d-flex justify-content-center"
                    >
                      <div class="footer-logo">
                        <logo></logo>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mkb-copy-buttons d-md-flex flex-md-column mb-4 mb-md-0"
              >
                <template v-if="$toolContext === 'internal'">
                  <div
                    class="text-nowrap mkb-copy-button mkb-clickable-container mb-3 text-uppercase"
                    style="padding-left: 50px; padding-right: 50px;"
                    @click="copyProductCode"
                  >
                    Copy Code
                  </div>
                  <div
                    class="text-nowrap mkb-copy-button mkb-clickable-container text-uppercase"
                    style="padding-left: 50px; padding-right: 50px;"
                    @click="copyColourCode"
                  >
                    Copy
                    {{ $store.state.trimColour ? "Colours" : "Colour" }}
                  </div>
                </template>
                <template v-else-if="$toolContext === 'homeowner'">
                  <div
                    v-if="
                      $store.state.tenant === 'bmco-www' ||
                        $store.state.tenant === 'bmco'
                    "
                    class="mkb-copy-button mkb-clickable-container mb-2 mkb-non-standard"
                    @click.prevent.stop="showNeedHelpForm()"
                  >
                    <div class="text-uppercase text-nowrap mb-2">
                      Need Further Help?
                    </div>
                    <div class="font-weight-normal mkb-button-subtext">
                      Measure my roof
                      <br />Get an installation quote <br />Get a sample
                      <br />
                    </div>
                  </div>
                  <PDFGenerator
                    v-if="
                      $store.state.tenant !== 'bmco-www' ||
                        $store.state.tenant === 'bmco'
                    "
                  />
                  <div
                    class="mkb-copy-button mkb-clickable-container mb-2 mkb-non-standard"
                    @click.prevent.stop="showEmailForm()"
                  >
                    <div class="text-uppercase text-nowrap">
                      Email Me My Kit
                    </div>
                  </div>
                  <div
                    v-if="
                      $store.state.tenant === 'bmco-www' ||
                        $store.state.tenant === 'bmco'
                    "
                    class="mkb-copy-button mkb-clickable-container mb-2 mkb-non-standard"
                    @click.prevent.stop="showContactForm()"
                  >
                    <div class="text-uppercase text-nowrap">
                      Contact Our Team
                    </div>
                  </div>
                  <div
                    class="mkb-restart-link mkb-clickable-container align-self-center d-flex justify-content-center mb-2 mt-3"
                    @click="restart"
                  >
                    <div
                      style="width: 1.2rem; height: auto;"
                      class="align-self-center mr-2"
                    >
                      <div>
                        <restart></restart>
                      </div>
                    </div>
                    <div class="align-self-center text-nowrap">
                      Restart Tool
                    </div>
                  </div>
                  <div
                    class="mkb-restart-link mkb-m10-link-2 mkb-clickable-container align-self-center d-flex justify-content-center mb-2 mt-3"
                    @click="redirectToM10"
                    v-if="$store.state.tenant === 'mitre-10-Strathalbyn'"
                  >
                    <div
                      style="width: 1.4rem; height: auto;"
                      class="align-self-center mr-1"
                    >
                      <div>
                        <ArrowRight />
                      </div>
                    </div>
                    <div class="align-self-center w-75 text-center">
                      Go back to Mitre 10 Strathalbyn's website
                    </div>
                  </div>
                  <!-- <div
                    class="mkb-m10-link mkb-clickable-container mkb-non-standard align-self-center d-flex justify-content-between mb-2 mt-2"
                    @click="redirectToM10"
                  >
                    <div
                      style="width: 1.2rem; height: auto;"
                      class="align-self-center mr-2"
                    >
                      <div>
                        <ArrowRight />
                      </div>
                    </div>
                    <div
                      class="align-self-center text-wrap mkb-m10-text"
                    >
                      Go back to Mitre 10 Strathalbyn's website
                    </div>
                  </div> -->
                </template>
                <template v-else-if="$toolContext === 'customer'">
                  <div
                    style="width: 220px;"
                    class="mkb-copy-button mkb-clickable-container mb-2"
                    @click="buy"
                  >
                    <div class="text-uppercase text-nowrap">
                      Order Now
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <find-store-modal ref="mkbFindStoreModal"></find-store-modal>
  </b-container>
</template>

<script>
import copy from "copy-to-clipboard";
import * as typeformEmbed from "@typeform/embed";
import PageHeader from "../components/PageHeader";
import InlineColour from "../components/InlineColour";
import Restart from "../assets/svg/icon-restart.svg";
import ArrowRight from "../assets/svg/icon-arrow-right.svg";
import Logo from "../assets/svg/logo.svg";
import Kit from "../components/Kit";
import PDFGenerator from "@/components/PDFGenerator";
import FindStoreModal from "../components/FindStoreModal.vue";
import axios from "axios";
export default {
  name: "ProductCode",
  components: {
    Kit,
    PageHeader,
    InlineColour,
    Restart,
    Logo,
    PDFGenerator,
    ArrowRight,
    FindStoreModal
  },
  data() {
    return {
      state: this.$store.state,
      getters: this.$store.getters
    };
  },
  mounted() {
    this.completedEngagement();
  },
  methods: {
    async completedEngagement() {
      if (this.$store.state.tenant === "localhost") return;
      const API_ENDPOINT = `${process.env.VUE_APP_API_ENDPOINT}/tool/add-engagement`;

      try {
        const payloads = {
          tenant: this.$store.state.tenant,
          engagement: 3,
          tool: "mkb"
        };

        await axios.post(API_ENDPOINT, payloads, {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    redirectToM10() {
      window.location.href = "https://www.strathalbynmitre10.com.au/";
    },
    restart() {
      this.$store.commit("reset");
      this.$router.push({ name: "installationtype" });
    },
    copyProductCode() {
      copy(this.$store.getters.products[0].code);

      this.$bvToast.toast(
        "The product code has been copied to your clipboard.",
        {
          title: `Code Copied`,
          toaster: "b-toaster-top-center",
          solid: true,
          autoHideDelay: 1000
        }
      );
    },
    copyColourCode() {
      let colour = this.$store.state.meshColour;
      if (this.$store.state.trimColour) {
        colour += ";" + this.$store.state.trimColour;
      }
      copy(colour);

      this.$bvToast.toast(
        "The colour code has been copied to your clipboard.",
        {
          title: `Colours Copied`,
          toaster: "b-toaster-top-center",
          solid: true,
          autoHideDelay: 1000
        }
      );
    },
    showFindStoreModal() {
      this.$refs.mkbFindStoreModal.show();
    },
    getAQuote() {
      if (this.$store.state.tenant === "steeline") {
        return this.showFindStoreModal();
      }
      if (this.$store.state.tenant === "4933348840") {
        return this.showFindStoreModal();
      }
      if (this.$store.state.type === "Solar Panel") {
        this.$store.commit("setHubspotForm", "solarPanelQuoteFormId");
      } else {
        this.$store.commit("setHubspotForm", "quoteFormId");
      }
      this.$store.commit("enquiryStep", "get-a-quote-form");
    },
    buy() {
      //BMCO2 function
      if (this.$store.state.tenant === "bmco2") {
        let object = [
          { portalId: "3779244" },
          { formId: "5a0f136f-2d5f-4af5-ac14-94b66582bd7f" }
        ];
        this.$store.commit("setHubspotForm", object);
        this.$store.commit("enquiryStep", "bmco2-form");
      } else {
        const params = [
          {
            param: `mesh_colour=${this.$store.state.meshColour}`,
            value: this.$store.state.meshColour
          },
          {
            param: `trim_colour=${this.$store.state.trimColour}`,
            value: this.$store.state.trimColour
          },
          {
            param: `horizontal=${this.roundBy5(this.$store.state.panelWidth)}`,
            value: this.$store.state.panelWidth
          },
          {
            param: `vertical=${this.roundBy5(this.$store.state.panelHeight)}`,
            value: this.$store.state.panelHeight
          }
        ];
        const includedParams = params.filter(v => v.value !== null);
        let result = "";
        for (let i in includedParams) {
          result += includedParams[i].param;
          if (i < includedParams.length - 1) {
            result = result.concat("&");
          }
        }

        let shopUrl = new URL(
          `${this.$store.getters.products[0].url.bmco}?${result}`
        );
        window.open(shopUrl.href, "_blank");
      }

      // shopUrl = new URL(location.host + shopUrl.pathname);
      // shopUrl.searchParams.append(
      //   "mesh_colour",
      //   this.$store.getters.colours[this.$store.state.meshColour].name
      //     .toLowerCase()
      //     .trim()
      //     .replace(" ", "-")
      // );
      // if (this.$store.state.trimColour) {
      //   shopUrl.searchParams.append(
      //     "trim_colour",
      //     this.$store.getters.colours[this.$store.state.trimColour].name
      //       .toLowerCase()
      //       .trim()
      //       .replace(" ", "-")
      //   );
      // }
    },
    roundBy5(value) {
      if (value < 10) {
        return 10;
      } else {
        return Math.ceil(value / 5) * 5;
      }
    },
    print() {
      window.print();
    },
    showNeedHelpForm() {
      this.$store.commit("setHubspotForm", "needHelpFormId");
      this.$store.commit("enquiryStep", "need-help-form");
    },
    showEmailForm() {
      this.$store.commit("setHubspotForm", "emailFormId");
      this.$store.commit("enquiryStep", "email-form");

      // this.showTypeform("https://bmco497112.typeform.com/to/C3fMJ5Ur");
    },
    showContactForm() {
      this.$store.commit("setHubspotForm", "contactFormId");
      this.$store.commit("enquiryStep", "contact-form");
      // this.showTypeform("https://bmco497112.typeform.com/to/egxjtlmU");
    },
    showTypeform(typeformUrl) {
      const urlWithHiddenFields = new URL(typeformUrl);
      urlWithHiddenFields.searchParams.append(
        "product_code",
        this.$store.getters.products[0].code
      );
      urlWithHiddenFields.searchParams.append(
        "product_description",
        this.$store.getters.products[0].description
      );
      urlWithHiddenFields.searchParams.append(
        "mesh_colour",
        this.$store.getters.colours[this.$store.state.meshColour].name
      );
      if (
        this.$store.state.trim !== "No Trim" &&
        this.$store.state.trim !== null
      ) {
        urlWithHiddenFields.searchParams.append(
          "trim_colour",
          this.$store.getters.colours[this.$store.state.trimColour].name
        );
      }

      const reference = typeformEmbed.makePopup(urlWithHiddenFields, {
        mode: "popup",
        autoClose: 3000,
        hideHeaders: true,
        hideFooters: true
      });

      reference.open();
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  color: #707070;
}

.mkb-kit-image-wrapper {
  width: 500px;
  margin: 0 auto;
  border: 1px solid #707070;
  img {
    max-width: 100%;
    height: auto;
  }
}
.mkb-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;

  div {
    position: absolute;
    top: 2;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.mkb-image-wrapper-solar {
  position: relative;
  width: 100%;
  padding-top: 100%;
  div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.mkb-bottom-pad {
  width: 350px;
  border: 1px solid #ffffff;
  margin-right: 60px;
}

.mkb-info-row {
  display: flex;
  margin-bottom: 15px;
}

.mkb-info-row:last-child {
  margin-bottom: 0;
}

.mkb-info-label {
  width: 130px !important;
  color: #707070;
  font-size: 0.75rem;
  margin-right: 15px;
}
.mkb-info-label-length,
.mkb-info-label-colour {
  width: 145px !important;
  color: #707070;
  font-size: 0.75rem;
  margin-right: 15px;
}

.mkb-info-value {
  width: 100%;
  color: #002f6c;
}

.mkb-info-value-code {
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 1;
}

.mkb-info-value-description {
  font-size: 0.75rem;
}
.mkb-standard,
.mkb-customer-copy-button,
.mkb-customer-print-button {
  background-color: #002f6c;
  border: 1px solid #002f6c;
  color: #ffffff;
  text-align: center;
  padding: 15px 24px;
  font-weight: 500;

  path {
    fill: #ffffff;
  }

  &:hover {
    color: #002f6c;
    background-color: transparent;
    path {
      fill: #002f6c;
    }
  }
}
.mkb-non-standard {
  background-color: #ffffff;
  border: 1px solid #002f6c;
  color: #002f6c;
  text-align: center;
  padding: 15px 24px;
  font-weight: 500;

  path {
    fill: #002f6c;
  }

  &:hover {
    color: #ffffff;
    background-color: #002f6c;
    path {
      fill: #ffffff;
    }
  }
}

.mkb-customer-print-button {
  background-color: transparent;
  color: #002f6c;

  path {
    fill: #002f6c;
  }

  &:hover {
    color: #ffffff;
    background-color: #002f6c;
    path {
      fill: #ffffff;
    }
  }
}

.mkb-button-subtext {
  font-size: 0.85rem;
}

.mkb-disclaimer-text {
  font-size: 0.55rem;
  color: #707070;
  width: 100%;
}

.mkb-restart-link {
  color: #758892;
  &.mkb-m10-link-2 {
    path.mkb-st1 {
      fill: none !important;
    }
  }
  path {
    fill: #758892 !important;
  }
  font-weight: 500;

  &:hover {
    color: #002f6c;
    path {
      fill: #002f6c !important;
    }
  }
}
.mkb-m10-link {
  background-color: #ffffff;
  border: 1px solid #002f6c;
  color: #002f6c;
  text-align: center;
  padding: 15px 24px;
  font-weight: 500;
  border: 1px solid #002f6c;
  font-weight: 500;
  path.mkb-st1 {
    fill: none !important;
  }
  &:hover {
    color: #fff;
  }
  div.mkb-m10-text {
    width: 75%;
    font-size: 14px;
  }
}
@media (max-width: 1200px) and (min-width: 770px) {
  .mkb-info-value-code {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
  }
}
p {
  @media (max-width: 767.98px) {
    margin-bottom: 12px;
    padding: 8px 0px;
  }
  @media (width: 820px) {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .mkb-info-label-length,
  .mkb-info-label-colour {
    margin-bottom: 15px;
  }

  .mkb-kit-image-wrapper {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    img {
      max-width: 50%;
      height: auto;
    }
  }

  .mkb-info-label {
    display: none;
    margin: 0;
  }

  .mkb-product-information {
    background-color: #ffffff !important;
    padding: 0 !important;
  }

  .mkb-info-value-description {
    font-size: 0.8rem;
  }

  .mkb-copy-buttons {
    width: 100%;
  }

  .mkb-restart-link {
    font-size: 1.3rem;
    padding: 10px 0px 40px 0px;
  }
  .mkb-m10-link {
    font-size: 1.3rem;
    padding: 10px 0px 40px 0px;
    div.mkb-m10-text {
      width: 50%;
    }
  }

  .mkb-disclaimer-text {
    font-size: 0.7rem;
    margin-right: 0;
  }
  .mkb-length-rows,
  .mkb-colour-rows {
    display: flex;
    .mkb-info-row {
      margin-bottom: 0;
      width: 50%;
      display: block;

      .mkb-info-label {
        font-size: 0.95rem;
      }
    }
  }
  .mkb-length-rows,
  .mkb-colour-rows .mkb-info-value {
    display: flex;
    font-size: 15px !important;
    font-weight: 500;
    // justify-content: center;
  }
}
.mkb-product-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  @media (width: 820px) {
    margin-top: 50px;
  }
}
</style>
