import CorrugatedImage from "../assets/img/roof-types/roof-corrugated.svg";
import TileImage from "../assets/img/roof-types/roof-tile.svg";
import TrimdekImage from "../assets/img/roof-types/roof-trimdek.svg";
import SteelCladImage from "../assets/img/roof-types/roof-trimdek.svg";
import KlipLokImage from "../assets/img/roof-types/roof-kliplok.svg";
import LokdekImage from "../assets/img/roof-types/roof-kliplok.svg";
import LongspanImage from "../assets/img/roof-types/roof-longspan.svg";
import SpandekImage from "../assets/img/roof-types/roof-spandek.svg";
import SteelspanImage from "../assets/img/roof-types/roof-spandek.svg";
import DecramasticImage from "../assets/img/roof-types/roof-decramastic.svg";
import BullnoseImage from "../assets/img/roof-types/roof-bullnose.svg";
import SteelineHiDekImage from "../assets/img/roof-types/roof-steeline-hi-dek.svg";
import SteelRib500 from "../assets/img/roof-types/roof-steel-rib-500.svg";
import SteelRib675 from "../assets/img/roof-types/roof-steel-rib-675.svg";

import CGICorrugatedImage from "../assets/img/roof-types/roof-cgi-corrugated.svg";
import CooldekCorrugatedImage from "../assets/img/roof-types/roof-cooldek-corrugated.svg";
import SuperdekImage from "../assets/img/roof-types/roof-superdek.svg";
import CooldekClassicImage from "../assets/img/roof-types/roof-cooldek-classic.svg";
import SpacedekImage from "../assets/img/roof-types/roof-spacedek.svg";
import SmartspanImage from "../assets/img/roof-types/roof-smartspan.svg";
import ProdekImage from "../assets/img/roof-types/roof-prodek.svg";
import Topdek700Image from "../assets/img/roof-types/roof-topdek700.svg";
import Maximus22Image from "../assets/img/roof-types/roof-maximus22.svg";
import Maximus33Image from "../assets/img/roof-types/roof-maximus33.svg";

const roofTypes = {
  Corrugated: {
    image: CorrugatedImage
  },
  Tile: {
    image: TileImage
  },
  Trimdek: {
    image: TrimdekImage,
    rMark: true
  },
  SteelClad: {
    image: SteelCladImage
  },
  "Klip-Lok": {
    image: KlipLokImage,
    rMark: true
  },
  "Lokdek 680/700": {
    image: LokdekImage
  },
  Spandek: {
    image: SpandekImage,
    rMark: true
  },
  Steelspan: {
    image: SteelspanImage
  },
  Longspan: {
    image: LongspanImage,
    rMark: true
  },
  Decramastic: {
    image: DecramasticImage
  },
  Bullnose: {
    image: BullnoseImage
  },
  "Ember Guard": {
    image: CorrugatedImage
  },
  "Steeline Hi Dek": {
    image: SteelineHiDekImage
  },
  "Steel Rib 500": {
    image: SteelRib500
  },
  "Steel Rib 675": {
    image: SteelRib675
  },
  "CGI Corrugated": {
    image: CGICorrugatedImage
  },
  "Cooldek® Corrugated": {
    image: CooldekCorrugatedImage
  },
  Superdek: {
    image: SuperdekImage
  },
  "Cooldek® Classic": {
    image: CooldekClassicImage
  },
  "Spacedek®": {
    image: SpacedekImage
  },
  Smartspan: {
    image: SmartspanImage
  },
  Prodek: {
    image: ProdekImage
  },
  "Topdek® 700": {
    image: Topdek700Image
  },
  "Maximus 22": {
    image: Maximus22Image
  },
  "Maximus 33": {
    image: Maximus33Image
  }
};

export default roofTypes;
